import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import ComplexTable from "views/admin/forms/components/ComplexTable";
import {
  columnsDataComplex,
} from "views/admin/forms/variables/columnsData";

import { API_SERVER } from "config/constant";

export default function Forms() {
  const programIdStorage = localStorage.getItem('programId');
  const [programIdFilter, setProgramIdFilter] = useState(programIdStorage);

  const INITIAL_FILTERS = {
    title: '',
    date: '',
  }

  const [loading, setLoading] = useState(false);
  const [loadForms, setLoadForms] = useState(false);
  const [refreshForms, setRefreshForms] = useState(false);

  const [filters, setFilters] = useState(INITIAL_FILTERS);
  
  const [forms, setForms] = useState([]);
  const [dataForms, setDataForms] = useState({
    total: 0,
  });

  useEffect(() => { 
    window.scrollTo(0, 0);

    const getForms = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/forms/${programIdStorage}`, {
        params: {
          title: filters.title,
          date: filters.date,
          programId: programIdStorage,
        }
      });

      const { forms } = data;

      setDataForms({
        total: forms?.length
      })
      setForms(forms);
      setRefreshForms(false);
      setLoading(false);
    }

    if (programIdFilter > 0) {
      getForms();
    } else {
      setDataForms({
        total: 0
      })
      setForms([]);
    }
  }, [refreshForms, programIdFilter])

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    
    setFilters({ ...filters, [name]: ev.target.value });
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getForms = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/forms/2`, {
        params: {
          title: filters.title,
          date: filters.date,
          programId: programIdStorage,
        }
      });

      const { forms } = data;

      setDataForms({
        total: forms?.length,
      })
      setForms(forms);
      setLoadForms(false);
    }

    setLoadForms(true);
    getForms();
  }

  const handleChangeProgramId = (ev) => {
    // dispatch(setCurrentPage(1));
    // dispatch(setFilters(INITIAL_FILTERS));
    
    localStorage.setItem('programId', ev.target.value);

    setProgramIdFilter(ev.target.value);

    window.location.href = '/#/admin/forms';
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <ComplexTable
            programId={programIdStorage}
            onChangeCompanies={setRefreshForms}
            onChangeFilter={handleChangeFilter}
            submitFilter={handleSubmitFilter}
            onChangeProgramId={handleChangeProgramId}
            clearFilters={() => setFilters(INITIAL_FILTERS)}
            filtersData={filters}
            loadUsersByFilter={loadForms}
            columnsData={columnsDataComplex}
            tableData={forms}
            dataCompanies={dataForms}
          />
        </SimpleGrid>
      </Box>
    </>
  );
}
