import React, { useMemo, useState } from "react";
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
} from "@chakra-ui/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../../store/interviews/filtersActions';
import ClipLoader from "react-spinners/ClipLoader";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import Pagination from "./pagination";

// Assets
import {MdGroups,  MdCheckCircle, MdCancel, MdOutlineWork, MdSchool, MdOutlineChat, MdNoteAlt } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaFemale, FaMale, FaSearchPlus } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { SiGoogleclassroom } from "react-icons/si";

import avatarProfile from 'assets/img/avatars/avatar.png';

export default function ColumnsTable(props) {
  const { 
    columnsData, 
    tableData, 
    dataCourse, 
    programId,
    filtersData,
    loadUsersByFilter,
    onChangeProgramId,
    onChangeFilter, 
    submitFilter,
    clearFilters
  } = props;

  const currentPage = useSelector((state) => state.interviewsCurrentPage);
  const dispatch = useDispatch();

  const [loadingReport, setLoadingReport] = useState(false);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage - 1 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const handleSubmitFilter = (ev) => {
    dispatch(setCurrentPage(1));
    gotoPage(0);

    submitFilter(ev);
  }

  const getGender = (gender) => {
    let userGender = '';

    switch (gender) {
      case 'male':
        userGender = 'Masculino';
        break;

      case 'female':
        userGender = 'Feminino';
        break;
    
      default:
        break;
    }

    return userGender;
  }

  const getProgram = (programId) => {
    let programName = '';

    if (programId == 1) {
      programName = '#GoDev 2022';
    }

    if (programId == 2) {
      programName = 'SharpCoders';
    }

    return programName;
  }

  function exportXLSX() {    
    if (loadingReport) { 
      return false;
    }

    setLoadingReport(true);

    const arrayOfArrays = tableData?.map(obj => [
      obj.name?.toUpperCase(),
      getProgram(obj.program_name),
      obj.presentation_note,
      obj.communication_note,
      obj.academic_professional_note,
      obj.interviewer_name,
      obj.date,
    ]);
    arrayOfArrays.unshift(['Nome', 'Programa', 'Nota Apresentação', 'Nota Comunicação', 'Nota Histórico Profissional/Acadêmico', 'Entrevistador', 'Data da Entrevista']);

    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Entrevistas');
    const fileName = 'entrevistas.xlsx';
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);

    setLoadingReport(false);
  }

  return (
    <>
      <Flex px='0px' justify='space-between' mt='10px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='0px'>
          <Select 
            name="programId"
            onChange={onChangeProgramId}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            mb='0px'
            fontWeight='500'
            size='lg'
            placeholder="Selecione um programa" 
            bg={'white'}
          >
            <option value="3" selected={programId == 3 ? true : false}>Todas Tech</option>
            <option value="2" selected={programId == 2 ? true : false}>SharpCoders</option>
            <option value="1" selected={programId == 1 ? true : false}>GoDev 2022</option>
          </Select>
        </SimpleGrid>
      </Flex>

      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 4, lg: 4, "2xl": 4 }}
          gap='20px'
          mb='0px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdOutlineChat} color={'#1f2471'} />
                }
              />
            }
            name='Total'
            value={dataCourse?.total}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdNoteAlt} color={'#666666'} />
                }
              />
            }
            name='Média apresentação'
            value={dataCourse?.mediaPresentation?.toFixed(2)}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdNoteAlt} color={'#666666'} />
                }
              />
            }
            name='Média comunicação'
            value={dataCourse?.mediaCommunication?.toFixed(2)}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdNoteAlt} color={'#666666'} />
                }
              />
            }
            name='Média histórico'
            value={dataCourse?.mediaAcademic?.toFixed(2)}
          />
        </SimpleGrid>
      </Box>
    </Card>

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <form onSubmit={handleSubmitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 5, xl: 5 }} gap='20px' mb='20px'>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input 
              name="name" 
              value={filtersData.name || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input 
              name="email"
              value={filtersData.email || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Nota Apresentação</FormLabel>
            <Select
              name="presentation_note"
              value={filtersData.presentation_note || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Nota Comunicação</FormLabel>
            <Select
              name="communication_note"
              value={filtersData.communication_note || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Nota Histórico</FormLabel>
            <Select
              name="academic_professional_note"
              value={filtersData.academic_professional_note || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </FormControl>

          <FormControl>
            <ButtonGroup gap='2'>
              <Button 
                type="submit"
                onClick={handleSubmitFilter} 
                colorScheme='brandScheme'
              >
              {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
      </Flex>
      </form>
    </Card>

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Entrevistas
        </Text>

        <Flex align='center'>
          <Button 
            onClick={exportXLSX}
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Exportar
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        {!loadUsersByFilter &&
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "PICTURE") {
                    data = (
                      <img 
                        style={{"borderRadius": "100px", "width": "40px", "height": "40px"}}
                        src={cell?.value != null && cell?.value != '' ? cell.value : avatarProfile} 
                        width="40" 
                        height="40"
                        alt="Picture" 
                      />
                    );
                  } else if (cell.column.Header === "NAME") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "PROGRAM") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value == 1 ? '#GoDev 2022' : 'SharpCoders'}
                      </Text>
                    );
                  } else if (cell.column.Header === "TYPE") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        Soft Skills
                      </Text>
                    );
                  } else if (cell.column.Header === "INTERVIEWER_NAME") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } 
                  
                  // else if (cell.column.Header === "PRESENTATION_NOTE") {
                  //   data = (
                  //     <Flex align='center' style={{"justifyContent": "center"}}>
                  //       <Text color={textColor} fontSize='sm' fontWeight='700'>
                  //         {cell.value}
                  //       </Text>
                  //     </Flex>
                  //   );
                  // } else if (cell.column.Header === "COMMUNICATION_NOTE") {
                  //   data = (
                  //     <Flex align='center' style={{"justifyContent": "center"}}>
                  //       <Text color={textColor} fontSize='sm' fontWeight='700'>
                  //         {cell.value}
                  //       </Text>
                  //     </Flex>
                  //   );
                  // } else if (cell.column.Header === "ACADEMIC_NOTE") {
                  //   data = (
                  //     <Flex align='center' style={{"justifyContent": "center"}}>
                  //       <Text color={textColor} fontSize='sm' fontWeight='700'>
                  //         {cell.value}
                  //       </Text>
                  //     </Flex>
                  //   );
                  // } 
                  
                  else if (cell.column.Header === "DATE") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Flex align='center'>
                          <ButtonGroup gap='4'>
                            <Link
                              color={'brandScheme'}
                              fontWeight='500'
                              me={{ base: "34px", md: "44px" }}
                              href={`/#/admin/entrevista/detalhes/${cell.value}/${programId}`}>
                              <Button colorScheme='brandScheme'><FaSearchPlus /></Button>
                            </Link>
                          </ButtonGroup>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>}
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (dataCourse?.total > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            currentPage={initialState.pageIndex + 1}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>
    </>
  );
}
