import { createStore, combineReducers } from 'redux';
import filtersReducer from './students/filtersReducer';
import currentPageReducer from './students/currentPageReducer';
import interviewsCurrentPageReducer from './interviews/currentPageReducer';
import interviewsFiltersReducer from './interviews/filtersReducer';
import dataReducer from './students/dataReducer';

const rootReducer = combineReducers({
  filters: filtersReducer,
  currentPage: currentPageReducer,
  data: dataReducer,
  interviewsCurrentPage: interviewsCurrentPageReducer,
  interviewsFilters: interviewsFiltersReducer,
});

const store = createStore(rootReducer);

export default store;
