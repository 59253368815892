import React, { useState } from "react";
import { Avatar, Box, Flex, Text, useColorModeValue, Link, Button, Input } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { FaCheck, FaEdit, FaGithub, FaLink, FaLinkedin } from "react-icons/fa";
import { BsPersonBadge } from "react-icons/bs";
import { TbIdBadge, TbIdBadge2 } from "react-icons/tb";
import { MdOutlineBadge, MdSchool } from "react-icons/md";
import { IoSchoolOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";

import photoProfileFranCoimbra from "../../../../assets/img/users/fran-coimbra.jpeg";

export default function Banner({ banner, avatar, name, interviewTitle = '', userId = 0, age = 0, city = '', uf = '', programId = 0, isInterviewer = false }) {
  // const { banner, avatar, name, job } = props;
  const [nameBefore, setNameBefore] = useState(name);
  
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={nameBefore == 'Fran Coimbra' ? photoProfileFranCoimbra : avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      /> 
      <Text style={{"textTransform": "capitalize"}} color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {nameBefore}
      </Text>
      
      {interviewTitle ? 
        <Text color={textColorPrimary} fontWeight='normal' fontSize='md' mt='2px'>
          <b>Avaliação</b>: {interviewTitle}
        </Text>
      : null}

      {/* {!isInterviewer ? 
        <a href={`/#/admin/aluno/${userId}/${programId}`} target="_blank">
          <Flex w='100%' justify='center' mt='10px'>
              <Text 
                style={{
                  "display": "flex",
                  "alignItems": "center",
                  "justifyContent": "center",
                  "fontWeight": "bold",
                  "backgroundColor": "#2759A5",
                  "padding": "5px 10px",
                  "borderRadius": "100px",
                  "color": "#fff",
                  "fontSize": "13px"
                }} 
                color={textColorSecondary}  
                fontSize='sm'
              >
                <FaLink size={12} style={{"marginRight": "5px"}} />
                Ver perfil
              </Text> 
          </Flex>
        </a>
      : null} */}
      
      {/* <Flex w='100%' justify='center' mt='15px'>
        {isInterviewer ? 
          <Text 
            style={{
              "display": "flex",
              "alignItems": "center",
              "justifyContent": "center",
              "fontWeight": "bold",
              "backgroundColor": "#ce1151",
              "padding": "5px 10px",
              "borderRadius": "100px",
              "color": "#fff",
              "fontSize": "13px"
            }} 
            color={textColorSecondary}  
            fontSize='sm'
          >
            <TbIdBadge2 size={15} style={{"marginRight": "5px"}} />
            Entrevistador
          </Text> 
        : null}
      </Flex> */}
    </Card>
  );
}
