import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Progress,
  Text,
  useColorModeValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

import avatarProfile from 'assets/img/avatars/avatar.png';

import { API_SERVER } from "config/constant";
import { FaPlusCircle, FaSearch, FaTrash, FaUsers } from "react-icons/fa";

export default function ModalAddStudentsGroups({ programId, classLeaderUserId, classLeaderName, modalIsOpen, setModalIsOpen, setRefreshClassLeaders, refreshClassLeaders }) {
  const [loading, setLoading] = useState(false);
  const [refreshStudentsGroups, setRefreshStudentsGroups] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [statusSearch, setStatusSearch] = useState(false);
  const [loadingAddLeader, setLoadingAddLeader] = useState(false);
  const [formModalError, setFormModalError] = useState(false);
  const [messageAddClassLeader, setMessageAddClassLeader] = useState(false);
  const [messageRemoveClassLeader, setMessageRemoveClassLeader] = useState(false);
  const [studentsToGroup, setStudentsToGroup] = useState([]);
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);
  const [userSelected, setUserSelected] = useState(0);
  const [search, setSearch] = useState('');
  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    const getStudentsByClassLeaderId = async (classLeaderUserId) => {
      try {   
        if (loading) {
          return false;
        }

        setLoading(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/classGroupsByClassLeaderId/${classLeaderUserId}`);
        const { studentsToGroup } = data;

        setStudentsToGroup(studentsToGroup);
        setRefreshStudentsGroups(false);
        setLoading(false);
      } catch (error) {
        console.log(error);
  
        setLoading(false);
      }
    }

    getStudentsByClassLeaderId(classLeaderUserId);
  }, [refreshStudentsGroups]);

  const handleSearchByName = async (ev) => {
    ev.preventDefault();

    if (search?.length > 0) {
      try {      
        if (loadingSearch) {
          return false;
        }

        setMessageAddClassLeader(false);
        setLoadingSearch(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/userClassGroupsByName/${programId}/${search}`);
        const { users } = data;

        setLeaders(users);
        users?.length == 0 ? setStatusSearch(true) : setStatusSearch(false);
        setLoadingSearch(false);
      } catch (error) {
        console.log(error);

        setLoading(false);
        setStatusSearch(false);
      }
    } else {
      setStatusSearch(false);
    }
	};

  const addStudentToGroup = async (userId, classLeaderUserId) => {
    try {    
      setUserSelected(userId);

      if (loadingAddLeader) {
        return false;
      }

      setLoadingAddLeader(true);

      await axios.post(`${API_SERVER}dashboard/studentsClassGroups`, {
        userId: userId,
        classLeaderUserId: classLeaderUserId,
        programId: programId,
      });

      setRefreshStudentsGroups(true);

      setLeaders([]);
      setSearch('');
      setLoadingSearch(false);
      setMessageAddClassLeader(true);
      setMessageRemoveClassLeader(false);
      setLoadingAddLeader(false);
      setRefreshClassLeaders(!refreshClassLeaders);
    } catch (error) {
      console.log(error);

      setMessageAddClassLeader(false);
      setMessageRemoveClassLeader(false);
      setRefreshStudentsGroups(true);
      setLoadingAddLeader(false);
    }
  }

  const removeStudentToGroup = async (userId, classLeaderUserId) => {
    try {    
      setUserSelected(userId);

      if (loadingRemoveUser) {
        return false;
      }

      setLoadingRemoveUser(true);

      await axios.delete(`${API_SERVER}dashboard/studentsClassGroups`, { data: {
        userId: userId,
        classLeaderUserId: classLeaderUserId,
        programId: programId,
      }});

      setRefreshStudentsGroups(true);

      setLeaders([]);
      setSearch('');
      setLoadingSearch(false);
      setMessageRemoveClassLeader(true);
      setMessageAddClassLeader(false);
      setLoadingRemoveUser(false);
      setLoadingAddLeader(false);
      setRefreshClassLeaders(!refreshClassLeaders);
    } catch (error) {
      console.log(error);

      setMessageRemoveClassLeader(false);
      setMessageAddClassLeader(false);
      setRefreshStudentsGroups(true);
      setLoadingRemoveUser(false);
      setLoadingAddLeader(false);
    }
  }

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
        <form onSubmit={handleSearchByName}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar aluno ao grupo</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {!loadingSearch && messageAddClassLeader && <Text mb='15px' color='green.600'>Aluno adicionado com sucesso!</Text>}
            {!loadingSearch && messageRemoveClassLeader && <Text mb='15px' color='green.600'>Aluno removido com sucesso!</Text>}

            <SimpleGrid columns='2' gap='20px' mb='20px'>
              <FormControl>
                <Input 
                  type="text"
                  name="name"
                  value={search || ''}
                  style={{"minWidth": "330px"}}
                  placeholder="Digite o nome do aluno"
                  onChange={(ev) => setSearch(ev.target.value)}
                  required={true}
                />
              </FormControl>
              <FormControl 
                style={{
                  "display": "flex",
                  "justifyContent": "flex-end"
                }}>
                <Button 
                  type="button"
                  onClick={handleSearchByName}
                  colorScheme='blue' mr={3}
                  disabled={!search || loadingSearch}
                >
                  <FaSearch />
                </Button>
              </FormControl>
            </SimpleGrid>

            {/* <br /> */}
            {/* <hr style={{"margin": "20px 0px 15px 0px"}} /> */}
            {/* <br /> */}

            {!loadingSearch ?
              <>
              <Text
                style={{
                  "fontSize": "17px",
                  "fontWeight": "600",
                  "marginLeft": "8px",
                  "marginTop": "15px",
                  "marginBottom": "0px",
                }}
              >
                Resultados da busca
              </Text>
              <hr style={{"margin": "10px 0px 15px 0px"}} />
              {leaders?.length == 0 && <Text style={{"marginLeft": "8px", "marginBottom": "15px"}}>Nenhum resultado encontrado.</Text>}
              </>
            : null}

            {loadingSearch || loading ?
              <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
                <SimpleGrid
                  mt='15px'
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                    <Flex mx='auto' mt='10px' align='center' direction='column'>
                      <ClipLoader 
                        color={'#2759a5'} 
                        loading={loadingSearch || loading} 
                        size={60} 
                      />
                    </Flex>
                  </SimpleGrid>
              </Box>
            : null}
            
            {!loadingSearch && leaders?.map(({ user_id, picture, name, is_group, is_leader }) =>
                <div 
                  style={{"display": "flex",
                    "flexDirection": "row",
                    "justifyContent": "space-between",
                    "alignItems": "center",
                    "marginTop": "10px",
                    "marginBottom": "15px",
                    "padding": "10px 15px",
                    "border": "1px solid #2759A5",
                    "borderRadius": "100px",
                  }}
                >
                  <img 
                    width={32}
                    src={picture ? picture : avatarProfile} 
                    style={{
                      "borderRadius": "100%"
                    }}
                    alt="" 
                  />
                  <Text
                    style={{"color": "#2759A5",
                      "fontWeight": "600"
                    }}
                  >
                    <a href={`/#/admin/aluno/${user_id}/${programId}`} target="_blank">{name}</a>
                  </Text>
                  {!is_leader && !is_group ? (
                    loadingAddLeader && userSelected === user_id ? 
                      <ClipLoader 
                        color={'#2759a5'} 
                        loading={loadingAddLeader} 
                        size={20} 
                      />
                    : 
                    <Link 
                      onClick={() => addStudentToGroup(user_id, classLeaderUserId)}
                    >
                    <FaPlusCircle 
                      color='#009000' 
                      size={20}
                    />
                  </Link>
                  )
                  : <FaUsers 
                      color='#2759A5' 
                      size={20}
                    />
                  }
                </div>
            )}

            {!loadingSearch && statusSearch && search?.length > 0 && leaders?.length == 0 ?
              <p style={{"marginLeft": "8px"}}>Nenhum resultado encontrado.</p>
            : null}


            {!loading &&
              <>
              <br />
              <Text
                style={{
                  "fontSize": "17px",
                  "fontWeight": "600",
                  "marginLeft": "8px",
                  "marginBottom": "10px"
                }}
              >
                Alunos do grupo
              </Text>
              <hr style={{"marginBottom": "15px"}} />
              {studentsToGroup?.length == 0 && <Text style={{"marginLeft": "8px"}}>Nenhum aluno vinculado ao grupo ainda</Text>}
              </>
            }
            
            {!loading && studentsToGroup?.map(({ user_id, picture, name }) =>
                <div 
                  style={{
                    "display": "flex",
                    "flexDirection": "row",
                    "justifyContent": "space-between",
                    "alignItems": "center",
                    "marginTop": "10px",
                    "padding": "10px 15px",
                    "backgroundColor": "#2759A5",
                    "border": "1px solid #2759A5",
                    "borderRadius": "100px",
                  }}
                >
                  <img 
                    width={32}
                    src={picture ? picture : avatarProfile} 
                    style={{
                      "borderRadius": "100%"
                    }}
                    alt="" 
                  />
                  <Text
                    style={{
                      "color": "#ffffff",
                      "fontWeight": "600",
                    }}
                  >
                    <a href={`/#/admin/aluno/${user_id}/${programId}`} target="_blank">{name}</a>
                  </Text>
                    {loadingRemoveUser && userSelected === user_id ? 
                      <ClipLoader 
                        color={'#ffffff'} 
                        loading={loadingRemoveUser} 
                        size={20} 
                      />
                    : 
                    <Link 
                      onClick={() => removeStudentToGroup(user_id, classLeaderUserId)}
                    >
                    <FaTrash
                      color='#ce1151' 
                      size={20}
                    />
                  </Link>
                  }
                </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => setModalIsOpen(false)}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
        </form>
      </Modal>
    )
}