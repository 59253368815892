export function setFilters(filters) {
    return {
        type: 'SET_FILTERS_INTERVIEWS',
        payload: filters,
    };
}

export function clearFilters() {
    return {
        type: 'CLEAR_FILTERS_INTERVIEWS',
    };
}
  
export const setCurrentPage = (page) => ({
    type: 'SET_CURRENT_PAGE_INTERVIEWS',
    payload: page,
});
  