import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
} from "@chakra-ui/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ClipLoader from "react-spinners/ClipLoader";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import ModalAddCompany from "./ModalAddCompany";

// Assets
import {MdGroups,  MdCheckCircle, MdCancel, MdOutlineWork, MdSchool } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaBuilding, FaEdit, FaPlus, FaPlusCircle, FaSearchPlus, FaUniversity, FaUserAlt, FaUsers } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { BsBuilding } from "react-icons/bs";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbBuildingStore } from "react-icons/tb";

import avatarProfile from 'assets/img/avatars/avatar.png';
import ModalConfirm from "./ModalConfirm";
import { AiFillCloseCircle } from "react-icons/ai";
import ModalEditCompany from "./ModalEditCompany";
import Pagination from "components/pagination";

export default function ColumnsTable(props) {
  const { 
    programId,
    onChangeCompanies,
    columnsData, 
    tableData, 
    dataCompanies, 
    filtersData,
    loadUsersByFilter,
    onChangeFilter, 
    submitFilter,
    clearFilters
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    pageOptions,
    // page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

  const [companyIdEditSelected, setCompanyIdEditSelected] = useState(0);
  const [companyIdSelected, setCompanyIdSelected] = useState(0);
  const [companyPrograms, setCompanyPrograms] = useState(false);

  useEffect(() => {
    if (!modalDeleteIsOpen) {
      setCompanyPrograms(false);
    }
  }, [modalDeleteIsOpen])

  const isOpenModalEdit = (companyId) => {
    setCompanyIdEditSelected(companyId);
    setModalEditIsOpen(true);
  }

  const isOpenModalDelete = (companyId, isCompanyProgram) => {
    isCompanyProgram > 0 ? setCompanyPrograms(true) : setCompanyPrograms(false);
    setCompanyIdSelected(companyId);
    setModalDeleteIsOpen(true);
  }

  const getType = (type) => {
    let typeSchool = 'N/A';

    switch (type) {
      case 'school':
        typeSchool = 'Escola';
        break;

      case 'university':
        typeSchool = 'Universidade';
      break;

      case 'course':
        typeSchool = 'Curso Preparatório';
      break;

      case 'others':
        typeSchool = 'Outros';
      break;
    
      default:
        break;
    }

    return typeSchool;
  }

  const getPlan = (type) => {
    let typePlan = 'N/A';

    switch (type) {
      case 'trial':
        typePlan = 'Trial';
        break;

      case 'starter':
        typePlan = 'Starter';
      break;

      case 'pro':
        typePlan = 'Pro';
      break;

      case 'custom':
        typePlan = 'Custom';
      break;
    
      default:
        break;
    }

    return typePlan;
  }

  const formatDate = (date) => {
    let objectDate = new Date(date);

    let day = objectDate.getDate() <= 9 ? `0${objectDate.getDate()}` : objectDate.getDate();
    let month = objectDate.getMonth() + 1 <= 9 ? `0${objectDate.getMonth() + 1}` : objectDate.getMonth();
    let year = objectDate.getFullYear();

    return `${day}/${month}/${year}`;
  }

  return (
    <>
      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 4, lg: 4, "2xl": 5 }}
          gap='20px'
          mb='0px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaUniversity} color={brandColor} />
                }
              />
            }
            name='Total'
            value={dataCompanies?.total}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaUsers} color={brandColor} />
                }
              />
            }
            name='Alunos'
            value={dataCompanies?.totalStudents}
          />
        </SimpleGrid>
      </Box>
    </Card>

    <Card
      display='none'
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <form onSubmit={submitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 5, xl: 5 }} gap='20px' mb='20px'>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input 
              name="name" 
              value={filtersData.name || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>CNPJ</FormLabel>
            <Input 
              name="cnpj"
              value={filtersData.cnpj || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Porte</FormLabel>
            <Select 
              value={filtersData.size}
              name="size"
              onChange={onChangeFilter}
              fontSize='sm'
              fontWeight='500'
              placeholder="Selecione" 
              bg={'white'}
            >
              <option value="small">Pequeno</option>
              <option value="medium">Médio</option>
              <option value="large">Grande</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Cidade</FormLabel>
            <Input 
              name="city"
              value={filtersData.city || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>UF</FormLabel>
            <Select 
              value={filtersData.uf}
              name="uf"
              onChange={onChangeFilter}
              fontSize='sm'
              fontWeight='500'
              placeholder="Selecione" 
              bg={'white'}
            >
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
            </Select>
          </FormControl>

          <FormControl>
            {/* <FormLabel style={{"color": "transparent"}}>.</FormLabel> */}
            <ButtonGroup gap='2'>
              <Button 
                type="submit"
                onClick={submitFilter} 
                colorScheme='brandScheme'
              >
               {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
      </Flex>
      </form>
    </Card>

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Instituições de Ensino
        </Text>

        <Flex align='center'>
        <Button 
            onClick={() => setModalIsOpen(true)}
            me={5}
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
          >
            <Icon as={FaPlusCircle} h='16px' w='16px' me='8px' />
            <Text 
              fontSize='sm' 
              fontWeight='400'
            >
              Adicionar
            </Text>
          </Button>
          {/* <Button 
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Exportar
            </Text>
          </Button> */}
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        {!loadUsersByFilter &&
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "LOGO") {
                    data = (
                      <img 
                        style={{"borderRadius": "100px"}}
                        src={cell?.value != null && cell?.value != '' ? cell.value : avatarProfile} 
                        width="40" 
                        alt="Picture" 
                      />
                    );
                  } else if (cell.column.Header === "NAME") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "TYPE") {
                    data = (
                      <Text 
                        color={textColor} fontSize='sm' fontWeight='700'>
                        {getType(cell.value)}
                      </Text>
                    );
                  } else if (cell.column.Header === "PLAN") {
                    data = (
                      <Text 
                        color={textColor} fontSize='sm' fontWeight='700'>
                        {getPlan(cell.value)}
                      </Text>
                    );
                  } else if (cell.column.Header === "CITY") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "UF") {
                    data = (
                      <Text 
                        style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "QTD_STUDENTS") {
                    data = (
                      <Text 
                        style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        108
                      </Text>
                    );
                  } else if (cell.column.Header === "CREATED_AT") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {formatDate(cell.value)}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    const { qty_programs } = row.cells[index]?.row?.original;
                    data = (
                      <Flex align='center'>
                          <ButtonGroup gap='1'>
                            <Button 
                              onClick={() => isOpenModalEdit(cell.value)}
                              colorScheme='brandScheme'
                            >
                              <FaSearchPlus size={20} />
                            </Button>
                            {/* <Button 
                              onClick={() => isOpenModalDelete(cell.value, qty_programs)}
                              style={{
                                "backgroundColor": "#ce1151", 
                                "color": "#ffffff"
                              }}
                            >
                              <AiFillCloseCircle size={20} />
                            </Button> */}
                          </ButtonGroup>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>}
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (dataCompanies?.total > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>

      <ModalAddCompany
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        refreshCompanies={onChangeCompanies}
      />

      {modalEditIsOpen && companyIdEditSelected &&
        <ModalEditCompany
          companyId={companyIdEditSelected}
          modalIsOpen={modalEditIsOpen}
          setModalIsOpen={setModalEditIsOpen}
          refreshCompanies={onChangeCompanies}
        />
      }

      {modalDeleteIsOpen && companyIdSelected &&
        <ModalConfirm 
          companyId={companyIdSelected} 
          companyProgram={companyPrograms}
          modalIsOpen={modalDeleteIsOpen}
          setModalIsOpen={setModalDeleteIsOpen} 
          refreshCompanies={onChangeCompanies}
        />
      }
    </>
  );
}
