export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "PICTURE",
    label: 'Foto',
    accessor: "picture",
  },
  {
    Header: "NAME",
    label: 'Nome',
    accessor: "name",
  },
  {
    Header: "PROGRAM_NAME",
    label: 'Programa',
    accessor: "program_name",
  },
  {
    Header: "QTY_STUDENTS",
    label: 'Qtd. Alunos',
    accessor: "qty_students",
  },
  {
    Header: "ACTION",
    label: 'Ações',
    accessor: "user_id",
  },
];
