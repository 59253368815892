import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    RadioGroup,
    Stack,
    Radio,
    EditableTextarea,
    Textarea,
    ButtonGroup,
  } from "@chakra-ui/react";

  import { API_SERVER } from "config/constant";
import { ClipLoader } from "react-spinners";

export default function ModalDetailsForm({ formId, userId, modalIsOpen, setModalIsOpen, refreshForms }) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [formFields, setFormFields] = useState([]);

    useEffect(() => {
      const getFormByUserId = async (formId, userId) => {
        try {  
          if (loading) {
            return false;
          }
    
          setLoading(true);
    
          const { data } = await axios.get(`${API_SERVER}dashboard/forms/${formId}/${userId}/details`);

          setFormFields(data?.result);
          setLoading(false);
        } catch (error) {    
          setFormFields([]);
          setLoading(false);
        }
      }

      getFormByUserId(formId, userId);
    }, [])

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            size={'xl'}
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Formulário de presença</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {loading && (
              <Box pt={{ base: "130px", md: "40px", xl: "40px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='0px' align='center' direction='column'>
                    <ClipLoader
                      color={'#2759a5'} 
                      loading={loading} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            )}

            {!loading ? (
            <>
      
          {!loading && formFields?.length > 0 ? (
          <>
          <div 
            style={{
              "marginBottom": "15px",
              "display": "flex",
              "alignItems": "center"
            }}
          >
            <img 
              style={{
                "borderRadius": "100%",
                "marginRight": "10px"
              }}
              src={formFields[0]?.user_picture} 
              width="35" 
              alt="" 
            /> 
            <p>{formFields[0]?.user_name}</p>
          </div>

          <p><b>{formFields[0]?.title}</b></p>
          <p className="mb-5"><b>Data</b>: {formFields[0]?.date}</p>

          {formFields?.map((data, i) => (
            <FormControl mt={4}>
              <FormLabel>{i + 1}. {data?.question}</FormLabel>

              {data?.question_type == 'multiple' && (
                <RadioGroup value={data?.multiple_choice_answer} disabled={true}>
                  <Stack direction='column'>
                    {data?.option_a && (<Radio value={data?.option_a}>{data?.option_a}</Radio>)}
                    {data?.option_b && (<Radio value={data?.option_b}>{data?.option_b}</Radio>)}
                    {data?.option_c && (<Radio value={data?.option_c}>{data?.option_c}</Radio>)}
                    {data?.option_d && (<Radio value={data?.option_d}>{data?.option_d}</Radio>)}
                    {data?.option_e && (<Radio value={data?.option_e}>{data?.option_e}</Radio>)}
                  </Stack>
                </RadioGroup>
              )}

              {data?.question_type == 'open' && <p>{data?.open_answer}</p>}
            </FormControl>
          ))}
          </>
          ): null}

          </>
          ) : 
              null
          }

          </ModalBody>

          <ModalFooter>
            <Button 
              onClick={() => setModalIsOpen(false)}
            >
                Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}