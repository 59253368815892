import React, { useEffect, useState }  from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, clearFilters, setData, setCurrentPage } from '../../../store/students/filtersActions';

import { Box, SimpleGrid, Flex, filter } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import DevelopmentTable from "views/admin/students/components/DevelopmentTable";
import CheckTable from "views/admin/students/components/CheckTable";
import ColumnsTable from "views/admin/students/components/ColumnsTable";
import ComplexTable from "views/admin/students/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/students/variables/columnsData";
import tableDataDevelopment from "views/admin/students/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/students/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/students/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/students/variables/tableDataComplex.json";

import { API_SERVER } from "config/constant";

export default function Students() {
  const hash = window.location.hash;
  const id = hash.split('/alunos/');
  const programIdStorage = localStorage.getItem('programId');
  const programId = id[1] ? id[1] : (programIdStorage ? programIdStorage : 0);

  const INITIAL_FILTERS = {
    name: '',
    email: '',
    gender: 'all',
    classroomId: 'all',
    status: 'all',
    situation: 'all',
    uf: 'all',
    cities: [],
    city: 'all',
    practical_projects: 'all',
    hard_skills: 'all',
  }

  const [loading, setLoading] = useState(false);
  const [loadUsers, setLoadUsers] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);

  const [programIdFilter, setProgramIdFilter] = useState(programId);

  const filters = useSelector(state => state.filters);
  const usersDataRedux = useSelector(state => state.data);
  const dispatch = useDispatch();
  
  const [users, setUsers] = useState([]);
  const [usersCities, setUsersCities] = useState([]);
  const [dataCourse, setDataCourse] = useState({
    total: 0,
    studentsEmFormacao: 0,
    studentsFormados: 0,
    inativos: 0,
    desligados: 0,
    turma1: 0,
    fastTrack: 0,
    contratados: 0,
    genderMale: 0,
    genderFemale: 0,
  });
  const [allHardSkills, setAllHardSkills] = useState('');

  useEffect(() => {     
    const getUsers = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/users/${programId}`, {
        params: {
          name: filters.name,
          email: filters.email,
          gender: filters.gender,
          classroomId: filters.classroomId,
          status: filters.status,
          situation: filters.situation,
          uf: filters.uf,
          city: filters.city,
          practical_projects: filters.practical_projects,
          active: filters.active,
          programId: programIdFilter,
          hard_skills: allHardSkills
        }
      });

      const { users, total, studentsEmFormacao, studentsFormados, inativos, desligados, turma1, fastTrack, contratados, genderMale, genderFemale } = data?.usersData;

      setDataCourse({
        total: total,
        studentsEmFormacao: studentsEmFormacao,
        studentsFormados: studentsFormados,
        inativos: inativos,
        desligados: desligados,
        turma1: turma1,
        fastTrack: fastTrack,
        contratados: contratados,
        genderMale: genderMale,
        genderFemale: genderFemale,
      })

      // const arrData = Object.values(users);
     
      // dispatch(setData(arrData));
      setUsers(users);
      setLoading(false);
    }

    // if (programIdFilter > 0 && Object.values(usersDataRedux)?.length < 2) {
    if (programIdFilter > 0) {
      getUsers();
    }

    dispatch(setFilters(filters));
  }, [programIdFilter])

  const handleChangeProgramId = (ev) => {
    dispatch(setCurrentPage(1));
    dispatch(setFilters(INITIAL_FILTERS));
    
    localStorage.setItem('programId', ev.target.value);

    setProgramIdFilter(ev.target.value);

    window.location.href = '/#/admin/alunos';
  }

  const handleChangeFilter = async (ev) => {
    const name = ev.target.name;
    const value = ev.target.value;

    if (name == 'uf') {
      setLoadingCities(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/usersCityByUf/${programId}/${value}`);

      dispatch(setFilters({ ...filters, uf: value, cities: data.cities }));
      setLoadingCities(false);
    } else {
      dispatch(setFilters({ ...filters, [name]: value }));
    }
  }

  const handleChangeHardSkills = (choice) => {
    const selectedValues = choice.map((option) => `'${option.value}'`).join(",");
    dispatch(setFilters({ ...filters, allHardSkills: selectedValues }));

    // setAllHardSkills(selectedValues);
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getUsers = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/users/${programId}`, {
        params: {
          name: filters.name,
          email: filters.email,
          gender: filters.gender,
          classroomId: filters.classroomId,
          status: filters.status,
          situation: filters.situation,
          uf: filters.uf,
          city: filters.city,
          practical_projects: filters.practical_projects,
          active: filters.active,
          programId: programIdFilter,
          hard_skills: allHardSkills
        }
      });

      const { users, total, studentsEmFormacao, studentsFormados, inativos, desligados, turma1, fastTrack, contratados, genderMale, genderFemale } = data?.usersData;

      setDataCourse({
        total: total,
        studentsEmFormacao: studentsEmFormacao,
        studentsFormados: studentsFormados,
        inativos: inativos,
        desligados: desligados,
        turma1: turma1,
        fastTrack: fastTrack,
        contratados: contratados,
        genderMale: genderMale,
        genderFemale: genderFemale,
      });

      // const arrData = Object.values(users);


      // dispatch(setData(arrData));
      // setUsers(arrData);
      setUsers(users);
      setLoadUsers(false);
    }

    setLoadUsers(true);
    getUsers();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
        <ComplexTable
          programId={programIdFilter}
          onChangeProgramId={handleChangeProgramId}
          onChangeFilter={handleChangeFilter}
          onChangeHardSkills={handleChangeHardSkills}
          submitFilter={handleSubmitFilter}
          clearFilters={() => { dispatch(clearFilters()); setUsersCities([]); }}
          filtersData={filters}
          loadUsersByFilter={loadUsers}
          columnsData={columnsDataComplex}
          tableData={users}
          usersCities={filters.cities}
          loadingCities={loadingCities}
          dataCourse={dataCourse}
          allHardSkills={filters.allHardSkills}
        />
      </SimpleGrid>
    </Box>
  );
}
