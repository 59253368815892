import React, { useMemo, useState } from "react";
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
} from "@chakra-ui/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ClipLoader from "react-spinners/ClipLoader";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import ModalAddClassLeader from "./ModalAddClassLeaders";
import ModalAddStudentsGroups from "./ModalAddStudentsGroups";
import ModalConfirm from "./ModalConfirm";
import Pagination from "components/pagination";

// Assets
import {MdGroups,  MdCheckCircle, MdCancel, MdOutlineWork, MdSchool, MdNoMealsOuline } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaSearchPlus, FaUserAlt, FaUsers, FaPlusCircle } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { AiFillCloseCircle } from "react-icons/ai";

import avatarProfile from 'assets/img/avatars/avatar.png';

export default function ColumnsTable(props) {
  const { 
    programId,
    onChangeProgram,
    columnsData, 
    tableData, 
    dataCourse, 
    filtersData,
    loadUsersByFilter,
    modalIsOpen, 
    setModalIsOpen, 
    setRefreshClassLeaders,
    onChangeFilter, 
    submitFilter,
    clearFilters
  } = props;

  const [modalStudentsGroupsIsOpen, setModalStudentsGroupsIsOpen] = useState(false);
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);
  const [classLeaderUserId, setClassLeaderUserId] = useState('');

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    pageOptions,
    // page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const openModalStudentsGroups = (classLeaderUserId) => {
    setClassLeaderUserId(classLeaderUserId);
    setModalStudentsGroupsIsOpen(true);
  }

  const openModalConfirm = (classLeaderUserId) => {
    setClassLeaderUserId(classLeaderUserId);
    setModalConfirmIsOpen(true);
  }

  return (
    <>
      <Flex px='0px' justify='space-between' mt='10px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='0px'>
          <Select 
            name="programId"
            onChange={onChangeProgram}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            mb='0px'
            fontWeight='500'
            size='lg'
            placeholder="Selecione um programa" 
            bg={'white'}
          >
            <option value="3" selected={programId == 3 ? true : false}>Todas Tech</option>
            <option value="2" selected={programId == 2 ? true : false}>SharpCoders</option>
            <option value="1" selected={programId == 1 ? true : false}>GoDev 2022</option>
          </Select>
        </SimpleGrid>
      </Flex>

      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 3, lg: 3, "2xl": 3 }}
          gap='20px'
          mb='0px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdGroups} color={'#1f2471'} />
                }
              />
            }
            name='Total'
            value={dataCourse?.total}
          />
          {/* <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdOutlineWork} color={brandColor} />
                }
              />
            }
            name='Ramp-up'
            value={dataCourse?.rampUp}
          /> */}
          {/* <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdOutlineWork} color={brandColor} />
                }
              />
            }
            name='Contratados'
            value={dataCourse?.contratados}
          /> */}
        </SimpleGrid>
      </Box>
    </Card>

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <form onSubmit={submitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px' mb='20px'>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input 
              name="name" 
              value={filtersData.name || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input 
              name="email"
              value={filtersData.email || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel style={{"color": "transparent"}}>.</FormLabel>
            <ButtonGroup gap='1'>
              <Button 
                type="submit"
                onClick={submitFilter} 
                colorScheme='brandScheme'
              >
               {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
      </Flex>
      </form>
    </Card>

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Monitores
        </Text>

        <Flex align='center'>
          <Button 
            me={5}
            onClick={() => setModalIsOpen(true)}
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
            disabled={programId > 0 ? false : true}
          >
            <Icon as={FaPlusCircle} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Adicionar
            </Text>
          </Button>
          {/* <Button 
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
            disabled={programId > 0 ? false : true}
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Exportar
            </Text>
          </Button> */}
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        {!loadUsersByFilter &&
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "PICTURE") {
                    data = (
                      <img 
                        style={{"borderRadius": "100px"}}
                        src={cell?.value != null && cell?.value != '' ? cell.value : avatarProfile} 
                        width="40" 
                        alt="Picture" 
                      />
                    );
                  } else if (cell.column.Header === "NAME") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "PROGRAM_NAME") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "QTY_STUDENTS") {
                    data = (
                      <Text style={{"textAlign": "center"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Flex align='center'>
                          <ButtonGroup gap='1'>
                            <Link
                              fontWeight='500'
                              href={`/#/admin/aluno/${cell.value}/${programId}`}
                              target={'_blank'}
                              >
                              <Button style={{"backgroundColor": "#F4F7FE", "color": "#2759A5"}}><FaUserAlt /></Button>
                            </Link>
                            <Link 
                              href={`/#/admin/grupos/${cell.value}/${programId}`}
                            >
                              <Button 
                                // onClick={() => openModalStudentsGroups(cell.value)}
                                colorScheme='brandScheme'
                              >
                                  <FaUsers size={20} />
                              </Button>
                            </Link>
                            <Button 
                                onClick={() => openModalConfirm(cell.value)}
                                style={{
                                  "backgroundColor": "#ce1151", 
                                  "color": "#ffffff"
                                }}
                              >
                                <AiFillCloseCircle size={20} />
                              </Button>
                          </ButtonGroup>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>}
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (dataCourse?.total > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>

    {modalIsOpen && programId ?
      <ModalAddClassLeader
        programId={programId}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        refreshClassLeaders={setRefreshClassLeaders}
      />
    : null}

    {modalStudentsGroupsIsOpen && classLeaderUserId ?
      <ModalAddStudentsGroups
        programId={programId}
        classLeaderUserId={classLeaderUserId}
        modalIsOpen={modalStudentsGroupsIsOpen}
        setModalIsOpen={setModalStudentsGroupsIsOpen}
        refreshClassLeaders={setRefreshClassLeaders}
      />
    : null}

    {modalConfirmIsOpen && classLeaderUserId ?
      <ModalConfirm 
        programId={programId} 
        classLeaderUserId={classLeaderUserId}
        modalIsOpen={modalConfirmIsOpen}
        setModalIsOpen={setModalConfirmIsOpen}
        refreshClassLeaders={setRefreshClassLeaders}
      />
    : null}
    </>
  );
}
