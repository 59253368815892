// Chakra imports
import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Flex, Text, useColorModeValue, 
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel, 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tooltip,
  GridItem,
  SimpleGrid,
  Button} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import Select, { StylesConfig } from 'react-select';
import { useReactToPrint } from 'react-to-print';
import chroma from "chroma-js";
import axios from "axios";

import {
  columnsDataComplex,
} from "./variables/columnsData";

// Custom components
import Banner from "views/admin/aliceAICompanies/components/Banner";
import ComplexTable from "./components/ComplexTable";

import { API_SERVER } from "config/constant";
import Card from "components/card/Card";

export default function AliceAICompanies({ userId, programId }) {
  const componentRef = useRef();
  const [isEditFeedback, setIsEditFeedback] = useState(false);
  const [saveLoadFeedbacks, setSaveLoadFeedbacks] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [presenceTotal, setPresenceTotal] = useState(0);
  const [isEditAll, setIsEditAll] = useState(false);

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  const [companies, setCompanies] = useState([]);
  const [dataCompanies, setDataCompanies] = useState({
    total: 0,
    totalStudents: 0,
  });

  useEffect(() => { 
    const getCompanies = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}interviewai/getSchools`, {
        // params: {
        //   name: filters.name,
        //   cnpj: filters.cnpj,
        //   size: filters.size,
        //   uf: filters.uf,
        //   city: filters.city,
        //   programid_not_in: '',
        // }
      });

      const { schoolsData } = data;

      setDataCompanies({
        total: schoolsData?.length || 0,
        totalStudents: 108
      })
      setCompanies(schoolsData);
      // setRefreshCompanies(false);
      setLoading(false);
    }

    getCompanies();
  }, [])

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mx='auto' mt='80px' align='center' direction='column'>
          <ClipLoader 
            color={'#2759a5'} 
            loading={loading} 
            size={60} 
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box pt={{ base: "130px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <ComplexTable
            programId={3}
            onChangeCompanies={() => {}}
            onChangeFilter={() => {}}
            submitFilter={() => {}}
            clearFilters={() => {}}
            filtersData={[]}
            loadUsersByFilter={false}
            columnsData={columnsDataComplex}
            tableData={companies}
            dataCompanies={dataCompanies}
          />
        </SimpleGrid>
      </Box>
  );
}
