import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    RadioGroup,
    Stack,
    Radio,
    EditableTextarea,
    Textarea,
    ButtonGroup,
  } from "@chakra-ui/react";

  import { API_SERVER } from "config/constant";
import { ClipLoader } from "react-spinners";
import { BsCircle } from "react-icons/bs";

export default function ModalAddCompany({ programId, modalIsOpen, setModalIsOpen, refreshForms }) {
  const [loading, setLoading] = useState(false);
  const [formModalError, setFormModalError] = useState(false);
  const [typeForm, setTypeForm] = useState('');
  const [dateForm, setDateForm] = useState('');
  const [timeLimitForm, setTimeLimitForm] = useState('');
  const [addQuestion, setAddQuestion] = useState('');
  const [form, setForm] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [multipleOptions, setMultipleOptions] = useState([]);

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

    if (name == 'type_question' && value == '') {
      setForm([]);
    } else {
      setForm({...form, [name]: value});
    }
	};

  const handleSubmit = async () => {
    try {    
      const allQuestions = formFields;

      if (dateForm?.length == 0 || timeLimitForm?.length == 0 || (allQuestions?.length == 0 && addQuestion == 1)) {
        throw 'EMPTY_FIELDS';
      }

      if (loading) {
        return false;
      }

      setLoading(true);
      setFormModalError(false);

      if (typeForm != 'company_stacktech') {
        allQuestions.unshift({
          type: 'multiple',
          question_category: 'nps',
          position: 1,
          question: 'De 01 a 05 o quanto você indicaria essa aula para um amigo?',
          options: ['01', '02', '03', '04', '05'] 
        });
    
        allQuestions.push({
          type: 'open',
          question_category: 'feedback',
          position: allQuestions?.length + 1,
          question: 'Caso você tenha alguma observação ou feedback da aula por favor nos envie aqui:'
        });
      } else {
        allQuestions.push({
          type: 'open',
          question_category: 'custom',
          position: 1,
          question: 'Quais linguagens vocês utilizam?'
        });   
        
        allQuestions.push({
          type: 'open',
          question_category: 'custom',
          position: 2,
          question: 'Vocês utilizam algum framework?'
        }); 

        allQuestions.push({
          type: 'open',
          question_category: 'custom',
          position: 3,
          question: 'Vocês trabalham com testes? Se sim, fale um pouco sobre as ferramentas de teste que vocês utilizam.'
        }); 

        allQuestions.push({
          type: 'open',
          question_category: 'custom',
          position: 4,
          question: 'Qual banco de dados vocês utilizam?'
        }); 

        allQuestions.push({
          type: 'open',
          question_category: 'custom',
          position: 5,
          question: 'Onde vocês hospedam suas aplicações?'
        }); 

        allQuestions.push({
          type: 'open',
          question_category: 'custom',
          position: 6,
          question: 'Quais as metodologias de gerenciamento de equipes vocês usam? (Kanban, XP, Lean, etc)'
        }); 

        allQuestions.push({
          type: 'open',
          question_category: 'custom',
          position: 7,
          question: 'Quais habilidades não podem faltar de jeito nenhum para o profissional que vamos te entregar?'
        }); 
      }


      const dataForm = {
        formType: typeForm == 'company_stacktech' ? 'other' : 'presence',
        formSubType: typeForm,
        formTitle: typeForm == 'company_stacktech' ? 'Stack Tecnológico' : 'Lista de Presença Todas Tech',
        questions: allQuestions,
        dateStart: dateForm,
        dateEnd: `${dateForm} ${timeLimitForm}:00`,
        authorUserId: dashboardUserId,
        programId: 3
      }

      const { data } = await axios.post(`${API_SERVER}dashboard/forms`, dataForm);

      setDateForm('');
      setFormFields([]);
      refreshForms(true);
      setModalIsOpen(false);
      setLoading(false);
    } catch (error) {
      // console.log(error);

      setFormModalError(true);
      setLoading(false);
    }
  }

  const [option1Selected, setOption1Selected] = useState('');
  const [option2Selected, setOption2Selected] = useState('');
  const [option3Selected, setOption3Selected] = useState('');
  const [option4Selected, setOption4Selected] = useState('');

  const handleAddField = () => {
    const selectedOptions = []; 

    if (option1Selected) {
      selectedOptions.push(option1Selected);
    }
    if (option2Selected) {
      selectedOptions.push(option2Selected);
    }
    if (option3Selected) {
      selectedOptions.push(option3Selected);
    }
    if (option4Selected) {
      selectedOptions.push(option4Selected);
    }

    setFormFields([...formFields, { 
      type: form?.type_question, 
      question_category: 'custom',
      position: formFields?.length + 2,
      question: form?.title_question,
      options: selectedOptions
    }]);

    setForm([]);
    setOption1Selected('');
    setOption2Selected('');
    setOption3Selected('');
    setOption4Selected('');
  }

  const removePosition = (index) => {
    setFormFields(formFields.filter((item, i) => i !== index))
  }

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            size={'xl'}
        >
        <form onSubmit={handleSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar formulário</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {formModalError && <Text mb='15px' color='red.600'>Preencha todos os campos!</Text>}

            {loading && (
              <Box pt={{ base: "130px", md: "40px", xl: "40px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='0px' align='center' direction='column'>
                    <ClipLoader
                      color={'#2759a5'} 
                      loading={loading} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            )}

          {/* {!loading && (
            <FormControl mb={5}>
              <RadioGroup 
                value={typeForm}
                onChange={setTypeForm}
              >
                <Stack direction='row'>
                  <Radio value='presence'>Formulário de Presença</Radio>
                  <Radio value='other'>Outro</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          )} */}

          {!loading && (
            <FormControl mt={4}>
                <Select
                  name="type_form"
                  value={typeForm}
                  onChange={(ev) => setTypeForm(ev.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="aula_sincrona">Aula Síncrona</option>
                  <option value="livehelp">Live Help</option>
                  <option value="company_stacktech">Stack Tech - Empresas</option>
                </Select>
            </FormControl>
          )}

            {!loading && (typeForm == 'aula_sincrona' || typeForm == 'livehelp' || typeForm == 'company_stacktech') ? (
            <>
            <SimpleGrid columns='2' gap='20px' mt='4' mb='4'>
              <FormControl mt={4}>
                  <FormLabel>Data</FormLabel>
                  <Input 
                    type="date"
                    name="date"
                    value={dateForm || ''}
                    onChange={(ev) => setDateForm(ev.target.value)}
                    required={true}
                  />
              </FormControl>

              <FormControl mt={4}>
                  <FormLabel>Hora limite de preenchimento</FormLabel>
                  <Input 
                    type="time"
                    name="time_limit"
                    value={timeLimitForm || ''}
                    onChange={(ev) => setTimeLimitForm(ev.target.value)}
                    required={true}
                  />
              </FormControl>
            </SimpleGrid>
            
            {(typeForm == 'aula_sincrona' || typeForm == 'livehelp') && (
            <FormControl mt={4}>
                <FormLabel>Adicionar pergunta?</FormLabel>
                <Select
                  name="add_question"
                  value={addQuestion}
                  onChange={(ev) => setAddQuestion(ev.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">Sim</option>
                  <option value="2">Não</option>
                </Select>
            </FormControl>
            )}

            {addQuestion == 1 && (
              <SimpleGrid columns='2' gap='20px' mt='4' mb='4'>
                <FormControl mt={4}>
                  <FormLabel>Título da pergunta</FormLabel>
                  <Input 
                    type="text"
                    name="title_question"
                    value={form?.title_question || ''}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Tipo da pergunta</FormLabel>
                  <Select
                    name="type_question"
                    value={form?.type_question || ''}
                    onChange={handleChange}
                  >
                    <option value="">Selecione</option>
                    <option value="open">Campo aberto</option>
                    <option value="multiple">Múltipla escolha</option>
                  </Select>
                </FormControl>
              </SimpleGrid>
            )}

            {form?.type_question == 'multiple' && (
              <SimpleGrid columns='1' gap='0px' mb='4'>
                <FormLabel>Opções de resposta</FormLabel>
                <FormControl>
                  <Flex align={'center'} justifyContent={'space-between'} mb='10px'>
                    <BsCircle />
                    <Input 
                      type="text"
                      name="option1Selected"
                      value={option1Selected}
                      onChange={(ev) => setOption1Selected(ev.target.value)}
                      placeholder="Opção 1"
                      style={{
                        "width": "94%",
                        "borderTop": "0px",
                        "borderLeft": "0px",
                        "borderRight": "0px",
                        "borderBottom": "1px solid",
                        "borderRadius": "0px",
                      }}
                    />
                  </Flex>
                </FormControl>
                <FormControl>
                  <Flex align={'center'} justifyContent={'space-between'} mb='10px'>
                    <BsCircle />
                    <Input 
                      type="text"
                      name="option2Selected"
                      value={option2Selected}
                      onChange={(ev) => setOption2Selected(ev.target.value)}
                      placeholder="Opção 2"
                      style={{
                        "width": "94%",
                        "borderTop": "0px",
                        "borderLeft": "0px",
                        "borderRight": "0px",
                        "borderBottom": "1px solid",
                        "borderRadius": "0px",
                      }}
                    />
                  </Flex>
                </FormControl>
                <FormControl>
                  <Flex align={'center'} justifyContent={'space-between'} mb='10px'>
                    <BsCircle />
                    <Input 
                      type="text"
                      name="option3Selected"
                      value={option3Selected}
                      onChange={(ev) => setOption3Selected(ev.target.value)}
                      placeholder="Opção 3"
                      style={{
                        "width": "94%",
                        "borderTop": "0px",
                        "borderLeft": "0px",
                        "borderRight": "0px",
                        "borderBottom": "1px solid",
                        "borderRadius": "0px",
                      }}
                    />
                  </Flex>
                </FormControl>
                <FormControl>
                  <Flex align={'center'} justifyContent={'space-between'} mb='10px'>
                    <BsCircle />
                    <Input 
                      type="text"
                      name="option4Selected"
                      value={option4Selected}
                      onChange={(ev) => setOption4Selected(ev.target.value)}
                      placeholder="Opção 4"
                      style={{
                        "width": "94%",
                        "borderTop": "0px",
                        "borderLeft": "0px",
                        "borderRight": "0px",
                        "borderBottom": "1px solid",
                        "borderRadius": "0px",
                      }}
                    />
                  </Flex>
                </FormControl>
              </SimpleGrid>
            )}

           {form?.type_question == 'open' && (
            <Textarea
              mb='4'
              disabled={true}
              placeholder='Texto de resposta longa'
            >
            </Textarea>
           )}

          {!loading && form?.title_question && (form?.type_question == 'open' || form?.type_question == 'multiple') ? (
           <ButtonGroup mb='4'>
              <Button 
                disabled={form?.title_question ? false : true}
                onClick={handleAddField}
                variant={'action'}>Adicionar</Button>
              {/* <Button variant={'no-effects'}>Cancelar</Button> */}
           </ButtonGroup>
          ) : null}


          {!loading && typeForm != 'company_stacktech' && (formFields?.length > 0 || addQuestion == 2) ? (
          <>
          <hr />
          <Text mt='4'>Pré-visualização:</Text>

          <FormControl mt={4}>
            <FormLabel>E-mail:</FormLabel>
            <Input name='email' disabled={true} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>1. De 01 a 05 o quanto você indicaria essa aula para um amigo?</FormLabel>
            <RadioGroup>
                <Stack direction='column'>
                  <Radio value='01'>01</Radio>
                  <Radio value='02'>02</Radio>
                  <Radio value='03'>03</Radio>
                  <Radio value='04'>04</Radio>
                  <Radio value='05'>05</Radio>
                </Stack>
              </RadioGroup>
          </FormControl>

          {formFields?.map((data, i) => (
            <FormControl mt={4}>
              <FormLabel>{i + 2}. {data?.question} 
                <Button 
                  onClick={() => removePosition(i)}
                  style={{
                    "height": "28px",
                    "width": "5px !important",
                    "marginLeft": "5px"
                  }}
                  colorScheme={'red'}
                >
                  X
                </Button>
              </FormLabel>
      
              {data?.type == 'open' && <Textarea 
                placeholder='Texto de resposta longa'
                disabled={true} 
              />}

              {data?.type == 'multiple' && 
                <RadioGroup>
                  <Stack direction='column'>
                  {Object.entries(data?.options).map(([chave, valor]) => (
                    <Radio value={valor}>{valor}</Radio>
                  ))}
                  </Stack>
                </RadioGroup>
              }
            </FormControl>
          ))}

          <FormControl mt={4}>
            <FormLabel>{formFields?.length + 2}. Caso você tenha alguma observação ou feedback da aula por favor nos envie aqui:</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>
          </>
          ): null}

          </>
          ) : 
            // !loading && <p>Em breve!</p>
            <></>
          }

          {!loading && typeForm == 'company_stacktech' ? (
          <>
          <hr />
          <Text mt='4'>Pré-visualização:</Text>

          <FormControl mt={4}>
            <FormLabel>E-mail:</FormLabel>
            <Input name='email' disabled={true} />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>1. Quais linguagens vocês utilizam?</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>2. Vocês utilizam algum framework?</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>3. Vocês trabalham com testes? Se sim, fale um pouco sobre as ferramentas de teste que vocês utilizam.</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>4. Qual banco de dados vocês utilizam?</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>5. Onde vocês hospedam suas aplicações?</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>6. Quais as metodologias de gerenciamento de equipes vocês usam? (Kanban, XP, Lean, etc)</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>7. Quais habilidades não podem faltar de jeito nenhum para o profissional que vamos te entregar?</FormLabel>
            <Textarea 
              placeholder='Texto de resposta longa'
              disabled={true}
            />
          </FormControl>
          </>
          ) : null}

          </ModalBody>

          <ModalFooter>
            <Button 
              type="button"
              onClick={handleSubmit}
              colorScheme='blue' mr={3}
              disabled={dateForm?.length == 0 || timeLimitForm?.length == 0 || typeForm?.length == 0  ? true : false}
            >
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
            <Button 
              onClick={() => setModalIsOpen(false)}
            >
                Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
        </form>
      </Modal>
    )
}