import React, { memo } from 'react';

import MaskedInput from 'react-text-mask';

const InputCnpj = (props) => {
  return (
    <MaskedInput
      {...props}
      guide={false}
      mask={[
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        '.',
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        '.',
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        '/',
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        /[A-z|0-9]/,
        '-',
        /[A-z|0-9]/,
        /[A-z|0-9]/,
      ]}
    />
  );
};

export default memo(InputCnpj);
