// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Image,
  Link
} from "@chakra-ui/react";
import axios from 'axios';
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React, { useEffect, useState } from "react";
import { FaGithub } from "react-icons/fa";
import { TbIdBadge2 } from "react-icons/tb";

export default function StudentProfile(props) {
  const { name, picture, githubUrl, dateTime, boxShadow, badgeMonitor = false, userDropout = false, userId = 0, programId = 0, contributorsData = [] } = props;

  const [userGitHubData, setUserGitHubData] = useState([]);
  const [userNameGitHub, setUserNameGitHub] = useState('');

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  useEffect(() => {   
    const getUserProfileGitHub = (githubUrl) => {
      let githubUsername = githubUrl?.split('github.com/');
      githubUsername = githubUsername[1]?.replace('/', '');

      const filteredUser = Object.values(contributorsData).filter(user => user.name == githubUsername);
      setUserGitHubData(filteredUser[0]?.commits);
      setUserNameGitHub(githubUsername);
    }

    githubUrl && getUserProfileGitHub(githubUrl);
  }, [githubUrl]);

  return ( 
      <Link href={`/#/admin/aluno/${userId}/${programId}`} target={'_blank'}>
      <Card 
        style={boxShadow == true ? {
          "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        } : {}}
        py='15px'
        className='cardStudent'
      >
        <Flex
          my='auto'
          h='100%'
          style={{
            "display": "flex",
            "flexDirection": "column"
          }}
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}>
          <Image 
            style={userDropout ? {
              opacity: '0.6'
            } : {}}
            borderRadius='full'
            boxSize='60px'
            src={picture}
            alt="Photo Profile" 
          />

          <Text mt='10px' align={'center'} fontSize='sm' fontWeight={'600'} color={userDropout ? '#666666' : 'rgb(39, 89, 165)'}>{name}</Text>
          {dateTime && (<Text mt='2px' align={'center'} fontSize='sm'>{dateTime}</Text>)}
          {badgeMonitor && (
            <Text 
              mt='5px'
              style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "fontWeight": "bold",
                "backgroundColor": "#ce1151",
                "padding": "5px 10px",
                "borderRadius": "100px",
                "color": "#fff",
                "fontSize": "12px"
              }} 
              color={textColorSecondary}  
              fontSize='sm'
            >
              <TbIdBadge2 size={15} style={{"marginRight": "5px"}} />
              Monitor (a)
            </Text> 
          )}
          {!badgeMonitor && userGitHubData > 0 ? (
            <>
              <Text mt='2px' align={'center'} fontSize='sm' fontWeight='600' color='green'>
                Commits ({userGitHubData})
              </Text> 
              {userNameGitHub && (
              <Text 
                mt='2px' 
                align={'center'} 
                fontSize='sm' 
                color='#000'
                style={{
                  "display": "flex",
                  "flexDirection": "row",
                  "justifyContent": "center",
                  "alignItems": "center",
                }}
              >
                <FaGithub style={{marginRight: '3px'}} /> {userNameGitHub}
                {/* Último commit: 13/04 às 15:00 */}
              </Text> 
              )}
            </>
          ) : !badgeMonitor && (
            <Text mt='2px' align={'center'} fontSize='sm' color={userDropout ? '#666666' : 'red'}>
              Nenhuma contribuição enviada.
            </Text> 
          )} 
        </Flex>
      </Card>
      </Link>
  );
}
