// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Image,
  Link
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";
import { TbIdBadge2 } from "react-icons/tb";

export default function StudentProfile(props) {
  const { name, picture, dateTime, boxShadow, badgeMonitor = false, userId = 0, programId = 0, role = 'student' } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  return ( 
      role == 'student' ? 
      <Link href={`/#/admin/aluno/${userId}/${programId}`} target={'_blank'}>
      <Card 
        style={boxShadow == true ? {
          "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        } : {}}
        py='15px'
        className='cardStudent'
      >
        <Flex
          my='auto'
          h='100%'
          style={{
            "display": "flex",
            "flexDirection": "column"
          }}
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}>
          <Image 
            borderRadius='full'
            boxSize='60px'
            src={picture}
            alt="Photo Profile" 
          />

          <Text mt='10px' align={'center'} fontSize='sm' fontWeight={'600'} color={'rgb(39, 89, 165)'}>{name}</Text>
          {dateTime && (<Text mt='2px' align={'center'} fontSize='sm'>{dateTime}</Text>)}
          {badgeMonitor ? (
            <Text 
              mt='5px'
              style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "fontWeight": "bold",
                "backgroundColor": "#ce1151",
                "padding": "2px 10px",
                "borderRadius": "100px",
                "color": "#fff",
                "fontSize": "12px"
              }} 
              color={textColorSecondary}  
              fontSize='sm'
            >
              <TbIdBadge2 size={15} style={{"marginRight": "5px"}} />
              Monitor (a)
            </Text> 
          ) : (
            <Text 
              mt='5px'
              style={programId == 3 ? {
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "fontWeight": "bold",
                "backgroundColor": "#2d2765",
                "padding": "2px 10px",
                "borderRadius": "100px",
                "color": "#fff",
                "fontSize": "12px"
              } : {
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "fontWeight": "bold",
                "backgroundColor": "#6696df",
                "padding": "2px 10px",
                "borderRadius": "100px",
                "color": "#fff",
                "fontSize": "12px"
              }} 
              color={textColorSecondary}  
              fontSize='sm'
            >
              {/* <TbIdBadge2 size={15} style={{"marginRight": "5px"}} /> */}
              {programId == 1 && '#GoDev'}
              {programId == 2 && '#SharpCoders'}
              {programId == 3 && 'Todas Tech'} 
            </Text> 
          )}
        </Flex>
      </Card>
      </Link>
      : 
      <Card 
        style={boxShadow == true ? {
          "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        } : {}}
        py='15px'
        className='cardStudent'
      >
        <Flex
          my='auto'
          h='100%'
          style={{
            "display": "flex",
            "flexDirection": "column"
          }}
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}>
          <Image 
            borderRadius='full'
            boxSize='60px'
            src={picture}
            alt="Photo Profile" 
          />

          <Text mt='10px' align={'center'} fontSize='sm' fontWeight={'600'} color={'rgb(39, 89, 165)'}>{name}</Text>
          {dateTime && (<Text mt='2px' align={'center'} fontSize='sm'>{dateTime}</Text>)}
            <Text 
              mt='5px'
              style={{
                "display": "flex",
                "alignItems": "center",
                "justifyContent": "center",
                "fontWeight": "bold",
                "backgroundColor": "#666666",
                "padding": "2px 10px",
                "borderRadius": "100px",
                "color": "#fff",
                "fontSize": "12px"
              }} 
              color={textColorSecondary}  
              fontSize='sm'
            >
              {/* <TbIdBadge2 size={15} style={{"marginRight": "5px"}} /> */}
              Admin
            </Text> 
        </Flex>
      </Card>
  );
}
