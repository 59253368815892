import React, { useState, useEffect } from "react";

// Chakra imports
import { Button, Text, Textarea, Box, Flex, useColorModeValue } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

// Custom components
import Card from "components/card/Card.js";
import axios from "axios";
import CardTimeline from "views/admin/profile/components/CardTimeline";
import { API_SERVER } from "config/constant";

export default function Timeline({ subtitle = '', timeline, programId, authorUserId, classGroupId, loadingTimeline, setLoadingTimeline }) {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const handleComment = async () => {
    try {
      if (!classGroupId || !programId || !authorUserId || !comment) {
        return false;
      }

      if (loading) {
        return false;
      }

      setLoading(true);

      const formData = {
        comment: comment,
        programId: programId,
        authorUserId: authorUserId,
        classGroupId: classGroupId,
      }

      const { data } = await axios.post(`${API_SERVER}dashboard/groups/timeline/comment`, formData);

      setComment('');
      setLoading(false);
      setLoadingTimeline(true);
    } catch (error) {
      setLoading(false);
      setLoadingTimeline(false);
    }
  }

  const handleChange = (e, setFn) => {
    setFn(e.target.value);
  };
  const handleSave = ({ name, value, previousValue }) => {
    setComment(value);
  };

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='0px'>
        Anotações
      </Text>
      <Text
        color={textColorPrimary}
        fontWeight='normal'
        fontSize='1xl'
        mt='0px'
        mb='4px'>
        {subtitle ? subtitle : ''}
      </Text>

      <br />

      <EditTextarea
        name='description'
        value={comment}
        rows={5}
        style={{ fontSize: '16px', border: '1px solid #ccc', borderRadius: '10px', padding: '15px', }}
        onChange={(e) => handleChange(e, setComment)}
        onSave={handleSave}
      />
      <br />

      <Button
        disabled={loading ? true : false}
        onClick={handleComment}
        mb='40px'
        variant='action'
        style={{
          "width": "220px"
        }}
      >
        {loading ? 'Salvando...' : 'Salvar'}
      </Button>

      <hr />
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='30px'
      >
        Timeline de Eventos
      </Text>

     {loadingTimeline && (
          <Box pt={{ base: "130px", md: "50px", xl: "50px" }}>
            <Flex mx='auto' mt='10px' mb='20px' align='center' direction='column'>
              <ClipLoader 
                color={'#2759a5'} 
                loading={loadingTimeline} 
                size={60} 
              />
            </Flex>
          </Box>
        )
      }

      {!loadingTimeline && timeline.length > 0 ? timeline?.map((data) => (
        <CardTimeline 
          boxShadow={cardShadow}
          author={data?.author_name}
          date={data?.created_at}
          description={data?.description}
          value={'Aluno não entregou o projeto'}
        />
      )) : 
        (
          !loadingTimeline && (
            <Text
            mt='20px'
            color='#666666'
            fontSize='1rem'
          >
            Nenhum registro encontrado.
          </Text>
          )
        )
      }

    </Card>
  );
}
