import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
  } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

import InputCnpj from "components/inputCnpj";

import { API_SERVER } from "config/constant";
import SelectMultiple from "./SelectMultiple";

export default function ModalEditCompany({ companyId, modalIsOpen, setModalIsOpen, refreshCompanies }) {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [company, setCompany] = useState([]);
  const [tagsFitCultural, setTagsFitCultural] = useState([]);

  useEffect(() => {
    const getCompanyById = async (companyId) => {
      try {   
        if (loading) {
          return false;
        }

        setLoading(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/companiesById/${companyId}`);
        const { company, tags } = data;

        setCompany(company[0]);
        setTagsFitCultural(tags);
        setLoading(false);
      } catch (error) {
        console.log(error);
  
        setLoading(false);
      }
    }

    getCompanyById(companyId);
  }, []);

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

		setCompany({...company, [name]: value});
	};

  const handleEditCompany = async () => {
    try {      
      const validationEmptyFields = company?.name && company?.cnpj && company?.size && company?.uf && company?.city;

      if (!validationEmptyFields) {
        throw 'EMPTY_FIELDS';
      }
      
      if (loadingSubmit) {
        return false;
      }

      setLoadingSubmit(true);

      company.cnpj = company?.cnpj?.replace(/[^0-9]+/g,'');

      await axios.put(`${API_SERVER}dashboard/company`, { 
        companyId: company?.company_id,
        name: company?.name,
        cnpj: company?.cnpj,
        size: company?.size,
        uf: company?.uf,
        city: company?.city,
       });

      setCompany([]);
      refreshCompanies(true);
      setModalIsOpen(false);
      setLoadingSubmit(false);
    } catch (error) {
      console.log(error);

      setCompany([]);
      refreshCompanies(true);
      setLoadingSubmit(false);
    }
  }

  const isNotEmptyFields = company?.name && company?.cnpj && company?.size && company?.uf && company?.city;

  return (
    <Modal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
      >
      <form>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar empresa</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {/* {formModalError && <Text mb='15px' color='red.600'>Data de fim deve ser maior que data de início</Text>} */}

          {/* <FormControl>
            <FormLabel>Logo</FormLabel>
            <Input 
              type="file"
              name="logo"
              style={{
                "padding": "5px 5px 0px 5px"
              }}
              // value={program?.program_name}
              // onChange={handleChange}
              required={true}
            />
          </FormControl> */}

          {loading ?
            <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
              <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='10px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loading} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
            </Box>
          : null}

          {!loading ?
          <>
          <FormControl mt={4}>
            <FormLabel>Nome</FormLabel>
            <Input 
              type="text"
              name="name"
              value={company?.name || ''}
              onChange={handleChange}
              required={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>CNPJ</FormLabel>
            <InputCnpj
              type="text"
              name="cnpj"
              value={company?.cnpj || ''}
              onChange={handleChange}
              className="chakra-input css-1qfdsln"
              required={true}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Porte</FormLabel>
            <Select
              name="size"
              value={company?.size || ''}
              onChange={handleChange}
              required={true}
            >
              <option value="">Selecione</option>
              <option value="large">Grande</option>
              <option value="medium">Média</option>
              <option value="small">Pequena</option>
            </Select>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Fit cultural</FormLabel>

            <SelectMultiple companyId={companyId} tags={tagsFitCultural} />
          </FormControl>

          <SimpleGrid columns='2' gap='20px'>
          <FormControl mt={4}>
          <FormLabel>UF</FormLabel>
          <Select
            name="uf"
            value={company?.uf || ''}
            onChange={handleChange}
            required={true}
          >
              <option value="">Selecione</option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Cidade</FormLabel>
            <Input 
              type="text"
              name="city"
              value={company?.city || ''}
              onChange={handleChange}
              required={true}
            />
          </FormControl>
          </SimpleGrid>
          </>
          : null}
        </ModalBody>

        {!loading ?
          <ModalFooter>
            <Button 
              type="button"
              onClick={() => handleEditCompany()}
              colorScheme='blue' mr={3}
              disabled={!isNotEmptyFields}
            >
              {loadingSubmit ? 'Salvando...' : 'Salvar'}
            </Button>
            <Button onClick={() => setModalIsOpen(false)}>Cancelar</Button>
          </ModalFooter>
        : null}
      </ModalContent>
      </form>
    </Modal>
    )
}