// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Progress,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import IconBox from "components/icons/IconBox";
  import Menu from "components/menu/MainMenu";
  import React, { useState } from "react";
  // Assets
  import { MdCancel, MdCheckCircle, MdOutlineSchool, MdOutlineWork, MdSchool } from "react-icons/md";
  import { FaBookReader, FaGraduationCap, FaUserGraduate } from "react-icons/fa";
import { TbIdBadge2 } from "react-icons/tb";
  
  export default function Situation(props) {
    const { status, currentSituation, companyName, companyLogo, companyHiredIn, course } = props;

    const [statusBefore, setStatusBefore] = useState(status);
    const [currentSituationBefore, setCurrentSituationBefore] = useState(currentSituation);

    const getStatusName = (status) => {
      if (status == 'em_formacao') {
        return 'Em formação';
      }

      if (status == 'fora_programa') {
        return 'Desistente';
      }

      if (status == 'desligado') {
        return 'Desligado';
      }

      if (status == 'formado') {
        return 'Formado';
      }

      if (status == 'indicado') {
        return 'Indicado';
      }

      if (status == 'banco_talentos') {
        return 'Banco de talentos';
      }
  
      if (status == 'contratado') {
        return 'Contr. direto';
      }
  
      if (status == 'contratado_headhunting') {
        return 'Contr. Head Hunting';
      }
  
      if (status == 'contratado_indireto') {
        return 'JobOut';
      }
  
      if (status == 'indisponivel') {
        return 'Indisponível';
      }
    }

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    return (
      <Card mb={{ base: "0px", lg: "20px" }} align='center'>
        {(currentSituationBefore == 'contratado' || currentSituationBefore == 'contratado_headhunting') && (
          <Flex w='100%' justify='center' mb='10px'>
            <img 
              style={{
                "borderRadius": "100px",
                "border": "1px solid #ccc"
              }}
              src={companyLogo} 
              width="100" 
              alt="Logo empresa" 
            />
          </Flex>
        )}
        {(currentSituationBefore != 'contratado' && currentSituationBefore != 'contratado_headhunting') && (
          <IconBox
            mx='auto'
            h='100px'
            w='100px'
            icon={
              <>
                {currentSituationBefore == 'indisponivel' && (<Icon as={MdCancel} color={'red.500'} h='46px' w='46px' />)}
                {currentSituationBefore == 'banco_talentos' && (<Icon as={FaUserGraduate} color={'blue.500'} h='46px' w='46px' />)}
                {currentSituationBefore == 'indicado' && (<Icon as={MdOutlineWork} color={'orange.500'} h='46px' w='46px' />)}
                {currentSituationBefore == 'contratado' && (<Icon as={MdOutlineWork} color={'#5C4033'} h='46px' w='46px' />)}
                {currentSituationBefore == 'contratado_headhunting' && (<Icon as={MdOutlineWork} color={'#5C4033'} h='46px' w='46px' />)}
                {currentSituationBefore == 'contratado_indireto' && (<Icon as={MdOutlineWork} color={'red.500'} h='46px' w='46px' />)}
              </>
            }
            bg={box}
          />
        )}
        <Text 
          style={{"textTransform": "capitalize"}}
          color={textColorPrimary} 
          fontWeight='bold' 
          fontSize='2xl' 
          mt='10px'
        >
          {(currentSituationBefore == 'contratado' || currentSituationBefore == 'contratado_headhunting') ? companyName : 'Situação'}
        </Text>
        <Text
          color={textColorSecondary}
          fontSize='md'
          maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
          mx='auto'>
            {/* {currentSituationBefore == 'banco_talentos' || currentSituationBefore == 'contratado' || currentSituationBefore == 'contratado_headhunting' ? getStatusName(currentSituationBefore) : getStatusName(statusBefore)} */}
            {getStatusName(currentSituationBefore)}
        </Text>
        {currentSituationBefore == 'contratado' && (
          <Box w='100%' mt='auto'>
            <Flex w='100%' justify='space-between' mb='10px'>
              <Flex mx='auto' me='auto' align='center' direction='column'>
                <Text color={'#666666'} fontSize='1xl' fontWeight='700'>
                  {companyHiredIn}
                </Text>
                <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                  Data da contratação
                </Text>
              </Flex>
            </Flex>
          </Box>
        )}
        {currentSituationBefore != 'contratado' && (
          <Box w='100%' mt='auto'>
            <Flex w='100%' justify='center' mb='10px'>
              <Flex mx='auto' me='auto' align='center' direction='row'>
                <Text 
                  style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontWeight": "bold",
                    "backgroundColor": "#2759A5",
                    "padding": "5px 15px",
                    "borderRadius": "100px",
                    "color": "#fff",
                    "fontSize": "13px"
                  }} 
                  color={textColorSecondary} 
                  fontSize='sm'
                >
                  <MdSchool size={15} style={{"marginRight": "5px"}} />
                  {course}
                </Text>
              </Flex>
            </Flex>
          </Box>
        )}
      </Card>
    );
  }
  