import React, { memo } from 'react';

import MaskedInput from 'react-text-mask';

const InputCellphone = (props) => {
  return (
    <MaskedInput
      {...props}
      guide={false}
      mask={
        props?.value?.length > 15
          ? ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
          : ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
      }
    />
  );
};

export default memo(InputCellphone);
