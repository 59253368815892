import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdLogout,
  MdLineWeight,
  MdPeople,
  MdSchool,
  MdStackedBarChart,
  MdOutlineVoiceChat,
  MdOutlineChat,
  MdOutlineWork,
} from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaMedal, FaBuilding, FaThLarge, FaChartLine, FaUserCheck, FaRobot, FaSchool, FaFilePdf } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { TbIdBadge2 } from "react-icons/tb";
import { GrLineChart } from "react-icons/gr";
import { HiDocumentText } from "react-icons/hi";
import { FiBox } from "react-icons/fi";
import { DiGoogleAnalytics } from "react-icons/di";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";

// Admin Imports
import MainDashboard from "views/admin/default";
import Programas from "views/admin/programas";
import Programa from "views/admin/programa";
import ClassLeaders from "views/admin/classLeaders";
import Companies from "views/admin/companies";
import Teachers from "views/admin/teachers";
import Profile from "views/admin/profile";
import Students from "views/admin/students";
import Ranking from "views/admin/ranking";
import StudentAccess from "views/admin/studentAccess";
import Employability from "views/admin/employability";
import Interview from "views/admin/interview";
import Interviews from "views/admin/interviews";
import InterviewDetails from "views/admin/interview_details";
import Forms from "views/admin/forms";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
import SignUp from "views/auth/signUp/index.jsx";
import SignOut from "views/auth/signOut/index.jsx";
import FormsDetails from "views/admin/forms_details";
import ClassLeadersAndGroups from "views/admin/classLeadersAndGroups";
import Analytics from "views/admin/analytics";
import ProfilePdf from "views/admin/profile-pdf";
import AliceAI from "views/admin/aliceAI";
import AliceAIDetails from "views/admin/aliceAiDetails";
import GeneratePDF from "views/admin/generate-pdf";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdBarChart} width='24px' height='24px' color='inherit' />,
    component: MainDashboard,
    hide: false
  },
  {
    name: "Analytics",
    layout: "/admin",
    path: "/analytics",
    icon: <Icon as={DiGoogleAnalytics} width='22px' height='22px' color='inherit' />,
    component: Analytics,
    secondary: true,
    hide: false
  },
  // {
  //   name: "Acessos",
  //   layout: "/admin",
  //   path: "/acessos",
  //   icon: <Icon as={TbDeviceDesktopAnalytics} width='22px' height='22px' color='inherit' />,
  //   component: StudentAccess,
  //   secondary: true,
  //   hide: false
  // },
  {
    name: "Formulários",
    layout: "/admin",
    path: "/forms",
    icon: <Icon as={HiDocumentText} width='24px' height='24px' color='inherit' />,
    component: Forms,
    secondary: true,
    hide: false
  },
  {
    name: "Formulário de presença",
    layout: "/admin",
    path: "/form",
    icon: <Icon as={HiDocumentText} width='24px' height='24px' color='inherit' />,
    component: FormsDetails,
    secondary: true,
    hide: true
  },
  {
    name: "Alice AI",
    layout: "/admin",
    path: "/alice-ai-details",
    icon: (
      <Icon
        as={FaRobot}
        width='24px'
        height='24px'
        color='inherit'
      />
    ),
    component: AliceAIDetails,
    secondary: true,
    hide: true
  },
  {
    name: "Alice AI",
    layout: "/admin",
    path: "/alice-ai",
    icon: (
      <Icon
        as={FaRobot}
        width='24px'
        height='24px'
        color='inherit'
      />
    ),
    component: AliceAI,
    secondary: true,
    hide: true,
    // submenu: [
    //   {
    //     path: '/alice-ai/companies',
    //     icon: (
    //       <Icon
    //         as={FaSchool}
    //         width='24px'
    //         height='24px'
    //         color='inherit'
    //       />
    //     ),
    //     name: 'Escolas', 
    //   }
    // ]
  },
  {
    name: "Ficha do candidato",
    layout: "/admin",
    path: "/ficha-candidato",
    icon: <FaFilePdf as={FaMedal} width='20px' height='20px' color='inherit' />,
    component: GeneratePDF,
    hide: false
  },
  {
    name: "Programas",
    layout: "/admin",
    path: "/programas",
    icon: (
      <Icon
        as={FiBox}
        width='24px'
        height='24px'
        color='inherit'
      />
    ),
    component: Programas,
    secondary: true,
    hide: false
  },
  {
    name: "Programa",
    layout: "/admin",
    path: "/programa",
    icon: (
      <Icon
        as={MdLineWeight}
        width='22px'
        height='22px'
        color='inherit'
      />
    ),
    component: Programa,
    secondary: true,
    hide: true
  },
  {
    name: "Empresas",
    layout: "/admin",
    path: "/empresas",
    icon: (
      <Icon
        as={FaBuilding}
        width='22px'
        height='22px'
        color='inherit'
      />
    ),
    component: Companies,
    secondary: true,
    hide: false
  },
  {
    name: "Professores",
    layout: "/admin",
    path: "/professores",
    icon: (
      <Icon
        as={GiTeacher}
        width='22px'
        height='22px'
        color='inherit'
      />
    ),
    component: Teachers,
    secondary: true,
    hide: false
  },
  {
    name: "Alunos",
    layout: "/admin",
    icon: <Icon as={MdPeople} width='22px' height='22px' color='inherit' />,
    path: "/alunos",
    component: Students,
    hide: false
  },
  {
    name: "Status / Situação",
    layout: "/admin",
    icon: <Icon as={FaUserCheck} width='22px' height='22px' color='inherit' />,
    path: "/status",
    component: Employability,
    hide: false
  },
  {
    name: "Entrevistas",
    layout: "/admin",
    icon: <Icon as={MdOutlineChat} width='22px' height='22px' color='inherit' />,
    path: "/entrevistas",
    component: Interviews,
    hide: false
  },
  {
    name: "Monitores",
    layout: "/admin",
    path: "/monitores",
    icon: (
      <Icon
        as={TbIdBadge2}
        width='22px'
        height='22px'
        color='inherit'
      />
    ),
    component: ClassLeaders,
    secondary: true,
    hide: false
  },
  {
    name: "Grupos",
    layout: "/admin",
    path: "/grupos",
    icon: (
      <Icon
        as={TbIdBadge2}
        width='22px'
        height='22px'
        color='inherit'
      />
    ),
    component: ClassLeadersAndGroups,
    secondary: true,
    hide: true
  },
  {
    name: "Detalhes do aluno",
    layout: "/admin",
    path: "/aluno",
    icon: <Icon as={FaMedal} width='20px' height='20px' color='inherit' />,
    component: Profile,
    hide: true
  },
  {
    name: "Detalhes do aluno",
    layout: "/admin",
    path: "/pdf",
    icon: <Icon as={FaMedal} width='20px' height='20px' color='inherit' />,
    component: ProfilePdf,
    hide: true
  },
  {
    name: "Simulação de entrevista",
    layout: "/admin",
    path: "/entrevista/aluno",
    icon: <Icon as={FaMedal} width='20px' height='20px' color='inherit' />,
    component: Interview,
    hide: true
  },
  {
    name: "Detalhes da entrevista",
    layout: "/admin",
    path: "/entrevista/detalhes",
    icon: <Icon as={FaMedal} width='20px' height='20px' color='inherit' />,
    component: InterviewDetails,
    hide: true
  },
  {
    name: "Ranking",
    layout: "/admin",
    path: "/ranking",
    icon: <Icon as={FaMedal} width='20px' height='20px' color='inherit' />,
    component: Ranking,
    hide: false
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdHome} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    hide: true
  },
  {
    name: "Signout",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdHome} width='16px' height='16px' color='inherit' />
    ),
    component: SignOut,
    hide: true
  },
];

export const Logout = [
  {
    name: "Sair",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdLogout} width='22px' height='22px' color='inherit' />
    ),
    component: SignOut,
    hide: false
  }
];
export default routes;
