import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';

const RepoInfo = ({ userRepoGit, resultData }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = (result) => {
      // const result = await axios(
      //   `https://api.github.com/repos/${userRepoGit}/stats/contributors`,
      //   {
      //     method: "GET",
      //     headers: {
      //     'Authorization': 'token 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',
      //     }
      //   }
      // );

      // processar os dados para criar o gráfico de linhas
      const dates = result.data[0].weeks.map(week => new Date(week.w * 1000));
      const colors = ['red', 'blue', 'green', 'orange', 'yellow', 'black', 'gray', 'violet', 'brown']; // array de cores personalizadas
      const data = result.data.map((contributor, index) => ({
        label: contributor.author.login,
        data: contributor.weeks.map(week => week.c),
        fill: false,
        borderColor: colors[index % colors.length], // atribuir cor com base no índice
        lineTension: 0.1,
      }));

      const chartData = {
        labels: dates.map(date => date.toLocaleDateString()), // formatar as datas para exibição no gráfico
        datasets: data,
      };

      setChartData(chartData);
    };

    resultData && fetchData(resultData);
  }, [resultData]);

  return (
    <div>
      {chartData ? (
        <Line
          data={chartData}
          options={{
            responsive: true,
            title: {
              display: true,
              text: 'Contributions by date',
              fontSize: 20,
            },
            scales: {
              xAxes: [{
                type: 'time',
                time: {
                  unit: 'day', // exibir rótulos de dias
                  displayFormats: {
                    day: 'MMM D', // formato do rótulo
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Date',
                  fontSize: 16,
                },
              }],
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Commits',
                  fontSize: 16,
                },
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          }}
        />
      ) : (
        <p>Carregando...</p>
      )}
    </div>
  );
};

export default RepoInfo;
