// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import axios from 'axios';
import React, { useState, useEffect } from "react";
// Assets
import { FiExternalLink } from "react-icons/fi";
import { BsFillCheckCircleFill } from "react-icons/bs";

export default function Project(props) {
  const { title, projectSlug, video_url, deploy_url, github_url, github_url_2, image, created_at, contribuitorsGithub, userId, programId, ...rest } = props;

  const [contributors, setContributors] = useState([]);

  useEffect(() => {
    async function fetchData() {
      async function getContributorsData(repoUrl) {
        try {
          const urlGithub = repoUrl?.replace('https://github.com/', '');
          const [userName, repoName] = urlGithub?.split('/');
      
          const response = await axios.get(
            `https://api.github.com/repos/${userName}/${repoName}/contributors`,
            {
              method: "GET",
              headers: {
              'Authorization': 'token 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',
              }
            }
          );
      
          const contributors = response.data;
      
          const contributorsData = await Promise.all(
            contributors.map(async (contributor) => {
              const userDataResponse = await axios.get(contributor.url);
              const userData = userDataResponse.data;
      
              return {
                name: userData.name,
                avatarUrl: userData.avatar_url,
                githubUrl: userData.html_url,
              };
            })
          );
      
          return contributorsData;
        } catch (error) {
          console.error(error);
        }
      }
      
      const contributorsDataRes = await getContributorsData(github_url);
      setContributors(contributorsDataRes);
    }

    if (contribuitorsGithub) {
      fetchData();
    }
  }, []);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p='14px'>
      <Flex align='center' justifyContent={'space-between'} direction={{ base: "column", md: "row" }} mb={contributors?.length > 0 ? 15 : 0}>
      <Flex align='center' justifyContent={'flex-start'} direction={{ base: "column", md: "row" }}>
        <Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' />
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight='500'
            fontSize='md'
            mb='4px'>
            {title}
          </Text>
          <Text
            fontWeight='500'
            color={textColorSecondary}
            fontSize='sm'
            marginTop={2}
            me='4px'>
            Data do envio: {created_at} 
          </Text>
          <Text>
            {github_url && (
              <Link fontWeight='500' color={brandColor} target="_blank" href={github_url} fontSize='sm'>
                Código fonte {projectSlug == 'hackadev-sharp-4' ? (github_url_2) ? '(Front-End)' : '(Monorepo)' : ''} {contributors?.length > 0 ? `${" "} • ${" "}` : null}
              </Link>
            )}
            {github_url_2 && (
              <Link fontWeight='500' color={brandColor} target="_blank" href={github_url_2} fontSize='sm'>
                Código fonte (Back-End) {contributors?.length > 0 ? `${" "} • ${" "}` : null}
              </Link>
            )}
            {video_url && (
              <Link fontWeight='500' color={brandColor} target="_blank" href={video_url} fontSize='sm'>
                Vídeo {" "} • {" "}
              </Link>
            )}
            {deploy_url && (
              <Link fontWeight='500' color={brandColor} target="_blank" href={deploy_url} fontSize='sm'>
                Link {" "} • {" "} 
              </Link>
            )}
            {contributors?.length > 0 && (
              <Link fontWeight='500' color={brandColor} target="_blank" href={`/#/admin/grupos/${userId}/${programId}`} fontSize='sm'>
                Grupo
              </Link>
            )}
          </Text>
        </Box>
        </Flex>
        <BsFillCheckCircleFill size={50} color='#128C7E' />
        {/* <Link
          target="_blank"
          href={deploy_url ? deploy_url : github_url}
          variant='no-hover'
          me='16px'
          ms='auto'
          p='0px !important'>
          <Icon as={FiExternalLink} color='secondaryGray.500' h='18px' w='18px' />
        </Link> */}
      </Flex>

      {contributors?.length > 0 && (
      <>
      <hr />
      <Flex align='center' direction={{ base: "column", md: "row" }}>
        <Box mt={{ base: "10px", md: "0" }}>
        {contributors?.length > 0 && (
            <>
            <Text
              fontWeight='bold'
              color={textColorSecondary}
              fontSize='sm'
              marginTop={2}
              marginBottom={2}
              me='4px'>
              Enviaram commits no repositório do GitHub:
            </Text>
            <ul
              style={{
                "display": "flex",
                "justifyContent": "flex-start",
                "listStyle": "none"
              }}
            >
              {contributors.map((contributor) => (
                <li key={contributor.githubUrl}>
                  <a href={contributor.githubUrl} target={`_blank`}>
                    <img 
                      width={30} 
                      style={{
                        "borderRadius": "100px",
                        "border": "1px solid #000",
                        "marginRight": "5px"
                      }}
                      src={contributor.avatarUrl} 
                      alt={`${contributor.name}'s avatar`} 
                    />
                  </a>
                </li>
              ))}
            </ul>
            <Text
              fontWeight='bold'
              color={'#000'}
              fontSize='xs'
              marginTop={1}
              marginBottom={2}
              me='4px'>
              via GitHub API
            </Text>
            </>
          )}
          </Box>
      </Flex>
      </>
      )}
    </Card>
  );
}
