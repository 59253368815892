const initialState = {
    data: []
  };
  
  export default function dataReducer(state = initialState, action) {
    switch (action.type) {
      case 'SET_DATA':
        return {
          ...state,
          ...action.payload,
        };
      case 'CLEAR_DATA':
        return initialState;
      default:
        return state;
    }
  }
  