// Chakra imports
import React, { useState, useEffect } from "react";
import { Box, Grid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Custom components
import Banner from "views/admin/aliceAiDetails/components/Banner";
import General from "views/admin/aliceAiDetails/components/General";
import Education from "views/admin/aliceAiDetails/components/Education";
import SelectionProcess from "views/admin/aliceAiDetails/components/SelectionProcess";
import Notifications from "views/admin/aliceAiDetails/components/Notifications";
import Projects from "views/admin/aliceAiDetails/components/Projects";
import Storage from "views/admin/aliceAiDetails/components/Storage";
import Badge from "views/admin/aliceAiDetails/components/Badge";
import Situation from "views/admin/aliceAiDetails/components/Situation";
import Score from "views/admin/aliceAiDetails/components/Score";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

import { API_SERVER } from "config/constant";
import Timeline from "./components/Timeline";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";

export default function AliceAIDetails() {
  const hash = window.location.hash;
  const id = hash.split('/alice-ai-details/');
  const interviewId = id[1] ? id[1]?.split('/')[0] : 0;
  // const programId = id[1] ? id[1]?.split('/')[1] : 0;
  // const userId = id[1] ? id[1]?.split('/')[2] : 0;

  const programId = 3;

  const [loading, setLoading] = useState(false);
  const [interview, setInterview] = useState([]);
  const [responseGPT, setResponseGPT] = useState([]);
  const [responseUserFeedback, setResponseUserFeedback] = useState([]);

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  useEffect(() => {
    const getInterviewById = async (interviewId) => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}interviewai/interviews/${interviewId}`);
      const { interviewsData } = data;
      setInterview(interviewsData);

      const lastFeedback = JSON.parse(interviewsData[0]?.interview_feedback);
      setResponseGPT(lastFeedback);

      if (interviewsData[0]?.ai_user_feedback) {
        const userFeedback = JSON.parse(interviewsData[0]?.ai_user_feedback?.replace('"[', '')?.replace(']"', ''));
        setResponseUserFeedback(userFeedback);
      }

      setLoading(false);
    }

    if (interviewId > 0) {
      getInterviewById(interviewId);

      window.scrollTo(0, 0);
    }
  }, [])

  const dateStart = new Date(interview?.program_start_date);
  const dateEnd = new Date(interview?.program_end_date);
  const dateNow = new Date();

  var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  var Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  var Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;


  const formatTranscriptRender = (transcription) => {
    const data = JSON.parse(transcription);
    // const data = transcription;

    const questionsAndAnswers = data?.questions_answers?.map((item, index) => {
      const questionNumber = index + 1;
      const modifiedQuestion = item.question.replace(`${questionNumber}.`, `${questionNumber}. `);
      const modifiedAnswer = item.answer_candidate.replace('Resposta:', `Resposta ${questionNumber}:`);
  
      return (
        <div key={modifiedQuestion} 
          className={item.is_correct == 'yes' ? `chat-item-transcript chatItemCorrect` : `chat-item-transcript chatItemIncorrect`}
          style={item.is_correct == 'yes' ? {
            'padding': '15px 20px 10px',
            'borderRadius': '20px',
            'marginBottom': '20px',
            'backgroundColor': '#2e8b57',
            color: '#fff',
          } : {
            'padding': '15px 20px 10px',
            'borderRadius': '20px',
            'marginBottom': '20px',
            'backgroundColor': '#ff4c4c',
            color: '#fff'
          }}>
          <p className='mb-0'><strong>{modifiedQuestion}</strong></p>
          <p className='mt-0'>
            {modifiedAnswer}
            <hr style={{margin: '10px 0px'}} />
            <p
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >{item.is_correct == 'yes' ? 'Resposta correta' : `Resposta incorreta`} {item.is_correct == 'yes' ? <BsCheckCircleFill size={15} style={{marginLeft: '5px'}} /> : <MdCancel style={{marginLeft: '5px'}} />}</p>
            
            {/* {item?.probability && <p>{item?.probability}</p>} */}

            {item.is_correct == 'no' && (
              <>
                <hr style={{margin: '10px 0px'}}  />
                <p className='text-feedback-n2'
                  style={{
                    backgroundColor: '#222',
                    padding: '10px 15px',
                    borderRadius: '20px',
                    fontWeight: '600',
                    color: '#fff'
                  }}
                >Resposta correta: {item?.answer_feedback?.replace('Resposta incorreta.', '')?.replace('A resposta está incorreta.', '')?.replace('Incorreta.', '')?.replace('Resposta parcialmente correta.', '')}</p>
              </>
            )}
          </p>
        </div>
      );
    });
  
    return questionsAndAnswers;
  };


  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mx='auto' mt='80px' align='center' direction='column'>
          <ClipLoader 
            color={'#2759a5'} 
            loading={loading} 
            size={60} 
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1fr 1.30fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          // programId={programId}
          banner={banner}
          // userId={interview[0]?.user_id}
          avatar={interview[0]?.picture}
          name={interview[0]?.name}
          interviewTitle={interview[0]?.interview_title}
          // age={interview?.age}
          // city={interview?.city}
          // uf={interview?.uf}
          isInterviewer={false}
        /> 
        {/* <Banner
          gridArea='1 / 1 / 2 / 2'
          name={interview?.interviewer_name}
          isInterviewer={true}
        />  */}
        {/* <Situation
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          qtyInterviews={interview?.qty_interviews || 0}
          lastInterviewData={interview?.last_interview_date || '-'}
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          programName={interview?.program_name}
          programDescription={interview?.program_description}
          programStartDate={interview?.program_start_date}
          programEndDate={interview?.program_end_date}
          used={percentUsed}
          total={100}
        />  */}
      </Grid>
      
      {/* <General
        minH='290px'
        pe='20px'
        mb='20px'
        interviewData={interview}
      /> */}

        {!loading && (
      <Flex justify="space-between">
      <Box width="30%" p={4}>
      {(responseGPT?.pontuacao_final || responseGPT?.pontuacao_final === 0) && (
              <div className="col-12 col-sm-12 col-md-3 box-info-nt">
                <div className={`box-card-interview box-active`} 
                  style={{
                    width: '100%',
                    height: '120px',
                    borderRadius: '8px',
                
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                
                    color: '#fff',
                    fontWeight: 600,
                    backgroundColor: '#ce1151'
                  }}
                >
                  <p>{responseGPT?.pontuacao_final}</p>
                  <span className='interview-check-date'>Nota</span>
                </div>
              </div>
            )} 
      </Box>
      <Box width="30%" p={4}>
      {(responseGPT?.qtd_respostas_corretas || responseGPT?.qtd_respostas_corretas === 0) && (
              <div className="col-12 col-sm-12 col-md-3 box-info-nt">
                <div className={`box-card-interview box-success`} 
                  style={{
                    width: '100%',
                    height: '120px',
                    borderRadius: '8px',
                
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                
                    color: '#fff',
                    fontWeight: 600,
                    backgroundColor: '#2e8b57'
                  }}
                >
                  <p>{responseGPT?.qtd_respostas_corretas}</p>
                  <span className='interview-check-date'>Respostas corretas</span>
                </div>
              </div>
            )} 
      </Box>
      <Box width="30%" p={4}>
      {(responseGPT?.qtd_respostas_incorretas || responseGPT?.qtd_respostas_incorretas === 0) && (
              <div className="col-12 col-sm-12 col-md-3 box-info-nt mb-0">
                <div className={`box-card-interview box-answers-incorrect`}
                  style={{
                    width: '100%',
                    height: '120px',
                    borderRadius: '8px',
                
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                
                    color: '#fff',
                    fontWeight: 600,
                    backgroundColor: '#ff4c4c'
                  }}
                >
                  <p>{responseGPT?.qtd_respostas_incorretas}</p>
                  <span className='interview-check-date'>Respostas incorretas</span>
                </div>
              </div>
            )} 
      </Box>
    </Flex>
        )}

    {(responseUserFeedback?.userFeedback) && (
    <Flex justify="space-between">
      <Box width="100%" p={4}>
              <div className="col-12 col-sm-12 col-md-12 box-info-nt">
                <div className={`box-card-interview box-active`} 
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                
                    color: '#fff',
                    backgroundColor: '#ffffff',
                    color: '#222',
                    border: '1px solid #222',
                    padding: '10px 20px',
                  }}
                >
                  <Flex direction={'row'} justify="flex-start" alignItems={'center'} mb="20px">
                      <img 
                        src={interview[0]?.picture} 
                        width={'40px'}
                        style={{
                          borderRadius: '100px',
                          marginRight: '8px'
                        }}
                        alt="" 
                      />
                      <p style={{fontWeight: '600'}}>{interview[0]?.name}</p>
                  </Flex>

                  {/* <p>Conseguiu concluir o teste? {responseUserFeedback?.userConcluirTeste == 1 ? 'Sim' : 'Não'}</p> */}
                  <p style={{fontStyle: 'italic'}}>“{responseUserFeedback?.userFeedback}“</p>
                </div>
              </div>  
            </Box>
            </Flex>
          )}

      <Flex justify="space-between" mb="20px">
      <Box width="100%" p={4}>
      {(responseGPT?.feedback_geral) ? (
              <div className="col-12 col-sm-12 col-md-12 box-info-nt">
                <div className={`box-card-interview box-active`} 
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '8px',
                
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                
                    backgroundColor: '#f1f1f1',
                    color: '#222',
                    border: '1px solid #222',
                    padding: '20px 20px',
                  }}
                >
                  <p>{responseGPT?.feedback_geral}</p>
                </div>
              </div>
            ) : (
              <>
              </>
            )} 
      </Box>
      </Flex>

        {(!loading && interview[0]?.transcription) && (
          <div className="container box-transcript"
            style={{
              'opacity': '0.8',
              'padding': '20px 20px 10px',
              'borderRadius': '20px',
              'background': '#fff',
              'marginBottom': '100px'
            }}
          >
          <div className="row">
            {/* <hr className='text-white mt-0' /> */}
            <p className='mb-3 text-welcome mb-4'
            style={{
              fontSize: '1.5rem',
              marginBottom: '10px',
              color: '#222',
              fontWeight: 'bold'
            }}
            >Transcrição</p>
            

            <div className="col-12 col-md-8 text-white box-transcript-txt">
            {/* <div class="alert alert-danger" role="alert">
              Aviso: Transcrição em Beta. Possíveis erros. Avaliação pode ser afetada.
            </div> */}
              {/* <p className='text-white mb-3 text-welcome'>Transcrição</p> */}

              {formatTranscriptRender(interview[0]?.interview_feedback)}
            </div>
          </div>
          </div>
        )}
    </Box>
  );
}
