const initialState = {
  name: '',
  email: '',
  presentation_note: 'all',
  communication_note: 'all',
  academic_professional_note: 'all',
};
  
  export default function filtersReducer(state = initialState, action) {
    switch (action.type) {
      case 'SET_FILTERS_INTERVIEWS':
        return {
          ...state,
          ...action.payload,
        };
      case 'CLEAR_FILTERS_INTERVIEWS':
        return initialState;
      default:
        return state;
    }
  }
  