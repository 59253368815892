import React, { useState } from 'react';
import * as XLSX from 'xlsx';

function UploadSheets({ setUserData }) {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setError("Por favor, selecione um arquivo válido.");
      return;
    }

    setFile(selectedFile);
    setError(null);

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const fileData = event.target.result;
        const workbook = XLSX.read(fileData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const headers = sheetData[0];
        const extractedData = sheetData.slice(1).map((row) => {
          return {
            name: row[headers.indexOf("name")],
            email: row[headers.indexOf("email")],
            // cell_phone: row[headers.indexOf("cellphone")],
            picture: row[headers.indexOf("picture")],
            gender: row[headers.indexOf("gender")],
            city: row[headers.indexOf("city")],
            uf: row[headers.indexOf("uf")],
          };
        });

        setData(extractedData);
        setUserData(extractedData);
      } catch (error) {
        setError("Ocorreu um erro ao processar o arquivo.");
        console.error(error);
      }
    };

    reader.readAsBinaryString(selectedFile);
  };

  return (
    <div>
      <input type="file" accept=".xlsx" onChange={handleFileChange} />
      {error && <div>{error}</div>}
      {/* <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Whatsapp</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td><img src={item.foto} /></td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.whatsapp}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
}

export default UploadSheets;
