import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
// import axios from "axios";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import Pagination from "components/pagination";

// Assets
import {MdGroups,  MdCheckCircle, MdCancel, MdOutlineWork, MdSchool } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaMedal } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";

// import { API_SERVER } from "config/constant";

import avatarProfile from 'assets/img/avatars/avatar.png';
import { ClipLoader } from "react-spinners";

export default function ColumnsTable(props) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const [loadingReport, setLoadingReport] = useState(false);

  const { 
    columnsData, 
    tableData, 
    programId,
    dataRanking, 
    filtersData,
    realtime,
    loadUsersByFilter,
    onChangeProgramId,
    onChangeFilter, 
    submitFilter,
    clearFilters } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    pageOptions,
    // page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const getName = (fullName) => {
    const name = fullName?.split(' ');

    if (!fullName || fullName.length === 0) return;

    let firstWord = name[0];
    let lastWord = name[name.length - 1];
    // if (name[2] && name[2]?.length > 3) {
    //   return `${name[0]} ${name[2]}`.toLocaleLowerCase();
    // } else if (name[1] && name[1]?.length > 3) {
    //   return `${name[0]} ${name[1]}`.toLocaleLowerCase();
    // } else if (name[2] && name[2]?.length >= 3) {
    //   return `${name[0]} ${name[2]}`.toLocaleLowerCase();
    // }

    return `${firstWord} ${lastWord}`;
  }
  
  function exportXLSX() {    
    if (loadingReport) { 
      return false;
    }

    setLoadingReport(true);

    const arrayOfArrays = tableData?.map(obj => [
      `${obj.position}°`,
      obj.name?.split(';split;')[1].toUpperCase(),
      obj.badge,
      obj.beecrowd_note,
      obj.first_interview_note,
      obj.second_interview_note,
      obj.practical_projects_note,
      obj.qty_attendances_meet,
      obj.forum_note,
      obj.total_note,
    ]);
    arrayOfArrays.unshift(['Posição', 'Nome', 'Badge', 'Beecrowd', 'Entrevista Soft Skills', 'Entrevista Técnica', 'Projetos Práticos', 'Presença', 'Fórum', 'Total']);

    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Ranking');
    const fileName = 'ranking.xlsx';
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);

    setLoadingReport(false);
  }

  return (
    <>
      {!realtime && (
      <Flex px='0px' justify='space-between' mt='10px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='0px'>
          <Select 
            name="programId"
            onChange={onChangeProgramId}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            mb='0px'
            fontWeight='500'
            size='lg'
            placeholder="Selecione um programa" 
            bg={'white'}
          >
            <option value="3" selected={programId == 3 ? true : false}>Todas Tech</option>
            <option value="2" selected={programId == 2 ? true : false}>SharpCoders</option>
            <option value="1" selected={programId == 1 ? true : false}>GoDev 2022</option>
          </Select>
        </SimpleGrid>
      </Flex>
      )}

      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 5, lg: 5, "2xl": 5 }}
          gap='20px'
          mb='0px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaMedal} color={brandColor} />
                }
              />
            }
            name='Total'
            value={dataRanking?.total}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaMedal} color={'#666666'} />
                }
              />
            }
            name='Platina'
            value={dataRanking?.platina}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaMedal} color={'gold'} />
                }
              />
            }
            name='Ouro'
            value={dataRanking?.ouro}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaMedal} color={'silver'} />
                }
              />
            }
            name='Prata'
            value={dataRanking?.prata}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaMedal} color={'#cd7f32'} />
                }
              />
            }
            name='Bronze'
            value={dataRanking?.bronze}
          />
        </SimpleGrid>
      </Box>
    </Card>

    {!realtime && (
    <Card
      direction='column'
      w='100%'
      px='0px'
      mb='20px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <form onSubmit={submitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 4, xl: 4 }} gap='20px' mb='20px'>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input 
              name="name" 
              value={filtersData.name || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input 
              name="email"
              value={filtersData.email || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Sexo</FormLabel>
            <Select
              name="gender"
              value={filtersData.gender || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Badge</FormLabel>
            <Select
              name="badge"
              value={filtersData.badge || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="platina">Platina</option>
              <option value="ouro">Ouro</option>
              <option value="prata">Prata</option>
              <option value="bronze">Bronze</option>
            </Select>
          </FormControl>

          <FormControl>
            {/* <FormLabel style={{"color": "transparent"}}>.</FormLabel> */}
            <ButtonGroup gap='1'>
              <Button 
                type="submit"
                onClick={submitFilter} 
                colorScheme='brandScheme'
              >
               {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
      </Flex>
      </form>
    </Card>
    )}

    <Card
      direction='column'
      w='100%'
      mt='0px'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='2px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          {/* {!realtime ? 'Ranking' : 'Ranking - atualizado em 25/04 às 16:15'} */}
        </Text>

        <Flex align='center'>
          <Button 
            // disabled={!data?.length}
            // loading={loadingReport}
            onClick={exportXLSX}
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              {loadingReport ? 'Exportando...' : 'Exportar'}
            </Text>
          </Button>
        </Flex>
      </Flex>

        {realtime ? 
          <>
          <Flex px='25px' justify='flex-start' direction={'column'} mb='20px' align='start'>
            <Text
              color={textColor}
              mb='6px'
              fontSize='15px'
              fontWeight='bold'
              lineHeight='100%'>
              Pontuação:
            </Text>

            <Text
              color={textColor}
              mb='6px'
              fontSize='15px'
              fontWeight='normal'
              lineHeight='100%'>
              Beecrowd: 0-100
            </Text>

            <Text
              color={textColor}
              mb='6px'
              fontSize='15px'
              fontWeight='normal'
              lineHeight='100%'>
              Entrevista S.S: 0-50
            </Text> 

            <Text
              color={textColor}
              mb='6px'
              fontSize='15px'
              fontWeight='normal'
              lineHeight='100%'>
              Projetos: 0-100
            </Text> 

            <Text
              color={textColor}
              mb='6px'
              fontSize='15px'
              fontWeight='normal'
              lineHeight='100%'>
              Presença: 0-50
            </Text>

            <Text
              color={textColor}
              mb='6px'
              fontSize='15px'
              fontWeight='normal'
              lineHeight='100%'>
              Fórum: 0-50
            </Text> 
          </Flex>
          </>
        : null}

      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='5px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr 
                {...row.getRowProps()} 
                key={index}
              >
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "POSITION") {
                    data = (
                      <Text style={{"textAlign": "left"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}°

                        {/* PROBLEMA QUANDO PESQUISA POR NOME, AI ELE SETA A POSICAO ERRADA */}
                        {/* {cell.row.index + 1}° */}
                      </Text>
                    );
                  } else if (cell.column.Header === "NAME") {
                    data = (
                      <div
                        style={{
                          "display": "flex",
                          "flexDirection": "row",
                          "alignItems": "center"
                        }}
                      >
                        <img 
                          style={{
                            "borderRadius": "100px",
                            "marginRight": "6px",
                            "width": "34px", 
                            "height": "34px"
                          }}
                          src={cell?.value != null && cell?.value != '' && cell?.value?.split(';split;')[0] != 'picture_empty' ? cell?.value?.split(';split;')[0] : avatarProfile} 
                          width="30" 
                          alt="Picture" 
                        />
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {getName(cell?.value?.split(';split;')[1])}
                        </Text>
                      </div>
                    );
                  } else if (cell.column.Header === "BADGE") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value == 'platina' && <span style={{"padding": "2px 12px", "backgroundColor": "rgb(102, 102, 102)", "borderRadius": "4px", "color": "#fff"}}>{cell.value}</span>}
                          {cell.value == 'ouro' && <span style={{"padding": "2px 12px", "backgroundColor": "gold", "borderRadius": "4px", "color": "#fff"}}>{cell.value}</span>}
                          {cell.value == 'prata' && <span style={{"padding": "2px 12px", "backgroundColor": "silver", "borderRadius": "4px", "color": "#fff"}}>{cell.value}</span>}
                          {cell.value == 'bronze' && <span style={{"padding": "2px 12px", "backgroundColor": "#cd7f32", "borderRadius": "4px", "color": "#fff"}}>{cell.value}</span>}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "BEECROWD") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "FIRST_INTERVIEW") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "SECOND_INTERVIEW") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "PRACTICAL_PROJECTS") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "QTY_ATTENDANCES_MEET") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value ? cell.value : 0.00}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "FORUM") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "TOTAL") {
                    data = (
                      <Flex align='center'>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } 
                  // else if (cell.column.Header === "ACTION") {
                  //   data = (
                  //     <Flex align='center'>
                  //         <ButtonGroup gap='4'>
                  //           <Link
                  //             color={'brandScheme'}
                  //             fontWeight='500'
                  //             me={{ base: "34px", md: "44px" }}
                  //             href={`/#/admin/aluno/${cell.value}`}>
                  //             <Button colorScheme='brandScheme'><FaSearchPlus /></Button>
                  //           </Link>
                  //         </ButtonGroup>
                  //     </Flex>
                  //   );
                  // }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      style={{"paddingRight": "3px"}}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (dataRanking?.total > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>
    </>
  );
}
