import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import ComplexTable from "views/admin/forms_details/components/ComplexTable";
import {
  columnsDataComplex,
} from "views/admin/forms_details/variables/columnsData";

import { API_SERVER } from "config/constant";

export default function FormsDetails() {
  const hash = window.location.hash;
  const id = hash.split('/form/');
  const formId = id[1] ? id[1]?.split('/')[0] : 0;

  const programIdStorage = localStorage.getItem('programId');

  const INITIAL_FILTERS = {
    name: '',
    formId: formId,
  }

  const [loading, setLoading] = useState(false);
  const [loadForms, setLoadForms] = useState(false);
  const [refreshForms, setRefreshForms] = useState(false);

  const [filters, setFilters] = useState(INITIAL_FILTERS);
  
  const [forms, setForms] = useState([]);
  const [dataForms, setDataForms] = useState({
    total: 0,
    average_rating: `0 / 5`,
    form_feedback_ai: '',
    form_date: '',
  });

  useEffect(() => { 
    window.scrollTo(0, 0);

    const getForms = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/forms/${formId}/presence`, {
        params: {
          name: filters.name,
          formId: formId,
        }
      });

      const { result } = data;
      const { form_date, form_feedback_ai, average_rating } = result[0] || '';

      setDataForms({
        total: result?.length,
        average_rating: `${average_rating ? average_rating : 0} / 5`,
        form_feedback_ai: form_feedback_ai ? form_feedback_ai : '',
        form_date: form_date
      })
      setForms(result);
      setRefreshForms(false);
      setLoading(false);
    }

    getForms();
  }, [refreshForms])

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    
    setFilters({ ...filters, [name]: ev.target.value });
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getForms = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/forms/${formId}/presence`, {
        params: {
          name: filters.name,
          formId: formId,
        }
      });

      const { result } = data;
      const { form_date, form_feedback_ai, average_rating } = result[0] || '';

      setDataForms({
        total: result?.length,
        average_rating: `${average_rating ? average_rating : 0} / 5`,
        form_feedback_ai: form_feedback_ai ? form_feedback_ai : '',
        form_date: form_date
      })
      setForms(result);
      setLoadForms(false);
    }

    setLoadForms(true);
    getForms();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <ComplexTable
            programId={programIdStorage}
            onChangeCompanies={setRefreshForms}
            onChangeFilter={handleChangeFilter}
            submitFilter={handleSubmitFilter}
            clearFilters={() => setFilters(INITIAL_FILTERS)}
            filtersData={filters}
            loadUsersByFilter={loadForms}
            columnsData={columnsDataComplex}
            tableData={forms}
            dataCompanies={dataForms}
            formId={formId}
          />
        </SimpleGrid>
      </Box>
    </>
  );
}
