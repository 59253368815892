import React, { useState } from "react";
import { Avatar, Box, Flex, Text, useColorModeValue, Link, Button, Input } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { FaCheck, FaEdit, FaGithub, FaLinkedin } from "react-icons/fa";
import { BsPersonBadge } from "react-icons/bs";
import { TbIdBadge, TbIdBadge2 } from "react-icons/tb";
import { MdOutlineBadge, MdSchool } from "react-icons/md";
import { IoSchoolOutline } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Banner({ userId, status, currentSituation, banner, avatar, name, course, githubUrl, linkedinUrl, isEdit, setIsEdit, handleUpdateProfile, isMonitor = false }) {
  // const { banner, avatar, name, job } = props;
  const [nameBefore, setNameBefore] = useState(name);
  
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      {/* {!isEdit ?
        <Button
          onClick={() => setIsEdit(!isEdit)}
          style={{
              "height": "auto",
              "padding": "8px 15px",
              "backgroundColor": "rgb(206, 17, 81)", 
              "fontWeight": "normal",
              "fontSize": "11px",
              "borderRadius": "100px",
              "color": "#fff", 
              "position": "absolute",
              "top": "40px",
              "right": "40px",
            }}
          >
          <FaEdit size={14} style={{"marginRight": "6px"}} /> 
          Editar
        </Button>
      : 
          <div 
            style={{
              "display": "flex",
              "justifyContent": "flex-end",
              "alignItems": "center",
              "position": "absolute",
              "top": "40px",
              "right": "40px",
            }}
          >
            <Button
              onClick={handleUpdateProfile}
              style={{
                  "height": "auto",
                  "padding": "8px 15px",
                  "backgroundColor": "rgb(206, 17, 81)", 
                  "fontWeight": "normal",
                  "fontSize": "11px",
                  "borderRadius": "100px",
                  "color": "#fff", 
                }}
                mr={2}
              >
              <FaCheck size={14} style={{"marginRight": "6px"}} /> 
              Salvar
            </Button>
            <Button
              onClick={() => setIsEdit(!isEdit)}
              style={{
                  "height": "auto",
                  "padding": "8px 15px",
                  "backgroundColor": "#f1f1f1", 
                  "fontWeight": "normal",
                  "fontSize": "11px",
                  "borderRadius": "100px",
                  "color": "rgb(206, 17, 81)", 
                }}
              >
              <AiOutlineCloseCircle size={14} style={{"marginRight": "6px"}} /> 
              Cancelar
            </Button>
          </div>
      } */}
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='80px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      /> 
      <Text style={{"textTransform": "capitalize"}} color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {nameBefore}
      </Text>
      
      <Flex w='100%' justify='center' mt='15px'>
        {/* {isMonitor ? 
          <Text 
            mr='5px'
            style={{
              "display": "flex",
              "alignItems": "center",
              "justifyContent": "center",
              "fontWeight": "bold",
              "backgroundColor": "#ce1151",
              "padding": "5px 10px",
              "borderRadius": "100px",
              "color": "#fff",
              "fontSize": "13px"
            }} 
            color={textColorSecondary}  
            fontSize='sm'
          >
            <TbIdBadge2 size={15} style={{"marginRight": "5px"}} />
            Monitor (a)
          </Text> 
        : null} */}

        {/* {course && currentSituation == 'contratado' ?
          <Text 
            style={{
              "display": "flex",
              "alignItems": "center",
              "justifyContent": "center",
              "fontWeight": "bold",
              "backgroundColor": "#2759A5",
              "padding": "5px 15px",
              "borderRadius": "100px",
              "color": "#fff",
              "fontSize": "13px"
            }} 
            color={textColorSecondary} 
            fontSize='sm'
          >
            <MdSchool size={15} style={{"marginRight": "5px"}} />
            {course}
          </Text>
        : null} */}

        {githubUrl ? 
            <>
            <Link
            ml='5px'
            target={'_blank'}
            href={githubUrl}>
              <Button 
                style={{
                  "height": "auto",
                  "padding": "8px 15px",
                  "backgroundColor": "#000", 
                  "boxShadow": "none",
                  "fontWeight": "normal",
                  "fontSize": "11px",
                  "borderRadius": "100px",
                  "color": "#fff", 
                  }}
                >
                <FaGithub size={14} style={{"marginRight": "6px"}} /> 
                  GitHub
              </Button>
            </Link>
            {linkedinUrl && (<Link
                style={{marginLeft: '5px'}}
                target={'_blank'}
                href={linkedinUrl}>
                  <Button 
                    style={{
                      "height": "auto",
                      "padding": "8px 15px",
                      "boxShadow": "none",
                      "fontWeight": "normal",
                      "fontSize": "11px",
                      "borderRadius": "100px",
                      "color": "#fff", 
                    }}
                    colorScheme='brandScheme'
                    ><FaLinkedin size={14} style={{"marginRight": "6px"}} /> Linkedin</Button>
                </Link>
              )}
            </>
        : null}
      </Flex>
      {/* {githubUrl && isMonitor && currentSituation == 'contratado' && ( 
        <Flex w='100%' justify='center' mt='8px'>
          <Link
          ml='5px'
          target={'_blank'}
          href={githubUrl}>
            <Button 
              style={{
                "height": "auto",
                "padding": "8px 15px",
                "backgroundColor": "#000", 
                "boxShadow": "none",
                "fontWeight": "normal",
                "fontSize": "11px",
                "borderRadius": "100px",
                "color": "#fff", 
                }}
              >
              <FaGithub size={14} style={{"marginRight": "6px"}} /> 
                GitHub
            </Button>
          </Link>
        </Flex>
      )}

      {(linkedinUrl && isMonitor) && (<Link
        mt='6px'
        style={{marginLeft: '5px'}}
        target={'_blank'}
        href={linkedinUrl}>
          <Button 
            style={{
              "height": "auto",
              "padding": "8px 15px",
              "boxShadow": "none",
              "fontWeight": "normal",
              "fontSize": "11px",
              "borderRadius": "100px",
              "color": "#fff", 
            }}
            colorScheme='brandScheme'
            ><FaLinkedin size={14} style={{"marginRight": "6px"}} /> Linkedin</Button>
        </Link>
      )}  */}
    </Card>
  );
}
