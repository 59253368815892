export const tableColumnsTeachers = [
    {
      Header: "Name",
      label: 'Professor',
      accessor: "teacher_name",
    },
    {
      Header: "Subjects",
      label: 'Assunto',
      accessor: "teacher_subjects",
    }
  ];
  