import React, { useMemo, useState, useEffect } from "react";
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../../store/students/filtersActions';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ReactSelect, { StylesConfig } from 'react-select';
import ClipLoader from "react-spinners/ClipLoader";
import { saveAs } from 'file-saver';
import chroma from "chroma-js";
import * as XLSX from 'xlsx';

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import Pagination from "./pagination";

// Assets
import {MdGroups,  MdCheckCircle, MdCancel, MdOutlineWork, MdSchool, MdOutlineChat, MdScore, MdPeople } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaCheck, FaCheckCircle, FaFemale, FaFilter, FaMale, FaSearchPlus, FaUser, FaUserGraduate, FaUsers } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { SiGoogleclassroom } from "react-icons/si";

import avatarProfile from 'assets/img/avatars/avatar.png';
import { BsChevronDown, BsChevronUp, BsFillExclamationCircleFill, BsFillHandThumbsDownFill, BsFillQuestionCircleFill } from "react-icons/bs";
import ClassLeaders from "views/admin/classLeaders";
import { TbIdBadge2 } from "react-icons/tb";
import PdfDataExtractor from "components/upload-pdf";

export default function ColumnsTable(props) {
  const { 
    columnsData, 
    tableData, 
    dataCourse, 
    programId,
    filtersData,
    usersCities,
    loadingCities,
    loadUsersByFilter,
    allHardSkills,
    onChangeProgramId,
    onChangeFilter, 
    onChangeHardSkills,
    submitFilter,
    clearFilters
  } = props;

  const currentPage = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();

  const [openFilters, setOpenFilters] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage - 1 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const handleSubmitFilter = (ev) => {
    dispatch(setCurrentPage(1));
    gotoPage(0);

    setOpenFilters(false);
    submitFilter(ev);
  }

  useEffect(() => {
    const isVeryfyFilter = (filter) => {
      let status = false;

      Object.keys(filtersData)?.map((key) => {
        if (key == 'name' || key == 'email') {
          if (filtersData[key] !== '') {
            status = true;
          }
        } 

        if (key == 'gender' || key == 'classroomId' || key == 'status'
        || key == 'situation' || key == 'uf' || key == 'city' 
        || key == 'practical_projects' || key == 'hard_skills' ) {
          if (filtersData[key] !== 'all' && filter !== '') {
            status = true;
          }
        }
      });

      return status;
    }

    if (openFilters == false) {
      if (isVeryfyFilter(filtersData)) {
        setFiltersActive(true);
      } else {
        setFiltersActive(false);
      }
    }
  }, [filtersData, openFilters]);


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const colourOptions = [
    { value: 'angular', label: 'Angular', color: '#000000' },
    { value: 'angularjs', label: 'AngularJS', color: '#000000' },
    { value: 'vuejs', label: 'Vue.js', color: '#000000' },
    { value: 'reactjs', label: 'React.js', color: '#000000' },
    { value: 'javascript', label: 'JavaScript', color: '#000000' },
    { value: 'layout-responsivo', label: 'Layout Responsivo', color: '#000000' },
    { value: 'css', label: 'CSS', color: '#000000' },
    { value: 'html', label: 'HTML', color: '#000000' },
    { value: 'rubyonrails', label: 'Ruby on Rails', color: '#000000' },
    { value: 'kotlin', label: 'Kotlin', color: '#000000' },
    { value: 'rust', label: 'Rust', color: '#000000' },
    { value: 'go', label: 'Go', color: '#000000' },
    { value: 'graphql', label: 'GraphQL', color: '#000000' },
    { value: 'nestjs', label: 'Nest.js', color: '#000000' },
    { value: 'laravel', label: 'Laravel', color: '#000000' },
    { value: 'php', label: 'PHP', color: '#000000' },
    { value: 'springboot', label: 'Spring Boot', color: '#000000' },
    { value: 'java', label: 'Java', color: '#000000' },
    { value: 'dotnetcore', label: '.NET Core', color: '#000000' },
    { value: 'dotnet', label: '.NET Framework', color: '#000000' },
    { value: 'python', label: 'Python', color: '#000000' },
    { value: 'nodejs', label: 'Node.js', color: '#000000' },
    { value: 'typescript', label: 'TypeScript', color: '#000000' },
    { value: 'csharp', label: 'C#', color: '#000000' },
    { value: 'xamarin', label: 'Xamarin', color: '#000000' },
    { value: 'objective-c', label: 'Objective-C', color: '#000000' },
    { value: 'flutter', label: 'Flutter', color: '#000000' },
    { value: 'swift', label: 'Swift', color: '#000000' },
    { value: 'react-native', label: 'React Native', color: '#000000' },
    { value: 'couchbase', label: 'Couchbase', color: '#000000' },
    { value: 'oracle', label: 'Oracle', color: '#000000' },
    { value: 'mongodb', label: 'MongoDB', color: '#000000' },
    { value: 'postgres', label: 'PostgreSQL', color: '#000000' },
    { value: 'mysql', label: 'MySQL', color: '#000000' },
    { value: 'sqlserver', label: 'SQL Server', color: '#000000' },
    { value: 'ansible', label: 'Ansible', color: '#000000' },
    { value: 'gradle', label: 'Gradle', color: '#000000' },
    { value: 'aws_autoscale', label: 'AWS Autoscale', color: '#000000' },
    { value: 'cicd', label: 'CI/CD', color: '#000000' },
    { value: 'kubernetes', label: 'Kubernetes', color: '#000000' },
    { value: 'azure', label: 'Azure', color: '#000000' },
    { value: 'aws', label: 'AWS', color: '#000000' },
    { value: 'docker', label: 'Docker', color: '#000000' },
    { value: 'git', label: 'Git', color: '#000000' },
    { value: 'mocha', label: 'Mocha', color: '#000000' },
    { value: 'sonar', label: 'Sonar', color: '#000000' },
    { value: 'nunit', label: 'NUnit', color: '#000000' },
    { value: 'jasmine', label: 'Jasmine', color: '#000000' },
    { value: 'xunit', label: 'xUnit', color: '#000000' },
    { value: 'phpunit', label: 'PHPUnit', color: '#000000' },
    { value: 'junit', label: 'JUnit', color: '#000000' },
    { value: 'jest', label: 'Jest', color: '#000000' },
    { value: 'reactnative-testing-library', label: 'React Native Testing Library', color: '#000000' },
    { value: 'tdd', label: 'TDD', color: '#000000' },
  ];

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginBottom: '20px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  const getGender = (gender) => {
    let userGender = '';

    switch (gender) {
      case 'male':
        userGender = 'Masculino';
        break;

      case 'female':
        userGender = 'Feminino';
        break;
    
      default:
        break;
    }

    return userGender;
  }

  const getSituation = (current_situation) => {
    let userSituation = '';

    switch (current_situation) {
      case 'banco_talentos':
        userSituation = 'Banco de Talentos';
        break;

      case 'indisponivel':
        userSituation = 'Indisponível';
        break;

      case 'indicado':
        userSituation = 'Indicado';
        break;

      case 'contratado':
        userSituation = 'Contr. Direto';
        break;

      case 'contratado_headhunting':
        userSituation = 'Contr. Head Hunting';
        break;

      case 'contratado_indireto':
        userSituation = 'JobOut';
        break;
    
      default:
        break;
    }

    return userSituation;
  }

  const getStatus = (status) => {
    let userStatus = '';

    switch (status) {
      case 'em_formacao':
        userStatus = 'Em formação';
        break;

      case 'desligado':
        userStatus = 'Desligado';
        break;

      case 'fora_programa':
        userStatus = 'Desistente';
        break;

      case 'formado':
        userStatus = 'Formado';
        break;
    
      default:
        break;
    }

    return userStatus;
  }

  function exportXLSX() {    
    if (loadingReport) { 
      return false;
    }

    setLoadingReport(true);

    const arrayOfArrays = tableData?.map(obj => [
      obj.name.toUpperCase(),
      obj.email,
      getGender(obj.gender),
      obj.age,
      obj.cell_phone?.replace(/^(\d{2})(\d)/g,"($1) $2")?.replace(/(\d)(\d{4})$/,"$1-$2"),
      `https://api.whatsapp.com/send?phone=55${obj.cell_phone}`,
      obj.city,
      obj.uf,
      obj.schooling,
      obj.formation,
      obj.course,
      getStatus(obj.status),
      getSituation(obj.current_situation),
      obj.email ? 'Sim' : 'Não'
    ]);
    arrayOfArrays.unshift(['Nome', 'Email', 'Sexo', 'Idade', 'Telefone', 'Link WhatsApp', 'Cidade', 'Estado', 'Escolaridade', 'Formação', 'Turma', 'Status', 'Situação', 'Cadastro Confirmado?']);

    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Alunos');
    const fileName = 'alunos.xlsx';
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);

    setLoadingReport(false);
  }

  const isFilterActive = (filters) => {
    if (
      filters.name == '' ||
      filters.email == ''
    ) {
      return filters.name;
    }
  
    // Verifica se o filtro de cidades possui algum valor selecionado
    if (filters.cities.length === 0) {
      return `false_2`;
    }
  
    // Todos os filtros estão preenchidos
    return `true`;
  }

  return (
    <>
      <Flex px='0px' justify='space-between' mt='10px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='0px'>
          <Select
            defaultValue={programId} 
            name="programId"
            onChange={onChangeProgramId}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            mb='0px'
            fontWeight='500'
            size='lg'
            placeholder="Selecione um programa" 
            bg={'white'}
          >
            <option value="3" selected={programId == 3 ? true : false}>Todas Tech</option>
            <option value="2" selected={programId == 2 ? true : false}>SharpCoders</option>
            <option value="1" selected={programId == 1 ? true : false}>GoDev 2022</option>
          </Select>
        </SimpleGrid>
      </Flex>

      {/* <Tabs size='md' variant='enclosed' mt='0px'>
        <TabList>
          <Tab><MdPeople w={6} color="gray.500" style={{marginRight: 5}} /><b>Todos</b></Tab>
          <Tab><TbIdBadge2 w={6} color="gray.500" style={{marginRight: 5}} /><b>Monitores</b></Tab>
        </TabList>
        <TabPanels>
          <TabPanel> */}
      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 4, lg: 5, "2xl": 5 }}
          gap='20px'
          mb='0px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdGroups} color={'#1f2471'} />
                }
              />
            }
            name='Total'
            value={dataCourse?.total}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaMale} color={'#4040ff'} />
                }
              />
            }
            name='Masculino'
            value={dataCourse?.genderMale}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaFemale} color={'#ff6781'} />
                }
              />
            }
            name='Feminino'
            value={dataCourse?.genderFemale}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={SiGoogleclassroom} color={'#666666'} />
                }
              />
            }
            name='Turma 1'
            value={dataCourse?.turma1}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={SiGoogleclassroom} color={'#b8860b'} />
                }
              />
            }
            name='Fast Track'
            value={dataCourse?.fastTrack}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdSchool} color={'#ff8000'} />
                }
              />
            }
            name='Em formação'
            value={dataCourse?.studentsEmFormacao}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdCancel} color={'#2f2f2f'} />
                }
              />
            }
            name='Desligados'
            value={dataCourse?.desligados}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={BsFillHandThumbsDownFill} color={'#e32636'} />
                }
              />
            }
            name='Desistências'
            value={dataCourse?.inativos}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdCheckCircle} color={'#2e8b57'} />
                }
              />
            }
            name='Formados'
            value={dataCourse?.studentsFormados}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdOutlineWork} color={'#5C4033'} />
                }
              />
            }
            name='Contr. Diretos'
            value={dataCourse?.contratados}
          />
        </SimpleGrid>
      </Box>
    </Card>

    <Button
        colorScheme="brandScheme"
        variant={!openFilters ? 'solid' : 'outline'}
        onClick={() => setOpenFilters(!openFilters)}
        width={150}
      >
        {!openFilters ? 
          <>
            <FaFilter size={12} style={{marginRight: '4px'}} />
            {`Filtro ${filtersActive ? '(1)' : ''}`}
            <BsChevronDown style={{marginLeft: '10px'}} />
          </>
        :
          <>
            <FaFilter size={12} style={{marginRight: '4px'}} />
            {`Filtro ${filtersActive ? '(1)' : ''}`}
            <BsChevronUp style={{marginLeft: '10px'}} />
          </>
        }
    </Button>
    {openFilters ? 
    <Card
      direction='column'
      w='100%'
      px='0px'
      mb='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
      overflowY={{ sm: "scroll", lg: "hidden" }}
      >
      <form onSubmit={handleSubmitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 4, xl: 4 }} gap='20px' mb='0px'>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input 
              name="name" 
              value={filtersData.name || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input 
              name="email"
              value={filtersData.email || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Sexo</FormLabel>
            <Select
              name="gender"
              value={filtersData.gender || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Turma</FormLabel>
            <Select 
              name="classroomId"
              value={filtersData.classroomId || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              {programId == 1 && <option value={'515791119906'}>Turma 1</option>}
              {programId == 2 && <option value={'568596689920'}>Turma 1</option>}
              {programId == 3 && <option value={'123456'}>Turma 1</option>}
              {programId == 1 && <option value={'520438946382'}>Fast Track</option>}
              {programId == 2 && <option value={'491982126547'}>Fast Track</option>}
              {programId == 3 && <option value={'123456789'}>Fast Track</option>}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>UF</FormLabel>
            <Select
              name="uf"
              value={filtersData.uf || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Cidade</FormLabel>
            <Select
              name="city"
              value={filtersData.city || ''}
              onChange={onChangeFilter}
              disabled={loadingCities}
            >
              <option value="all">{loadingCities ? 'Carregando...' : 'Todos'}</option>
              {usersCities?.map((data) => (
                <option value={data.city}>{data.city}</option>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={filtersData.status || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="em_formacao">Em formação</option>
              <option value="fora_programa">Desistência</option>
              <option value="desligado">Desligado</option>
              <option value="formado">Formado</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Situação</FormLabel>
            <Select
              name="situation"
              value={filtersData.situation || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="indisponivel">Indisponível</option>
              <option value="banco_talentos">Banco de talentos</option>
              <option value="indicado">Indicado</option>
              <option value="contratado">Contratado Direto</option>
              <option value="contratado_headhunting">Contratado Head Hunting</option>
              <option value="contratado_indireto">JobOut</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Projetos práticos</FormLabel>
            <Select
              name="practical_projects"
              value={filtersData.practical_projects || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              {programId == 4 && <option value="todas-tech-4-task-hackadev">Hackadev 4° Task</option>}
              {programId == 3 && <option value="todas-tech-3-task-hackadev">Hackadev 3° Task</option>}
              {programId == 3 && <option value="todsas-tech-2-task-hackadev">Hackadev 2° Task</option>}
              {programId == 3 && <option value="todsas-tech-1-task-hackadev">Hackadev 1° Task</option>}
              {programId == 3 && <option value="todas-tech-desafio-fast-track">Desafio Fast Track / Monitoria</option>}
              {programId == 3 && <option value="todas-tech-clone-spotify">Clone do Spotify</option>}
              {programId == 3 && <option value="todas-tech-clone-tinder">Clone do Tinder</option>}
              {programId == 2 && <option value="hackadev-sharp-4">Hackadev 4° Task</option>}
              {programId == 2 && <option value="hackadev-sharp-3">Hackadev 3° Task</option>}
              {programId == 2 && <option value="hackadev-sharp-2">Hackadev 2° Task</option>}
              {programId == 2 && <option value="hackadev-sharp-1">Hackadev 1° Task</option>}
              {programId == 2 && <option value="game-hub-fase-1">GameHub Fase 1</option>}
              {programId == 2 && <option value="fast-track-monitor-sharpcoders">Desafio Fast Track / Monitoria</option>}
              {programId == 2 && <option value="byte-bank">Byte Bank</option>}
              {programId == 1 && <option value="related-products">Produtos relacionados</option>}
            </Select>
          </FormControl>

          <FormControl gridColumn={{ base: '1', md: '2 / span 3', xl: '2 / span 3' }}>
            <FormLabel>Hard Skills</FormLabel>
            <ReactSelect
              name="hard_skills"
              placeholder='Buscar...'
              defaultValue={[]}
              closeMenuOnSelect={false}
              isDisabled={false}
              isMulti
              options={colourOptions}
              styles={colourStyles}
              onChange={(choice) => { onChangeHardSkills(choice) }}
              menuPlacement="auto"
              menuPosition="fixed"
              maxMenuHeight={200}
            />
          </FormControl>
        </SimpleGrid>
      </Flex>

      <Flex px='25px' justify='space-between' mt='25px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 4, xl: 4 }} gap='20px' mb='0px'>
        <FormControl>
            {/* <FormLabel style={{"color": "transparent"}}>.</FormLabel> */}
            <ButtonGroup gap='2'>
              <Button 
                type="submit"
                onClick={handleSubmitFilter} 
                colorScheme='brandScheme'
              >
               {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
        </Flex>
      </form>
    </Card>
    :
      <></>
    }

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Alunos
        </Text>

        <Flex align='center'>
            {/* <PdfDataExtractor /> */}

          <Button 
            onClick={exportXLSX}
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Exportar
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        {!loadUsersByFilter &&
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "PICTURE") {
                    data = (
                      <img 
                        style={{"borderRadius": "100px", 
                          "width": "40px", 
                          "height": "40px"
                        }}
                        src={cell?.value != null && cell?.value != '' ? cell.value : avatarProfile} 
                        width="40" 
                        alt="Picture" 
                      />
                    );
                  } else if (cell.column.Header === "NAME") {
                    const studentGender = row.original.gender;
                    const studentCourse = row.original.course;

                    data = (
                      <>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='xs' fontWeight='normal' mb='0px'>
                          {getGender(studentGender)} • {studentCourse}
                        </Text>
                      </>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value === 'em_formacao' && <p style={{"color": "#fff", "backgroundColor": "#ff8000", "padding": "2px 5px", "borderRadius": "6px"}}>Em formação</p>}
                          {cell.value === 'fora_programa' && <p style={{"color": "#fff", "backgroundColor": "#e32636", "padding": "2px 5px", "borderRadius": "6px"}}>Desistente</p>}
                          {cell.value === 'desligado' && <p style={{"color": "#fff", "backgroundColor": "#2f2f2f", "padding": "2px 5px", "borderRadius": "6px"}}>Desligado</p>}
                          {cell.value === 'formado' && <p style={{"color": "#fff", "backgroundColor": "#2e8b57", "padding": "2px 5px", "borderRadius": "6px"}}>Formado</p>}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "SITUATION") {
                    data = (
                      <Flex 
                        align='center' 
                        // style={{"justifyContent": "center"}}
                      >
                        <Text 
                          style={(cell.value === 'contratado' || cell.value === 'contratado_headhunting' || cell.value === 'contratado_indireto') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": cell.value === 'contratado_indireto' ? "#e32636" : "#5C4033", 
                            "padding": "2px 5px", 
                            "borderRadius": "6px"
                          } : (cell.value === 'banco_talentos') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#4169E1", 
                            "padding": "2px 5px",  
                            "borderRadius": "6px"
                          } : (cell.value === 'indicado') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#ff8000", 
                            "padding": "2px 5px",  
                            "borderRadius": "6px"
                          } : (cell.value === 'indisponivel') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#cccccc", 
                            "padding": "2px 5px",  
                            "borderRadius": "6px"
                          } : { 

                          }}
                          fontSize='sm' 
                          fontWeight='700'
                        >
                          {getSituation(cell.value)}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "REGISTER") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {cell.value ? (
                          <FaCheckCircle size={20} color="#2E8B57" />
                        ) : ( 
                          <FaCheckCircle size={20} color="gray" />
                        )}
                      </Text>
                    );
                  } else if (cell.column.Header === "LAST_PRESENCE") {
                    // const qtyPresence = row.original.qty_presence;
                    const qtyPresenceConfirmed = cell?.value?.split('/');
                    const totalAulas = qtyPresenceConfirmed[1] / 3;
                    data = programId == 3 ? (
                      <Text 
                        color={qtyPresenceConfirmed[0] <= totalAulas ? 'red' 
                        : qtyPresenceConfirmed[0] > totalAulas && qtyPresenceConfirmed[0] <= totalAulas * 2 ? 
                          '#FFA701'
                        : qtyPresenceConfirmed[0] > totalAulas * 2 ? 'green' : textColor} 
                        fontSize='sm' 
                        fontWeight='700'
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                        {cell.value}
                      </Text>
                    ) : (
                      <Text 
                        color={textColor} 
                        fontSize='sm' 
                        fontWeight='700'>
                        -
                      </Text>
                    )
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Flex align='center'>
                          <ButtonGroup gap='1'>
                            <Link
                              color={'#ffffff'}
                              fontWeight='500'
                              me={{ base: "0px", md: "0px" }}
                              href={`/#/admin/aluno/${cell.value}/${programId}`}>
                              <Button colorScheme='brandScheme'><FaSearchPlus /></Button>
                            </Link>
                            <Link
                              color={'brandScheme'}
                              fontWeight='500'
                              me={{ base: "0px", md: "0px" }}
                              href={`/#/admin/entrevista/aluno/${cell.value}/${programId}`}>
                              <Button style={{"backgroundColor": "#666666", "color": "#ffffff"}}><MdOutlineChat /></Button>
                            </Link>
                          </ButtonGroup>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>}
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader 
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (tableData?.length > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            currentPage={initialState.pageIndex + 1}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>
    {/* </TabPanel>

    <TabPanel>
      <ClassLeaders />
    </TabPanel>
    </TabPanels>
    </Tabs> */}
    </>
  );
}
