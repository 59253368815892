export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "PICTURE",
    label: 'Foto',
    accessor: "picture",
  },
  {
    Header: "NAME",
    label: 'Nome',
    accessor: "name",
  },
  {
    Header: "PROGRAM",
    label: 'Programa',
    accessor: "program_name",
  },
  {
    Header: "STATUS",
    label: 'Status',
    accessor: "status",
  },
  {
    Header: "SITUATION",
    label: 'Situação',
    accessor: "current_situation",
  },
  {
    Header: "LAST_UPDATED_AT",
    label: 'Atualizado',
    accessor: "last_updated_at",
  },
  {
    Header: "ACTION",
    label: 'Ações',
    accessor: "user_id",
  },
];
