import React, { useMemo, useState, useEffect } from "react";
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../../store/students/filtersActions';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ReactSelect, { StylesConfig } from 'react-select';
import ClipLoader from "react-spinners/ClipLoader";
import { saveAs } from 'file-saver';
import chroma from "chroma-js";
import * as XLSX from 'xlsx';

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import Pagination from "./pagination";
import AliceAICompanies from "../../aliceAICompanies";

// Assets
import {MdGroups,  MdCheckCircle, MdCancel, MdOutlineWork, MdSchool, MdOutlineChat, MdScore, MdPeople } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaCheck, FaCheckCircle, FaClipboardList, FaFemale, FaFilter, FaHtml5, FaLaptopCode, FaMale, FaPlus, FaSearchPlus, FaUniversity, FaUser, FaUserCheck, FaUserGraduate, FaUsers } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { SiGoogleclassroom } from "react-icons/si";

import avatarProfile from 'assets/img/avatars/avatar.png';
import { BsChevronDown, BsChevronUp, BsEmojiNeutral, BsFillHandThumbsDownFill, BsFillPieChartFill } from "react-icons/bs";
import ClassLeaders from "views/admin/classLeaders";
import { TbIdBadge2 } from "react-icons/tb";
import TableFeedbacks from "./tableFeedbacks";
import ModalAddTestes from "./ModalAddTestes";
import { BiHappyAlt, BiSad } from "react-icons/bi";

export default function ColumnsTable(props) {
  const { 
    columnsData, 
    columnsDataFeedbacks,
    tableData, 
    dataCourse, 
    programId,
    filtersData,
    usersCities,
    loadingCities,
    loadUsersByFilter,
    allHardSkills,
    onChangeProgramId,
    onChangeFilter, 
    onChangeHardSkills,
    submitFilter,
    clearFilters
  } = props;

  const currentPage = useSelector((state) => state.currentPage);
  const dispatch = useDispatch();

  const [openFilters, setOpenFilters] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const [openModalQuestions, setOpenModalQuestions] = useState(false);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage - 1 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const handleSubmitFilter = (ev) => {
    dispatch(setCurrentPage(1));
    gotoPage(0);

    setOpenFilters(false);
    submitFilter(ev);
  }

  useEffect(() => {
    const isVeryfyFilter = (filter) => {
      let status = false;

      Object.keys(filtersData)?.map((key) => {
        if (key == 'name' || key == 'email') {
          if (filtersData[key] !== '') {
            status = true;
          }
        } 

        if (key == 'gender' || key == 'classroomId' || key == 'status'
        || key == 'situation' || key == 'uf' || key == 'city' 
        || key == 'practical_projects' || key == 'hard_skills' ) {
          if (filtersData[key] !== 'all' && filter !== '') {
            status = true;
          }
        }
      });

      return status;
    }

    if (openFilters == false) {
      if (isVeryfyFilter(filtersData)) {
        setFiltersActive(true);
      } else {
        setFiltersActive(false);
      }
    }
  }, [filtersData, openFilters]);


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const isFilterActive = (filters) => {
    if (
      filters.name == '' ||
      filters.email == ''
    ) {
      return filters.name;
    }
  
    // Verifica se o filtro de cidades possui algum valor selecionado
    if (filters.cities.length === 0) {
      return `false_2`;
    }
  
    // Todos os filtros estão preenchidos
    return `true`;
  }

  return (
    <>
      <Tabs size='md' variant='enclosed' mt='0px'>
        <TabList>
          <Tab><MdPeople w={6} color="gray.500" style={{marginRight: 5}} /><b>Avaliações</b></Tab>
          <Tab><FaUniversity w={6} color="gray.500" style={{marginRight: 5}} /><b>Instituições de Ensino</b></Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 4, lg: 5, "2xl": 5 }}
          gap='20px'
          mb='0px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdGroups} color={'#1f2471'} />
                }
              />
            }
            name='Total'
            value={dataCourse?.total}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaLaptopCode} color={'#222222'} />
                }
              />
            }
            name='Aval. Tech'
            value={dataCourse?.totalTech}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaUserCheck} color={'#222222'} />
                }
              />
            }
            name='Aval. Comp.'
            value={dataCourse?.totalComp}
          />
        </SimpleGrid>
        <SimpleGrid
        columns={{ base: 1, md: 4, lg: 4, "2xl": 3 }}
        gap='20px'
        mt='20px'
        mb='0px'
        >
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={BsFillPieChartFill} color={'rgb(65, 105, 225)'} />
                }
              />
            }
            name='NPS'
            value={'51.72'}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={BiSad} color={'#e32636'} />
                }
              />
            }
            name='Detratores (NPS)'
            value={'20.69%'}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='30px' h='30px' as={BsEmojiNeutral} color={'#666666'} />
                }
              />
            }
            name='Passivos (NPS)'
            value={'6.90%'}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={BiHappyAlt} color={'rgb(46, 139, 87)'} />
                }
              />
            }
            name='Promotores (NPS)'
            value={'72.41%'}
          />
        </SimpleGrid>
      </Box>
    </Card>

    {/* <Button
        colorScheme="brandScheme"
        variant={!openFilters ? 'solid' : 'outline'}
        onClick={() => setOpenFilters(!openFilters)}
        width={150}
      >
        {!openFilters ? 
          <>
            <FaFilter size={12} style={{marginRight: '4px'}} />
            {`Filtro ${filtersActive ? '(1)' : ''}`}
            <BsChevronDown style={{marginLeft: '10px'}} />
          </>
        :
          <>
            <FaFilter size={12} style={{marginRight: '4px'}} />
            {`Filtro ${filtersActive ? '(1)' : ''}`}
            <BsChevronUp style={{marginLeft: '10px'}} />
          </>
        }
    </Button> */}
    {openFilters ? 
    <Card
      direction='column'
      w='100%'
      px='0px'
      mb='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
      overflowY={{ sm: "scroll", lg: "hidden" }}
      >
      <form onSubmit={handleSubmitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 4, xl: 4 }} gap='20px' mb='0px'>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input 
              name="name" 
              value={filtersData.name || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input 
              name="email"
              value={filtersData.email || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Sexo</FormLabel>
            <Select
              name="gender"
              value={filtersData.gender || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </Select>
          </FormControl>
        </SimpleGrid>
      </Flex>

      <Flex px='25px' justify='space-between' mt='25px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 4, xl: 4 }} gap='20px' mb='0px'>
        <FormControl>
            {/* <FormLabel style={{"color": "transparent"}}>.</FormLabel> */}
            <ButtonGroup gap='2'>
              <Button 
                type="submit"
                onClick={handleSubmitFilter} 
                colorScheme='brandScheme'
              >
               {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
        </Flex>
      </form>
    </Card>
    :
      <></>
    }

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Avaliações
        </Text>

        <Flex align='center'>
          <Button 
            onClick={() => setOpenModalQuestions(!openModalQuestions)}
            fontSize='sm'
            colorScheme='brandScheme'
            fontWeight='500'
            w='100%'
          >
            <Icon as={FaPlus} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='600'>
              Adicionar
            </Text>
          </Button>
          {/* <Button 
            onClick={exportXLSX}
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Exportar
            </Text>
          </Button> */}
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        {!loadUsersByFilter &&
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "PICTURE") {
                    data = (
                      <img 
                        style={{"borderRadius": "100px", 
                          "width": "40px", 
                          "height": "40px"
                        }}
                        src={cell?.value != null && cell?.value != '' ? cell.value : avatarProfile} 
                        width="40" 
                        alt="Picture" 
                      />
                    );
                  } else if (cell.column.Header === "NAME") {
                    data = (
                      <>
                        <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </>
                    );
                  } else if (cell.column.Header === "INTERVIEW_TITLE") {
                    const interviewIcon = row.original.interview_icon;
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          {interviewIcon == 'FaHtml5' ? <FaHtml5 size={20} /> : <FaClipboardList size={20} />}
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "INTERVIEW_TYPE") {
                    data = (
                      <Flex 
                        align='center' 
                        // style={{"justifyContent": "center"}}
                      >
                        <Text 
                          style={(cell.value === 'technical') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#4169E1", 
                            "padding": "2px 5px",  
                            "borderRadius": "6px"
                          } : {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#ff8000", 
                            "padding": "2px 5px",  
                            "borderRadius": "6px"
                          }}
                          fontSize='sm' 
                          fontWeight='700'
                        >
                          {cell.value == 'technical' ? 'Técnica' : 'Comportamental'}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "COMPLETED") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {cell.value ? (
                          <FaCheckCircle size={20} color="#2E8B57" />
                        ) : ( 
                          <FaCheckCircle size={20} color="gray" />
                        )}
                      </Text>
                    );
                  } else if (cell.column.Header === "INTERVIEW_DATE") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "CREATED_AT") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Flex align='center'>
                          <ButtonGroup gap='1'>
                            <Link
                              color={'#ffffff'}
                              fontWeight='500'
                              me={{ base: "0px", md: "0px" }}
                              href={`/#/admin/alice-ai-details/${cell.value}`}>
                              <Button colorScheme='brandScheme'><FaSearchPlus /></Button>
                            </Link>
                            {/* <Link
                              color={'brandScheme'}
                              fontWeight='500'
                              me={{ base: "0px", md: "0px" }}
                              href={`/#/admin/entrevista/aluno/${cell.value}/${programId}`}>
                              <Button style={{"backgroundColor": "#666666", "color": "#ffffff"}}><MdOutlineChat /></Button>
                            </Link> */}
                          </ButtonGroup>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>}
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader 
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (tableData?.length > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            currentPage={initialState.pageIndex + 1}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>
    </TabPanel>

    <TabPanel>
      <AliceAICompanies userId={2} programId={3} />  

      {/* <TableFeedbacks 
        columnsData={columnsDataFeedbacks}
        
        onChangeProgramId={handleChangeProgramId}
        onChangeFilter={handleChangeFilter}
        onChangeHardSkills={handleChangeHardSkills}
        submitFilter={handleSubmitFilter}
        clearFilters={() => { dispatch(clearFilters()); setUsersCities([]); }}
        filtersData={filters}
        loadUsersByFilter={loadUsers}
        columnsDataFeedbacks={columnsDataFeedbacksComplex}
        tableData={users}
        usersCities={filters.cities}
        loadingCities={loadingCities}
        dataCourse={dataCourse}
        allHardSkills={filters.allHardSkills}
      /> */}
    </TabPanel> 
    </TabPanels>
    </Tabs>

      <ModalAddTestes
        programId={3}
        modalIsOpen={openModalQuestions}
        setModalIsOpen={setOpenModalQuestions}
        refreshForms={() => {}}
      />
    </>
  );
}
