// Chakra imports
import React, { useState, useEffect } from "react";
import { Box, Grid, Flex, Text, Icon, SimpleGrid, FormControl, FormLabel, Select } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Custom components
import Banner from "views/admin/interview/components/Banner";
import General from "views/admin/interview/components/General";
import Education from "views/admin/interview/components/Education";
import SelectionProcess from "views/admin/interview/components/SelectionProcess";
import Notifications from "views/admin/interview/components/Notifications";
import Projects from "views/admin/interview/components/Projects";
import Storage from "views/admin/interview/components/Storage";
import Badge from "views/admin/interview/components/Badge";
import Situation from "views/admin/interview/components/Situation";
import Score from "views/admin/interview/components/Score";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

import { API_SERVER } from "config/constant";
import Timeline from "./components/Timeline";
import Card from "components/card/Card";
import { MdBarChart } from "react-icons/md";
import { IoMdCheckmarkCircle } from "react-icons/io";
import HardSkillsInterview from "./components/HardSkillsInterview";

const INITIAL_VALUE = {
  type_interview: 'softskills',
  interviewerUserId: null,
  userId: null,
  programId: null, 
  nationality: null,
  you_live_with: null,
  academic_trajectory: null, 
  has_professional_experiences: null,
  comments_professional_experiences: '', 
  career_transition: null,
  comments_career_transition: '',
  currently_working: null,
  comments_currently_working: '',
  future_vision: null,
  domain_programming: null,
  knowledge_level: null,
  why_should_be_hired: null, 
  what_think_interview: null,
  presentation_note: null,
  communication_note: null,
  academic_professional_note: null,
}

export default function Interview() {
  const hash = window.location.hash;
  const id = hash.split('/aluno/');
  const userId = id[1] ? id[1]?.split('/')[0] : 0;
  const programId = id[1] ? id[1]?.split('/')[1] : 0;

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingTimeline, setLoadingTimeline] = useState(false);
  const [user, setUser] = useState([]);
  const [interview, setInterview] = useState(INITIAL_VALUE);
  const [qtyInterviews, setQtyInterviews] = useState({
    total: 0,
    last_interview_date: '-',
  });
  const [rankingPosition, setRankingPosition] = useState(0);

  const [userProjects, setUserProjects] = useState([]);
  const [userTimeline, setUserTimeline] = useState([]);
  const [isEditAll, setIsEditAll] = useState(false);
  const [sendInterviewSuccess, setSendInterviewSuccess] = useState(false);
  const [interviewId, setInterviewId] = useState(0);

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  useEffect(() => {
    const getUser = async (userId) => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/user/${userId}/${programId}`);

      const { user, position, interviews } = data;
      console.log(JSON.stringify(data))
    
      setQtyInterviews(interviews);
      setRankingPosition(position);
      setUser(user[0]);
      setLoading(false);
    }

    if (userId > 0) {
      getUser(userId);

      window.scrollTo(0, 0);
    }
  }, [])

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

		setInterview({...interview, [name]: value, ['interviewerUserId']: dashboardUserId, ['userId']: userId, ['programId']: programId});
	};

  const handleChangeButton = (typeNote, note) => {
    setInterview({...interview, [typeNote]: note});
	};

  const handleEdit = () => {
    if (percentUsed >= 100) {
      setIsEditAll(false);
    } else {
      setIsEditAll(true);
    }

    setIsEdit(!isEdit);
  }

  const handleSendInterview = async () => {
    try {
      let interviewData = interview;  

      const { interviewerUserId, userId, programId, nationality, you_live_with, academic_trajectory, has_professional_experiences, 
        comments_professional_experiences, career_transition, currently_working, comments_currently_working, future_vision,
        domain_programming, knowledge_level, why_should_be_hired, what_think_interview,
        presentation_note, communication_note, academic_professional_note
      } = interviewData;
      
      if (!interviewerUserId || !userId || !programId || !nationality || !you_live_with || !academic_trajectory || !has_professional_experiences || !career_transition || !currently_working || !future_vision || !domain_programming || !knowledge_level || !why_should_be_hired || !what_think_interview || !presentation_note || !communication_note || !academic_professional_note) {
        throw 'EMPTY_FIELDS';
      }

      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.post(`${API_SERVER}dashboard/sendInterviewByStudent`, interviewData);

      if (data) {
        setInterviewId(data?.interviewId);
        setSendInterviewSuccess(true);

        setInterview(INITIAL_VALUE);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error == 'EMPTY_FIELDS') {
        alert('Preencha todos os campos!')
      }

      setLoading(false);
    }
  }

  const dateStart = new Date(user?.program_start_date);
  const dateEnd = new Date(user?.program_end_date);
  const dateNow = new Date();

  var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  var Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  var Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;


  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mx='auto' mt='80px' align='center' direction='column'>
          <ClipLoader 
            color={'#2759a5'} 
            loading={loading} 
            size={60} 
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1fr 1.30fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          isEdit={isEdit}
          setIsEdit={handleEdit}
          handleChange={handleChange}
          handleUpdateProfile={() => {}}
          banner={banner}
          userId={userId}
          status={user?.status}
          currentSituation={user?.current_situation}
          avatar={user?.picture}
          name={user?.name}
          course={user?.course}
          isMonitor={user?.is_monitor}
          githubUrl={user?.github_url}
        /> 
        <Situation
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          qtyInterviews={qtyInterviews[0]?.total || 0}
          lastInterviewData={qtyInterviews[0]?.last_interview_date || '-'}
          status={user?.status}
          currentSituation={user?.current_situation}
          companyName={user?.company_name}
          companyLogo={user?.company_logo}
          companyHiredIn={user?.company_hired_in}
          course={user?.course}
        />
        <Badge
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          badge={user?.badge}
          score={user?.total_note}
          position={rankingPosition}
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          programName={user?.program_name}
          programDescription={user?.program_description}
          programStartDate={user?.program_start_date}
          programEndDate={user?.program_end_date}
          used={percentUsed}
          total={100}
        />
      </Grid>
      
      {sendInterviewSuccess && (
        <Card mb={'20px'}>
          <a  
            href={`/#/admin/entrevista/detalhes/${interviewId}/${programId}`}
            style={{
              "color": "#01B574",
              "display": "flex",
              "alignItems": "center",
              "textDecoration": "underline",
            }}
            fontWeight='bold'
            fontSize='2xl'
            >
            <Icon 
              as={IoMdCheckmarkCircle} 
              width='30px' 
              height='30px' 
              color='inherit' 
              mr={'5px'}
            />
              Entrevista realizada com sucesso! - Clique para ver.
          </a>
        </Card>
        )}

      <Card mb={'20px'}>
      <SimpleGrid columns='2' gap='20px' mb='20px'>
        <FormControl>
          <FormLabel mt={4} mb={0} ml={0} fontSize='lg'>Tipo da entrevista</FormLabel>
          <Select 
              value={interview?.type_interview || ''}
              name={'type_interview'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='softskills'>Soft Skills</option>
              <option value='hardskills'>Hard Skills</option>
            </Select>
        </FormControl>
      </SimpleGrid>
      </Card>

      {!sendInterviewSuccess && (!interview?.type_interview || interview?.type_interview == 'softskills') ? (
        <General
          minH='290px'
          pe='20px'
          mb='20px'
          interviewData={interview}
          handleChange={handleChange}
          handleChangeButton={handleChangeButton}
          handleSendInterview={handleSendInterview}
        />
      ) : null}

      {!sendInterviewSuccess && interview?.type_interview == 'hardskills' ? (
        <HardSkillsInterview
          minH='290px'
          pe='20px'
          mb='20px'
          interviewData={interview}
          // handleChange={handleChange}
          // handleChangeButton={handleChangeButton}
          // handleSendInterview={handleSendInterview}
        />
      ) : null}
    </Box>
  );
}
