import React, { useEffect, useState } from "react";
import {
  Text,
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  ButtonGroup,
  Button,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  FormControl,
  Textarea,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { FiBarChart2, FiBox } from "react-icons/fi";
import axios from 'axios';
import Calendar from 'react-calendar-heatmap';
import Chart from 'chart.js/auto';
import 'react-calendar-heatmap/dist/styles.css';

// Assets
import Usa from "assets/img/dashboards/usa.png";
import avatarProfile from 'assets/img/avatars/avatar.png';

// Custom components
import MiniStatistic from "components/card/MiniStatistics";
import StudentProfile from "./components/StudentProfile";
import IconBox from "components/icons/IconBox";
import Card from "components/card/Card.js";
import ModalAddStudentsGroups from "../classLeaders/components/ModalAddStudentsGroups";

import { API_SERVER } from "config/constant";

import {
  barChartStudentAccess,
  barChartOptionsStudentAccess,
} from "variables/charts";
import { BsFillCalendarCheckFill, BsFillInfoCircleFill } from "react-icons/bs";
import Timeline from "./components/Timeline";
import { FaClipboardList, FaPlusCircle, FaUsers } from "react-icons/fa";

import RepoInfo from "./components/GithubCharts";
import Projects from "./components/Projects";
import Feedbacks from "./components/Feedbacks";
import { MdPeople } from "react-icons/md";
import { BiMessageEdit } from "react-icons/bi";
import { RiMessage2Fill } from "react-icons/ri";

const INITIAL_DATA = {
  presentation_note: 0,
  communication_note: 0,
  academic_professional_note: 0,
  message: '',
}

export default function ClassLeadersAndGroups() {
  const hash = window.location.hash;
  const id = hash.split('/grupos/');
  const classLeaderUserId = id[1] ? id[1]?.split('/')[0] : 0;
  const programId = id[1] ? id[1]?.split('/')[1] : 0;

  const dateToday = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'medium', timeZone: 'America/Sao_Paulo' }).format(new Date())?.split(' ');
  const dateTodayFormat = `${dateToday[0]?.split('/')[2]}-${dateToday[0]?.split('/')[1]}-${dateToday[0]?.split('/')[0]}`;

  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const [loadingContributors, setLoadingContributors] = useState(true);
  const [modalStudentsGroupsIsOpen, setModalStudentsGroupsIsOpen] = useState(false);
  const [refreshClassLeaders, setRefreshClassLeaders] = useState(false);
  const [projectFeedback, setProjectFeedback] = useState(INITIAL_DATA);
  const [usersGroup, setUsersGroup] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [userRepo, setUserRepo] = useState('');
  const [groupTimeline, setGroupTimeline] = useState([]);

  const user = localStorage.getItem('user');
  const { dashboardUserId, dashboardUserName } = JSON.parse(user);

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [repoData, setRepoData] = useState(null);
  const [contributorsData, setContributorsData] = useState([]);
  const [usersInactive, setUsersInactive] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const getUsersGroup = async () => {
      if (loadingUsers) {
        return false;
      }

      setLoadingUsers(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/classGroupsByClassLeaderId/${classLeaderUserId}`);

      const { studentsToGroup, projectsData } = data;
      

      setUsersGroup(studentsToGroup);
      setUserProjects(projectsData);
      studentsToGroup[0]?.github_repository && setUserRepo(studentsToGroup[0]?.github_repository);
      setLoadingUsers(false);
    }

      getUsersGroup();
  }, [refreshClassLeaders]);

  useEffect(() => {
    const getTimelineGroup = async () => {
      setLoadingTimeline(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/groupsTimeline/${classLeaderUserId}/${programId}`);
      const { groupTimeline } = data;

      setGroupTimeline(groupTimeline);
      setLoadingTimeline(false);
    }

      if (loadingTimeline) {
        getTimelineGroup();
      }
  }, [loadingTimeline]);

  useEffect(() => {
    const fetchContributorsData = async () => {
      setLoadingContributors(true);

      const result = await axios(
        `https://api.github.com/repos/${userRepo}/stats/contributors`,
        {
          method: "GET",
          headers: {
            'Authorization': 'token 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',
          }
        }
      );
  
      if (result.status === 202) {
        setTimeout(fetchContributorsData, 5000);
        return;
      }
  
      const contributorsData = result.data.map(contributor => {
        return {
          name: contributor.author.login,
          commits: contributor.total,
          dates: contributor.weeks.map(week => new Date(week.w * 1000)),
          avatarUrl: contributor.author.avatar_url,
          html_url: contributor.author.html_url
        };
      });
  
      setContributorsData(contributorsData);

      setLoadingContributors(false);
    };
  
    userRepo && fetchContributorsData();
  }, [userRepo]);
  

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://api.github.com/repos/${userRepo}`
      );

      setRepoData(result.data);
    };

    userRepo && fetchData();
  }, [userRepo]);

  const [lastActivity, setLastActivity] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `https://api.github.com/repos/${userRepo}/languages`,
      );

      setLastActivity(result?.data);
    };

    userRepo && fetchData();
  }, [userRepo]);

  
  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  const getUserProfileGitHub = (githubUrl) => {
    if (!githubUrl) {
      // setUsersInactive(true);
      return 0;
    }

    let githubUsername = githubUrl?.split('github.com/');
    githubUsername = githubUsername[1]?.replace('/', '');

    const filteredUser = Object?.values(contributorsData)?.filter(user => user.name == githubUsername);
    // setUserGitHubData(filteredUser[0]?.commits);

    if (!filteredUser) {
      // setUsersInactive(true);
      return 0;
    }

    return filteredUser[0]?.commits;
  }

  const handleChange = (ev) => {
    const name = ev.target.name;
    const value = ev.target.value;

    setProjectFeedback({ ...projectFeedback, [name]: value });
  }

  const handleChangeButton = () => {

  }

  const handleSendFeedback = () => {
    if (projectFeedback?.message) {
      // alert(JSON.stringify(projectFeedback))
    }
  }

  return (
    <>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Tabs size='md' variant='enclosed'>
        <TabList>
          <Tab><FaUsers w={6} color="gray.500" style={{marginRight: 5}} /><b>Grupo</b></Tab>
          <Tab><BsFillCalendarCheckFill w={6} color="gray.500" style={{marginRight: 5}} /><b>Entregas</b></Tab>
          <Tab><RiMessage2Fill w={6} color="gray.500" style={{marginRight: 5}} /><b>Feedbacks</b></Tab>
          <Tab><FaClipboardList w={6} color="gray.500" style={{marginRight: 5}} /><b>Anotações</b></Tab>
        </TabList>
        <TabPanels>
        <TabPanel>
          {loadingUsers ?
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='80px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingUsers} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            : 
              <>
                <SimpleGrid
                    columns={{ base: 1, sm: 3, md: 4, lg: 4, "2xl": 4 }}
                    gap='20px'
                    mt='20px'
                    mb='20px'>
                      <StudentProfile
                        boxShadow={true}
                        name={usersGroup[0]?.leader_name}
                        picture={usersGroup[0]?.leader_picture ? usersGroup[0]?.leader_picture : avatarProfile}
                        dateTime={''}
                        badgeMonitor={true}
                        userId={classLeaderUserId}
                        programId={programId}
                      />
                      <Flex mx='auto' mt='20px' align='center' justifyContent={'space-between'} alignItems={'flex-end'} direction='row'>
                        <Box w='250px' mr='20px'>
                          <MiniStatistic
                            boxShadow={true}
                            startContent={
                              <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                  <Icon w='32px' h='32px' as={FaUsers} color={'#2759A5'} />
                                }
                              />
                            }
                            name='Ativos/Inativos'
                            value={usersGroup?.filter((user) => user?.active == 1)?.length}
                          />
                        </Box>
                        <Box w='250px' mr='20px'>
                          <MiniStatistic
                            boxShadow={true}
                            startContent={
                              <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={
                                  <Icon w='32px' h='32px' as={FaUsers} color={'#ff3232'} />
                                }
                              />
                            }
                            name='Desistentes'
                            value={usersGroup?.filter((user) => user?.active == 0)?.length}
                          />
                        </Box>
                      </Flex>

                      <Box></Box>
                      <Flex mx='auto' mt='20px' align='center' justifyContent={'space-between'} alignItems={'flex-end'} direction='row'>
                        <Box 
                          style={{
                            "display": "flex",
                            "justifyContent": "flex-end"
                          }}
                          w='200px' 
                          mr='0px'
                        >
                        <Button 
                            onClick={() => setModalStudentsGroupsIsOpen(true)}
                            fontSize='sm'
                            variant='brand'
                            fontWeight='500'
                            w='80%'
                          >
                            <Icon as={FaPlusCircle} h='16px' w='16px' me='8px' />
                            <Text fontSize='sm' fontWeight='400'>
                              Adicionar
                            </Text>
                          </Button>
                        </Box>
                      </Flex>
                </SimpleGrid>

                <hr />

                <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 1, lg: 1, "2xl": 2 }}
                    gap='20px'
                    mt='0px'
                    mb='20px'>
                      <Flex mt='20px' align='flex-start' justifyContent={'flex-start'} alignItems={'flex-start'} direction='row'>
                    
                          <Box w='50%' h='auto' ml='0px'> 
                          <Text 
                              style={{
                                "fontSize": "1rem",
                                "color": "#2759a5",
                                "padding": "4px 10px",
                                "background": "#fff",
                                "width": "220px",
                                "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                                "borderRadius": "10px",
                                "textAlign": "center",
                              }}
                              pl='10px' 
                              mb='20px' 
                              fontSize='lg' 
                              fontWeight='500'>
                                GitHub
                            </Text>
                            {!loadingContributors && repoData && userRepo ? (
                            <>
                            <h1><b>Repositorio</b>: <span style={{color: '#2759a5'}}>{repoData.name}</span></h1>
                            <p style={{marginBottom: 5}}><b>URL:</b> <a href={repoData.html_url} target={'_blank'} style={{textDecoration: 'underline', color: '#2759a5'}}>{repoData.html_url}</a></p>
                            <p><b>Linguagens</b>: 
                              {lastActivity && Object.entries(lastActivity).map(([key, value]) => (
                                <span style={{
                                  padding: '0px 10px',
                                  border: '2px solid rgb(39, 89, 165)',
                                  borderRadius: '100px',
                                  color: 'rgb(39, 89, 165)',
                                  fontWeight: 'bold',
                                  marginLeft: 5
                                }}>{key}</span>
                              ))}
                            </p>
                            <p style={{marginTop: '10px'}}> 
                            <div
                              style={{
                                "display": "flex",
                                "flexDirection": "row",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                              }}
                              >
                                <b style={{marginRight: '8px'}}>Contribuidores: </b>
                                {contributorsData && contributorsData.map((data) => (
                                    <a href={data?.html_url} target={`_blank`}>
                                      <img 
                                        src={data?.avatarUrl}
                                        style={{
                                          width: '35px',
                                          height: '35px',
                                          marginRight: '5px',
                                          borderRadius: '100px',
                                          border: '1px solid rgb(39, 89, 165)'
                                        }} 
                                        alt={``}
                                      />
                                    </a>
                                ))}
                              </div>
                            </p>

                            <br />

                              {/* {contributorsData && (<RepoInfo userRepoGit={userRepo} resultData={contributorsData} />)} */}
                            </>
                            ) : (
                              <p><b>Buscando informações no GitHub...</b></p>
                            )}
                          </Box>
                      </Flex>
                </SimpleGrid>
                
                {!loadingContributors && (
                <>
                <hr /> 
                <Text 
                  style={{
                    "fontSize": "1rem",
                    "color": "#2759a5",
                    "padding": "4px 10px",
                    "background": "#fff",
                    "width": "220px",
                    "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    "borderRadius": "10px",
                    "textAlign": "center",
                  }}
                  pl='10px' 
                  mt='20px' 
                  mb='20px' 
                  fontSize='lg' 
                  fontWeight='500'>
                   Ativos
                </Text>
                <SimpleGrid
                    columns={{ base: 1, sm: 3, md: 4, lg: 4, "2xl": 5 }}
                    gap='20px'
                    mt='20px'
                    mb='0px'>
                      {usersGroup?.filter((user) => user?.active == 1)?.map((data) => (
                         data?.github_url && getUserProfileGitHub(data?.github_url) > 0 ? (
                          <StudentProfile
                            boxShadow={true}
                            name={data?.name}
                            picture={data?.picture ? data?.picture : avatarProfile}
                            githubUrl={data?.github_url ? data?.github_url : ''}
                            dateTime={''}
                            badgeMonitor={false}
                            userId={data?.user_id}
                            programId={programId}
                            contributorsData={contributorsData}
                          />
                        ) : null
                      ))}
                </SimpleGrid>
                </>
                )}
                {!loadingContributors && usersGroup?.filter((user) => user?.active == 1)?.length == 0 && <Text fontSize={'2xl'} align={'center'} mt={'20px'}>Nenhum resultado encontrado.</Text>}

                {!loadingContributors && (
                <>
                <br />
                <hr />
                <Text 
                  style={{
                    "fontSize": "1rem",
                    "color": "#2759a5",
                    "padding": "4px 10px",
                    "background": "#fff",
                    "width": "220px",
                    "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    "borderRadius": "10px",
                    "textAlign": "center",
                  }}
                  pl='10px' 
                  mt='20px' 
                  mb='20px' 
                  fontSize='lg' 
                  fontWeight='500'>
                   Inativos
                </Text>
                <SimpleGrid
                    columns={{ base: 1, sm: 3, md: 4, lg: 4, "2xl": 5 }}
                    gap='20px'
                    mt='20px'
                    mb='0px'>
                      {usersGroup?.filter((user) => user?.active == 1)?.map((data) => (
                        !getUserProfileGitHub(data?.github_url) ? (
                          <StudentProfile
                            boxShadow={true}
                            name={data?.name}
                            picture={data?.picture ? data?.picture : avatarProfile}
                            githubUrl={data?.github_url ? data?.github_url : ''}
                            dateTime={''}
                            badgeMonitor={false}
                            userId={data?.user_id}
                            programId={programId}
                            contributorsData={contributorsData}
                          />
                        ) : null
                      ))}
                </SimpleGrid>
                </>
                )}
                {!loadingContributors && usersGroup?.filter((user) => user?.active == 1)?.length == 0 && <Text fontSize={'2xl'} align={'center'} mt={'20px'}>Nenhum resultado encontrado.</Text>}
                {/* {usersInactive && <p>Nenhum resultado encontrado</p>} */}

                <br />
                <hr />
                <Text 
                  style={{
                    "fontSize": "1rem",
                    "color": "#2759a5",
                    "padding": "4px 10px",
                    "background": "#fff",
                    "width": "220px",
                    "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                    "borderRadius": "10px",
                    "textAlign": "center",
                  }}
                  pl='10px' 
                  mt='20px' 
                  mb='20px' 
                  fontSize='lg' 
                  fontWeight='500'>
                    Desistentes
                </Text>
                <SimpleGrid
                    columns={{ base: 1, sm: 3, md: 4, lg: 4, "2xl": 5 }}
                    gap='20px'
                    mt='20px'
                    mb='0px'>
                      {usersGroup?.filter((user) => user?.active == 0)?.map((data) => (
                        <StudentProfile
                          boxShadow={true}
                          name={data?.name}
                          picture={data?.picture ? data?.picture : avatarProfile}
                          dateTime={''}
                          badgeMonitor={false}
                          userDropout={true}
                          userId={data?.user_id}
                          programId={programId}
                          contributorsData={contributorsData}
                        />
                      ))}
                </SimpleGrid>
                {usersGroup?.filter((user) => user?.active == 0)?.length == 0 && <Text fontSize={'2xl'} align={'center'} mt={'20px'}>Nenhum resultado encontrado.</Text>}
              </>
            }
          </TabPanel>
          <TabPanel>
            <Projects
              gridArea='2 / 2 / 2 / 2'
              projects={userProjects}
              userId={classLeaderUserId}
              programId={programId}
            />
          </TabPanel>
          
          <TabPanel>
            <Feedbacks 
              gridArea='1 / 1 / 1 / 1'
              loadingTimeline={loadingTimeline}
              setLoadingTimeline={setLoadingTimeline}
              feedbacks={[]}
              programId={programId}
              authorUserId={dashboardUserId}
              classGroupId={usersGroup[0]?.classgroup_id}
            />
            {/* <Card mb={{ base: "0px", "2xl": "20px" }}>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='10px'
                mb='0px'>
                Enviar feedback
              </Text>
              <Text 
                color={textColorPrimary} 
                fontSize='md' 
                fontWeight={700} 
                me='26px'
                mt='0px' 
                mb='0px'
              >
                O feedback escrito será mostrado no espaço do aluno para o monitor.
              </Text>

              <br />

              <SimpleGrid columns='1' gap='20px' mb='20px'>
                <FormControl>
                  <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Escreva abaixo o feedback personalizado:</FormLabel>
                  <Textarea name={'message'} onChange={handleChange} rows={5} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid columns='4' gap='20px' mb='20px'>
                  <Button 
                    disabled={projectFeedback?.message ? false : true}
                    onClick={handleSendFeedback}
                    bg={'green.400'} 
                    color='#fff'>
                      Enviar
                  </Button>
              </SimpleGrid>
            </Card> */}
          </TabPanel>

          <TabPanel>
          {loadingTimeline ?
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='80px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingTimeline} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            : 
              <Box mt='0px' pb='10px'>
                <Timeline
                  subtitle={'Utilize este espaço para registrar observações importantes sobre o grupo.'}
                  gridArea='1 / 1 / 1 / 1'
                  loadingTimeline={loadingTimeline}
                  setLoadingTimeline={setLoadingTimeline}
                  timeline={groupTimeline}
                  programId={programId}
                  authorUserId={dashboardUserId}
                  classGroupId={usersGroup[0]?.classgroup_id}
                /> 
              </Box>
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>

      {modalStudentsGroupsIsOpen && classLeaderUserId ?
        <ModalAddStudentsGroups
          programId={programId}
          classLeaderUserId={classLeaderUserId}
          modalIsOpen={modalStudentsGroupsIsOpen}
          setModalIsOpen={setModalStudentsGroupsIsOpen}
          refreshClassLeaders={refreshClassLeaders}
          setRefreshClassLeaders={setRefreshClassLeaders}
        />
      : null}
    </>
  );
}
