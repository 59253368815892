import React from "react";
// Chakra imports
import { Box, Text, Icon, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { MdCancel } from "react-icons/md";
import { FaImage, FaWhatsapp } from "react-icons/fa";
import { BsPlayCircleFill } from "react-icons/bs";

export default function Information({ title, value, link = false, linkWhatsApp = false, urlVideo = false, linkTestPersonality = false, ...rest }) {
  // const { 
  //   title, 
  //   link = false, 
  //   linkWhatsApp = false,
  //   value, 
  //   ...rest 
  // } = props;
  
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} style={{padding: '5px 5px'}}>
      <Box>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
          {title}
        </Text>
        <Text 
          style={{"display": "flex",
              "alignItems": "center"
          }}
          color={textColorPrimary}
          fontWeight='500' 
          fontSize='md'
        >
         {value === 'Desistente' || value === 'Inativo' ? <Icon
            w='24px'
            h='24px'
            me='5px'
            color={
              value === 'Desistente' || value === 'Inativo'
                ? "red.500"
                :  null
            }
            as={
              value === 'Desistente' || value === 'Inativo'
                ? MdCancel
                : null
            }
          /> : null}
          
          {urlVideo && value?.length > 0 ? 
            <a style={{
              "display": "flex",
              "justifyContent": "center",
              "alignItems": "center",
              "backgroundColor": "#ce1151",
              "padding": "5px 10px",
              "borderRadius": "100px",
              "color": "#fff",
              "marginTop": "3px",
              "fontSize": "13px"
            }} 
            href={value} 
            target="_blank">
              <BsPlayCircleFill style={{"marginRight": "5px"}} />
              Abrir vídeo
            </a> 
          : !linkTestPersonality && (value?.length > 0 || value > 0 || value == 0 ? value : <p style={{"color": "red"}}>Sem informação</p>)}

          {linkWhatsApp ? <a style={{
              "display": "flex",
              "justifyContent": "center",
              "alignItems": "center",
              "backgroundColor": "#25D366",
              "padding": "5px 10px",
              "borderRadius": "100px",
              "color": "#fff",
              "marginLeft": "10px",
              "fontSize": "13px"
            }} 
            href={`https://api.whatsapp.com/send?phone=55${value?.replace(/[^0-9]/g, '')}`} 
            target="_blank">
              <FaWhatsapp style={{"marginRight": "5px"}} />
              Enviar Mensagem
            </a> 
          : null}
          
          {linkTestPersonality ? <a style={{
              "display": "flex",
              "justifyContent": "center",
              "alignItems": "center",
              "backgroundColor": "#ce1151",
              "padding": "5px 10px",
              "borderRadius": "100px",
              "color": "#fff",
              "marginTop": "3px",
              "fontSize": "13px"
            }} 
            href={value} 
            target="_blank">
              <FaImage style={{"marginRight": "5px"}} />
              Abrir
            </a> 
          : null}
        </Text>
      </Box>
    </Card>
  );
}