import React, { useEffect, useState } from "react";
import axios from "axios";

import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    Stack,
    Checkbox,
  } from "@chakra-ui/react";
  import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";
import InputCellphone from "components/inputCellphone";

export default function ModalEditTeachers({ teacherId, modalIsOpen, setModalIsOpen, refreshTeachers }) {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [teacher, setTeacher] = useState({
    teachers_subjects: []
  });

  useEffect(() => {
    const getTeacherById = async (teacherId) => {
      try {   
        if (loading) {
          return false;
        }

        setLoading(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/teachersById/${teacherId}`);
        const { teacher } = data;

        const teachersSubjects = teacher[0]?.teachers_subjects;
        const ts = teachersSubjects?.split(';');

        setTeacher({
          ...teacher[0],
          teachers_subjects: [...ts]
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
  
        setLoading(false);
      }
    }

    getTeacherById(teacherId);
  }, []);

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;
    const checked = ev.target.checked;

    const { teachers_subjects } = teacher;

    if (checked && name === 'teachers_subjects') {
      setTeacher({
        ...teacher,
        teachers_subjects: [...teachers_subjects, value],
      });      
    } else if (!checked && name === 'teachers_subjects') {
      setTeacher({
        ...teacher,
        teachers_subjects: teachers_subjects.filter((e) => e !== value),
      });
    } else {
      setTeacher({
        ...teacher, 
        [name]: value
      });
    }
	};

  const handleEditTeacher = async () => {
    try {      
      const validationEmptyFields = teacher?.name && teacher?.teachers_subjects && teacher?.type && teacher?.uf && teacher?.city;

      if (!validationEmptyFields) {
        throw 'EMPTY_FIELDS';
      }
      
      if (loadingSubmit) {
        return false;
      }

      setLoadingSubmit(true);

      await axios.put(`${API_SERVER}dashboard/teachersById`, { 
        teacherId: teacher?.teacher_id,
        name: teacher?.name,
        email: teacher?.email,
        phone: teacher?.phone,
        linkedin_url: teacher?.linkedin_url,
        teachers_subjects: teacher?.teachers_subjects,
        type: teacher?.type,
        uf: teacher?.uf,
        city: teacher?.city,
       });

      setTeacher({
        teachers_subjects: []
      });
      refreshTeachers(true);
      setModalIsOpen(false);
      setLoadingSubmit(false);
    } catch (error) {
      console.log(error);

      setTeacher({
        teachers_subjects: []
      });
      refreshTeachers(true);
      setLoadingSubmit(false);
      setModalIsOpen(false);
    }
  }

  const isNotEmptyFields = teacher?.name && teacher?.teachers_subjects?.length > 0 && teacher?.type && teacher?.uf && teacher?.city;

  return (
    <Modal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
      >
      <form>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar professor</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {/* {formModalError && <Text mb='15px' color='red.600'>Data de fim deve ser maior que data de início</Text>} */}

          {/* <FormControl>
            <FormLabel>Logo</FormLabel>
            <Input 
              type="file"
              name="logo"
              style={{
                "padding": "5px 5px 0px 5px"
              }}
              // value={program?.program_name}
              // onChange={handleChange}
              required={true}
            />
          </FormControl> */}

          {loading ?
            <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
              <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='10px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loading} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
            </Box>
          : null}

          {!loading ?
          <>
            <FormControl mt={4}>
              <FormLabel>Nome</FormLabel>
              <Input 
                type="text"
                name="name"
                value={teacher?.name || ''}
                onChange={handleChange}
                required={true}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Telefone</FormLabel>
              <Input
                type={'text'}
                name={'phone'}
                value={teacher?.phone?.replace(/^(\d{2})(\d)/g,"($1) $2")?.replace(/(\d)(\d{4})$/,"$1-$2") || ''}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>E-mail</FormLabel>
              <Input 
                type="text"
                name="email"
                value={teacher?.email || ''}
                onChange={handleChange}
                required={true}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Linkedin</FormLabel>
              <Input 
                type="text"
                name="linkedin_url"
                value={teacher?.linkedin_url || ''}
                onChange={handleChange}
                required={true}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Assunto</FormLabel>
              <Stack spacing={5} direction='row'>
                <Checkbox
                  defaultChecked={teacher?.teachers_subjects[0] == 'front-end' || teacher?.teachers_subjects[1] == 'front-end' ? true : false}
                  name="teachers_subjects" 
                  value="front-end"
                  onChange={handleChange}
                >
                    Front-End
                </Checkbox>
                <Checkbox 
                  defaultChecked={teacher?.teachers_subjects[0] == 'back-end' || teacher?.teachers_subjects[1] == 'back-end'? true : false}
                  name="teachers_subjects" 
                  value="back-end"
                  onChange={handleChange}
                >
                    Back-End
                  </Checkbox>
              </Stack>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Tipo</FormLabel>
              <Select
                name="type"
                value={teacher?.type || ''}
                onChange={handleChange}
                required={true}
              >
                <option value="">Selecione</option>
                <option value="synchronous">Aula Síncrona</option>
                <option value="asynchronous">Aula Assíncrona</option>
              </Select>
            </FormControl>

            <SimpleGrid columns='2' gap='20px'>
            <FormControl mt={4}>
            <FormLabel>UF</FormLabel>
            <Select
              name="uf"
              value={teacher?.uf || ''}
              onChange={handleChange}
              required={true}
            >
                <option value="">Selecione</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Cidade</FormLabel>
              <Input 
                type="text"
                name="city"
                value={teacher?.city || ''}
                onChange={handleChange}
                required={true}
              />
            </FormControl>
          </SimpleGrid>
          </>
          : null}
        </ModalBody>

        {!loading ?
          <ModalFooter>
            <Button 
              type="button"
              onClick={() => handleEditTeacher()}
              colorScheme='blue' mr={3}
              disabled={!isNotEmptyFields}
            >
              {loadingSubmit ? 'Salvando...' : 'Salvar'}
            </Button>
            <Button onClick={() => setModalIsOpen(false)}>Cancelar</Button>
          </ModalFooter>
        : null}
      </ModalContent>
      </form>
    </Modal>
    )
}