import React from "react";
import PropTypes from "prop-types";
import { useHistory, NavLink } from "react-router-dom";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ userData, children }) => {
  const history = useHistory();

  let [user, setUser] = React.useState(userData);
  user = typeof user === "string" ? JSON.parse(user) : user;

  // deslogar membros antigos
  if (user?.dashboardUserId == 8874) {
    return history.push("/auth/sign-out");
  }

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  userData: PropTypes.any,
  children: PropTypes.any,
};

export const useAuth = () => React.useContext(AuthContext);
