// Chakra imports
import { SimpleGrid, 
  Text, 
  useColorModeValue, 
  FormControl,
  FormLabel, 
  Input,
  Select,
  Textarea,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import InputCpf from "components/inputCpf";
import InputCellPhone from "components/inputCellphone";
import React, { useState } from "react";
import Information from "views/admin/profile/components/Information";

// Assets
export default function GeneralInformation(props) {
  const {
    interviewData,
    handleSendInterview,
    handleChangeButton,
    handleChange,
    ...rest 
  } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='20px'>
        Entrevista de Soft Skills
      </Text>
      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px' 
        mb='0px'
      >
        Apresentação do candidato:
      </Text>
      <SimpleGrid columns='2' gap='20px' mb='20px'>
        <FormControl>
          <FormLabel mt={4} mb={0} ml={0} fontSize='lg'>Nacionalidade</FormLabel>
          <Select 
              name={'nationality'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='brasileiro'>Brasileiro(a)</option>
              <option value='venezuelano'>Venezuelano(a)</option>
              <option value='argentino'>Argentino(a)</option>
              <option value='chileno'>Chileno(a)</option>
              <option value='cubano'>Cubano(a)</option>
              <option value='boliviano'>Boliviano(a)</option>
            </Select>
        </FormControl>

        <FormControl>
          <FormLabel mt={4} mb={0} ml={0} fontSize='lg'>Mora com quem?</FormLabel>
          <Select 
              name={'you_live_with'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='alone'>Sozinho</option>
              <option value='spouse'>Cônjuge</option>
              <option value='lover'>Namorado(a)</option>
              <option value='parents'>Pais</option>
              <option value='grandparents'>Avós</option>
              <option value='sons'>Filhos</option>
              <option value='friends'>Amigos</option>
              <option value='relatives'>Parentes</option>
            </Select>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns='1' gap='20px' mb='20px'>
        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Me conte um pouco da sua trajetória acadêmica, como foram seus estudos?</FormLabel>
          <Textarea name={'academic_trajectory'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns='1' gap='0px'>
      <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Você já teve experiências profissionais?</FormLabel>
          <Select 
              name={'has_professional_experiences'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </Select>
        </FormControl>
        <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0}>Observações</FormLabel>
          <Textarea 
            name={'comments_professional_experiences'} 
            onChange={handleChange}
            rows={6}
            style={{"border": "1px solid rgb(39, 89, 165)"}}>
          </Textarea>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns='1' gap='0px'>
      <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Você está em transição de carreira?</FormLabel>
          <Select 
              name={'career_transition'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </Select>
        </FormControl>
        <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0}>Observações</FormLabel>
          <Textarea 
            name={'comments_career_transition'} 
            onChange={handleChange}
            rows={6}
            style={{"border": "1px solid rgb(39, 89, 165)"}}>
          </Textarea>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns='1' gap='0px'>
      <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Trabalha atualmente?</FormLabel>
          <Select 
              name={'currently_working'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='1'>Sim</option>
              <option value='0'>Não</option>
            </Select>
        </FormControl>
        <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0}>Observações</FormLabel>
          <Textarea name={'comments_currently_working'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px'
        mt='20px' 
        mb='0px'
      >
        Futuro Profissional: 
      </Text>

      <SimpleGrid columns='1' gap='0px' mb='20px'>
        <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Onde você se vê no futuro? (curto, médio e longo prazo)</FormLabel>
          <Textarea name={'future_vision'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px'
        mt='20px' 
        mb='0px'
      >
        Habilidades técnicas: 
      </Text>

      <SimpleGrid columns='1' gap='0px' mb='20px'>
        <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Qual seu nível de conhecimento em programação?</FormLabel>
          <Select 
              name={'knowledge_level'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='none'>Nenhum</option>
              <option value='basic'>Básico (já fiz cursos introdutórios)</option>
              <option value='intermediary'>Intermediário (já estudei na faculdade ou fiz cursos mais avançados)</option>
              <option value='advanced'>Avançado (já trabalho com programação)</option>
            </Select>
        </FormControl>

        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>O que você acredita que você domina dentro da área de programação?</FormLabel>
          <Textarea name={'domain_programming'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px'
        mt='20px' 
        mb='0px'
      >
        Fit Cultural, Soft Skills e Personalidade: 
      </Text>

      <SimpleGrid columns='1' gap='20px' mb='20px'>
        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Por quê você acha que deveria ser contratado por alguma das empresas contratantes?</FormLabel>
          <Textarea name={'why_should_be_hired'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px'
        mt='20px' 
        mb='0px'
      >
        Feedback da entrevista: 
      </Text>

      <SimpleGrid columns='1' gap='20px' mb='20px'>
        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>O que você achou da entrevista?</FormLabel>
          <Textarea name={'what_think_interview'} onChange={handleChange} rows={10} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns='1' gap='20px' mb='40px'>
        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Que nota você daria para apresentação do candidato?</FormLabel>
          <ButtonGroup>
            <Button onClick={() => handleChangeButton('presentation_note', 1)} colorScheme={interviewData?.presentation_note == 1 && 'blue'} variant={interviewData?.presentation_note == 1 && 'outline'}>1</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 2)} colorScheme={interviewData?.presentation_note == 2 && 'blue'} variant={interviewData?.presentation_note == 2 && 'outline'}>2</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 3)} colorScheme={interviewData?.presentation_note == 3 && 'blue'} variant={interviewData?.presentation_note == 3 && 'outline'}>3</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 4)} colorScheme={interviewData?.presentation_note == 4 && 'blue'} variant={interviewData?.presentation_note == 4 && 'outline'}>4</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 5)} colorScheme={interviewData?.presentation_note == 5 && 'blue'} variant={interviewData?.presentation_note == 5 && 'outline'}>5</Button>
          </ButtonGroup>
        </FormControl>

        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Que nota você daria para comunicação do candidato?</FormLabel>
          <ButtonGroup>
            <Button onClick={() => handleChangeButton('communication_note', 1)} colorScheme={interviewData?.communication_note == 1 && 'blue'} variant={interviewData?.communication_note == 1 && 'outline'}>1</Button>
            <Button onClick={() => handleChangeButton('communication_note', 2)} colorScheme={interviewData?.communication_note == 2 && 'blue'} variant={interviewData?.communication_note == 2 && 'outline'}>2</Button>
            <Button onClick={() => handleChangeButton('communication_note', 3)} colorScheme={interviewData?.communication_note == 3 && 'blue'} variant={interviewData?.communication_note == 3 && 'outline'}>3</Button>
            <Button onClick={() => handleChangeButton('communication_note', 4)} colorScheme={interviewData?.communication_note == 4 && 'blue'} variant={interviewData?.communication_note == 4 && 'outline'}>4</Button>
            <Button onClick={() => handleChangeButton('communication_note', 5)} colorScheme={interviewData?.communication_note == 5 && 'blue'} variant={interviewData?.communication_note == 5 && 'outline'}>5</Button>
          </ButtonGroup>
        </FormControl>

        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Que nota você daria para o histórico profissional/acadêmico do candidato?</FormLabel>
          <ButtonGroup>
            <Button onClick={() => handleChangeButton('academic_professional_note', 1)} colorScheme={interviewData?.academic_professional_note == 1 && 'blue'} variant={interviewData?.academic_professional_note == 1 && 'outline'}>1</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 2)} colorScheme={interviewData?.academic_professional_note == 2 && 'blue'} variant={interviewData?.academic_professional_note == 2 && 'outline'}>2</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 3)} colorScheme={interviewData?.academic_professional_note == 3 && 'blue'} variant={interviewData?.academic_professional_note == 3 && 'outline'}>3</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 4)} colorScheme={interviewData?.academic_professional_note == 4 && 'blue'} variant={interviewData?.academic_professional_note == 4 && 'outline'}>4</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 5)} colorScheme={interviewData?.academic_professional_note == 5 && 'blue'} variant={interviewData?.academic_professional_note == 5 && 'outline'}>5</Button>
          </ButtonGroup>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns='4' gap='20px' mb='20px'>
          <Button 
            onClick={handleSendInterview}
            bg={'green.400'} 
            color='#fff'>
              Salvar
          </Button>
      </SimpleGrid>
    </Card>
  );
}
