export const tableColumnsMonitores = [
  {
    Header: "Name",
    label: 'Monitor',
    accessor: "classleader_name",
  },
  {
    Header: "Alunos",
    label: 'Qtd. Alunos',
    accessor: "groups_qty_students",
  }
];
