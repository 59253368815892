import React, { useEffect, useState } from "react";
import axios from "axios";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Progress,
  Text,
  useColorModeValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

// Custom components
import Banner from "views/admin/programa/components/Banner";
import TableTopCreators from "views/admin/programa/components/TableTopCreators";
import Teachers from "views/admin/programa/components/Teachers";
import Monitores from "views/admin/programa/components/Monitores";
import HistoryItem from "views/admin/programa/components/HistoryItem";
import MiniCardsProgramas from "components/card/MiniCardProgramas";
import Card from "components/card/Card.js";
import ModalEditProgram from "./components/ModalEditProgram";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";

import { tableColumnsTopCreators } from "views/admin/programa/variables/tableColumnsTopCreators";
import { tableColumnsMonitores } from "views/admin/programa/variables/tableColumnsMonitores";
import { tableColumnsTeachers } from "views/admin/programa/variables/tableColumnsTeachers";

import { API_SERVER } from "config/constant";
import ModalAddCompanies from "./components/ModalAddCompanies";
import ModalAddTeachers from "./components/ModalAddTeachers";

export default function Programa() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";

  const hash = window.location.hash;
  const id = hash.split('/programa/');
  const programId = id[1] ?? 0;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formModalError, setFormModalError] = useState(false);

  const [modalCompaniesIsOpen, setModalCompaniesIsOpen] = useState(false);
  const [modalTeachersIsOpen, setModalTeachersIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingProgram, setLoadingProgram] = useState(false);
  const [program, setProgram] = useState([]);
  const [programEdit, setProgramEdit] = useState([]);
  const [topCoders, setTopCoders] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [classGroups, setClassGroups] = useState([]);
 
  useEffect(() => {
    window.scrollTo(0, 0);
    
    const getProgramById = async (userId) => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/program/${programId}`);

      const { program, companies, teachers, classLeadersGroups } = data?.dataProgram;
      
      program[0] && setProgram(program[0]);
      program[0] && setProgramEdit(program[0]);
      companies[0]?.company_id && setCompanies(companies);
      teachers[0]?.teacher_name && setTeachers(teachers);
      classLeadersGroups && setClassGroups(classLeadersGroups);
      setLoading(false);
    }

    if (programId > 0 || loadingProgram) {
      getProgramById(programId);

      setLoadingProgram(false);
    }

    const getTopCoders = async () => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/topCoders/${programId}`);

      const { topCoders } = data;

      setTopCoders(topCoders);
      setLoading(false);
    }

    getTopCoders();
  }, [loadingProgram])

  const formatDate = (date, separator = '/') => {
    return date?.substring(0,10).split('-').reverse().join(separator);
  }

  const isOpenModal = () => {
    setFormModalError(false);
    setProgramEdit(program);
    setModalIsOpen(!modalIsOpen);
  }

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

		setProgramEdit({...programEdit, [name]: value});
	};

  const handleEditProgram = async () => {
    const programData = programEdit;
    programData.program_start_date = programEdit?.program_start_date?.substring(0,10);
    programData.program_end_date = programEdit?.program_end_date?.substring(0,10);

    const startDate = programEdit?.program_start_date?.substring(0,10);
    const endDate = programEdit?.program_end_date?.substring(0,10);

    const formError = (startDate >= endDate) ? true : false;
    setFormModalError(formError);

    try {      
      if (formError) {
        throw 'END_DATE_MINOR_START_DATE';
      }

      if (!programId) {
        throw 'PROGRAMID_IS_EMPTY';
      }

      if (loading) {
        return false;
      }

      setLoading(true);

      await axios.post(`${API_SERVER}dashboard/program/${programId}`, programData);

      setProgram(programData);
      setModalIsOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }

  const dateStartProgram = program?.program_start_date?.substring(0,10);
  const dateEndProgram = program?.program_end_date?.substring(0,10);

  const dateStart = new Date(dateStartProgram);
  const dateEnd = new Date(dateEndProgram);
  const dateNow = new Date();

  const total = 100;
  const Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  const Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  return (
    <>
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Banner 
            data={program}
            handleChange={isOpenModal}
          />
          <Flex direction='column'>
            {/* <Flex
              mt='20px'
              mb='20px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                Mais programas
              </Text>
              <Flex
                align='center'
                me='20px'
                ms={{ base: "24px", md: "0px" }}
                mt={{ base: "20px", md: "0px" }}>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#art'>
                  Ativos
                </Link>
                <Link color={textColorBrand} me={{ base: "34px", md: "44px" }} fontWeight='500' to='#sports'>
                  Em breve
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  to='#music'>
                  Finalizados
                </Link>
              </Flex>
            </Flex> */}
            <SimpleGrid columns={{ base: 1, md: 3 }} mt='20px' gap='20px'>
              <MiniCardsProgramas
                name='Grade curricular'
                author=''
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft1}
                currentbid=''
                download={`/#/admin/programa/${programId}`}
              />
              <MiniCardsProgramas
                name='Alunos'
                author=''
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft2}
                currentbid=''
                download={`/#/admin/alunos/${programId}`}
              />
              <MiniCardsProgramas
                name='Ranking'
                author=''
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft3}
                currentbid=''
                download={`/#/admin/ranking/${programId}`}
              />
            </SimpleGrid>
          </Flex>


        <Flex
          mt='20px'
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 2", "2xl": "1 / 1 / 2 / 2" }}
          >
          <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px'>
          <Card px='0px' mb='20px'>
            <Monitores
              programId={programId}
              tableData={classGroups}
              columnsData={tableColumnsMonitores}
            />
            </Card>
            <Card px='0px' mb='20px'>
            <Teachers
              programId={programId}
              tableData={teachers}
              columnsData={tableColumnsTeachers}
              setModalTeachersIsOpen={setModalTeachersIsOpen}
            />
          </Card>
          </SimpleGrid>
        </Flex>
        </Flex>

        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
          <Box 
            w='100%' 
            mt='auto'
            px='22px'
            pb='20px'
          >
            <Text color={textColor} fontSize='xl' fontWeight='600' mb='0px'>
              Progresso
            </Text>
            <Text color={textColor} 
              style={{"color": "#A0AEC0"}}
              fontSize='13px' 
              fontWeight='500' 
              mb='20px'
            >
              {formatDate(program?.program_start_date)} a {formatDate(program?.program_end_date)}
            </Text>
            <Flex w='100%' justify='space-between' mb='10px'>
              <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                {percentUsed.toFixed(0) >= 100 ? '' : `${percentUsed.toFixed(0)} %`} 
              </Text>
              <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                {total} %
              </Text>
            </Flex>
            <Progress
              align='start'
              colorScheme={percentUsed.toFixed(0) >= 100 ? 'green' : 'brandScheme'}
              value={(percentUsed / total) * 100}
              w='100%'
            />
          </Box>
          </Card>
          <Card px='0px' mb='20px'>
            <TableTopCreators
              tableData={companies}
              columnsData={tableColumnsTopCreators}
              setModalCompaniesIsOpen={setModalCompaniesIsOpen}
            />
          </Card>
          <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
                Top Coders
              </Text>
              <Link href={`/#/admin/ranking`}>
                <Button variant='action'>Ver todos</Button>
              </Link>
            </Flex>

            {topCoders.length > 0 && topCoders?.map((user, i) => (
              i <= 9 && <HistoryItem
                name={user?.name}
                author={user?.badge}
                date={`${i + 1}°`}
                image={user?.picture}
                price=''
              />
            ))} 

            {topCoders.length === 0 &&
              <p
                style={{
                  "textAlign": "center",
                  "padding": "20px 0px 40px",
                  "color": "var(--chakra-colors-gray-500)"
                }}
              >
                Nenhum resultado encontrado.
              </p>
            }
          </Card>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>

    <ModalEditProgram 
      programEdit={programEdit}
      handleEditProgram={handleEditProgram}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      formModalError={formModalError}
      handleChange={handleChange}
    />
    
    {modalCompaniesIsOpen &&
      <ModalAddCompanies 
        programId={programId}
        modalIsOpen={modalCompaniesIsOpen}
        setModalIsOpen={setModalCompaniesIsOpen}
        refreshProgramById={setLoadingProgram}
      />
    }

    {(modalTeachersIsOpen && teachers) &&
      <ModalAddTeachers
        programId={programId}
        modalIsOpen={modalTeachersIsOpen}
        setModalIsOpen={setModalTeachersIsOpen}
        refreshProgramById={setLoadingProgram}
      />
    }
    </>
  );
}
