// Chakra imports
import { SimpleGrid, 
  Text, 
  useColorModeValue, 
  FormControl,
  FormLabel, 
  Input,
  Select,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import InputCpf from "components/inputCpf";
import InputCellPhone from "components/inputCellphone";
import React from "react";
import Information from "views/admin/profile/components/Information";
import GeneratePDF from "./GeneratePDF";

// Assets
export default function GeneralInformation(props) {
  const {
    userData,
    programId, 
    name, 
    cpf,
    email, 
    cellphone,
    gender,
    age,
    city,
    uf,
    status,
    currentSituation, 
    reasonRenunciation,
    companyId,
    presenceTotal,
    active, 
    urlTestPersonality,
    createdAt,
    isEdit, 
    isEditAll,
    setIsEdit,
    handleChange,
    ...rest 
  } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const getStatusName = (status) => {
    if (status == 'em_formacao') {
      return 'Em formação';
    }

    if (status == 'desligado') {
      return 'Desligado';
    }

    if (status == 'fora_programa') {
      return 'Desistente';
    }

    if (status == 'formado') {
      return 'Formado';
    }

    if (status == 'indicado') {
      return 'Indicado';
    }

    if (status == 'banco_talentos') {
      return 'Banco de talentos';
    }

    if (status == 'contratado') {
      return 'Contratado direto';
    }

    if (status == 'contratado_headhunting') {
      return 'Contratado Head Hunting';
    }

    if (status == 'contratado_indireto') {
      return 'JobOut';
    }

    if (status == 'indisponivel') {
      return 'Indisponível';
    }
  }

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Informações gerais
      </Text>
      {/* <GeneratePDF user={userData} /> */}
      
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        As we live, our hearts turn colder. Cause pain is what we go through as
        we become older. We get insulted by others, lose trust for those others.
        We get back stabbed by friends. It becomes harder for us to give others
        a hand. We get our heart broken by people we love, even that we give
        them all...
      </Text> */}
      <SimpleGrid columns='2' gap='20px'>
        {!isEdit ? 
          <Information
            style={{"textTransform": "capitalize"}}
            boxShadow={cardShadow}
            title='Nome'
            value={name}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Nome</FormLabel>
            <Input
              type={'text'}
              name={'name'}
              value={name || ''}
              onChange={handleChange}
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        }
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='CPF'
            value={cpf?.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4")}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>CPF</FormLabel>
            <InputCpf
              type={'text'}
              name={'cpf'}
              value={cpf?.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4") || ''}
              onChange={handleChange}
              className="chakra-input css-kag1qr"
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              required={true}
              disabled={!isEditAll ? true : false}
            />
          </FormControl>
        }
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='E-mail'
            value={email}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>E-mail</FormLabel>
            <Input
              type={'text'}
              name={'email'}
              value={email || ''}
              onChange={handleChange}
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        }
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Celular'
            linkWhatsApp={cellphone?.length > 0 ? true : false}
            value={cellphone?.replace(/^(\d{2})(\d)/g,"($1) $2")?.replace(/(\d)(\d{4})$/,"$1-$2")}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Celular</FormLabel>
            <InputCellPhone
              type={'text'}
              name={'cell_phone'}
              value={cellphone?.replace(/^(\d{2})(\d)/g,"($1) $2")?.replace(/(\d)(\d{4})$/,"$1-$2") || ''}
              onChange={handleChange}
              className="chakra-input css-kag1qr"
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              required={true}
              disabled={!isEditAll ? true : false}
            />
          </FormControl>
        }
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Sexo'
            value={gender == 'male' ? 'Masculino' : (gender == 'female' ? 'Feminino' : 'Sem informação')}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Sexo</FormLabel>
            <Select 
              name={'gender'}
              value={gender || ''}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='male'>Masculino</option>
              <option value='female'>Feminino</option>
            </Select>
          </FormControl>
        }
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Idade'
            value={age}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Idade</FormLabel>
            <Input
              type={'number'}
              name={'age'}
              value={age || ''}
              onChange={handleChange}
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        }
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Cidade / UF'
            value={`${city} - ${uf}`}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Cidade / UF</FormLabel>
            <SimpleGrid columns='2' gap='20px'>
            <Input
              type={'text'}
              name={'city'}
              value={city || ''}
              onChange={handleChange}
              placeholder={'Cidade'}
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
            <Select 
              name={'uf'}
              value={uf || ''}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </Select>
            </SimpleGrid>
          </FormControl>
        }
        <Information
          boxShadow={cardShadow}
          title='Data cadastro'
          value={createdAt}
        />
        <Information
          boxShadow={cardShadow}
          title='Status'
          value={
            getStatusName(status)
          }
          reasonRenunciation={reasonRenunciation}
        />
        <Information
          boxShadow={cardShadow}
          title='Situação'
          value={
            getStatusName(currentSituation)
          }
        />
        <Information
          boxShadow={cardShadow}
          title='Teste de personalidade'
          value={urlTestPersonality}
          linkTestPersonality={urlTestPersonality ? true : false}
        />
        <Information
          boxShadow={cardShadow}
          title='Aulas assistidas'
          value={presenceTotal}
        />
      </SimpleGrid>
    </Card>
  );
}
