export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    label: 'Nome',
    accessor: "name",
  },
  {
    Header: "TYPE",
    label: 'Tipo',
    accessor: "type",
  },
  {
    Header: "PLAN",
    label: 'Plano',
    accessor: "plan",
  },
  {
    Header: "CITY",
    label: 'Cidade',
    accessor: "city",
  },
  {
    Header: "UF",
    label: 'Estado',
    accessor: "uf",
  },
  {
    Header: "QTD_STUDENTS",
    label: 'Qtd. Alunos',
    accessor: "company_school_id",
  },
  {
    Header: "CREATED_AT",
    label: 'Criado em',
    accessor: "created_at",
  },
  // {
  //   Header: "ACTION",
  //   label: 'Ações',
  //   accessor: "company_id",
  // },
];
