// Chakra imports
import React, { useState, useEffect } from "react";
import { Box, Grid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Custom components
import Banner from "views/admin/interview_details/components/Banner";
import General from "views/admin/interview_details/components/General";
import Education from "views/admin/interview_details/components/Education";
import SelectionProcess from "views/admin/interview_details/components/SelectionProcess";
import Notifications from "views/admin/interview_details/components/Notifications";
import Projects from "views/admin/interview_details/components/Projects";
import Storage from "views/admin/interview_details/components/Storage";
import Badge from "views/admin/interview_details/components/Badge";
import Situation from "views/admin/interview_details/components/Situation";
import Score from "views/admin/interview_details/components/Score";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

import { API_SERVER } from "config/constant";
import Timeline from "./components/Timeline";

export default function InterviewDetails() {
  const hash = window.location.hash;
  const id = hash.split('/detalhes/');
  const interviewId = id[1] ? id[1]?.split('/')[0] : 0;
  const programId = id[1] ? id[1]?.split('/')[1] : 0;
  // const userId = id[1] ? id[1]?.split('/')[2] : 0;

  const [loading, setLoading] = useState(false);
  const [interview, setInterview] = useState([]);

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  useEffect(() => {
    const getInterviewById = async () => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/interviewById/${interviewId}/${programId}`);
    
      setInterview(data?.dataInterview[0]);
      setLoading(false);
    }

    if (programId > 0) {
      getInterviewById();

      window.scrollTo(0, 0);
    }
  }, [])

  const dateStart = new Date(interview?.program_start_date);
  const dateEnd = new Date(interview?.program_end_date);
  const dateNow = new Date();

  var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  var Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  var Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;


  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mx='auto' mt='80px' align='center' direction='column'>
          <ClipLoader 
            color={'#2759a5'} 
            loading={loading} 
            size={60} 
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1fr 1.30fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          programId={programId}
          banner={banner}
          userId={interview?.user_id}
          avatar={interview?.picture}
          name={interview?.name}
          age={interview?.age}
          city={interview?.city}
          uf={interview?.uf}
          isInterviewer={false}
        /> 
        <Banner
          gridArea='1 / 1 / 2 / 2'
          name={interview?.interviewer_name}
          isInterviewer={true}
        /> 
        <Situation
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          qtyInterviews={interview?.qty_interviews || 0}
          lastInterviewData={interview?.last_interview_date || '-'}
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          programName={interview?.program_name}
          programDescription={interview?.program_description}
          programStartDate={interview?.program_start_date}
          programEndDate={interview?.program_end_date}
          used={percentUsed}
          total={100}
        />
      </Grid>
      
      <General
        minH='290px'
        pe='20px'
        mb='20px'
        interviewData={interview}
      />
    </Box>
  );
}
