import {
  Text,
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";

// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlineWork,
  MdSchool,
  MdLineWeight,
  MdCheck,
  MdCheckCircle,
  MdCancel,
  MdGroups,
  MdWorkOff,
} from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";
import { FaBuilding, FaUserAlt, FaUserGraduate } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { TbIdBadge2 } from "react-icons/tb";
import { BiLoaderCircle } from "react-icons/bi";
import { HiDocumentText } from "react-icons/hi";
import { FiBox } from "react-icons/fi";
import { BsFillHandThumbsDownFill } from "react-icons/bs";

export default function UserReports() {
  const programId = localStorage.getItem('programIdMetrics');

  const [programIdFilter, setProgramIdFilter] = useState(programId);
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);

  const user = JSON.parse(localStorage.getItem('user'));
  const dashboardUserName = user.dashboardUserName;

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const getMetrics = async () => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/metrics/${programIdFilter}`);

      const { metricsData } = data;

      setMetrics(metricsData);
      setLoading(false);
    }

    getMetrics();
  }, [programIdFilter]);

  const handleChangeProgramId = (ev) => {
    localStorage.setItem('programIdMetrics', ev.target.value);

    setProgramIdFilter(ev.target.value);
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {dashboardUserName && (<Text pl='10px' mb='0px' fontSize='2xl' fontWeight='500'>Olá, {dashboardUserName}</Text>)}
      {dashboardUserName && (<Text pl='10px' mb='20px' fontSize='1xl' fontWeight='0'>seja bem-vindo(a) ao dashboard.</Text>)}

      <SimpleGrid
        columns={{ base: 1, md: 4, lg: 4, "2xl": 4 }}
        gap='20px'
        mb='30px'>
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FiBox} color={'#4169E1'} />
              }
            />
          }
          name='Programas'
          value={metrics?.totalPrograms}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={BiLoaderCircle} color={'#ff8000'} />
              }
            />
          }
          name='Em andamento'
          value={metrics?.programsAtivos}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdCheckCircle} color={'#32CD32'} />
              }
            />
          }
          name='Finalizados'
          value={metrics?.programsInativos}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaBuilding} color={'#708090'} />
              }
            />
          }
          name='Empresas'
          value={metrics?.totalCompanies}
        />
      </SimpleGrid>

      <hr />
      <br />
        
        <Flex px='0px' 
          justifyContent={'space-between'} 
          alignItems={'center'} 
          direction='row'
          // justify='space-between' 
          // align='center'
          mt='10px' 
          mb='15px' 
          >
            <Box w='250px' mr='20px'>
              <Text 
                style={{
                  "fontSize": "1rem",
                  "color": "#2759a5",
                  "padding": "4px 10px",
                  "background": "#fff",
                  "width": "220px",
                  "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  "borderRadius": "10px",
                  "textAlign": "center",
                }}
                pl='10px' 
                // mb='15px' 
                mb='0px' 
                fontSize='lg' 
                fontWeight='500'>
                  Education Recruiting
              </Text>
            </Box>

            <Box w='250px' mr='20px'>
              <Select 
                name="programId"
                onChange={handleChangeProgramId}
                variant='auth'
                fontSize='sm'
                mb='0px'
                fontWeight='500'
                size='lg'
                bg={'white'}
                style={{
                  "height": "33px",
                  "borderRadius": "10px",
                  "marginLeft": "0px",
                  "boxShadow": "rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px",
                }}
              >
                <option value="0" selected={programId == 0 ? true : false}>Todos programas</option>
                <option value="3" selected={programId == 3 ? true : false}>Todas Tech</option>
                <option value="2" selected={programId == 2 ? true : false}>SharpCoders</option>
                <option value="1" selected={programId == 1 ? true : false}>GoDev 2022</option>
              </Select>
            </Box>
        </Flex>

      <SimpleGrid
        columns={{ base: 1, md: 4, lg: 4, "2xl": 6 }}
        gap='20px'
        mb='30px'>
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdGroups} color={'#4169E1'} />
              }
            />
          }
          name='Total alunos'
          value={metrics?.totalStudents}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdSchool} color={'#ff8000'} />
              }
            />
          }
          name='Em formação'
          value={metrics?.studentsEmFormacao}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdCancel} color={'#2f2f2f'} />
              }
            />
          }
          name='Desligados'
          value={metrics?.studentsDesligados}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={BsFillHandThumbsDownFill} color={'#e32636'} />
              }
            />
          }
          name='Desistentes'
          value={metrics?.studentsForaPrograma}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdCheckCircle} color={'#32CD32'} />
              }
            />
          }
          name='Formados'
          value={metrics?.studentsFormados}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaUserAlt} color={'#666666'} />
              }
            />
          }
          name='Indicados'
          value={metrics?.studentsIndicados}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdOutlineWork} color={'#5C4033'} />
              }
            />
          }
          name='Contratados'
          value={metrics?.studentsContratados}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdWorkOff} color={'#ff4040'} />
              }
            />
          }
          name='JobOut'
          value={metrics?.studentsJobOut}
        />
      </SimpleGrid>

      <Text 
        style={{
          "fontSize": "1rem",
          "color": "#2759a5",
          "padding": "4px 10px",
          "background": "#fff",
          "width": "220px",
          "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          "borderRadius": "10px",
          "textAlign": "center",
        }}
        pl='10px' mb='15px' fontSize='lg' fontWeight='500'>Head Hunting</Text>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='30px'>
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaUserGraduate} color={'#4169E1'} />
              }
            />
          }
          name='Banco de Talentos'
          value={metrics?.totalBancoTalentos}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaUserAlt} color={'#666666'} />
              }
            />
          }
          name='Indicados'
          value='0'
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdOutlineWork} color={'#5C4033'} />
              }
            />
          }
          name='Contratados'
          value='2'
        />
      </SimpleGrid>

      <Text 
        style={{
          "fontSize": "1rem",
          "color": "#2759a5",
          "padding": "4px 10px",
          "background": "#fff",
          "width": "220px",
          "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          "borderRadius": "10px",
          "textAlign": "center",
        }}
        pl='10px' mb='15px' fontSize='lg' fontWeight='500'>Time Ímã</Text>
      <SimpleGrid
        columns={{ base: 1, md: 3, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='30px'>
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={GiTeacher} color={'#4169E1'} />
              }
            />
          }
          name='Professores'
          value={metrics?.totalTeachers}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaUserAlt} color={'#666666'} />
              }
            />
          }
          name='Tutores'
          value={metrics?.totalTutores}
        />
        <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={TbIdBadge2} color={'#b8860b'} />
              }
            />
          }
          name='Monitores'
          value={metrics?.totalClassLeaders}
        />
      </SimpleGrid> 

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
