// Chakra imports
import React, { useState, useEffect } from "react";
import { Box, Grid, Flex, Text, useColorModeValue, 
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel, 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tooltip,
  GridItem,
  Button,
  Link,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import Select, { StylesConfig } from 'react-select';
import chroma from "chroma-js";
import axios from "axios";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Education from "views/admin/profile/components/Education";
import SelectionProcess from "views/admin/profile/components/SelectionProcess";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Badge from "views/admin/profile/components/Badge";
import Situation from "views/admin/profile/components/Situation";
import Score from "views/admin/profile/components/Score";
import GeneratePDF from "./components/GeneratePDF";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

import { API_SERVER } from "config/constant";
import Timeline from "./components/Timeline";
import Card from "components/card/Card";
import { BsFileEarmarkPdfFill, BsFileEarmarkPlusFill, BsFillArrowLeftCircleFill, BsFillCheckCircleFill } from "react-icons/bs";
import { MdOutlineChat, MdOutlineInfo, MdOutlineOndemandVideo, MdScore } from "react-icons/md";
import { FaCheck, FaExternalLinkAlt, FaFileUpload } from "react-icons/fa";

import ProfilePdf from "../profile-pdf";
import { FaClipboardList, FaLaptopCode, FaUser, FaUserAlt } from "react-icons/fa";
import { GrScorecard } from "react-icons/gr";
import { GoFileSubmodule } from "react-icons/go";

export default function Overview() {
  const hash = window.location.hash;
  const id = hash.split('/aluno/');
  const userId = id[1] ? id[1]?.split('/')[0] : 0;
  const programId = id[1] ? id[1]?.split('/')[1] : 0;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingInterviews, setLoadingInterviews] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingTimeline, setLoadingTimeline] = useState(false);
  const [user, setUser] = useState([]);
  const [rankingPosition, setRankingPosition] = useState(0);
  const [userProjects, setUserProjects] = useState([]);
  const [userTimeline, setUserTimeline] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [userCulturalFitTags, setUserCulturalFitTags] = useState([]);
  const [userCompaniesMatch, setUserCompaniesMatch] = useState([]);
  const [userCompanyFitTags, setUserCompanyFitTags] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [presenceTotal, setPresenceTotal] = useState(0);
  const [isEditAll, setIsEditAll] = useState(false);
  const [isOpenFormInterview, setIsOpenFormInterview] = useState(false);
  const [refreshInterviews, setRefreshInterviews]= useState(true);

  const [loadingSendInterview, setLoadingSendInterview] = useState(false);
  const [errorFormInterview, setErrorFormInterview] = useState(false);
  const [formSuccessInterview, setFormSuccessInterview] = useState(false);
  const [dateInterview, setDateInterview] = useState('');
  const [typeInterview, setTypeInterview] = useState('');
  const [transcriptInterview, setTranscriptInterview] = useState('');
  const [obsInterview, setObsInterview] = useState('');

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  const colourOptions = [
    { value: 'autogerenciavel', label: 'AUTOGERENCIÁVEL', color: '#00B8D9' },
    { value: 'comunicativo', label: 'COMUNICATIVO', color: '#0052CC' },
    { value: 'metodico', label: 'METÓDICO', color: '#5243AA' },
    { value: 'planejador', label: 'PLANEJADOR', color: '#FF5630' },
    { value: 'executor', label: 'EXECUTOR', color: '#FF8B00' },
    { value: 'analista', label: 'ANALISTA', color: '#FFC400' },
    { value: 'lider', label: 'LÍDER', color: '#36B37E' },
  ];

  const companiesOptions = [
    { value: 1, label: 'NECTAR CRM', color: '#00B8D9' },
    { value: 2, label: 'TRINUS', color: '#0052CC' },
    { value: 3, label: 'POLI', color: '#5243AA' },
    { value: 4, label: 'AUVO', color: '#FF5630' },
    { value: 5, label: 'MXM', color: '#FF8B00' },
    { value: 6, label: 'E-SOLUTION', color: '#FFC400' },
    { value: 7, label: 'GENPAG', color: '#36B37E' },
  ];

  useEffect(() => {
    const getUser = async (userId) => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/user/${userId}/${programId}`);

      const { user, position, projects, timeline, culturalFitTags, companiesMatchStudent, companyFitTags, presenceTotal } = data;

      setRankingPosition(position);
      setUser(user[0]);
      setUserProjects(projects);
      setUserTimeline(timeline);
      setUserCulturalFitTags(culturalFitTags);
      setUserCompaniesMatch(companiesMatchStudent);
      setUserCompanyFitTags(companyFitTags);
      setPresenceTotal(presenceTotal);
      setLoading(false);
    }

    if (userId > 0) {
      getUser(userId);

      window.scrollTo(0, 0);
    }
  }, [])

  useEffect(() => {
    const getUser = async (userId) => {
      setLoadingTimeline(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/user/${userId}/${programId}`);

      const { timeline } = data;

      setUserTimeline(timeline);
      setLoadingTimeline(false);
    }

    if (loadingTimeline && userId > 0) {
      getUser(userId);
    }
  }, [loadingTimeline]);

  useEffect(() => {
    const getInterviews = async (userId) => {
      setLoadingInterviews(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/usersInterviewByUserId/${userId}/${programId}`);

      const { interviewsData } = data;

      setInterviews(interviewsData.interviews);
      setLoadingInterviews(false);
      setRefreshInterviews(false);
    }

    if (refreshInterviews) {
      getInterviews(userId);
    }
  }, [refreshInterviews])

  useEffect(() => {
    const getHardSkills = async (userId) => {
      // setLoadingHardSkills(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/usersHardSkillsByUserId/${userId}`);

      const { userHardSkills } = data;
      // alert(JSON.stringify(userHardSkills));

      setHardSkills(userHardSkills);
      // setLoadingHardSkills(false);
    }

    getHardSkills(userId);
  }, [])

  const dateStart = new Date(user?.program_start_date);
  const dateEnd = new Date(user?.program_end_date);
  const dateNow = new Date();

  var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  var Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  var Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

    if (user?.status != 'contratado') {
      let userData = user;
      userData.current_situation = null;
      userData.company_id = null;

      setUser(userData);
    } 
    
    if (user?.status == 'contratado' || user?.company_id > 0) {
      let userData = user;
      userData.current_situation = 'contratado';
      userData.company_id = user?.company_id;

      setUser(userData);
    }

		setUser({...user, [name]: value});
	};

  const handleEdit = () => {
    if (percentUsed >= 100) {
      setIsEditAll(false);
    } else {
      setIsEditAll(true);
    }

    setIsEdit(!isEdit);
  }

  const handleUpdateProfile = async () => {
    try {
      let userData = user;
      userData.programId = programId;
      userData.cpf = user?.cpf?.replace(/[^0-9]+/g,'');
      userData.cell_phone = user?.cell_phone?.replace(/[^0-9]+/g,'');

      const { name, email, cpf, cell_phone, gender, age, city, uf, status, company_id } = userData;
            
      if (!programId || !userId || !name || !email || !cpf || !cell_phone || !gender || !age || !city || !uf || !status) {
        throw 'EMPTY_FIELDS';
      }

      if (status == 'contratado' && !company_id) {
        throw 'EMPTY_FIELDS';
      }

      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.post(`${API_SERVER}dashboard/user/${userId}`, userData);

      if (data?.dataCompany[0]) {
        const { companyId, companyName, companyLogo, companyHiredIn } = data?.dataCompany[0];
        
        userData.company_id = companyId;
        userData.company_name = companyName;
        userData.company_logo = companyLogo;
        userData.company_hired_in = companyHiredIn;

        setUser(userData);
      }

      setIsEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error == 'EMPTY_FIELDS') {
        alert('Preencha todos os campos!')
      }

      setLoading(false);
    }
  }
  
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginBottom: '20px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  const addTags = async (choices) => {
    try {
      if (loadingTags) {
        return false;
      }

      setLoadingTags(true);
      choices ? setUserCulturalFitTags(choices) : setUserCulturalFitTags([]);

      const formData = {
        userId: userId,
        programId: programId,
        choices: choices
      }

      const { data } = await axios.post(`${API_SERVER}dashboard/user/culturalFit/addTag`, formData);

      data?.companiesMatch ? setUserCompaniesMatch(data?.companiesMatch) : setUserCompaniesMatch([]);
      setLoadingTags(false);
    } catch (error) {
      setLoadingTags(false);
    }
  }

  const addCompanies = async (choices) => {
    try {
      if (loadingCompanies) {
        return false;
      }

      setLoadingCompanies(true);

      const formData = {
        userId: userId,
        programId: programId,
        choices: choices
      }

      await axios.post(`${API_SERVER}dashboard/user/companyFit/addTag`, formData);

      setLoadingCompanies(false);
    } catch (error) {
      setLoadingCompanies(false);
    }
  }

  const getNationality = (nationality) => {
    if (nationality == 'brasileiro') {
      return 'Brasileiro(a)';
    }

    if (nationality == 'venezuelano') {
      return 'Venezuelano(a)';
    }

    if (nationality == 'argentino') {
      return 'Argentino(a)';
    }

    if (nationality == 'chileno') {
      return 'Chileno(a)';
    }

    if (nationality == 'cubano') {
      return 'Cubano(a)';
    }

    if (nationality == 'boliviano') {
      return 'Boliviano(a)';
    }

    return nationality;
  }

  const getYouLiveWith = (type) => {
    if (type == 'alone') {
      return 'Sozinho(a)';
    }

    if (type == 'spouse') {
      return 'Cônjuge';
    }

    if (type == 'lover') {
      return 'Namorado(a)';
    }

    if (type == 'parents') {
      return 'Pais';
    }

    if (type == 'grandparents') {
      return 'Avós';
    }

    if (type == 'sons') {
      return 'Filhos';
    }

    if (type == 'friends') {
      return 'Amigos';
    }

    if (type == 'relatives') {
      return 'Parentes';
    }

    return type;
  }

  const getLevelProgramming = (level) => {
    if (level == 'none') {
      return 'Nenhum';
    }

    if (level == 'basic') {
      return 'Básico (já fiz cursos introdutórios)';
    }

    if (level == 'intermediary') {
      return 'Intermediário (já estudei na faculdade ou fiz cursos mais avançados)';
    }

    if (level == 'advanced') {
      return 'Avançado (já trabalho com programação)';
    }

    return level;
  }

  const getNameByKey = (key) => {
    if (key == 'dotnet') {
      return '.NET';
    }

    if (key == 'dotnetcore') {
      return '.NET Core';
    }

    if (key == 'csharp') {
      return 'C#';
    }

    if (key == 'java') {
      return 'Java';
    }

    if (key == 'php') {
      return 'PHP';
    }

    if (key == 'javascript') {
      return 'JavaScript';
    }

    if (key == 'typescript') {
      return 'TypeScript';
    }

    if (key == 'nodejs') {
      return 'NodeJS';
    }

    if (key == 'graphql') {
      return 'GraphQL';
    }

    if (key == 'nestjs') {
      return 'NestJS';
    }

    if (key == 'reactjs') {
      return 'ReactJS';
    }

    if (key == 'vuejs') {
      return 'Vue.js';
    }

    if (key == 'angularjs') {
      return 'AngularJS';
    }

    if (key == 'angular') {
      return 'Angular';
    }

    if (key == 'layout-responsivo') {
      return 'Layout Responsivo';
    }

    if (key == 'html') {
      return 'HTML';
    }

    if (key == 'css') {
      return 'CSS';
    }

    if (key == 'springboot') {
      return 'Spring Boot';
    }

    if (key == 'laravel') {
      return 'Laravel';
    }

    if (key == 'rubyonrails') {
      return 'Ruby on Rails';
    }

    if (key == 'kotlin') {
      return 'Kotlin';
    }

    if (key == 'rust') {
      return 'Rust';
    }

    if (key == 'go') {
      return 'GO';
    }

    if (key == 'python') {
      return 'Python';
    }

    if (key == 'aws') {
      return 'AWS';
    }

    if (key == 'azure') {
      return 'Azure';
    }

    if (key == 'git') {
      return 'GIT';
    }

    if (key == 'mysql') {
      return 'MySQL';
    }

    if (key == 'couchbase') {
      return 'Couchbase';
    }

    if (key == 'oracle') {
      return 'Oracle';
    }

    if (key == 'postgres') {
      return 'PostgreSQL';
    }

    if (key == 'mongodb') {
      return 'MongoDB';
    }

    if (key == 'sqlserver') {
      return 'SQL Server';
    }

    if (key == 'docker') {
      return 'Docker';
    }

    if (key == 'kubernetes') {
      return 'Kubernetes';
    }

    if (key == 'jasmine') {
      return 'Jasmine';
    }

    if (key == 'junit') {
      return 'JUnit';
    }

    if (key == 'jest') {
      return 'Jest';
    }

    if (key == 'xunit') {
      return 'XUnit.net';
    }

    if (key == 'phpunit') {
      return 'PHPUnit';
    }

    if (key == 'nunit') {
      return 'NUnit';
    }

    if (key == 'sonar') {
      return 'Sonar';
    }

    if (key == 'mocha') {
      return 'Mocha';
    }

    if (key == 'tdd') {
      return 'TDD';
    }

    if (key == 'ansible') {
      return 'Ansible';
    }

    if (key == 'gradle') {
      return 'Gradle';
    }

    if (key == 'aws_autoscale') {
      return 'AWS Auto Scaling';
    }

    if (key == 'cicd') {
      return 'CI/CD';
    }

    if (key == 'google-cloud') {
      return 'Google Cloud Platform (GCP)';
    }

    if (key == 'reactnative-testing-library') {
      return 'React Native Testing Library';
    }

    if (key == 'swift') {
      return 'Swift';
    }

    if (key == 'flutter') {
      return 'Flutter';
    }

    if (key == 'objective-c') {
      return 'Objective-C';
    }

    if (key == 'xamarin') {
      return 'Xamarin';
    }

    if (key == 'react-native') {
      return 'React Native';
    }

    return key;
  }

  const getLevelProgrammingHardSkills = (key) => {
    if (key == 'theoretical') {
      return 'teórico';
    }

    if (key == 'beginner') {
      return 'iniciante';
    }

    if (key == 'intermediate') {
      return 'intermediário';
    }

    if (key == 'advanced') {
      return 'avançado';
    }

    return key;
  }

  const handleCloseFormInterview = () => {
    setDateInterview('');
    setTypeInterview('');
    setTranscriptInterview('');
    setObsInterview('');

    setIsOpenFormInterview(false);
  }

  const handleSubmitInterview = async () => {
    try {            
      setFormSuccessInterview(false);
      setErrorFormInterview(false);

      if (!dateInterview || !typeInterview || !transcriptInterview || !obsInterview) {
        throw 'EMPTY_FIELDS';
      }

      const formData = {
        interviewerUserId: dashboardUserId,
        userId: userId,
        programId: programId,
        dateInterview: dateInterview,
        typeInterview: typeInterview,
        transcriptInterview: transcriptInterview,
        obsInterview: obsInterview,
      };

      if (loadingSendInterview) {
        return false;
      }

      setLoadingSendInterview(true);

      const { data } = await axios.post(`${API_SERVER}dashboard/sendTranscriptInterviewByStudent`, formData);

      setErrorFormInterview(false);
      setRefreshInterviews(true);
      
      setLoadingSendInterview(false);

      setDateInterview('');
      setTypeInterview('');
      setTranscriptInterview('');
      setObsInterview('');

      setFormSuccessInterview(true);
    } catch (error) {
      if (error == 'EMPTY_FIELDS') {
        setErrorFormInterview(true);
        setRefreshInterviews(false);
      }

      setFormSuccessInterview(false);
      setLoadingSendInterview(false);
    }
  } 

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mx='auto' mt='80px' align='center' direction='column'>
          <ClipLoader 
            color={'#2759a5'} 
            loading={loading} 
            size={60} 
          />
        </Flex>
      </Box>
    )
  }

  const totalAulasByProgram = programId == 3 ? 68 : (programId == 2 ? 48 : 0);
  const presenceStudent = programId == 2 && presenceTotal > 48 ? 48 : presenceTotal;
  const presenceStudentText = programId > 1 ? `${presenceStudent}/${totalAulasByProgram}` : <p style={{"color": "red"}}>Sem informação</p>;

  return (
    <Tabs size='md' variant='enclosed' mt='80px'>
        <Link href='/#/admin/alunos'>
          <Button colorScheme="brandScheme" mb='20px'>
            <BsFillArrowLeftCircleFill style={{"marginRight": "6px"}} />
            Voltar
          </Button>
        </Link>
        <TabList>
          <Tab><FaUser w={5} color="gray.500" style={{marginRight: 5}} /><b>Perfil</b></Tab>
          <Tab><MdScore w={5} color="gray.500" style={{marginRight: 5}} /><b>Pontuação</b></Tab>
          <Tab><GoFileSubmodule w={5} color="gray.500" style={{marginRight: 5}} /><b>Projetos</b></Tab>
          <Tab><FaLaptopCode w={5} color="gray.500" style={{marginRight: 5}} /><b>Hard Skills</b></Tab>
          <Tab><MdOutlineChat w={5} color="gray.500" style={{marginRight: 5}} /><b>Entrevistas</b></Tab>
          <Tab><BsFileEarmarkPdfFill w={5} color="gray.500" style={{marginRight: 5}} /><b>Doc. indicação</b></Tab>
          {dashboardUserId && (<Tab><FaClipboardList w={5} color="gray.500" style={{marginRight: 5}} /><b>Anotações</b></Tab>)}
        </TabList>
        <TabPanels>
          <TabPanel>

    <Box pt={{ base: "130px", md: "10px", xl: "10px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1fr 1.30fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          isEdit={isEdit}
          setIsEdit={handleEdit}
          handleChange={handleChange}
          handleUpdateProfile={handleUpdateProfile}
          banner={banner}
          userId={userId}
          status={user?.status}
          currentSituation={user?.current_situation}
          avatar={user?.picture}
          name={user?.name}
          course={user?.course}
          isMonitor={user?.is_monitor}
          githubUrl={user?.github_url}
          linkedinUrl={user?.linkedin_url}
          certifiedUrl={user?.certified_url}
        /> 
        <Situation
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          status={user?.status}
          currentSituation={user?.current_situation}
          companyName={user?.company_name}
          companyLogo={user?.company_logo}
          companyHiredIn={user?.company_hired_in}
          course={user?.course}
        />
        <Badge
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          badge={user?.badge}
          score={user?.total_note}
          position={rankingPosition}
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          programName={user?.program_name}
          programDescription={user?.program_description}
          programStartDate={user?.program_start_date}
          programEndDate={user?.program_end_date}
          used={percentUsed}
          total={100}
        />
      </Grid>
      
      <General
        minH='290px'
        pe='20px'
        mb='20px'
        isEdit={isEdit}
        isEditAll={isEditAll}
        setIsEdit={handleEdit}
        handleChange={handleChange}
        programId={programId}
        userData={user}
        name={user?.name}
        cpf={user?.cpf}
        email={user?.email}
        cellphone={user?.cell_phone}
        gender={user?.gender}
        age={user?.age}
        city={user?.city}
        uf={user?.uf}
        status={user?.status}
        urlTestPersonality={user?.url_test_personality}
        currentSituation={user?.current_situation}
        reasonRenunciation={user?.reason_renunciation}
        companyId={user?.company_id}
        active={user?.active}
        createdAt={user?.date}
        presenceTotal={presenceStudentText}
      />

      <Education
        minH='290px'
        pe='20px'
        mb='20px'
        isEdit={isEdit}
        setIsEdit={handleEdit}
        handleChange={handleChange}
        schooling={user?.schooling}
        formation={user?.formation}
        previousKnowledge={user?.previous_knowledge}
        educationalInstitution={user?.educational_institution}
      />

      <SelectionProcess
        minH='185px'
        pe='20px'
        isEdit={isEdit}
        setIsEdit={handleEdit}
        handleChange={handleChange}
        logicTestNote={user?.logic_test_note}
        presentationVideo={user?.presentation_video}
      />

        {dashboardUserId ? 
          <>
              <Grid
                mt='20px'
                mb='0px'
                templateColumns={{
                  base: "1fr",
                  lg: "repeat(2, 1fr)",
                  "2xl": "1.34fr 1.62fr 1fr",
                }}
                templateRows={{
                  base: "1fr",
                  lg: "repeat(1, 1fr)",
                  "2xl": "1fr",
                }}
                gap={{ base: "20px", xl: "20px" }}
                >
                <Card mb={{ base: "20px", "2xl": "20px" }}>
                  <Text
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='0px'>
                    Fit cultural
                  </Text>
                  <Text
                    color={textColorPrimary}
                    fontWeight='normal'
                    fontSize='1xl'
                    mt='0px'
                    mb='10px'>
                    Selecione as tags que descrevem as habilidades do aluno e encontre as empresas mais indicadas para ele.
                  </Text>
                  <Select
                    placeholder='Selecione uma tag'
                    defaultValue={userCulturalFitTags}
                    closeMenuOnSelect={false}
                    isDisabled={loadingTags}
                    isMulti
                    options={colourOptions}
                    styles={colourStyles}
                    onChange={(choice) => { addTags(choice) }}
                  />
                  {(userCulturalFitTags?.length > 0 || loadingTags) &&
                    <>
                      <hr />
                      <Text
                        color={textColorPrimary}
                        fontWeight='bold'
                        fontSize='1xl'
                        mt='5px'
                        mb='8px'>
                          Match com empresas:
                      </Text>
                    </>
                  }
                  {!loadingTags && userCompaniesMatch?.length > 0 ? 
                  <div
                    style={{
                      "display": "flex",
                      "justifyContent": "flex-start",
                      "alignItems": "center",
                    }}
                  >
                    {userCompaniesMatch?.map((data, i) => (
                      <Tooltip key={i} hasArrow label={`${data?.name}: ${data?.tags}`} placement='bottom' bg='gray.300' color='black'>
                        <img 
                          src={data?.company_logo}
                          width={35}
                          style={{"borderRadius": "100px", "marginRight": "10px"}}
                          alt={data?.name} 
                        />
                      </Tooltip>
                    ))}
                  </div>
                  : 
                  !loadingTags && userCulturalFitTags?.length > 0 && userCompaniesMatch?.length == 0 ?
                    <Text
                      color={textColorPrimary}
                      fontWeight='normal'
                      fontSize='1xl'
                      mt='0px'
                      mb='0px'>
                        Nenhum match ainda.
                    </Text>
                    : loadingTags ? 'Carregando...' : null
                  }
                  
                </Card>
                <Card mb={{ base: "20px", "2xl": "20px" }}>
                  <Text
                    color={textColorPrimary}
                    fontWeight='bold'
                    fontSize='2xl'
                    mt='10px'
                    mb='6px'>
                    Empresa ideal
                  </Text>
                  <Text
                    color={textColorPrimary}
                    fontWeight='normal'
                    fontSize='1xl'
                    mt='0px'
                    mb='10px'>
                    Selecione a empresa, ou as empresas, que você considera ideais para o aluno.
                  </Text>
                  <Select
                    placeholder='Selecione uma empresa'
                    defaultValue={userCompanyFitTags}
                    closeMenuOnSelect={false}
                    isDisabled={loadingCompanies}
                    isMulti
                    options={companiesOptions}
                    styles={colourStyles}
                    onChange={(choice) => { addCompanies(choice) }}
                  />
                </Card>
            </Grid>
          </>
        : null}
    </Box>
    </TabPanel>

    <TabPanel>
      <Box mt='10px' pb='10px'>
        <Score
          gridArea='2 / 2 / 2 / 2'
          minH='365px'
          pe='20px'
          beecrowd={user?.beecrowd_note}
          firstInterview={user?.first_interview_note}
          secondInterview={user?.second_interview_note}
          practicalProjects={user?.practical_projects_note}
          qtyAttendancesMeet={user?.qty_attendances_meet}
          forum={user?.forum_note}
          scoreTotal={user?.total_note}
        />
      </Box>
    </TabPanel>

    <TabPanel>
      <Box mt='10px' pb='10px'>
        <Projects
          gridArea='2 / 2 / 2 / 2'
          projects={userProjects}
          userId={userId}
          programId={programId}
        />
      </Box>
    </TabPanel>

    <TabPanel>
        <Text 
        style={{
          "fontSize": "1rem",
          color: '#000'
        }}
        pl='0px' mb='30px' fontSize='md'>
          <Flex mx='auto' mt='0px' align='center' direction='row'>
            <MdOutlineInfo size={20} /> <b style={{marginLeft: '5px'}}>Dados informados pelo aluno</b>
          </Flex>
        </Text>
        <Grid templateColumns='repeat(2, 1fr)' gap={2}>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Front-End</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'frontend')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'frontend' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Back-End</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'backend')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'backend' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Desenvolvimento Mobile</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'mobile')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'mobile' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Banco de Dados</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'database')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'database' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Testes</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'test')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'test' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>DevOps</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'devops')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'devops' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
        </Grid>
    </TabPanel>

    <TabPanel>
    <Accordion defaultIndex={[0]} allowToggle>
      {!loadingInterviews && (
          <>
            <Button colorScheme="green" mb='15px' onClick={() => setIsOpenFormInterview(!isOpenFormInterview)}>
              <BsFileEarmarkPlusFill style={{"marginRight": "6px"}} />
              Adicionar
            </Button>
            <hr />
            <br />
            {isOpenFormInterview && (
              <form
                style={{
                  backgroundColor: "#fff",
                  padding: "15px 15px 0px",
                  borderRadius: "8px"
                }}
              >
                <p style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}>Cadastrar entrevista</p>

                {errorFormInterview && (
                  <p style={{
                    color: 'red',
                    marginBottom: '10px',
                  }}>Preencha todos os campos!</p>
                )}

                {formSuccessInterview && (
                  <p style={{
                    color: 'green',
                    marginBottom: '10px',
                  }}>Entrevista salva com sucesso!</p>
                )}

                <FormControl mb='20px'>
                  <FormLabel><b>Data da entrevista</b></FormLabel>
                  <Input type='date' name={'date_interview'} onChange={(ev) => setDateInterview(ev.target.value)} required={true} />
                </FormControl>

                <FormControl mb='20px'>
                  <FormLabel><b>Tipo da Entrevista</b></FormLabel>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Seleciona uma opção"
                    isDisabled={false}
                    isClearable={false}
                    name={'type_interview'}
                    required={true}
                    options={[
                      { value: 'hardskills', label: 'Técnica', color: '#00B8D9' },
                      { value: 'softskills', label: 'Comportamental', color: '#00B8D9' }
                    ]}
                    onChange={(choice) => setTypeInterview(choice.value)}
                    // onChange={(choice) => { onChangeHardSkills(choice) }}
                  />
                </FormControl>

                <FormControl mb='20px'>
                  <FormLabel><b>Link da transcrição</b></FormLabel>
                  <Input type='text' name={'link_transcript'} required={true} onChange={(ev) => setTranscriptInterview(ev.target.value)} />
                </FormControl>

                <FormControl mb='20px'>
                  <FormLabel><b>Observações</b></FormLabel>
                  <Textarea name={'what_think_interview'} required={true} onChange={(ev) => setObsInterview(ev.target.value)} />
                </FormControl>

                <Button disabled={loadingSendInterview} colorScheme="red" mb='15px' mr="8px" onClick={handleCloseFormInterview}>
                  Cancelar
                </Button>
                <Button disabled={loadingSendInterview} colorScheme="brandScheme" mb='15px' onClick={handleSubmitInterview}>
                  <FaCheck style={{"marginRight": "6px"}} />
                  {loadingSendInterview ? 'Salvando...' : 'Salvar'}
                </Button>
              </form>
            )}
            <br />
          </>
      )}
      {loadingInterviews ? 'Carregando...' 
        : 
        interviews?.length == 0 && 'Nenhuma entrevista realizada ainda.'
      }

      {interviews?.map((data, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                Entrevista {data?.type == 'softskills' ? 'Comportamental' : 'Técnica'} - Data: {data?.interview_date}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {data?.transcript_url ? (
              <>
                <p style={{marginBottom: '12px'}}><b>Tipo da Entrevista:</b> {data?.type == 'softskills' ? 'Comportamental' : 'Técnica'}</p> 

                <a href={data?.transcript_url} target="_blank">
                  <p style={{color: 'blue', marginBottom: '12px'}}><b style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}><FaExternalLinkAlt style={{marginRight: '5px'}} /> Abrir transcrição</b></p> 
                </a>
                
                <p><b>Observações:</b></p> 
                <p style={{marginBottom: '12px'}}>{data?.what_think_interview}</p>
              </>
            ) : (
            <>
            <p style={{
              "fontSize": "1.2rem",
              "color": "rgb(39, 89, 165)"
            }}><b>Apresentação do candidato:</b></p>
            <hr />
            <br />

            <p><b>1. Nacionalidade</b>: {getNationality(data?.nationality)}</p> 
            <br />

            <p><b>2. Mora com quem?</b></p> 
            <p>{getYouLiveWith(data?.you_live_with)}</p>
            <br />
            
            <p><b>3. Me conte um pouco da sua trajetória acadêmica, como foram seus estudos?</b></p>
            <div dangerouslySetInnerHTML={{
              __html: data?.academic_trajectory?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p><b>4. Você já teve experiências profissionais?</b></p> 
            <p>{data?.has_professional_experiences == 1 ? 'Sim' : 'Não'}</p>
            <p><b>4.1. Observações:</b></p>
            <div dangerouslySetInnerHTML={{
              __html: data?.comments_professional_experiences?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p><b>5. Você está em transição de carreira?</b></p> 
            <p>{data?.career_transition == 1 ? 'Sim' : 'Não'}</p>
            <p><b>5.1. Observações:</b></p>
            <div dangerouslySetInnerHTML={{
              __html: data?.comments_career_transition?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p><b>6. Trabalha atualmente?</b></p> 
            <p>{data?.currently_working == 1 ? 'Sim' : 'Não'}</p>
            <p><b>6.1. Observações:</b></p>
            <div dangerouslySetInnerHTML={{
              __html: data?.comments_currently_working?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p style={{
              "fontSize": "1.2rem",
              "color": "rgb(39, 89, 165)"
            }}><b>Futuro Profissional:</b></p>
            <hr />
            <br />

            <p><b>7. Onde você se vê no futuro? (curto, médio e longo prazo)</b></p> 
            <div dangerouslySetInnerHTML={{
              __html: data?.future_vision?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p style={{
              "fontSize": "1.2rem",
              "color": "rgb(39, 89, 165)"
            }}><b>Habilidades técnicas:</b></p>
            <hr />
            <br />

            <p><b>8. Qual seu nível de conhecimento em programação?</b></p> 
            <p>{getLevelProgramming(data?.knowledge_level)}</p>
            <br />

            <p><b>9. O que você acredita que você domina dentro da área de programação?</b></p> 
            <div dangerouslySetInnerHTML={{
              __html: data?.domain_programming?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p style={{
              "fontSize": "1.2rem",
              "color": "rgb(39, 89, 165)"
            }}><b>Fit Cultural, Soft Skills e Personalidade:</b></p>
            <hr />
            <br />

            <p><b>10. Por quê você acha que deveria ser contratado por alguma das empresas contratantes?</b></p> 
            <div dangerouslySetInnerHTML={{
              __html: data?.why_should_be_hired?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p style={{
              "fontSize": "1.2rem",
              "color": "rgb(39, 89, 165)"
            }}><b>Feedback da entrevista:</b></p>
            <hr />
            <br />

            <p><b>O que você achou da entrevista?</b></p> 
            <div dangerouslySetInnerHTML={{
              __html: data?.what_think_interview?.replace(/\n/g, '<br />'),
            }} /> 
            <br />

            <p><b>De 1 a 5, Que nota você daria para apresentação do candidato?</b></p> 
            <p>{data?.presentation_note}</p>
            <br />

            <p><b>De 1 a 5, Que nota você daria para comunicação do candidato?</b></p> 
            <p>{data?.communication_note}</p>
            <br />

            <p><b>De 1 a 5, Que nota você daria para o histórico profissional/acadêmico do candidato?</b></p> 
            <p>{data?.academic_professional_note}</p>
            <br />
            </>
            )}

          </AccordionPanel>
        </AccordionItem>
      ))}
    
      </Accordion>
    </TabPanel>
    <TabPanel>
      <ProfilePdf userId={userId} programId={programId} />
    </TabPanel>
    {dashboardUserId && (
      <TabPanel>
        <Timeline
          subtitle={'Utilize este espaço para registrar observações importantes sobre o aluno.'}
          gridArea='1 / 1 / 1 / 1'
          loadingTimeline={loadingTimeline}
          setLoadingTimeline={setLoadingTimeline}
          timeline={userTimeline}
          userId={userId}
          programId={programId}
          authorUserId={dashboardUserId}
        />
      </TabPanel>
    )}
    </TabPanels>
    </Tabs>
  );
}
