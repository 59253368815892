export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "POSITION",
    label: '#',
    accessor: "position",
  },
  {
    Header: "NAME",
    label: 'Nome',
    accessor: "name",
  },
  {
    Header: "BADGE",
    label: 'Badge',
    accessor: "badge",
  },
  {
    Header: "BEECROWD",
    label: 'Beecrowd',
    accessor: "beecrowd_note",
  },
  {
    Header: "FIRST_INTERVIEW",
    label: 'Entv. S.S',
    accessor: "first_interview_note",
  },
  {
    Header: "SECOND_INTERVIEW",
    label: 'Entv. Tech',
    accessor: "second_interview_note",
  },
  {
    Header: "PRACTICAL_PROJECTS",
    label: 'Projetos',
    accessor: "practical_projects_note",
  },
  {
    Header: "QTY_ATTENDANCES_MEET",
    label: 'Presença',
    accessor: "qty_attendances_meet",
  },
  {
    Header: "FORUM",
    label: 'Fórum',
    accessor: "forum_note",
  },
  {
    Header: "TOTAL",
    label: 'Total',
    accessor: "total_note",
  },
];
