import React, { useEffect, useState } from "react";
import axios from "axios";

import Select from 'react-select';
import chroma from "chroma-js";

import { API_SERVER } from "config/constant";

export default function SelectMultiple({ companyId, tags }) {
  const [loadingTags, setLoadingTags] = useState(false);

  const colourOptions = [
    { value: 'estruturada', label: 'ESTRUTURADA', color: '#00B8D9' },
    { value: 'tradicional', label: 'TRADICIONAL', color: '#0052CC' },
    { value: 'fintech', label: 'FINTECH', color: '#5243AA' },
    { value: 'startup', label: 'STARTUP', color: '#FF5630' },
    { value: 'plano-de-carreira', label: 'PLANO DE CARREIRA', color: '#FF8B00' },
    { value: 'salario', label: 'SALÁRIO', color: '#FFC400' },
  ];

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginBottom: '20px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  const addTags = async (choices) => {
    try {
      if (loadingTags) {
        return false;
      }

      setLoadingTags(true);

      const formData = {
        companyId: companyId,
        choices: choices
      }

      await axios.post(`${API_SERVER}dashboard/user/culturalFit/addTag`, formData);

      setLoadingTags(false);
    } catch (error) {
      setLoadingTags(false);
    }
  }

  return (
    <>
        <Select
            defaultValue={tags}
            placeholder='Selecione uma tag'
            closeMenuOnSelect={false}
            isMulti
            isDisabled={loadingTags}
            options={colourOptions}
            styles={colourStyles}
            onChange={(choice) => { addTags(choice) }}
        />
    </>
    )
}