import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    RadioGroup,
    Stack,
    Radio,
    EditableTextarea,
    Textarea,
    ButtonGroup,
  } from "@chakra-ui/react";

  import { API_SERVER } from "config/constant";
import { ClipLoader } from "react-spinners";

export default function ModalViewForm({ formId, modalIsOpen, setModalIsOpen, refreshForms }) {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [formFields, setFormFields] = useState([]);

    useEffect(() => {
      const getFormById = async (formId) => {
        try {  
          if (loading) {
            return false;
          }
    
          setLoading(true);
    
          const { data } = await axios.get(`${API_SERVER}forms/${formId}`);

          setFormFields(data?.form_questions);
          setLoading(false);
        } catch (error) {
          // console.log(error);
    
          setFormFields([]);
          setLoading(false);
        }
      }

      getFormById(formId);
    }, [])

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            size={'xl'}
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Detalhes do Formulário</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {loading && (
              <Box pt={{ base: "130px", md: "40px", xl: "40px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='0px' align='center' direction='column'>
                    <ClipLoader
                      color={'#2759a5'} 
                      loading={loading} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            )}

            {!loading ? (
            <>
      
          {!loading && formFields?.length > 0 ? (
          <>
          <p><b>{formFields[0]?.title}</b></p>
          <p><b>Data e hora</b>: {formFields[0]?.date} até {formFields[0]?.date_end}</p>

          <FormControl mt={4}>
            <FormLabel>E-mail:</FormLabel>
            <Input name='email' disabled={true} />
          </FormControl>

          {formFields?.map((data, i) => (
            <FormControl mt={4}>
              <FormLabel>{i + 1}. {data?.question}</FormLabel>

              {data?.type_question == 'multiple' && 
                <RadioGroup>
                  <Stack direction='column'>
                    {data?.option_a && (<Radio value={data?.option_a}>{data?.option_a}</Radio>)}
                    {data?.option_b && (<Radio value={data?.option_b}>{data?.option_b}</Radio>)}
                    {data?.option_c && (<Radio value={data?.option_c}>{data?.option_c}</Radio>)}
                    {data?.option_d && (<Radio value={data?.option_d}>{data?.option_d}</Radio>)}
                    {data?.option_e && (<Radio value={data?.option_e}>{data?.option_e}</Radio>)}
                  </Stack>
                </RadioGroup>
              }

              {data?.type_question == 'open' && <Textarea 
                placeholder='Texto de resposta longa'
                disabled={true} 
              />}
            </FormControl>
          ))}
          </>
          ): null}

          </>
          ) : 
              null
          }

          </ModalBody>

          <ModalFooter>
            <Button 
              onClick={() => setModalIsOpen(false)}
            >
                Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}