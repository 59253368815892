export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "PICTURE",
    label: 'Foto',
    accessor: "picture",
  },
  {
    Header: "NAME",
    label: 'Nome',
    accessor: "name",
  },
  {
    Header: "INTERVIEW_TITLE",
    label: 'Avaliação',
    accessor: "interview_title",
  },
  {
    Header: "INTERVIEW_TYPE",
    label: 'Tipo',
    accessor: "interview_type",
  },
  {
    Header: "COMPLETED",
    label: 'Concluído',
    accessor: "completed",
  },
  {
    Header: "INTERVIEW_DATE",
    label: 'Realizada em',
    accessor: "interview_date",
  },
  {
    Header: "CREATED_AT",
    label: 'Criada em',
    accessor: "created_at",
  },
  // {
  //   Header: "INTERVIEW_FEEDBACK",
  //   label: 'Mensagem',
  //   accessor: "interview_feedback",
  // },
  {
    Header: "ACTION",
    label: 'Ações',
    accessor: "interview_id",
  },
];

export const columnsDataFeedbacksComplex = [
  {
    Header: "PICTURE",
    label: 'Foto',
    accessor: "picture",
  },
  {
    Header: "NAME",
    label: 'Nome',
    accessor: "name",
  },
  {
    Header: "INTERVIEW_TITLE",
    label: 'Avaliação',
    accessor: "interview_title",
  },
  {
    Header: "INTERVIEW_FEEDBACK",
    label: 'Mensagem',
    accessor: "interview_feedback",
  }
];
