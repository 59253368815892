import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from "@chakra-ui/react";

export default function ModalEditProgram({ programEdit, handleEditProgram, modalIsOpen, setModalIsOpen, formModalError, handleChange }) {
  const isNotEmptyFields = programEdit?.program_name && programEdit?.program_description && programEdit?.program_start_date && programEdit?.program_end_date;

  return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
        <form>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar programa</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {formModalError && <Text mb='15px' color='red.600'>Data de fim deve ser maior que data de início</Text>}

            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input 
                type="text"
                name="program_name"
                value={programEdit?.program_name}
                onChange={handleChange}
                required={true}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Descrição</FormLabel>
              <Input 
                type="text"
                name="program_description"
                value={programEdit?.program_description}
                onChange={handleChange}
                required={true}
              />
            </FormControl>

            <SimpleGrid columns='2' gap='20px'>
            <FormControl mt={4}>
              <FormLabel>Data de início</FormLabel>
              <Input 
                type="date"
                name="program_start_date"
                value={programEdit?.program_start_date?.substring(0,10) || ''}
                onChange={handleChange}
                required={true}
              />
            </FormControl>
            <FormControl mt={4}>
            <FormLabel>Data de fim</FormLabel>
              <Input 
                type="date"
                name="program_end_date"
                min={programEdit?.program_start_date?.substring(0,10) || ''}
                value={programEdit?.program_end_date?.substring(0,10) || ''}
                onChange={handleChange}
                style={formModalError ? {"border": "1px solid red"} : {}}
                required={true}
              />
            </FormControl>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button 
              type="button"
              onClick={handleEditProgram}
              colorScheme='blue' mr={3}
              disabled={!isNotEmptyFields}
            >
              Salvar
            </Button>
            <Button onClick={() => setModalIsOpen(false)}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </form>
      </Modal>
    )
}