import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Progress,
  Text,
  useColorModeValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";
import { FaPlusCircle, FaSearch, FaTrash, FaUsers } from "react-icons/fa";
import { trim } from "lodash";
import { AiFillCloseCircle } from "react-icons/ai";

export default function ModalAddTeachers({ programId, modalIsOpen, setModalIsOpen, refreshProgramById }) {
  const [loading, setLoading] = useState(false);
  const [loadingAddTeacher, setLoadingAddTeacher] = useState(false);
  const [editTeachers, setEditTeachers] = useState(false);
  const [loadingRemoveTeacher, setLoadingRemoveTeacher] = useState(false);
  const [teachersSelected, setTeachersSelected] = useState([]);
  const [teachersNotSelected, setTeachersNotSelected] = useState([]);
  const [teacherSelected, setTeacherSelected] = useState(0);

  useEffect(() => {
    const getTeachers = async () => {
      try {   
        if (loading) {
          return false;
        }

        setLoading(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/teachersByFilters/${programId}`);
        const { teachersSelected, teachersNotSelected } = data || data?.teachersData;

        setTeachersNotSelected(teachersNotSelected);
        setTeachersSelected(teachersSelected);
        setLoading(false);
      } catch (error) {
        console.log(error);
  
        setLoading(false);
      }
    }

    getTeachers();
  }, []);

  const getTeachersRefresh = async () => {
    try {   
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/teachersByFilters/${programId}`);
      const { teachersSelected, teachersNotSelected } = data || data?.teachersData;

      setTeachersNotSelected(teachersNotSelected);
      setTeachersSelected(teachersSelected);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }

  const addTeacher = async (teacherId) => {
    setTeacherSelected(teacherId);

    try {      
      if (!programId || !teacherId) {
        throw 'PROGRAMID_OR_TEACHERID_IS_EMPTY';
      }

      if (loadingAddTeacher) {
        return false;
      }

      setLoadingAddTeacher(true);

      await axios.post(`${API_SERVER}dashboard/teachers`, {
        teacherId: teacherId,
        programId: programId,
      });

      getTeachersRefresh();

      setEditTeachers(true);
      setLoadingAddTeacher(false);
    } catch (error) {
      console.log(error);

      setLoadingAddTeacher(false);
    }
  }

  const removeTeacher = async (teacherId) => {
    setTeacherSelected(teacherId);

    try {      
      if (!programId || !teacherId) {
        throw 'PROGRAMID_OR_TEACHERID_IS_EMPTY';
      }

      if (loadingRemoveTeacher) {
        return false;
      }

      setLoadingRemoveTeacher(true);

      await axios.delete(`${API_SERVER}dashboard/teachers`, { data: {
        teacherId: teacherId,
        programId: programId,
      }});

      getTeachersRefresh();

      setEditTeachers(true);
      setLoadingRemoveTeacher(false);
    } catch (error) {
      console.log(error);

      setLoadingRemoveTeacher(false);
    }
  }

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => {
              setModalIsOpen(false);
              editTeachers && refreshProgramById(true);
            }}
        >
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>Editar professores</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {loading ?
              <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                    <Flex mx='auto' mt='10px' align='center' direction='column'>
                      <ClipLoader 
                        color={'#2759a5'} 
                        loading={loading} 
                        size={60} 
                      />
                    </Flex>
                  </SimpleGrid>
              </Box>
            : null}

            {(!loading && teachersNotSelected?.length > 0) &&
              <Text
                style={{
                  "fontSize": "17px",
                  "fontWeight": "600",
                  "marginLeft": "8px",
                  "marginBottom": "15px"
                }}
              >
                Todos professores
              </Text>
            }

            {!loading && teachersNotSelected?.map(({ teacher_id, teacher_name }) =>
              <div 
                style={{"display": "flex",
                  "flexDirection": "row",
                  "justifyContent": "space-between",
                  "alignItems": "center",
                  "marginTop": "10px",
                  "padding": "10px 15px",
                  "border": "1px solid #2759A5",
                  "borderRadius": "100px",
                }}
              >
                <Text
                  style={{"color": "#2759A5",
                    "fontWeight": "600"
                  }}
                >
                  {teacher_name}
                </Text>
                  {loadingAddTeacher && teacherSelected === teacher_id ? 
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingAddTeacher} 
                      size={20} 
                    />
                  : 
                  <Link 
                    onClick={() => addTeacher(teacher_id)}
                  >
                  <FaPlusCircle 
                    color='#009000' 
                    size={20}
                  />
                </Link>
                }
              </div>
            )}

            {(!loading && teachersSelected?.length > 0) &&
              <>
              {teachersNotSelected?.length > 0 &&
                <br />
              }
              <Text
                style={{
                  "fontSize": "17px",
                  "fontWeight": "600",
                  "marginLeft": "8px",
                  "marginBottom": "15px"
                }}
              >
                Professores no programa
              </Text>
              </>
            }
            
            {!loading && teachersSelected?.map(({ teacher_id, teacher_name }) =>
              <div 
                style={{"display": "flex",
                "flexDirection": "row",
                "justifyContent": "space-between",
                "alignItems": "center",
                "marginTop": "10px",
                "padding": "10px 15px",
                "backgroundColor": "#2759A5",
                "border": "1px solid #2759A5",
                "borderRadius": "100px",
                }}
              >
                <Text
                  style={{"color": "#ffffff",
                    "fontWeight": "600"
                  }}
                >
                  {teacher_name}
                </Text>
                  {loadingRemoveTeacher && teacherSelected === teacher_id ? 
                    <ClipLoader 
                      color={'#ffffff'} 
                      loading={loadingRemoveTeacher} 
                      size={20} 
                    />
                  : 
                  <Link 
                    onClick={() => removeTeacher(teacher_id)}
                  >
                  <FaTrash 
                    color='#ce1151' 
                    size={20}
                  />
                </Link>
                }
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => {
              setModalIsOpen(false);
              editTeachers && refreshProgramById(true);
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}