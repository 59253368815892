import React, { useMemo, useState } from "react";
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
} from "@chakra-ui/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ClipLoader from "react-spinners/ClipLoader";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import Pagination from "components/pagination";

// Assets
import {MdGroups,  MdCheckCircle, MdCancel, MdOutlineWork, MdSchool, MdOutlineChat, MdWorkOff } from "react-icons/md";
import { FaArrowCircleLeft, FaArrowCircleRight, FaFemale, FaMale, FaSearchPlus, FaUserGraduate } from "react-icons/fa";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { SiGoogleclassroom } from "react-icons/si";

import avatarProfile from 'assets/img/avatars/avatar.png';
import ModalUpdateStatus from "./ModalUpdateStatus";

export default function ColumnsTable(props) {
  const { 
    columnsData, 
    tableData, 
    dataCourse, 
    programId,
    filtersData,
    loadUsersByFilter,
    onChangeProgramId,
    onChangeFilter, 
    submitFilter,
    clearFilters,
    setLoadUsers,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    pageOptions,
    // page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const getSituation = (current_situation) => {
    let userSituation = '';

    switch (current_situation) {
      case 'banco_talentos':
        userSituation = 'Banco de Talentos';
        break;

      case 'indisponivel':
        userSituation = 'Indisponível';
        break;

      case 'indicado':
        userSituation = 'Indicado';
        break;

      case 'contratado':
        userSituation = 'Contr. Direto';
        break;

      case 'contratado_headhunting':
        userSituation = 'Contr. Head Hunting';
        break;

      case 'contratado_indireto':
        userSituation = 'JobOut';
        break;
    
      default:
        break;
    }

    return userSituation;
  }

  const [modalUpdateStatusIsOpen, setModalUpdateStatusIsOpen] = useState(false);
  const [userId, setUserId] = useState(0);

  const openModalUpdateStatus = (userId) => {
    setUserId(userId);
    setModalUpdateStatusIsOpen(true);
  }

  return (
    <>
      <Flex px='0px' justify='space-between' mt='10px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='0px'>
          <Select 
            name="programId"
            onChange={onChangeProgramId}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            mb='0px'
            fontWeight='500'
            size='lg'
            placeholder="Selecione um programa" 
            bg={'white'}
          >
            <option value="3" selected={programId == 3 ? true : false}>Todas Tech</option>
            <option value="2" selected={programId == 2 ? true : false}>SharpCoders</option>
            <option value="1" selected={programId == 1 ? true : false}>GoDev 2022</option>
          </Select>
        </SimpleGrid>
      </Flex>

      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 4, lg: 4, "2xl": 4 }}
          gap='20px'
          mb='20px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdGroups} color={'#1f2471'} />
                }
              />
            }
            name='Total'
            value={dataCourse?.total}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={FaUserGraduate} color={'#4169E1'} />
                }
              />
            }
            name='Banco de talentos'
            value={dataCourse?.studentsBankTalents}
          />
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 4, lg: 4, "2xl": 4 }}
          gap='20px'
          mb='0px'>
        <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdOutlineWork} color={'#ff8000'} />
                }
              />
            }
            name='Indicados'
            value={dataCourse?.studentsIndicated}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdOutlineWork} color={'#2e8b57'} />
                }
              />
            }
            name='Contr. Diretos'
            value={dataCourse?.studentsHiredDirect}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdOutlineWork} color={'#5C4033'} />
                }
              />
            }
            name='Contr. Head Hunting'
            value={dataCourse?.studentsHiredHunting}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdWorkOff} color={'#e32636'} />
                }
              />
            }
            name='JobOut'
            value={dataCourse?.studentsHiredIndirect}
          />
        </SimpleGrid>
      </Box>
    </Card>

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <form onSubmit={submitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 5, xl: 5 }} gap='20px' mb='20px'>
          <FormControl>
            <FormLabel>Nome</FormLabel>
            <Input 
              name="name" 
              value={filtersData.name || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>E-mail</FormLabel>
            <Input 
              name="email"
              value={filtersData.email || ''}
              onChange={onChangeFilter}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Sexo</FormLabel>
            <Select
              name="gender"
              value={filtersData.gender || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={filtersData.status || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="em_formacao">Em formação</option>
              <option value="fora_programa">Desistência</option>
              <option value="desligado">Desligado</option>
              <option value="formado">Formado</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Situação</FormLabel>
            <Select 
              name="situation"
              value={filtersData.situation || ''}
              onChange={onChangeFilter}
            >
              <option value="all">Todos</option>
              <option value="banco_talentos">Banco de Talentos</option>
              <option value="indicado">Indicados</option>
              <option value="contratado">Contratados Diretos</option>
              <option value="contratado_headhunting">Contratados Head Hunting</option>
              <option value="contratado_indireto">JobOut</option>
            </Select>
          </FormControl>

          <FormControl>
            {/* <FormLabel style={{"color": "transparent"}}>.</FormLabel>  */}
            <ButtonGroup gap='2'>
              <Button 
                type="submit"
                onClick={submitFilter} 
                colorScheme='brandScheme'
              >
               {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
      </Flex>
      </form>
    </Card>

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Alunos
        </Text>

        {/* <Flex align='center'>
          <Button 
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              Exportar
            </Text>
          </Button>
        </Flex> */}
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        {!loadUsersByFilter &&
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "PICTURE") {
                    data = (
                      <img 
                        style={{"borderRadius": "100px"}}
                        src={cell?.value != null && cell?.value != '' ? cell.value : avatarProfile} 
                        width="40" 
                        alt="Picture" 
                      />
                    );
                  } else if (cell.column.Header === "NAME") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "PROGRAM") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "STATUS") {
                    data = (
                      <Flex align='center'>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value === 'em_formacao' && <p style={{"color": "#fff", "backgroundColor": "#ff8000", "padding": "2px 5px", "borderRadius": "6px"}}>Em formação</p>}
                          {cell.value === 'fora_programa' && <p style={{"color": "#fff", "backgroundColor": "#e32636", "padding": "2px 5px", "borderRadius": "6px"}}>Desistente</p>}
                          {cell.value === 'desligado' && <p style={{"color": "#fff", "backgroundColor": "#2f2f2f", "padding": "2px 5px", "borderRadius": "6px"}}>Desligado</p>}
                          {cell.value === 'formado' && <p style={{"color": "#fff", "backgroundColor": "#2e8b57", "padding": "2px 5px", "borderRadius": "6px"}}>Formado</p>}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "SITUATION") {
                    data = (
                      <Flex 
                        align='center' 
                        // style={{"justifyContent": "center"}}
                      >
                        <Text 
                          style={(cell.value === 'contratado' || cell.value === 'contratado_headhunting' || cell.value === 'contratado_indireto') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": cell.value === 'contratado_indireto' ? "#e32636" : "#5C4033", 
                            "padding": "2px 5px", 
                            "borderRadius": "6px"
                          } : (cell.value === 'banco_talentos') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#4169E1", 
                            "padding": "2px 5px", 
                            "borderRadius": "6px"
                          } : (cell.value === 'indicado') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#ff8000", 
                            "padding": "2px 5px", 
                            "borderRadius": "6px"
                          } : (cell.value === 'indisponivel') ? {
                            "display": "flex",
                            "flexDirection": "row",
                            "alignItems": "center",
                            "justifyContent": "center",
                            "color": "#fff", 
                            "backgroundColor": "#cccccc", 
                            "padding": "2px 5px", 
                            "borderRadius": "6px"
                          } : { 

                          }}
                          fontSize='sm' 
                          fontWeight='700'
                        >
                          {/* <Icon
                            w='17px'
                            h='17px'
                            me='3px'
                            color={
                              (cell.value === 'contratado' || cell.value === 'contratado_headhunting' || cell.value === 'contratado_indireto')
                                ? "#ffffff"
                                : cell.value === 'banco_talentos' ? '#ffffff' 
                                  : cell.value === 'indicado' ? '#ffffff' 
                                    : cell.value === 'indisponivel' ? '#ffffff' 
                                    : null
                            }
                            as={
                              (cell.value === 'contratado' || cell.value === 'contratado_headhunting' || cell.value === 'contratado_indireto')
                                ? MdOutlineWork
                                : cell.value === 'banco_talentos' ? FaUserGraduate 
                                  : cell.value === 'indicado' ? MdOutlineWork 
                                    : cell.value === 'indisponivel' ? MdCancel 
                                    : null
                            }
                          /> */}
                          {getSituation(cell.value)}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "LAST_UPDATED_AT") {
                    data = (
                      <Text style={{textAlign: 'center'}} color={textColor} fontSize='sm' fontWeight='normal'>
                        {cell.value ? cell.value : '-'}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Flex align='center'>
                          <ButtonGroup gap='1'>
                            <Button onClick={() => openModalUpdateStatus(cell.value)} colorScheme='brandScheme'><FaSearchPlus /></Button>
                          </ButtonGroup>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>}
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (dataCourse?.total > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>

    {modalUpdateStatusIsOpen && userId > 0 ?
      <ModalUpdateStatus
        userId={userId}
        programId={programId}
        modalIsOpen={modalUpdateStatusIsOpen}
        setModalIsOpen={setModalUpdateStatusIsOpen}
        refreshUsers={setLoadUsers}
      />
    : null}
    </>
  );
}
