import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Box,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  SimpleGrid,
  useColorModeValue,
  FormControl,
  FormLabel,
  Select,
  ButtonGroup,
  Button, 
  Input,
  Link,
} from "@chakra-ui/react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import ModalAddCompany from "./ModalAddCompany";

// Assets
import { FaArrowCircleLeft, FaArrowCircleRight, FaPlusCircle, FaSearchPlus, FaUsers } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import ModalViewForm from "./ModalViewForm";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, child, get } from "firebase/database";
import { FiExternalLink } from "react-icons/fi";
import Pagination from "components/pagination";
import { RiFileExcel2Fill } from "react-icons/ri";

import { API_SERVER } from "config/constant";

const firebaseConfig = {
  databaseURL: "https://forms-24e25-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export default function ColumnsTable(props) {
  const { 
    programId,
    onChangeCompanies,
    columnsData, 
    tableData, 
    dataCompanies, 
    filtersData,
    loadUsersByFilter,
    onChangeFilter, 
    submitFilter,
    clearFilters,
    onChangeProgramId,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    pageOptions,
    // page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;
  initialState.pageSize = 10;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "brand.500");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);

  const [companyIdEditSelected, setCompanyIdEditSelected] = useState(0);
  const [companyIdSelected, setCompanyIdSelected] = useState(0);
  const [companyPrograms, setCompanyPrograms] = useState(false);

  const [loadingReport, setLoadingReport] = useState(false);

  const [formId, setFormId] = useState(0);

  const getSlugProgram = programId => programId == 1 ? 'godev' : (programId == 2 ? 'sharpcoders' : (programId == 3 ? 'todas-tech' : programId));
  const getProgramName = programId => programId == 1 ? 'GoDev' : (programId == 2 ? 'SharpCoders' : (programId == 3 ? 'Todas Tech' : programId));

  useEffect(() => {
    if (!modalDeleteIsOpen) {
      setCompanyPrograms(false);
    }
  }, [modalDeleteIsOpen])

  const getSizeCompany = (size) => {
    let sizeCompany = '';

    switch (size) {
      case 'small':
          sizeCompany = 'Pequeno';
        break;

      case 'medium':
        sizeCompany = 'Médio';
      break;

      case 'large':
        sizeCompany = 'Grande';
      break;
    
      default:
        break;
    }

    return sizeCompany;
  }

  const isOpenModalEdit = (formId) => {
    setFormId(formId);
    setModalEditIsOpen(true);
  }

  const isOpenModalDelete = (companyId, isCompanyProgram) => {
    isCompanyProgram > 0 ? setCompanyPrograms(true) : setCompanyPrograms(false);
    setCompanyIdSelected(companyId);
    setModalDeleteIsOpen(true);
  }

  const exportXLSX = async () => {
    if (loadingReport) {
      return false;
    }
  
    setLoadingReport(true);
  
    try {
      const filterDate = filtersData.date?.length > 2 ? `/${filtersData.date}` : '';

      const response = await axios.get(`${API_SERVER}dashboard/formsNps/${programId}${filterDate}`); 
      const tableData = response?.data?.forms;

      const arrayOfArrays = tableData?.map(obj => [
        obj.form_id,
        obj.form_title.toUpperCase(),
        obj.form_date,
        obj.form_question,
        obj.form_user_choice,
        obj.user_name,
      ]);
      arrayOfArrays.unshift(['ID', 'Título', 'Data', 'Pergunta', 'Resposta', 'Aluno']);
  
      const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Formulário de presença - NPS');
      const fileName = `forms-${getSlugProgram(programId)}-presence-nps.xlsx`;
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);
  
      setLoadingReport(false);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
      setLoadingReport(false);
    }
  };
  

  return (
    <>
    <Flex px='0px' justify='space-between' mt='10px' mb='0px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='0px'>
          <Select
            defaultValue={programId} 
            name="programId"
            onChange={onChangeProgramId}
            variant='auth'
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            mb='0px'
            fontWeight='500'
            size='lg'
            placeholder="Selecione um programa" 
            bg={'white'}
          >
            <option value="3" selected={programId == 3 ? true : false}>Todas Tech</option>
            <option value="2" selected={programId == 2 ? true : false}>SharpCoders</option>
            <option value="1" selected={programId == 1 ? true : false}>GoDev 2022</option>
          </Select>
        </SimpleGrid>
      </Flex>

      <Card
      direction='column'
      w='100%'
      px='0px'
      bg={'transparent'}
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
        <SimpleGrid
          columns={{ base: 1, md: 4, lg: 4, "2xl": 5 }}
          gap='20px'
          mb='0px'>
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={HiDocumentText} color={brandColor} />
                }
              />
            }
            name='Total'
            value={dataCompanies?.total}
          />
        </SimpleGrid>
      </Box>
    </Card>

    {programId > 0 && (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <form onSubmit={submitFilter}>
      <Flex px='25px' justify='space-between' mt='20px' mb='20px' align='center'>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
          {/* <FormControl>
            <FormLabel>Título</FormLabel>
            <Input 
              name="title" 
              value={filtersData.title || ''}
              onChange={onChangeFilter}
            />
          </FormControl> */}

          <FormControl>
            <FormLabel>Data</FormLabel>
            <Input 
              type="date" 
              name="date" 
              value={filtersData.date || ''}
              onChange={onChangeFilter}
            />
          </FormControl>


          <FormControl>
            <FormLabel style={{"color": "transparent"}}>.</FormLabel>
            <ButtonGroup gap='2'>
              <Button 
                type="submit"
                onClick={submitFilter} 
                colorScheme='brandScheme'
                disabled={programId > 0 ? false : true}
              >
               {loadUsersByFilter ? 'Buscando...' : 'Buscar'}
              </Button>
              <Button 
                type="button"
                onClick={clearFilters}
                colorScheme='brandScheme'
              >
                Limpar
              </Button>
            </ButtonGroup>
          </FormControl>
        </SimpleGrid>
      </Flex>
      </form>
    </Card>
    )}

    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          {programId > 0 ? `Formulários do Programa ${getProgramName(programId)}` : 'Formulários'}
        </Text>

        {programId > 0 && (
        <Flex align='center'>
        <Button 
            // disabled={true}
            onClick={() => setModalIsOpen(true)}
            me={5}
            fontSize='sm'
            variant='brand'
            fontWeight='500'
            w='100%'
          >
            <Icon as={FaPlusCircle} h='16px' w='16px' me='8px' />
            <Text 
              fontSize='sm' 
              fontWeight='400'
            >
              Adicionar
            </Text>
          </Button>
          
          <Button 
            onClick={exportXLSX}
            fontSize='sm'
            color='#107C41'
            style={{"border": "1px solid #107C41"}}
            fontWeight='500'
            w='100%'
            disabled={loadingReport}
          >
            <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
            <Text fontSize='sm' fontWeight='400'>
              {loadingReport ? 'Exportando...' : 'Exportar'}
            </Text>
          </Button>
        </Flex>
        )}
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("label")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        {!loadUsersByFilter &&
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "TITLE") {
                    data = (
                      <Text style={{"textTransform": "capitalize"}} color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "TYPE") {
                    data = (
                      <Flex align='center'>
                        <Text 
                          color={textColor} fontSize='sm' fontWeight='700'>
                          {/* {cell.value} */}
                          {cell.value == 'aula_sincrona' && <p style={{"color": "#fff", "backgroundColor": "#2759A5", "padding": "2px 5px", "borderRadius": "6px"}}>Aula Síncrona</p>}
                          {cell.value == 'livehelp' && <p style={{"color": "#fff", "backgroundColor": "#993399", "padding": "2px 5px", "borderRadius": "6px"}}>Live Help</p>}
                          {cell.value == 'company_stacktech' && <p style={{"color": "#fff", "backgroundColor": "#222222", "padding": "2px 5px", "borderRadius": "6px"}}>Stack Tech</p>}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "ACTIVE") {
                    data = (
                      <Flex align='center'>
                        <Text 
                          color={textColor} fontSize='sm' fontWeight='700'>
                          {/* {cell.value} */}
                          {cell.value == 1 ?  
                            <p style={{"color": "#fff", "backgroundColor": "#008000", "padding": "2px 5px", "borderRadius": "6px"}}>Aberto</p>
                              : 
                            <p style={{"color": "#fff", "backgroundColor": "#FF0000", "padding": "2px 5px", "borderRadius": "6px"}}>Encerrado</p>}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "ANSWERS") {
                    data = (
                      <Flex align='center'>
                        <Text 
                          color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "DATE") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    const { qty_programs, type } = row.cells[index]?.row?.original;
                    data = (
                      <Flex align='center'>
                          <ButtonGroup gap='1'>
                            <Button 
                              onClick={() => isOpenModalEdit(cell.value)}
                              colorScheme='brandScheme'
                            >
                              <FaSearchPlus size={20} />
                            </Button>
                            <Link 
                              href={`/#/admin/form/${cell.value}`}
                            >
                              <Button colorScheme='brandScheme'>
                                <FaUsers size={20} />
                              </Button>
                            </Link>
                            <Link 
                              href={`https://alunos.imatech.io/forms/${type == 'other' ? 'other' : 'presence'}/${cell.value}`}
                              target={'_blank'}
                            >
                              <Button colorScheme='brandScheme'>
                                <FiExternalLink size={20} />
                              </Button>
                            </Link>
                            {/* <Button 
                              disabled={true}
                              // onClick={() => isOpenModalDelete(cell.value, qty_programs)}
                              style={{
                                "backgroundColor": "#ce1151", 
                                "color": "#ffffff"
                              }}
                            >
                              <AiFillCloseCircle size={20} />
                            </Button> */}
                          </ButtonGroup>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>}
      </Table>
      <div 
        style={{
          "display": "flex", 
          "flexDirection": "row",
          "alignItems": "center",
          "justifyContent": "center",
          "padding": "0px 25px",
        }}
      >
        {loadUsersByFilter ?
          <ClipLoader
            color={'#2759a5'} 
            loading={loadUsersByFilter} 
            size={60} 
          />
        : (dataCompanies?.total > 0 ?
          <Pagination
            pageIndex={pageIndex}
            pageCount={pageOptions.length}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          /> 
        : 'Nenhum resultado encontrado.')}
      </div>
    </Card>

      <ModalAddCompany
        programId={programId}
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        refreshForms={onChangeCompanies}
      />

      {modalEditIsOpen &&
        <ModalViewForm
          formId={formId}
          modalIsOpen={modalEditIsOpen}
          setModalIsOpen={setModalEditIsOpen}
          refreshForms={onChangeCompanies}
        />
      }

      {/* {modalDeleteIsOpen && companyIdSelected &&
        <ModalConfirm 
          companyId={companyIdSelected} 
          companyProgram={companyPrograms}
          modalIsOpen={modalDeleteIsOpen}
          setModalIsOpen={setModalDeleteIsOpen} 
          refreshCompanies={onChangeCompanies}
        />
      } */}
    </>
  );
}
