// Chakra imports
import { SimpleGrid, Text, useColorModeValue, FormControl, FormLabel, Input } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";

// Assets
export default function SelectionProcess(props) {
  const { 
    logicTestNote,
    presentationVideo,
    isEdit, 
    setIsEdit,
    handleChange,
    ...rest 
  } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Processo seletivo
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        As we live, our hearts turn colder. Cause pain is what we go through as
        we become older. We get insulted by others, lose trust for those others.
        We get back stabbed by friends. It becomes harder for us to give others
        a hand. We get our heart broken by people we love, even that we give
        them all...
      </Text> */}
      <SimpleGrid columns='2' gap='20px'>
          <Information
            boxShadow={cardShadow}
            title='Teste de lógica'
            value={logicTestNote}
          />
          <Information
            boxShadow={cardShadow}
            title='Vídeo de apresentação'
            link={true}
            urlVideo={true}
            value={presentationVideo}
          />
        {/* {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Teste de lógica'
            value={logicTestNote}
          />
        : null}
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Vídeo de apresentação'
            link={true}
            urlVideo={true}
            value={presentationVideo}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Vídeo de apresentação (URL)</FormLabel>
            <Input
              type={'text'}
              name={'presentation_video'}
              value={presentationVideo || ''}
              onChange={handleChange}
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        } */}
      </SimpleGrid>
    </Card>
  );
}
