import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Progress,
  Text,
  useColorModeValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";
import { FaPlusCircle, FaSearch, FaTrash, FaUsers } from "react-icons/fa";

export default function ModalConfirm({ companyId, companyProgram, modalIsOpen, setModalIsOpen, refreshCompanies }) {
  const [loading, setLoading] = useState(false);

  const deleteCompany = async (companyId) => {
    try {    
      if (!companyId) {
        throw 'EMPTY_FIELDS';
      }

      if (loading) {
        return false;
      }

      setLoading(true);

      await axios.delete(`${API_SERVER}dashboard/company`, { data: {
        companyId: companyId,
      }});

      refreshCompanies(true);

      setLoading(false);
      setModalIsOpen(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
      setModalIsOpen(false);
    }
  }

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text 
              style={{
                "fontSize": "20px",
                "fontWeight": "600",
                "color": "#ce1151",
              }}
              pt={8}
            >
              {companyProgram ? 'Aviso!' : 'Tem certeza que deseja excluir esta empresa?'}
            </Text>
            {companyProgram &&
              <Text 
                style={{
                  "fontSize": "16px",
                }}
                pt={2}
              >
                Empresa não pode ser excluída porque ela está vinculada a um programa.
              </Text>
            }

            {loading ?
              <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                    <Flex mx='auto' mt='10px' align='center' direction='column'>
                      <ClipLoader 
                        color={'#ce1151'} 
                        loading={loading} 
                        size={60} 
                      />
                    </Flex>
                  </SimpleGrid>
              </Box>
            : null}

          </ModalBody>

          <ModalFooter>
            {companyProgram ?
              <Button 
                style={{
                  "marginRight": "6px",
                }}
                colorScheme={'brandScheme'}
                onClick={() => setModalIsOpen(false)}
              >
                Ok, entendi!
              </Button>
            :
              <> 
                <Button 
                  style={{
                    "backgroundColor": "#ce1151", 
                    "color": "#ffffff",
                    "marginRight": "6px",
                  }}
                  onClick={() => deleteCompany(companyId)}
                >
                  {loading ? 'Excluindo...' : 'Excluir'}
                </Button>
                <Button onClick={() => setModalIsOpen(false)}>Cancelar</Button>
              </>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}