// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Progress,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/card/Card.js";
  import IconBox from "components/icons/IconBox";
  import Menu from "components/menu/MainMenu";
  import React, { useState } from "react";
  // Assets
  import { MdCancel, MdCheckCircle, MdOutlineSchool, MdSchool } from "react-icons/md";
  import { FaBookReader } from "react-icons/fa";
import { TbIdBadge2 } from "react-icons/tb";
  
  export default function Situation(props) {
    const { status, currentSituation, qtyInterviews, lastInterviewData } = props;

    const [statusBefore, setStatusBefore] = useState(status);
    const [currentSituationBefore, setCurrentSituationBefore] = useState(currentSituation);

    const getStatusName = (status) => {
      if (status == 'em_formacao') {
        return 'Em formação';
      }

      if (status == 'fora_programa') {
        return 'Fora do programa';
      }

      if (status == 'formado') {
        return 'Formado';
      }

      if (status == 'contratado') {
        return 'Contratado';
      }
    }

    // Chakra Color Mode
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const brandColor = useColorModeValue("brand.500", "white");
    const textColorSecondary = "gray.400";
    const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    return (
      <Card mb={{ base: "0px", lg: "20px" }} align='center'>
          <IconBox
            mx='auto'
            h='100px'
            w='100px'
            icon={
              <Text 
                style={{"textTransform": "capitalize"}}
                color={textColorPrimary} 
                fontWeight='bold' 
                fontSize='2xl' 
              >
                {qtyInterviews}
              </Text>
            }
            bg={box}
          />
        <Text 
          style={{"textTransform": "capitalize"}}
          color={textColorPrimary} 
          fontWeight='bold' 
          fontSize='2xl' 
          mt='10px'
        >
            {/* Qtd. de entrevistas */}
        </Text>
        <Text
          color={textColorSecondary}
          fontSize='md'
          maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
          mx='auto'
          mb='10px'>
            Qtd. de entrevistas
        </Text>
        {/* <Button bg={brandColor} color='#fff'>Ver todas</Button> */}
        {/* {currentSituationBefore == 'contratado' && ( */}
          <Box w='100%' mt='auto'>
            <Flex w='100%' justify='space-between' mb='10px'>
              <Flex mx='auto' me='auto' align='center' direction='column'>
                <Text color={'#666666'} fontSize='1xl' fontWeight='700'>
                  {lastInterviewData}
                </Text>
                <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
                  Última entrevista
                </Text>
              </Flex>
            </Flex>
          </Box>
        {/* )} */}
        {/* {currentSituationBefore != 'contratado' && (
          <Box w='100%' mt='auto'>
            <Flex w='100%' justify='center' mb='10px'>
              <Flex mx='auto' me='auto' align='center' direction='row'>
                <Text 
                  style={{
                    "display": "flex",
                    "alignItems": "center",
                    "justifyContent": "center",
                    "fontWeight": "bold",
                    "backgroundColor": "#2759A5",
                    "padding": "5px 15px",
                    "borderRadius": "100px",
                    "color": "#fff",
                    "fontSize": "13px"
                  }} 
                  color={textColorSecondary} 
                  fontSize='sm'
                >
                  <MdSchool size={15} style={{"marginRight": "5px"}} />
                  {course}
                </Text>
              </Flex>
            </Flex>
          </Box>
        )} */}
      </Card>
    );
  }
  