import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import ComplexTable from "views/admin/teachers/components/ComplexTable";
import {
  columnsDataComplex,
} from "views/admin/teachers/variables/columnsData";

import { API_SERVER } from "config/constant";

export default function Teachers() {
  const INITIAL_FILTERS = {
    name: '',
    type: '',
  }

  const [loading, setLoading] = useState(false);
  const [loadUsers, setLoadUsers] = useState(false);
  const [refreshTeachers, setRefreshTeachers] = useState(false);

  const [filters, setFilters] = useState(INITIAL_FILTERS);
  
  const [teachers, setTeachers] = useState([]);
  const [dataTeachers, setDataTeachers] = useState({
    total: 0,
    teachersSynchronous: 0,
    teachersAsynchronous: 0,
  });

  useEffect(() => { 
    window.scrollTo(0, 0);

    const getCompanies = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/teachers`, {
        params: {
          name: filters.name,
          type: filters.type,
        }
      });

      const { teachers, total, teachersSynchronous, teachersAsynchronous } = data?.teachersData;

      setDataTeachers({
        total: total,
        teachersSynchronous: teachersSynchronous,
        teachersAsynchronous: teachersAsynchronous,
      })
      setRefreshTeachers(false);
      setTeachers(teachers);
      setLoading(false);
    }

    getCompanies();
  }, [refreshTeachers])

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    
    setFilters({ ...filters, [name]: ev.target.value });
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getCompanies = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/teachers`, {
        params: {
          name: filters.name,
          type: filters.type,
        }
      });

      const { teachers, total, teachersSynchronous, teachersAsynchronous } = data?.teachersData;

      setDataTeachers({
        total: total,
        teachersSynchronous: teachersSynchronous,
        teachersAsynchronous: teachersAsynchronous,
      })
      setTeachers(teachers);
      setLoadUsers(false);
    }

    setLoadUsers(true);
    getCompanies();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
        <ComplexTable
          programId={0}
          onChangeTeachers={setRefreshTeachers}
          onChangeProgram={handleChangeFilter}
          onChangeFilter={handleChangeFilter}
          submitFilter={handleSubmitFilter}
          clearFilters={() => setFilters(INITIAL_FILTERS)}
          filtersData={filters}
          loadUsersByFilter={loadUsers}
          columnsData={columnsDataComplex}
          tableData={teachers}
          dataTeachers={dataTeachers}
        />
      </SimpleGrid>
    </Box>
  );
}
