// Chakra imports
import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Flex, Text, useColorModeValue, 
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel, 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tooltip,
  GridItem,
  SimpleGrid,
  Button} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import Select, { StylesConfig } from 'react-select';
import { useReactToPrint } from 'react-to-print';
import chroma from "chroma-js";
import axios from "axios";

// Custom components
import Banner from "views/admin/generate-pdf/components/Banner";
import General from "views/admin/generate-pdf/components/General";
import Education from "views/admin/generate-pdf/components/Education";
import SelectionProcess from "views/admin/generate-pdf/components/SelectionProcess";
import Notifications from "views/admin/generate-pdf/components/Notifications";
import Projects from "views/admin/generate-pdf/components/Projects";
import Storage from "views/admin/generate-pdf/components/Storage";
import Badge from "views/admin/generate-pdf/components/Badge";
import Situation from "views/admin/generate-pdf/components/Situation";
import Score from "views/admin/generate-pdf/components/Score";
import GeneratePDF from "./components/GeneratePDF";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

import { API_SERVER } from "config/constant";
import Timeline from "./components/Timeline";
import Card from "components/card/Card";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import { FaCheck, FaEdit, FaFilePdf } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import UploadSheets from "./components/UploadSheets";

export default function GeneratePdf() {
  // const hash = window.location.hash;
  // const id = hash.split('/pdf/');
  // const userId = id[1] ? id[1]?.split('/')[0] : 0;
  // const programId = id[1] ? id[1]?.split('/')[1] : 0;

  const userId = 9754; 
  const programId = 3;

  const componentRef = useRef();
  const [feedbacksBorder, setFeedbacksBorder] = useState(true);
  const [loadPdf, setLoadPdf] = useState(false);
  const [isEditFeedback, setIsEditFeedback] = useState(false);
  const [saveLoadFeedbacks, setSaveLoadFeedbacks] = useState(false);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingInterviews, setLoadingInterviews] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingTimeline, setLoadingTimeline] = useState(false);
  const [user, setUser] = useState([]);
  const [rankingPosition, setRankingPosition] = useState(0);
  const [userProjects, setUserProjects] = useState([]);
  const [userTimeline, setUserTimeline] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [userCulturalFitTags, setUserCulturalFitTags] = useState([]);
  const [userCompaniesMatch, setUserCompaniesMatch] = useState([]);
  const [userCompanyFitTags, setUserCompanyFitTags] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [presenceTotal, setPresenceTotal] = useState(0);
  const [isEditAll, setIsEditAll] = useState(false);

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  const colourOptions = [
    { value: 'autogerenciavel', label: 'AUTOGERENCIÁVEL', color: '#00B8D9' },
    { value: 'comunicativo', label: 'COMUNICATIVO', color: '#0052CC' },
    { value: 'metodico', label: 'METÓDICO', color: '#5243AA' },
    { value: 'planejador', label: 'PLANEJADOR', color: '#FF5630' },
    { value: 'executor', label: 'EXECUTOR', color: '#FF8B00' },
    { value: 'analista', label: 'ANALISTA', color: '#FFC400' },
    { value: 'lider', label: 'LÍDER', color: '#36B37E' },
  ];

  const companiesOptions = [
    { value: 1, label: 'NECTAR CRM', color: '#00B8D9' },
    { value: 2, label: 'TRINUS', color: '#0052CC' },
    { value: 3, label: 'POLI', color: '#5243AA' },
    { value: 4, label: 'AUVO', color: '#FF5630' },
    { value: 5, label: 'MXM', color: '#FF8B00' },
    { value: 6, label: 'E-SOLUTION', color: '#FFC400' },
    { value: 7, label: 'GENPAG', color: '#36B37E' },
  ];

  useEffect(() => {
    const getUser = async (userId) => {
      if (loading) { 
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/user/${userId}/${programId}`);

      const { user, position, projects, timeline, culturalFitTags, companiesMatchStudent, companyFitTags, presenceTotal } = data;

      setRankingPosition(position);
      setUser(user[0]);
      setUserProjects(projects);
      setUserTimeline(timeline);
      setUserCulturalFitTags(culturalFitTags);
      setUserCompaniesMatch(companiesMatchStudent);
      setUserCompanyFitTags(companyFitTags);
      setPresenceTotal(presenceTotal);
      setLoading(false);
    }

    // if (userId > 0) {
    //   getUser(userId);
    // }
  }, [])

  // useEffect(() => {
  //   const getHardSkills = async (userId) => {
  //     // setLoadingHardSkills(true);

  //     const { data } = await axios.get(`${API_SERVER}dashboard/usersHardSkillsByUserId/${userId}`);

  //     const { userHardSkills } = data;
  //     // alert(JSON.stringify(userHardSkills));

  //     setHardSkills(userHardSkills);
  //     // setLoadingHardSkills(false);
  //   }

  //   getHardSkills(userId);
  // }, [])

  const dateStart = new Date(user?.program_start_date);
  const dateEnd = new Date(user?.program_end_date);
  const dateNow = new Date();

  var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  var Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  var Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

    if (user?.status != 'contratado') {
      let userData = user;
      userData.current_situation = null;
      userData.company_id = null;

      setUser(userData);
    } 
    
    if (user?.status == 'contratado' || user?.company_id > 0) {
      let userData = user;
      userData.current_situation = 'contratado';
      userData.company_id = user?.company_id;

      setUser(userData);
    }

		setUser({...user, [name]: value});
	};

  const handleEdit = () => {
    if (percentUsed >= 100) {
      setIsEditAll(false);
    } else {
      setIsEditAll(true);
    }

    setIsEdit(!isEdit);
  }

  const handleUpdateProfile = async () => {
    try {
      let userData = user;
      userData.programId = programId;
      userData.cpf = user?.cpf?.replace(/[^0-9]+/g,'');
      userData.cell_phone = user?.cell_phone?.replace(/[^0-9]+/g,'');

      const { name, email, cpf, cell_phone, gender, age, city, uf, status, company_id } = userData;
            
      if (!programId || !userId || !name || !email || !cpf || !cell_phone || !gender || !age || !city || !uf || !status) {
        throw 'EMPTY_FIELDS';
      }

      if (status == 'contratado' && !company_id) {
        throw 'EMPTY_FIELDS';
      }

      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.post(`${API_SERVER}dashboard/user/${userId}`, userData);

      if (data?.dataCompany[0]) {
        const { companyId, companyName, companyLogo, companyHiredIn } = data?.dataCompany[0];
        
        userData.company_id = companyId;
        userData.company_name = companyName;
        userData.company_logo = companyLogo;
        userData.company_hired_in = companyHiredIn;

        setUser(userData);
      }

      setIsEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error == 'EMPTY_FIELDS') {
        alert('Preencha todos os campos!')
      }

      setLoading(false);
    }
  }

  const handlePrint = useReactToPrint({
    content: () => {
      const component = componentRef.current;

      return component;
    },
    documentTitle: `${user?.name} - Ficha do Candidato`
  });

  const handleSaveFeedbacks = async () => {
    setSaveLoadFeedbacks(true);

    setIsEditFeedback(true);

    const userFeedback = {
      userId: user?.user_id,
      feedbackHardSkills: user?.feedback_hardskills || '',
      feedbackSoftSkills: user?.feedback_softskills || '',
      programId: programId,
    };
    try {
      await axios.post(`${API_SERVER}dashboard/user/feedback/update`, userFeedback);

      setIsEditFeedback(false);
      setSaveLoadFeedbacks(false);
    } catch (error) {
      setIsEditFeedback(false);
      setSaveLoadFeedbacks(false);

      console.error(error);
    }

    setSaveLoadFeedbacks(false);
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mx='auto' mt='80px' align='center' direction='column'>
          <ClipLoader 
            color={'#2759a5'} 
            loading={loading} 
            size={60} 
          />
        </Flex>
      </Box>
    )
  }

  const setUserData = (data) => {
    // alert(JSON.stringify(data[0]))
    setUser(data[0]);
  }

  return (
    <>
    <Box pt={{ base: "0px", md: "0px", xl: "0px" }} ref={componentRef}>
        <SimpleGrid columns='1' gap='20px' mt="120px">
        <UploadSheets setUserData={setUserData} />
  
        <Banner
          isEdit={isEdit}
          setIsEdit={handleEdit}
          handleChange={handleChange}
          handleUpdateProfile={handleUpdateProfile}
          banner={banner}
          userId={userId}
          status={user?.status}
          currentSituation={user?.current_situation}
          avatar={user?.picture}
          name={user?.name}
          course={user?.course}
          isMonitor={user?.is_monitor}
          githubUrl={user?.github_url}
          linkedinUrl={user?.linkedin_url}
        /> 
        </SimpleGrid>
        
      {user?.name?.length > 0 ? (
      <General
        minH='290px'
        pe='10px'
        mb='20px'
        isEdit={isEdit}
        isEditAll={isEditAll}
        setIsEdit={handleEdit}
        handleChange={handleChange}
        programId={programId}
        userData={user}
        name={user?.name}
        cpf={user?.cpf}
        email={user?.email}
        cellphone={user?.cell_phone}
        gender={user?.gender}
        age={user?.age}
        city={user?.city}
        uf={user?.uf}
        status={user?.status}
        urlTestPersonality={user?.url_test_personality}
        currentSituation={user?.current_situation}
        companyId={user?.company_id}
        active={user?.active}
        createdAt={user?.date}
        presenceTotal={`${presenceTotal > 47 ? 47 : presenceTotal}/47`}
        schooling={user?.schooling}
        formation={user?.formation}
        previousKnowledge={user?.previous_knowledge}
        educationalInstitution={user?.educational_institution}
        userProjects={userProjects}
        hardSkills={hardSkills}
        feedbackHardSkills={user?.feedback_hardskills}
        feedbackSoftSkills={user?.feedback_softskills}
        isEditFeedback={isEditFeedback}
      />
      ) : null}
    </Box>
      

    {user?.name?.length > 0 ? ( 
    <Flex w='100%' justify='center' mt='8px'>
      {!isEditFeedback ? (
        <>
          <Button colorScheme='red' onClick={() => {setIsEditFeedback(!isEditFeedback)}} mr='5px'>
            <FaEdit style={{marginRight: '5px'}}/>
            Editar
          </Button>
          <Button colorScheme='green' onClick={handlePrint}>
            <FaFilePdf style={{marginRight: '5px'}}/>
            Gerar PDF
          </Button>
        </>
      ) : (
        <>
          {!saveLoadFeedbacks && (
            <Button disabled={saveLoadFeedbacks} colorScheme='gray' onClick={() => {setIsEditFeedback(false)}} mr='5px'>
              <FcCancel style={{marginRight: '5px'}}/>
              Cancelar
            </Button>
          )}
          <Button disabled={saveLoadFeedbacks} colorScheme='green' onClick={handleSaveFeedbacks} mr='0px'>
            <FaCheck style={{marginRight: '5px'}}/>
            {!saveLoadFeedbacks ? 'Salvar': 'Salvando...'}
          </Button>
        </>
      )}
    </Flex>
    ) : null}
    </>
  );
}
