export function setFilters(filters) {
    return {
        type: 'SET_FILTERS',
        payload: filters,
    };
}

export function clearFilters() {
    return {
        type: 'CLEAR_FILTERS',
    };
}
  
export const setCurrentPage = (page) => ({
    type: 'SET_CURRENT_PAGE',
    payload: page,
});

export function setData(dataUsers) {
    return {
        type: 'SET_DATA',
        payload: dataUsers,
    };
}
  