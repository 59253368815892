// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "assets/img/profile/Project1.png";
import RelatedProducts from "assets/img/profile/related-products.webp";
import ByteBank from "assets/img/profile/desafio-byte-bank.jpeg";
import Xadrez from "assets/img/profile/desafio-xadrez.jpeg";
import GameHubFase1 from "assets/img/profile/desafio-gamehub-fase1.png";
import Hackadev1 from "assets/img/profile/hackadev-1.png";

// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Project from "views/admin/classLeadersAndGroups/components/Project";

export default function Projects({ projects, userId, programId }) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

    const getNameProject = (projectSlug) => {
      if (projectSlug == 'related-products') {
        return 'Produtos Relacionados';
      }

      if (projectSlug == 'byte-bank') {
        return 'Byte Bank';
      }

      if (projectSlug == 'fast-track-monitor-sharpcoders') {
        return 'Desafio Fast Track / Monitor';
      }

      if (projectSlug == 'game-hub-fase-1') {
        return 'GameHub Fase 1';
      }

      if (projectSlug == 'hackadev-sharp-1') {
        return 'Hackadev 1° Task';
      }

      if (projectSlug == 'hackadev-sharp-2') {
        return 'Hackadev 2° Task';
      }

      if (projectSlug == 'hackadev-sharp-3') {
        return 'Hackadev 3° Task';
      }

      if (projectSlug == 'hackadev-sharp-4') {
        return 'Hackadev 4° Task';
      }

      return projectSlug;
    }

    const getIconProject = (projectSlug) => {
      if (projectSlug == 'related-products') {
        return RelatedProducts;
      }

      if (projectSlug == 'byte-bank') {
        return ByteBank;
      }

      if (projectSlug == 'fast-track-monitor-sharpcoders') {
        return Xadrez;
      }

      if (projectSlug == 'game-hub-fase-1') {
        return GameHubFase1;
      }

      if (projectSlug == 'hackadev-sharp-1') {
        return Hackadev1;
      }

      if (projectSlug == 'hackadev-sharp-2') {
        return Hackadev1;
      }

      if (projectSlug == 'hackadev-sharp-3') {
        return Hackadev1;
      }

      if (projectSlug == 'hackadev-sharp-4') {
        return Hackadev1;
      }

      return Project1;
    }

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Projetos enviados
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Here you can find more details about your projects. Keep you user
        engaged by providing meaningful information.
      </Text> */}

      {projects?.length == 0 ? 
        <Text
          fontSize={16}
          fontWeight={100}
          marginTop={2}
          className="chakra-text css-10gtq1k"
        >
          Nenhuma entrega realizada ainda.
        </Text>
        : 
        (projects?.map((data) => (
          <Project
            boxShadow={cardShadow}
            mb='20px'
            image={getIconProject(data?.project_slug)}
            title={getNameProject(data?.project_slug)}
            projectSlug={data?.project_slug}
            video_url={data?.video_url} 
            deploy_url={data?.deploy_url} 
            github_url={data?.github_url}
            github_url_2={data?.github_url_2}
            created_at={data?.created_at}
            contribuitorsGithub={data?.project_slug == 'hackadev-sharp-1' || data?.project_slug == 'hackadev-sharp-2' || data?.project_slug == 'hackadev-sharp-3' ? true : false}
            userId={userId}
            programId={programId}
          />
        )))
      }
    </Card>
  );
}
