import React from 'react';

function FeedbackAi({ feedback }) {
 // Texto vindo do banco de dados
 const feedbackText1 = `${feedback}`;
 console.log('feedbackText:', typeof feedbackText1);

 // Separar o texto em partes
 const feedbackParts2 = feedbackText1.split('\n\n');
 console.log('feedbackParts:', feedbackParts2);


  // Texto vindo do banco de dados
const feedbackText = `${feedback}`;

  // Separar o texto em partes
  const feedbackParts = feedbackText.split("\\n");

  return (
    <div style={{marginTop: '10px'}}>
      {feedbackParts.map((part, index) => (
        part == 'Elogios:' || part == 'Sugestões:' || part == 'Problemas relatados:' || part == 'Sentimento geral:' || part == 'O Professor:' || part == 'Avaliação:' || part == 'Qty_feedbacks_positivos_x_negativos:' ?
            <div key={index} style={{marginBottom: '0px', fontSize: '14px', fontWeight: 'bold'}}>
                <p>{
                    part?.replace('Qty_feedbacks_positivos_x_negativos:', '')
                }</p>
            </div>
        : part?.length > 0 ?
            <div key={index} style={{marginBottom: '5px', fontSize: '14px', fontWeight: 'normal'}}>
                <p style={{marginLeft: '10px'}}>{part?.replace('- ', ' ● ')}</p>
            </div>
        : <></>
      ))}
    </div>
  );
}

export default FeedbackAi;
