const initialState = {
    name: '',
    email: '',
    gender: 'all',
    classroomId: 'all',
    status: 'all',
    situation: 'all',
    uf: 'all',
    cities: [],
    city: 'all',
    practical_projects: 'all',
    hard_skills: 'all',
    allHardSkills: 'all'
  };
  
  export default function filtersReducer(state = initialState, action) {
    switch (action.type) {
      case 'SET_FILTERS':
        return {
          ...state,
          ...action.payload,
        };
      case 'CLEAR_FILTERS':
        return initialState;
      default:
        return state;
    }
  }
  