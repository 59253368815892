import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
  } from "@chakra-ui/react";

  import InputCnpj from "components/inputCnpj";

  import { API_SERVER } from "config/constant";

export default function ModalAddCompany({ modalIsOpen, setModalIsOpen, refreshCompanies }) {
  const [loading, setLoading] = useState(false);
  const [formModalError, setFormModalError] = useState(false);
  const [company, setCompany] = useState([]);

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

		setCompany({...company, [name]: value});
	};

  const handleAddCompany = async () => {
    try {      
      const validationEmptyFields = company?.company_name && company?.company_cnpj && company?.company_size && company?.company_uf && company?.company_city;

      if (!validationEmptyFields) {
        throw 'EMPTY_FIELDS';
      }
      
      if (loading) {
        return false;
      }

      setLoading(true);

      company.company_cnpj = company?.company_cnpj?.replace(/[^0-9]+/g,'');

      await axios.post(`${API_SERVER}dashboard/company`, company);

      setCompany([]);
      refreshCompanies(true);
      setModalIsOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setCompany([]);
      refreshCompanies(true);
      setLoading(false);
    }
  }

  const isNotEmptyFields = company?.company_name && company?.company_cnpj && company?.company_size && company?.company_uf && company?.company_city;

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            size={'lg'}
        >
        <form>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar Instituição de Ensino</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* {formModalError && <Text mb='15px' color='red.600'>Data de fim deve ser maior que data de início</Text>} */}

            {/* <FormControl>
              <FormLabel>Logo</FormLabel>
              <Input 
                type="file"
                name="company_logo"
                style={{
                  "padding": "5px 5px 0px 5px"
                }}
                // value={program?.program_name}
                // onChange={handleChange}
                required={true}
              />
            </FormControl> */}

            <FormControl mt={4}>
              <FormLabel>Nome</FormLabel>
              <Input 
                type="text"
                // name="company_name"
                // value={company?.company_name || ''}
                // onChange={handleChange}
                required={true}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>CNPJ</FormLabel>
              <InputCnpj
                type="text"
                // name="company_cnpj"
                // value={company?.company_cnpj || ''}
                // onChange={handleChange}
                className="chakra-input css-1qfdsln"
                required={true}
              />
            </FormControl>

            <SimpleGrid columns='2' gap='20px'>
            <FormControl mt={4}>
            <FormLabel>UF</FormLabel>
            <Select
              // name="company_uf"
              // value={company?.company_uf || ''}
              // onChange={handleChange}
              required={true}
            >
                <option value="">Selecione</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Cidade</FormLabel>
              <Input 
                type="text"
                // name="company_city"
                // value={company?.company_city || ''}
                // onChange={handleChange}
                required={true}
              />
            </FormControl>
            </SimpleGrid>

            <SimpleGrid columns='2' gap='20px'>
              <FormControl mt={4}>
                <FormLabel>Tipo</FormLabel>
                <Select
                  name="company_size"
                  // value={company?.company_size || ''}
                  // onChange={handleChange}
                  required={true}
                >
                  <option value="">Selecione</option>
                  <option value="school">Escola</option>
                  <option value="university">Universidade</option>
                  <option value="course">Curso Preparatório</option>
                  {/* <option value="others">Outros</option> */}
                </Select>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Modalidade</FormLabel>
                <Select
                  name="modality"
                  // value={company?.company_size || ''}
                  // onChange={handleChange}
                  required={true}
                >
                  <option value="">Selecione</option>
                  <option value="ead">EAD</option>
                  <option value="presencial">Presencial</option>
                </Select>
              </FormControl>
            </SimpleGrid>

            <FormControl mt={4}>
              <FormLabel>Qtd. de alunos</FormLabel>
              <Input 
                type="number"
                // name="email"
                // value={company?.company_name || ''}
                // onChange={handleChange}
                required={true}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Plano</FormLabel>
              <Select
                name="plan"
                // value={company?.company_size || ''}
                // onChange={handleChange}
                required={true}
              >
                <option value="">Selecione</option>
                <option value="trial">Período de teste (7 dias )</option>
                <option value="starter">Starter (até 200 alunos / 1 teste por semana)</option>
                <option value="pro">Pro (até 1000 alunos / 2 testes por semana / Aplicativo)</option>
                <option value="custom">Custom (1000+ alunos / Testes ilimitados / Aplicativo)</option>
              </Select>
            </FormControl>

            <br />
            <hr />
            <FormControl mt={4}>
              <FormLabel>Para qual e-mail posso enviar o link de primeiro acesso?</FormLabel>
              <Input 
                type="email"
                name="email"
                // value={company?.company_name || ''}
                // onChange={handleChange}
                required={true}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button 
              type="button"
              onClick={handleAddCompany}
              colorScheme='blue' mr={3}
              disabled={!isNotEmptyFields}
            >
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
            <Button onClick={() => setModalIsOpen(false)}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </form>
      </Modal>
    )
}