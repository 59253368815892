import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import ComplexTable from "views/admin/classLeaders/components/ComplexTable";
import {
  columnsDataComplex,
} from "views/admin/classLeaders/variables/columnsData";

import { API_SERVER } from "config/constant";

export default function ClassLeaders() {
  const hash = window.location.hash;
  const id = hash.split('/monitores/');
  const programIdStorage = localStorage.getItem('programId');
  const programId = id[1] ? id[1] : (programIdStorage ? programIdStorage : 0);

  const INITIAL_FILTERS = {
    name: '',
    email: '',
    // programId: programId,
  }

  const [loading, setLoading] = useState(false);
  const [programIdFilter, setProgramIdFilter] = useState(programId);
  const [loadUsers, setLoadUsers] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [refreshClassLeaders, setRefreshClassLeaders] = useState(false);

  const [filters, setFilters] = useState(INITIAL_FILTERS);
  
  const [users, setUsers] = useState([]);
  const [dataCourse, setDataCourse] = useState({
    total: 0,
    rampUp: 0,
    contratados: 0,
  });

  useEffect(() => { 
    window.scrollTo(0, 0);

    const getUsers = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/classLeadersAndGroups`, {
        params: {
          name: filters.name,
          email: filters.email,
          programId: programIdFilter,
        }
      });

      const { classLeaders, total, rampUp, contratados } = data?.usersData;

      setDataCourse({
        total: total,
        rampUp: rampUp,
        contratados: contratados,
      })
      setUsers(classLeaders);
      setRefreshClassLeaders(false);
      setLoading(false);
    }

    if (programIdFilter > 0) {
      getUsers();
    }
  }, [programIdFilter, refreshClassLeaders])

  const handleChangeProgramId = (ev) => {
    localStorage.setItem('programId', ev.target.value);

    setProgramIdFilter(ev.target.value);

    window.location.href = '/#/admin/monitores';
  }

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    
    setFilters({ ...filters, [name]: ev.target.value });
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getUsers = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/classLeadersAndGroups`, {
        params: {
          name: filters.name,
          email: filters.email,
          programId: programIdFilter,
        }
      });

      const { classLeaders, total, rampUp, contratados } = data?.usersData;

      setDataCourse({
        total: total,
        rampUp: rampUp,
        contratados: contratados,
      });
      setUsers(classLeaders);
      setLoadUsers(false);
    }

    setLoadUsers(true);
    getUsers();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <>
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
        <ComplexTable
          programId={programIdFilter}
          onChangeProgram={handleChangeProgramId}
          onChangeFilter={handleChangeFilter}
          submitFilter={handleSubmitFilter}
          clearFilters={() => setFilters(INITIAL_FILTERS)}
          filtersData={filters}
          loadUsersByFilter={loadUsers}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setRefreshClassLeaders={setRefreshClassLeaders}
          columnsData={columnsDataComplex}
          tableData={users}
          dataCourse={dataCourse}
        />
      </SimpleGrid>
    </Box>
    </>
  );
}
