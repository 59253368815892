// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Progress,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React from "react";
// Assets
import { MdOutlineSchool } from "react-icons/md";
import { FaMedal } from "react-icons/fa";

export default function Badge(props) {
  const { badge, score, position } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <IconBox
        mx='auto'
        h='100px'
        w='100px'
        style={
          score > 0 && badge == 'platina' ? {border: '1px solid rgb(102, 102, 102)'} 
          : 
          score > 0 && badge == 'ouro' ? {border: '1px solid gold'} : 
          score > 0 && badge == 'prata' ? {border: '1px solid silver'} : 
          score > 0 && badge == 'bronze' ? {border: '1px solid rgb(205, 127, 50)'} : 
          {}
        }
        icon={
          <Icon 
            as={FaMedal} 
            color={
            score > 0 && badge == 'platina' ? 'rgb(102, 102, 102)' 
            : 
            score > 0 && badge == 'ouro' ? 'gold' : 
            score > 0 && badge == 'prata' ? 'silver' : 
            score > 0 && badge == 'bronze' ? 'rgb(205, 127, 50)' : brandColor}
            h='46px' w='46px' 
          />
        }
        bg={box}
      />
      <Text 
        style={{"textTransform": "capitalize"}}
        color={textColorPrimary} 
        fontWeight='bold' 
        fontSize='2xl' 
        mt='10px'
      >
        {score > 0 ? badge : '-'}
      </Text>
      <Text
        color={textColorSecondary}
        fontSize='md'
        maxW={{ base: "100%", xl: "80%", "3xl": "60%" }}
        mx='auto'>
        {score > 0 && position > 0 ? `${position}° no Ranking` : ''}
      </Text>
      <Box w='100%' mt='auto'>
        <Flex w='100%' justify='space-between' mb='10px'>
          <Flex mx='auto' me='40px' align='center' direction='column'>
            <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {score > 0 && position != undefined && position != '' ? `${position}°` : '-'}
            </Text>
            <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
              Ranking
            </Text>
          </Flex>
          <Flex mx='auto' me='10px' align='center' direction='column'>
            <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
              {score}
            </Text>
            <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
              Score
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Card>
  );
}
