import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Grid,
  GridItem,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import axios from "axios";

import { API_SERVER } from "config/constant";

const ModalAddTestes = ({ modalIsOpen, setModalIsOpen }) => {
  const [loading, setLoading] = useState(false);
  const [formsQuestions, setFormsQuestions] = useState({
    form_theme: '',
    form_serie: '',
    level_questions: '',
    qtd_questions: '',
    forms_available: '',
  });
  const [questionsAi, setQuestionsAI] = useState([]);
  const [questionsCreated, setQuestionsCreated] = useState(false);

  const handleClose = () => {
    setModalIsOpen(false);
  };

  const handleOpen = () => {
    setModalIsOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (loading) {
      return false;
    }

    setLoading(true);

    if (questionsAi?.length > 0) {
      setLoading(true);

      const { data } = await axios.post(`${API_SERVER}dashboard/interviewai/createInterviewByAdmin`, {
        interviewTitle: formsQuestions?.form_theme,
        questions: questionsAi 
      });

      setLoading(false);
      setQuestionsCreated(true);
      setFormsQuestions({
        form_theme: '',
        form_serie: '',
        level_questions: '',
        qtd_questions: '',
        forms_available: '',
      });
      setQuestionsAI([]);

      return false;
    }

    const prompt = `Crie uma lista de ${formsQuestions?.qtd_questions} questões de nível avançado para um aluno do ${formsQuestions?.form_serie}º ano da Faculdade de Medicina sobre o tema: ${formsQuestions?.form_theme}. 
    \n\nMe retorne as perguntas em formato JSON, no mesmo formato exemplo abaixo: 
    \n\n[
      {
          "question": "[question_text]",
          "level": "[level_text]"
      },
      {
          "question": "[question_text]",
          "level": "[level_text]"
      }
    ]`;

    const { data } = await axios.post(`${API_SERVER}openai/createQuestionsInterview`, {
      question: prompt 
    });

    if (data?.data) {
      setQuestionsAI(JSON.parse(data?.data));
    }

    setLoading(false);
  };

  const handleChange = (ev) => {
    const name = ev.target.name;
    const value = ev.target.value;
    
    setFormsQuestions({ ...formsQuestions, [name]: value })
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Formulário de Criação de Avaliações</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            
            <ModalBody>
              {questionsCreated && (
                <Alert status='success' mb="20px">
                  <AlertIcon />
                  Avaliação cadastrada com sucesso!
                </Alert>
              )}

              <FormControl id="tema">
                <FormLabel>1. Tema</FormLabel>
                <Input 
                  name="form_theme"
                  onChange={handleChange}
                  value={formsQuestions?.form_theme}
                  type="text" placeholder="Digite o tema" mb='20px' />
              </FormControl>

              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
                <FormControl id="serie">
                  <FormLabel>2. Ano na faculdade</FormLabel>
                    <Select 
                      name="form_serie"
                      onChange={handleChange}
                      value={formsQuestions?.form_serie}
                      placeholder="Selecione" mb='20px'>
                      <option value="1">1ª Ano</option>
                      <option value="2">2ª Ano</option>
                      <option value="3">3ª Ano</option>
                      <option value="4">4ª Ano</option>
                      <option value="5">5ª Ano</option>
                      {/* <option value="6">6ª Ano</option> */}
                      {/* <option value="5">5ª Ano</option>
                      <option value="6">6ª Ano</option>
                      <option value="7">7ª Ano</option>
                      <option value="8">8ª Ano</option> */}
                    </Select>
                  </FormControl>
                </GridItem>

                {/* <GridItem>
                  <FormControl id="nivel">
                    <FormLabel>3. Nível de dificuldade</FormLabel>
                    <Select 
                      name="level_questions"
                      onChange={handleChange}
                      value={formsQuestions?.level_questions}
                      placeholder="Selecione" mb="20px">
                      <option value="fácil">I (Fácil)</option>
                      <option value="intermediário">II (Intermediário)</option>
                      <option value="avançado">III (Avançado)</option>
                    </Select>
                  </FormControl>
                </GridItem> */}
              </Grid>

              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
              <FormControl id="qtdPerguntas">
                <FormLabel>4. Qtd. Perguntas</FormLabel>
                <Input 
                  type="number" 
                  name="qtd_questions"
                  value={formsQuestions?.qtd_questions}
                  onChange={handleChange}
                  placeholder="Digite a quantidade de perguntas" mb='20px' />
              </FormControl>
              </GridItem>

              <GridItem>
              <FormControl id="forms_available">
                <FormLabel>5. Disponível para:</FormLabel>
                <Select placeholder="Selecione" mb='20px' 
                  name="forms_available"
                  onChange={handleChange}
                  value={formsQuestions?.forms_available}
                >
                  <option value="admin">Administradores</option>
                  <option value="admin_students">Alunos e Administradores</option>
                  <option value="students">Somente alunos</option>
                </Select>
              </FormControl>
              </GridItem>
              </Grid>

            {formsQuestions?.form_theme && 
            formsQuestions?.form_serie && 
            formsQuestions?.level_questions && 
            formsQuestions?.qtd_questions && 
            formsQuestions?.forms_available 
            ? (
              <>
              <hr />
              {/* <Text mt="10px" mb="10px">
                Prompt: <b> */}
                  {/* {`Crie uma lista de ${formsQuestions?.qtd_questions} questões de nível ${formsQuestions?.level_questions} 
                  para um aluno do ${formsQuestions?.form_serie}º ano do Ensino Fundamental sobre ${formsQuestions?.form_theme}`} */}

                  {/* {`Crie uma lista de ${formsQuestions?.qtd_questions} questões de nível avançado 
                  para um aluno do ${formsQuestions?.form_serie}º ano da Faculdade de Medicina sobre o tema: ${formsQuestions?.form_theme}.`}
                </b>
              </Text> */}
              </>
            ) : null}

            {(questionsAi?.length > 0) && (
              <>
                <hr />
                <Text mt="10px" mb="10px" fontWeight={'600'}>Resultado:</Text>
                {
                  questionsAi?.map((data, index) => (
                    // <p key={index}><b>{index + 1}</b>. {data?.question} ({data.level})</p>
                    <p key={index}><b>{index + 1}</b>. {data?.question}</p>
                  ))
                }
              </>
            )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={handleClose} mr={3} style={{"color": "red", "border": "1px solid red"}}>Cancelar</Button>
              
              {questionsAi?.length > 0 ? (
                <Button 
                  disabled={
                      formsQuestions?.form_theme && 
                      formsQuestions?.form_serie && 
                      // formsQuestions?.level_questions && 
                      formsQuestions?.qtd_questions && 
                      formsQuestions?.forms_available && 
                      !loading ? false : true
                  }
                  colorScheme="blue" type="submit">
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
              )
              :
                (
                  <Button 
                  disabled={
                      formsQuestions?.form_theme && 
                      formsQuestions?.form_serie && 
                      // formsQuestions?.level_questions && 
                      formsQuestions?.qtd_questions && 
                      formsQuestions?.forms_available && 
                      !loading ? false : true
                  }
                  colorScheme="blue" type="submit">
                    {loading ? 'Buscando...' : 'Buscar'}
                  </Button>
                )
              }
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAddTestes;
