import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    Checkbox,
    Stack,
  } from "@chakra-ui/react";

  import InputCnpj from "components/inputCnpj";

  import { API_SERVER } from "config/constant";

export default function ModalAddTeacher({ modalIsOpen, setModalIsOpen, refreshTeachers }) {
  const [loading, setLoading] = useState(false);
  const [formModalError, setFormModalError] = useState(false);
  const [teacher, setTeacher] = useState({
    teachers_subjects: []
  });

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;
    const checked = ev.target.checked;

    const { teachers_subjects } = teacher;

    if (checked && name === 'teachers_subjects') {
      setTeacher({
        ...teacher,
        teachers_subjects: [...teachers_subjects, value],
      });      
    } else if (!checked && name === 'teachers_subjects') {
      setTeacher({
        ...teacher,
        teachers_subjects: teachers_subjects.filter((e) => e !== value),
      });
    } else {
      setTeacher({
        ...teacher, 
        [name]: value
      });
    }
	};

  const handleAddTeacher = async () => {
    try {          
      const validationEmptyFields = teacher?.teacher_name && teacher?.teachers_subjects?.length > 0 && teacher?.teacher_type && teacher?.teacher_uf && teacher?.teacher_city;

      if (!validationEmptyFields) {
        throw 'EMPTY_FIELDS';
      }
      
      if (loading) {
        return false;
      }

      setLoading(true);

      await axios.post(`${API_SERVER}dashboard/teacher`, teacher);

      setTeacher([]);
      refreshTeachers(true);
      setModalIsOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setTeacher([]);
      refreshTeachers(true);
      setLoading(false);
    }
  }

  const isNotEmptyFields = teacher?.teacher_name && teacher?.teachers_subjects?.length > 0 && teacher?.teacher_type && teacher?.teacher_uf && teacher?.teacher_city;

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
        <form>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar professor</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* {formModalError && <Text mb='15px' color='red.600'>Data de fim deve ser maior que data de início</Text>} */}

            {/* <FormControl>
              <FormLabel>Logo</FormLabel>
              <Input 
                type="file"
                name="company_logo"
                style={{
                  "padding": "5px 5px 0px 5px"
                }}
                // value={program?.program_name}
                // onChange={handleChange}
                required={true}
              />
            </FormControl> */}

            <FormControl mt={4}>
              <FormLabel>Nome</FormLabel>
              <Input 
                type="text"
                name="teacher_name"
                value={teacher?.teacher_name || ''}
                onChange={handleChange}
                required={true}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Assunto</FormLabel>
              <Stack spacing={5} direction='row'>
                <Checkbox 
                  name="teachers_subjects" 
                  value="front-end"
                  onChange={handleChange}
                >
                    Front-End
                </Checkbox>
                <Checkbox 
                  name="teachers_subjects" 
                  value="back-end"
                  onChange={handleChange}
                >
                    Back-End
                  </Checkbox>
              </Stack>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Tipo</FormLabel>
              <Select
                name="teacher_type"
                value={teacher?.teacher_type || ''}
                onChange={handleChange}
                required={true}
              >
                <option value="">Selecione</option>
                <option value="synchronous">Aula Síncrona</option>
                <option value="asynchronous">Aula Assíncrona</option>
              </Select>
            </FormControl>

            <SimpleGrid columns='2' gap='20px'>
            <FormControl mt={4}>
            <FormLabel>UF</FormLabel>
            <Select
              name="teacher_uf"
              value={teacher?.teacher_uf || ''}
              onChange={handleChange}
              required={true}
            >
                <option value="">Selecione</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AP">AP</option>
                <option value="AM">AM</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MT">MT</option>
                <option value="MS">MS</option>
                <option value="MG">MG</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PR">PR</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RS">RS</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="SC">SC</option>
                <option value="SP">SP</option>
                <option value="SE">SE</option>
                <option value="TO">TO</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Cidade</FormLabel>
              <Input 
                type="text"
                name="teacher_city"
                value={teacher?.teacher_city || ''}
                onChange={handleChange}
                required={true}
              />
            </FormControl>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button 
              type="button"
              onClick={handleAddTeacher}
              colorScheme='blue' mr={3}
              disabled={!isNotEmptyFields}
            >
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
            <Button onClick={() => setModalIsOpen(false)}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </form>
      </Modal>
    )
}