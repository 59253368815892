import {
  Text,
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  ButtonGroup,
  Button,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
} from "@chakra-ui/react";
import axios from "axios";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import BarChart from "components/charts/BarChart";
import MiniStatistic from "components/card/MiniStatistics";
import StudentProfile from "components/card/StudentProfile";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";
import { FiBarChart2, FiBox } from "react-icons/fi";

import {
  barChartStudentAccess,
  barChartOptionsStudentAccess,
} from "variables/charts";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { IoWarning } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";

export default function StudentAccess() {
  const dateToday = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'medium', timeZone: 'America/Sao_Paulo' }).format(new Date())?.split(' ');
  let dateTodayFormat = `${dateToday[0]?.split('/')[2]}-${dateToday[0]?.split('/')[1]}-${dateToday[0]?.split('/')[0]}`;
  dateTodayFormat = dateTodayFormat?.replace(',', '');

  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [accessDate, setAccessDate] = useState(dateTodayFormat);
  const [usersAccess, setUsersAccess] = useState([]);
  const [dataStudentsAccess, setDataStudentsAccess] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const dashboardUserName = user.dashboardUserName;

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    window.scrollTo(0, 0);

    const getUsersAccess = async () => {
      if (loadingUsers || accessDate == '') {
        return false;
      }

      setLoadingUsers(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/usersAccess/${accessDate}`);

      const { usersAccessData, dataAccess } = data?.studentsAccessData;

      setDataStudentsAccess(dataAccess);
      setUsersAccess(usersAccessData);
      setLoadingUsers(false);
    }

    getUsersAccess();
  }, [accessDate, dataStudentsAccess]);

  function exportXLSX() {    
    if (loadingReport) { 
      return false;
    }

    setLoadingReport(true);

    const arrayOfArrays = usersAccess?.map(obj => [
      obj.name?.toUpperCase(),
      obj.access_date_time
    ]);
    arrayOfArrays.unshift(['Nome', 'Data']);

    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Acessos');
    const fileName = 'acessos.xlsx';
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);

    setLoadingReport(false);
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  return (
    <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 4, lg: 4, "2xl": 4 }}
        gap='20px'
        mt='0px'
        mb='0px'>
        {/* <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FiBarChart2} color={'#4169E1'} />
              }
            />
          }
          name='Total'
          value={metrics?.totalPrograms}
        /> */}
      </SimpleGrid>

      {/* <Text 
        style={{
          "fontSize": "1rem",
          "color": "#2759a5",
          "padding": "4px 10px",
          "background": "#fff",
          "width": "220px",
          "boxShadow": "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          "borderRadius": "10px",
          "textAlign": "center",
        }}
        pl='10px' mb='15px' fontSize='lg' fontWeight='500'>Espaço dos alunos</Text> */}
        <Text 
        style={{
          "fontSize": "1rem",
        }}
        pl='10px' mb='30px' fontSize='md'>
          Acessos de usuários com sessão ativa dentro do espaço dos alunos, os acessos em páginas que não exigem autenticação são ignorados.
        </Text>

      <Tabs size='md' variant='enclosed'>
        <TabList>
          <Tab><b>Total</b></Tab>
          <Tab><b>Alunos</b></Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
          {loadingUsers ?
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='80px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingUsers} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            : 
              <Box h='260px' mt='30px'>
                {/* <Flex mx='auto' mt='0px' align='center' direction='row'>
                  <IoWarning size={30} color="#ffcc13" />{' '}Esta tela está passando por atualizações. Em breve, estará disponível novamente!
                </Flex> */}
                <BarChart
                  chartData={dataStudentsAccess}
                  chartOptions={barChartOptionsStudentAccess}
                />
              </Box>
            }
          </TabPanel>
          <TabPanel>
          {loadingUsers ?
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='80px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingUsers} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            : 
              <>
                <Flex mx='auto' mt='20px' align='center' justifyContent={'space-between'} alignItems={'flex-end'} direction='row'>
                  <Box w='250px' mr='20px'>
                    <MiniStatistic
                      boxShadow={true}
                      startContent={
                        <IconBox
                          w='56px'
                          h='56px'
                          bg={boxBg}
                          icon={
                            <Icon w='32px' h='32px' as={FiBarChart2} color={'#4169E1'} />
                          }
                        />
                      }
                      name='Total'
                      value={usersAccess?.length}
                    />
                  </Box>
                  
                <Input 
                  type={'date'} 
                  min={'2022-12-04'}
                  max={dateTodayFormat}
                  value={accessDate}
                  onChange={(ev) => setAccessDate(ev.target.value)}
                  style={{
                    "width": "220px", 
                    "backgroundColor": "#fff",
                    "borderRadius": "20px",
                    "padding": "25px"
                  }} 
                />
                </Flex>
                <Button 
                  onClick={exportXLSX}
                  fontSize='sm'
                  color='#107C41'
                  style={{"border": "1px solid #107C41"}}
                  fontWeight='500'
                  w='150px'
                  mt='20px'
                >
                  <Icon as={RiFileExcel2Fill} h='16px' w='16px' me='8px' />
                  <Text fontSize='sm' fontWeight='400'>
                    Exportar
                  </Text>
                </Button>
                <SimpleGrid
                    columns={{ base: 1, sm: 3, md: 4, lg: 4, "2xl": 5 }}
                    gap='20px'
                    mt='20px'
                    mb='0px'>
                      {usersAccess?.map((data) => (
                        <StudentProfile
                          boxShadow={true}
                          name={data?.name}
                          picture={data?.picture}
                          dateTime={data?.access_date_time}
                          userId={data?.user_id}
                          programId={data?.program_id}
                          role={data?.role}
                        />
                      ))}
                </SimpleGrid>
                {usersAccess?.length == 0 && <Text fontSize={'2xl'} align={'center'} mt={'20px'}>Nenhum resultado encontrado.</Text>}
              </>
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
