// Chakra imports
import { SimpleGrid, 
  Text, 
  useColorModeValue, 
  FormControl,
  FormLabel, 
  Input,
  Select,
  Textarea,
  GridItem,
  Flex,
  Grid,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import InputCpf from "components/inputCpf";
import InputCellPhone from "components/inputCellphone";
import React from "react";
import Information from "views/admin/profile-pdf/components/Information";
import GeneratePDF from "./GeneratePDF";

import logo from "assets/img/layout/ima-tech-logo-blue.png";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

// Assets
export default function GeneralInformation(props) {
  const {
    userData,
    programId, 
    name, 
    cpf,
    email, 
    cellphone,
    gender,
    age,
    city,
    uf,
    status,
    currentSituation, 
    companyId,
    presenceTotal,
    active, 
    urlTestPersonality,
    createdAt,
    schooling,
    formation,
    educationalInstitution,
    previousKnowledge,
    userProjects,
    hardSkills,
    feedbackHardSkills,
    feedbackSoftSkills,
    feedbackBorder,
    isEditFeedback,
    isEdit, 
    isEditAll,
    setIsEdit,
    handleChange,
    ...rest 
  } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const getStatusName = (status) => {
    if (status == 'em_formacao') {
      return 'Em formação';
    }

    if (status == 'desligado') {
      return 'Desligado';
    }

    if (status == 'fora_programa') {
      return 'Desistente';
    }

    if (status == 'formado') {
      return 'Formado';
    }

    if (status == 'indicado') {
      return 'Indicado';
    }

    if (status == 'banco_talentos') {
      return 'Banco de talentos';
    }

    if (status == 'contratado') {
      return 'Contratado direto';
    }

    if (status == 'contratado_headhunting') {
      return 'Contratado Head Hunting';
    }

    if (status == 'contratado_indireto') {
      return 'JobOut';
    }

    if (status == 'indisponivel') {
      return 'Indisponível';
    }
  }

  const getNameProject = (projectSlug) => {
    if (projectSlug == 'related-products') {
      return 'Produtos Relacionados';
    }

    if (projectSlug == 'byte-bank') {
      return 'Byte Bank';
    }

    if (projectSlug == 'fast-track-monitor-sharpcoders') {
      return 'Desafio Fast Track / Monitor';
    }

    if (projectSlug == 'game-hub-fase-1') {
      return 'GameHub';
    }

    if (projectSlug == 'hackadev-sharp-1') {
      return 'Hackadev';
    }

    if (projectSlug == 'hackadev-sharp-2') {
      return 'Hackadev';
    }

    if (projectSlug == 'hackadev-sharp-3') {
      return 'Hackadev';
    }

    if (projectSlug == 'hackadev-sharp-4') {
      return 'Hackadev';
    }

    return projectSlug;
  }

  const getNameByKey = (key) => {
    if (key == 'dotnet') {
      return '.NET';
    }

    if (key == 'dotnetcore') {
      return '.NET Core';
    }

    if (key == 'csharp') {
      return 'C#';
    }

    if (key == 'java') {
      return 'Java';
    }

    if (key == 'php') {
      return 'PHP';
    }

    if (key == 'javascript') {
      return 'JavaScript';
    }

    if (key == 'typescript') {
      return 'TypeScript';
    }

    if (key == 'nodejs') {
      return 'NodeJS';
    }

    if (key == 'graphql') {
      return 'GraphQL';
    }

    if (key == 'nestjs') {
      return 'NestJS';
    }

    if (key == 'reactjs') {
      return 'ReactJS';
    }

    if (key == 'vuejs') {
      return 'Vue.js';
    }

    if (key == 'angularjs') {
      return 'AngularJS';
    }

    if (key == 'angular') {
      return 'Angular';
    }

    if (key == 'layout-responsivo') {
      return 'Layout Responsivo';
    }

    if (key == 'html') {
      return 'HTML';
    }

    if (key == 'css') {
      return 'CSS';
    }

    if (key == 'springboot') {
      return 'Spring Boot';
    }

    if (key == 'laravel') {
      return 'Laravel';
    }

    if (key == 'rubyonrails') {
      return 'Ruby on Rails';
    }

    if (key == 'kotlin') {
      return 'Kotlin';
    }

    if (key == 'rust') {
      return 'Rust';
    }

    if (key == 'go') {
      return 'GO';
    }

    if (key == 'python') {
      return 'Python';
    }

    if (key == 'aws') {
      return 'AWS';
    }

    if (key == 'azure') {
      return 'Azure';
    }

    if (key == 'git') {
      return 'GIT';
    }

    if (key == 'mysql') {
      return 'MySQL';
    }

    if (key == 'couchbase') {
      return 'Couchbase';
    }

    if (key == 'oracle') {
      return 'Oracle';
    }

    if (key == 'postgres') {
      return 'PostgreSQL';
    }

    if (key == 'mongodb') {
      return 'MongoDB';
    }

    if (key == 'sqlserver') {
      return 'SQL Server';
    }

    if (key == 'docker') {
      return 'Docker';
    }

    if (key == 'kubernetes') {
      return 'Kubernetes';
    }

    if (key == 'jasmine') {
      return 'Jasmine';
    }

    if (key == 'junit') {
      return 'JUnit';
    }

    if (key == 'jest') {
      return 'Jest';
    }

    if (key == 'xunit') {
      return 'XUnit.net';
    }

    if (key == 'phpunit') {
      return 'PHPUnit';
    }

    if (key == 'nunit') {
      return 'NUnit';
    }

    if (key == 'sonar') {
      return 'Sonar';
    }

    if (key == 'mocha') {
      return 'Mocha';
    }

    if (key == 'tdd') {
      return 'TDD';
    }

    if (key == 'ansible') {
      return 'Ansible';
    }

    if (key == 'gradle') {
      return 'Gradle';
    }

    if (key == 'aws_autoscale') {
      return 'AWS Auto Scaling';
    }

    if (key == 'cicd') {
      return 'CI/CD';
    }

    if (key == 'google-cloud') {
      return 'Google Cloud Platform (GCP)';
    }

    if (key == 'reactnative-testing-library') {
      return 'React Native Testing Library';
    }

    if (key == 'swift') {
      return 'Swift';
    }

    if (key == 'flutter') {
      return 'Flutter';
    }

    if (key == 'objective-c') {
      return 'Objective-C';
    }

    if (key == 'xamarin') {
      return 'Xamarin';
    }

    if (key == 'react-native') {
      return 'React Native';
    }

    return key;
  }

  const getLevelProgrammingHardSkills = (key) => {
    if (key == 'theoretical') {
      return 'teórico';
    }

    if (key == 'beginner') {
      return 'iniciante';
    }

    if (key == 'intermediate') {
      return 'intermediário';
    }

    if (key == 'advanced') {
      return 'avançado';
    }

    return key;
  }

  return (
    <Card mb={{ base: "0px", "2xl": "10px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Informações gerais
      </Text>
      {/* <GeneratePDF user={userData} /> */}
      
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        As we live, our hearts turn colder. Cause pain is what we go through as
        we become older. We get insulted by others, lose trust for those others.
        We get back stabbed by friends. It becomes harder for us to give others
        a hand. We get our heart broken by people we love, even that we give
        them all...
      </Text> */}
      <SimpleGrid columns='2' gap='10px'>
        {!isEdit ? 
          <Information
            style={{"textTransform": "capitalize"}}
            // boxShadow={cardShadow}
            title='Nome'
            value={name}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Nome</FormLabel>
            <Input
              type={'text'}
              name={'name'}
              value={name || ''}
              onChange={handleChange}
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        }
        {/* {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='CPF'
            value={cpf?.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4")}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>CPF</FormLabel>
            <InputCpf
              type={'text'}
              name={'cpf'}
              value={cpf?.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4") || ''}
              onChange={handleChange}
              className="chakra-input css-kag1qr"
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              required={true}
              disabled={!isEditAll ? true : false}
            />
          </FormControl>
        } */}
        {!isEdit ? 
          <Information
            // boxShadow={cardShadow}
            title='E-mail'
            value={email}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>E-mail</FormLabel>
            <Input
              type={'text'}
              name={'email'}
              value={email || ''}
              onChange={handleChange}
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        }
        <Information
          // boxShadow={cardShadow}
          title='Celular'
          linkWhatsApp={cellphone?.length > 0 ? true : false}
          value={cellphone?.replace(/^(\d{2})(\d)/g,"($1) $2")?.replace(/(\d)(\d{4})$/,"$1-$2")}
        />
        {/* {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Celular'
            linkWhatsApp={cellphone?.length > 0 ? true : false}
            value={cellphone?.replace(/^(\d{2})(\d)/g,"($1) $2")?.replace(/(\d)(\d{4})$/,"$1-$2")}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Celular</FormLabel>
            <InputCellPhone
              type={'text'}
              name={'cell_phone'}
              value={cellphone?.replace(/^(\d{2})(\d)/g,"($1) $2")?.replace(/(\d)(\d{4})$/,"$1-$2") || ''}
              onChange={handleChange}
              className="chakra-input css-kag1qr"
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              required={true}
              disabled={!isEditAll ? true : false}
            />
          </FormControl>
        } */}
        {!isEdit ? 
          <Information
            // boxShadow={cardShadow}
            title='Sexo'
            value={gender == 'male' ? 'Masculino' : (gender == 'female' ? 'Feminino' : 'Sem informação')}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Sexo</FormLabel>
            <Select 
              name={'gender'}
              value={gender || ''}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='male'>Masculino</option>
              <option value='female'>Feminino</option>
            </Select>
          </FormControl>
        }
        {age && (
          <Information
            // boxShadow={cardShadow}
            title='Idade'
            value={age}
          />
        )}
        {!isEdit ? 
          <Information
            // boxShadow={cardShadow}
            title='Cidade / UF'
            value={`${city} - ${uf}`}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Cidade / UF</FormLabel>
            <SimpleGrid columns='2' gap='20px'>
            <Input
              type={'text'}
              name={'city'}
              value={city || ''}
              onChange={handleChange}
              placeholder={'Cidade'}
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
            <Select 
              name={'uf'}
              value={uf || ''}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              disabled={!isEditAll ? true : false}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </Select>
            </SimpleGrid>
          </FormControl>
        }
        {/* <Information
          boxShadow={cardShadow}
          title='Data cadastro'
          value={createdAt}
        /> */}
        {/* <Information
          boxShadow={cardShadow}
          title='Status'
          value={getStatusName(status)}
        />
        <Information
          boxShadow={cardShadow}
          title='Situação'
          value={
            getStatusName(currentSituation)
          }
        /> */}
        {(programId != 1 && urlTestPersonality) && (
        <>
          <Information
            // boxShadow={cardShadow}
            title='Teste de personalidade'
            value={urlTestPersonality}
            linkTestPersonality={urlTestPersonality ? true : false}
          />
          <Information
            // boxShadow={cardShadow}
            title='Aulas assistidas'
            value={presenceTotal}
          />
        </>
        )}
      </SimpleGrid>

        <Text
          color={textColorPrimary}
          fontWeight='bold'
          fontSize='2xl'
          mt='10px'
          mb='4px'>
          Educação
        </Text>

        <SimpleGrid columns='2' gap='10px'>
        {schooling && (
          <Information
            // boxShadow={cardShadow}
            title='Escolaridade'
            value={schooling?.length > 0 ? schooling : null}
          />
        )}
        {formation && (
          <Information
            style={{"textTransform": "capitalize"}}
            // boxShadow={cardShadow}
            title='Formação'
            value={formation}
          />
        )}
        {educationalInstitution && (
          <Information
            // boxShadow={cardShadow}
            title='Instituição de Ensino'
            value={educationalInstitution}
          />
        )}
        {previousKnowledge && (
          <Information
            // boxShadow={cardShadow}
            title='Conhecimento prévio'
            value={previousKnowledge?.length > 0 ? previousKnowledge : null}
          />
        )}
        </SimpleGrid>

        {/* {userProjects?.length > 0 && 
          <Text
            color={textColorPrimary}
            fontWeight='bold'
            fontSize='2xl'
            mt={userProjects?.length > 2 ? '10px' : '50px'}
            mb='4px'>
            Projetos enviados
          </Text>
        }
        {userProjects?.length > 0 && userProjects?.map((data) => (
          (data?.project_slug != 'hackadev-sharp-1' && data?.project_slug != 'hackadev-sharp-2' && data?.project_slug != 'hackadev-sharp-3') &&
          <p style={{marginBottom: "4px"}}><b>{getNameProject(data?.project_slug)}</b>: <a href={data?.github_url} target="_blank" style={{color: 'blue', textDecoration: 'underline'}}>{data?.github_url}</a></p>
        ))} */}


      {hardSkills?.length > 0 && (
      <>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        pt='20px'
        mt={userProjects?.length > 0 ? '100px' : '10px'}
        mb='4px'>
        Conhecimento técnico
      </Text>
      <Text 
        style={{
          "fontSize": "1rem",
          color: '#000'
        }}
        pl='0px' mb='30px' fontSize='md'>
          <Flex mx='auto' mt='0px' align='center' direction='row'>
            <MdOutlineInfo size={20} /> <b style={{marginLeft: '5px'}}>Dados informados pelo aluno</b>
          </Flex>
        </Text>
        <Grid templateColumns='repeat(2, 1fr)' gap={2}>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Front-End</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'frontend')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'frontend' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Back-End</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'backend')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'backend' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Desenvolvimento Mobile</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'mobile')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'mobile' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Banco de Dados</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'database')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'database' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>Testes</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'test')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'test' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
          <GridItem w='100%' mb='20px'>
            <Text mb='10px' color="#ce1151"><b>DevOps</b></Text>

            <ul
              style={{
                listStyle: 'none',
                paddingLeft: '10px'
              }}
            >
              {hardSkills?.filter((data) => data?.area === 'devops')?.length > 0 ? (
                  hardSkills?.map((data) => 
                    data?.area == 'devops' && (
                      <li
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          color: '#2d2765',
                        }}
                      >
                        <BsFillCheckCircleFill />&nbsp;&nbsp;<b>{getNameByKey(data?.value)}</b>&nbsp;- [{getLevelProgrammingHardSkills(data?.level)}]
                      </li>
                    )
                  )
                ) : (
                  <p>Nenhum resultado encontrado.</p>
              )}
            </ul>
          </GridItem>
        </Grid>
        </>
        )}

        {isEditFeedback ? 
          (
            <>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                pt={(userProjects?.length == 0 && hardSkills?.length == 0) ? '0px' : '20px'}
                mt={(userProjects?.length > 0 && hardSkills?.length == 0 && programId != 1) ? '100px' : '10px'}
                mb={(userProjects?.length == 0 && hardSkills?.length == 0) ? '0px' : '4px'}
                >
                Avaliação técnica
              </Text>

              <SimpleGrid columns='1' gap='20px'>
                <Textarea 
                  className="form-control" 
                  onChange={handleChange} 
                  name={'feedback_hardskills'} 
                  value={feedbackHardSkills} 
                /> 
              </SimpleGrid>
            </>
          )
        : feedbackHardSkills && (
            <>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='20px'
                mb={(userProjects?.length == 0 && hardSkills?.length == 0) ? '0px' : '4px'}
                >
                Avaliação técnica
              </Text>
              <Text
                color={textColorPrimary}
                fontWeight='normal'
                fontSize='1xl'
                mt='0px'
                >
                {feedbackHardSkills}
              </Text>
            </>
        )}

        {isEditFeedback ? 
          (
            <>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='20px'
                mb={(userProjects?.length == 0 && hardSkills?.length == 0) ? '0px' : '4px'}
                >
                Avaliação comportamental
              </Text>

              <SimpleGrid columns='1' gap='20px'>
                <Textarea 
                  className="form-control" 
                  onChange={handleChange} 
                  name={'feedback_softskills'} 
                  value={feedbackSoftSkills} 
                />
              </SimpleGrid>
            </>
          )
        : feedbackSoftSkills && (
            <>
              <Text
                color={textColorPrimary}
                fontWeight='bold'
                fontSize='2xl'
                mt='20px'
                mb={(userProjects?.length == 0 && hardSkills?.length == 0) ? '0px' : '4px'}
                >
                Avaliação comportamental
              </Text>
              <Text
                color={textColorPrimary}
                fontWeight='normal'
                fontSize='1xl'
                mt='0px'
                >
                {feedbackSoftSkills}
              </Text>
            </>
        )}

        <div>
          <img 
            src={logo} 
            width={150} 
            style={{margin: '10px auto 0px'}}
            alt={'Logo da Ímã Tech'} 
          />
        </div>
    </Card>
  );
}
