import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, clearFilters, setCurrentPage } from '../../../store/interviews/filtersActions';
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import DevelopmentTable from "views/admin/interviews/components/DevelopmentTable";
import CheckTable from "views/admin/interviews/components/CheckTable";
import ColumnsTable from "views/admin/interviews/components/ColumnsTable";
import ComplexTable from "views/admin/interviews/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/interviews/variables/columnsData";
import tableDataDevelopment from "views/admin/interviews/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/interviews/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/interviews/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/interviews/variables/tableDataComplex.json";

import { API_SERVER } from "config/constant";

export default function Interviews() {
  const hash = window.location.hash;
  const id = hash.split('/alunos/');
  const programIdStorage = localStorage.getItem('programId');
  const programId = id[1] ? id[1] : (programIdStorage ? programIdStorage : 0);

  const filters = useSelector(state => state.interviewsFilters);
  const dispatch = useDispatch();

  const INITIAL_FILTERS = {
    name: '',
    email: '',
    presentation_note: 'all',
    communication_note: 'all',
    academic_professional_note: 'all',
  }

  const [loading, setLoading] = useState(false);
  const [loadUsers, setLoadUsers] = useState(false);

  const [programIdFilter, setProgramIdFilter] = useState(programId);
  // const [filters, setFilters] = useState(INITIAL_FILTERS);
  
  const [users, setUsers] = useState([]);
  const [dataCourse, setDataCourse] = useState({
    total: 0,
    mediaPresentation: 0,
    mediaCommunication: 0,
    mediaAcademic: 0,
    interviews: []
  });

  useEffect(() => {    
    const getUsers = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/interviews/${programId}`, {
        params: {
          name: filters.name,
          email: filters.email,
          presentation_note: filters.presentation_note,
          communication_note: filters.communication_note,
          academic_professional_note: filters.academic_professional_note,
          programId: programIdFilter,
        }
      });

      const { interviews, total, mediaPresentation, mediaCommunication, mediaAcademic } = data?.interviewsData;

      setDataCourse({
        total: total,
        mediaPresentation: mediaPresentation,
        mediaCommunication: mediaCommunication,
        mediaAcademic: mediaAcademic,
        interviews: interviews
      })
      setUsers(interviews);
      setLoading(false);
    }

    if (programIdFilter > 0) {
      getUsers();
    }
  }, [programIdFilter])

  const handleChangeProgramId = (ev) => {
    dispatch(setCurrentPage(1));
    setFilters(INITIAL_FILTERS);
    
    localStorage.setItem('programId', ev.target.value);

    setProgramIdFilter(ev.target.value);

    window.location.href = '/#/admin/entrevistas';
  }

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    const value = ev.target.value;
    
    // setFilters({ ...filters, [name]: ev.target.value });
    dispatch(setFilters({ ...filters, [name]: value }));
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getUsers = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/interviews/${programId}`, {
        params: {
          name: filters.name,
          email: filters.email,
          presentation_note: filters.presentation_note,
          communication_note: filters.communication_note,
          academic_professional_note: filters.academic_professional_note,
          programId: programIdFilter,
        }
      });

      const { interviews, total, mediaPresentation, mediaCommunication, mediaAcademic } = data?.interviewsData;

      setDataCourse({
        total: total,
        mediaPresentation: mediaPresentation,
        mediaCommunication: mediaCommunication,
        mediaAcademic: mediaAcademic,
        interviews: interviews
      });
      setUsers(interviews);
      setLoadUsers(false);
    }

    setLoadUsers(true);
    getUsers();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
        <ComplexTable
          programId={programIdFilter}
          onChangeProgramId={handleChangeProgramId}
          onChangeFilter={handleChangeFilter}
          submitFilter={handleSubmitFilter}
          clearFilters={() => { dispatch(clearFilters()); }}
          filtersData={filters}
          loadUsersByFilter={loadUsers}
          columnsData={columnsDataComplex}
          tableData={users}
          dataCourse={dataCourse}
        />
      </SimpleGrid>
    </Box>
  );
}
