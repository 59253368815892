// Chakra imports
import { SimpleGrid, 
  Text, 
  useColorModeValue, 
  FormControl,
  FormLabel, 
  Input,
  Select,
  Textarea,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import InputCpf from "components/inputCpf";
import InputCellPhone from "components/inputCellphone";
import React, { useState } from "react";
import Information from "views/admin/profile/components/Information";

// Assets
export default function HardSkillsInterview(props) {
  const {
    interviewData,
    handleSendInterview,
    handleChangeButton,
    handleChange,
    ...rest 
  } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='20px'>
        Entrevista de Hard Skills
      </Text>
      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px' 
        mb='0px'
      >
        Tópicos:
      </Text>
      <SimpleGrid columns='1' gap='20px' mb='20px'>
        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Escreva os tópicos que serão abordados na entrevista:</FormLabel>
          <Textarea name={'academic_trajectory'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px' 
        mb='0px'
      >
        Entrevista:
      </Text>
      <SimpleGrid columns='1' gap='0px'>
        <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0}>Escreva as perguntas dos topicos acima e as respostas dadas pelo candidato na entrevista aqui:</FormLabel>
          <Textarea 
            name={'comments_professional_experiences'} 
            onChange={handleChange}
            rows={6}
            style={{"border": "1px solid rgb(39, 89, 165)"}}>
          </Textarea>
        </FormControl>
      </SimpleGrid>

      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px'
        mt='20px' 
        mb='0px'
      >
        Futuro Profissional: 
      </Text>

      <SimpleGrid columns='1' gap='0px' mb='20px'>
        <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Em que área da programação você acha que se encaixa?</FormLabel>
          <Select 
              // name={'knowledge_level'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='frontend'>Full-Stack</option>
              <option value='frontend'>Front-End</option>
              <option value='backend'>Back-End</option>
              <option value='infra'>Infra</option>
              <option value='none'>Não sei ainda</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Observações:</FormLabel>
          <Textarea name={'academic_trajectory'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl>
      </SimpleGrid>

      <Text 
        style={{
          "textDecoration": "underline"
        }}
        color={textColorPrimary} 
        fontSize='lg' 
        fontWeight={700} 
        me='26px'
        mt='20px' 
        mb='0px'
      >
        Feedback da entrevista: 
      </Text>

      <SimpleGrid columns='1' gap='20px' mb='20px'>
      <FormControl mb='20px'>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Como você define o nível de conhecimento em programação do candidato atualmente?</FormLabel>
          <Select 
              name={'knowledge_level'}
              onChange={handleChange}
              placeholder='Selecione uma opção'
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            >
              <option value='none'>Nenhum</option>
              <option value='basic'>Básico</option>
              <option value='intermediary'>Intermediário</option>
              <option value='advanced'>Avançado</option>
            </Select>
        </FormControl>

        {/* <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Poderia me contar sobre um problema de programação que você tenha resolvido e que tenha te deixado muito orgulhoso?</FormLabel>
          <Textarea name={'domain_programming'} onChange={handleChange} rows={6} style={{"border": "1px solid rgb(39, 89, 165)"}}></Textarea>
        </FormControl> */}
      </SimpleGrid>

      <SimpleGrid columns='1' gap='20px' mb='40px'>
        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Que nota você daria para o nível técnico do candidato?</FormLabel>
          <ButtonGroup>
            <Button onClick={() => handleChangeButton('presentation_note', 1)} colorScheme={interviewData?.presentation_note == 1 && 'blue'} variant={interviewData?.presentation_note == 1 && 'outline'}>1</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 2)} colorScheme={interviewData?.presentation_note == 2 && 'blue'} variant={interviewData?.presentation_note == 2 && 'outline'}>2</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 3)} colorScheme={interviewData?.presentation_note == 3 && 'blue'} variant={interviewData?.presentation_note == 3 && 'outline'}>3</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 4)} colorScheme={interviewData?.presentation_note == 4 && 'blue'} variant={interviewData?.presentation_note == 4 && 'outline'}>4</Button>
            <Button onClick={() => handleChangeButton('presentation_note', 5)} colorScheme={interviewData?.presentation_note == 5 && 'blue'} variant={interviewData?.presentation_note == 5 && 'outline'}>5</Button>
          </ButtonGroup>
        </FormControl>

        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Que nota você daria para comunicação do candidato?</FormLabel>
          <ButtonGroup>
            <Button onClick={() => handleChangeButton('communication_note', 1)} colorScheme={interviewData?.communication_note == 1 && 'blue'} variant={interviewData?.communication_note == 1 && 'outline'}>1</Button>
            <Button onClick={() => handleChangeButton('communication_note', 2)} colorScheme={interviewData?.communication_note == 2 && 'blue'} variant={interviewData?.communication_note == 2 && 'outline'}>2</Button>
            <Button onClick={() => handleChangeButton('communication_note', 3)} colorScheme={interviewData?.communication_note == 3 && 'blue'} variant={interviewData?.communication_note == 3 && 'outline'}>3</Button>
            <Button onClick={() => handleChangeButton('communication_note', 4)} colorScheme={interviewData?.communication_note == 4 && 'blue'} variant={interviewData?.communication_note == 4 && 'outline'}>4</Button>
            <Button onClick={() => handleChangeButton('communication_note', 5)} colorScheme={interviewData?.communication_note == 5 && 'blue'} variant={interviewData?.communication_note == 5 && 'outline'}>5</Button>
          </ButtonGroup>
        </FormControl>

        <FormControl>
          <FormLabel mt={4} mb={2} ml={0} fontSize='lg'>Que nota você daria para os projetos do candidato?</FormLabel>
          <ButtonGroup>
            <Button onClick={() => handleChangeButton('academic_professional_note', 1)} colorScheme={interviewData?.academic_professional_note == 1 && 'blue'} variant={interviewData?.academic_professional_note == 1 && 'outline'}>1</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 2)} colorScheme={interviewData?.academic_professional_note == 2 && 'blue'} variant={interviewData?.academic_professional_note == 2 && 'outline'}>2</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 3)} colorScheme={interviewData?.academic_professional_note == 3 && 'blue'} variant={interviewData?.academic_professional_note == 3 && 'outline'}>3</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 4)} colorScheme={interviewData?.academic_professional_note == 4 && 'blue'} variant={interviewData?.academic_professional_note == 4 && 'outline'}>4</Button>
            <Button onClick={() => handleChangeButton('academic_professional_note', 5)} colorScheme={interviewData?.academic_professional_note == 5 && 'blue'} variant={interviewData?.academic_professional_note == 5 && 'outline'}>5</Button>
          </ButtonGroup>
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns='4' gap='20px' mb='20px'>
          <Button 
            disabled={true}
            // onClick={handleSendInterview}
            bg={'green.400'} 
            color='#fff'>
              Salvar
          </Button>
      </SimpleGrid>
    </Card>
  );
}
