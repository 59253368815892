import {
  Text,
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  ButtonGroup,
  Button,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";

// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import BarChart from "components/charts/BarChart";
import MiniStatistic from "components/card/MiniStatistics";
import StudentProfile from "components/card/StudentProfile";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import TotalSpent from "./components/TotalSpent";

import { API_SERVER } from "config/constant";
import { FiBarChart2, FiBox } from "react-icons/fi";

import {
  barChartStudentAccess,
  barChartOptionsStudentAccess,
} from "variables/charts";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { MdOutlineEventNote, MdOutlineOndemandVideo } from "react-icons/md";
import StudentAccess from "../studentAccess";
import { DiGoogleAnalytics } from "react-icons/di";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";

export default function Analytics() {
  const dateToday = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'medium', timeZone: 'America/Sao_Paulo' }).format(new Date())?.split(' ');
  const dateTodayFormat = `${dateToday[0]?.split('/')[2]}-${dateToday[0]?.split('/')[1]}-${dateToday[0]?.split('/')[0]}`;

  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingVideosAccess, setLoadingVideosAccess] = useState(false);
  const [videosAccess, setVideosAccess] = useState([]);
  // const [accessDate, setAccessDate] = useState(dateTodayFormat);
  // const [usersAccess, setUsersAccess] = useState([]);
  // const [dataStudentsAccess, setDataStudentsAccess] = useState([]);

  const [programId, setProgramId] = useState(3);

  const user = JSON.parse(localStorage.getItem('user'));
  const dashboardUserName = user.dashboardUserName;

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    window.scrollTo(0, 0);

    const getVideosAccess = async () => {
      if (loadingVideosAccess) {
        return false;
      }

      setLoadingVideosAccess(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/videosAccess`);

      const { videosAccess } = data;

      setVideosAccess(videosAccess);
      setLoadingVideosAccess(false);
    }

    getVideosAccess();
  }, []);

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 4, lg: 4, "2xl": 4 }}
        gap='20px'
        mt='20px'
        mb='0px'>
        {/* <MiniStatistics
          boxShadow={true}
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FiBarChart2} color={'#4169E1'} />
              }
            />
          }
          name='Total'
          value={metrics?.totalPrograms}
        /> */}
      </SimpleGrid>

      <Tabs size='md' variant='enclosed'>
      <TabList>
          <Tab fontWeight="bold">
            <DiGoogleAnalytics w={6} color="gray.500" style={{marginRight: 5}} /> Frequência
          </Tab>
          <Tab fontWeight="bold">
            <TbDeviceDesktopAnalytics w={6} color="gray.500" style={{marginRight: 5}} />
            Espaço dos alunos
          </Tab>
          {/* <Tab fontWeight="bold">
            <MdOutlineOndemandVideo w={6} color="gray.500" style={{marginRight: 5}} />
            Aulas assíncronas
          </Tab> */}
      </TabList>

        <TabPanels>
          <TabPanel>
              <Text 
              style={{
                "fontSize": "1rem",
              }}
              pl='10px' mb='30px' fontSize='md'>
                Acompanhe a frequência dos alunos e suas respectivas presenças nas aulas síncronas.
              </Text>

              <Flex px='0px' justify='space-between' mt='10px' mb='0px' align='center'>
                <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='0px' mb='0px'>
                  <Select
                    defaultValue={programId} 
                    name="programId"
                    onChange={(ev) => setProgramId(ev.target.value)}
                    variant='auth'
                    fontSize='sm'
                    ms={{ base: "0px", md: "0px" }}
                    mb='0px'
                    fontWeight='500'
                    size='lg'
                    placeholder="Selecione um programa" 
                    bg={'white'}
                  >
                    <option value="3">Todas Tech</option>
                    <option value="2">SharpCoders</option>
                  </Select>
                </SimpleGrid>
              </Flex>

          {loadingUsers ?
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='80px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingUsers} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            : 
              <Box h='260px' mt='30px'>
                <TotalSpent programId={programId} />
              </Box>
            }
          </TabPanel>

          <TabPanel>
            <StudentAccess />            
          </TabPanel>

          <TabPanel>
          <Text 
              style={{
                "fontSize": "1rem",
              }}
              pl='10px' mb='30px' fontSize='md'>
                Acompanhe os acessos dos alunos nas aulas assíncronas.
              </Text>

              {loadingVideosAccess ?
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='80px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingVideosAccess} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
              </Box>
            : 
              <Box mt='10px' mb='30px'
              >
              <SimpleGrid
                  mb='20px'
                  columns={{ sm: 2, md: 3 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                {videosAccess?.map((data) => (
                  <div
                    style={{
                      "display": "flex",
                      "justifyContent": "center",
                      "alignItems": "center",
                      "flexDirection": "column",
                      "textAlign": "center",
                      "maxWidth": "300px",
                      "border": "1px solid #ccc",
                      "padding": "10px",
                      "borderRadius": "10px",
                      "margin": "0px 15px 10px 0px",
                    }}
                  >
                    <MdOutlineOndemandVideo size={40} /> 
                    <p style={{"margin": "0px 10px"}}><b>{data?.video_title}</b></p>
                    <p>(<b>{data?.total}</b>)</p>
                  </div>
                ))}
                </SimpleGrid>
              </Box>
            }
          </TabPanel>

        </TabPanels>
      </Tabs>
    </Box>
  );
}
