import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import ComplexTable from "views/admin/companies/components/ComplexTable";
import {
  columnsDataComplex,
} from "views/admin/companies/variables/columnsData";

import { API_SERVER } from "config/constant";

export default function Companies() {
  const INITIAL_FILTERS = {
    name: '',
    cnpj: '',
    size: '',
    uf: '',
    city: '',
  }

  const [loading, setLoading] = useState(false);
  const [loadUsers, setLoadUsers] = useState(false);
  const [refreshCompanies, setRefreshCompanies] = useState(false);

  const [filters, setFilters] = useState(INITIAL_FILTERS);
  
  const [companies, setCompanies] = useState([]);
  const [dataCompanies, setDataCompanies] = useState({
    total: 0,
    sizeLarge: 0,
    sizeMedium: 0,
    sizeSmall: 0,
  });

  useEffect(() => { 
    window.scrollTo(0, 0);

    const getCompanies = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/companies`, {
        params: {
          name: filters.name,
          cnpj: filters.cnpj,
          size: filters.size,
          uf: filters.uf,
          city: filters.city,
          programid_not_in: '',
        }
      });

      const { companies, total, sizeLarge, sizeMedium, sizeSmall } = data?.companiesData;

      setDataCompanies({
        total: total,
        sizeLarge: sizeLarge,
        sizeMedium: sizeMedium,
        sizeSmall: sizeSmall,
      })
      setCompanies(companies);
      setRefreshCompanies(false);
      setLoading(false);
    }

    getCompanies();
  }, [refreshCompanies])

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    
    setFilters({ ...filters, [name]: ev.target.value });
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getCompanies = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/companies`, {
        params: {
          name: filters.name,
          cnpj: filters.cnpj,
          size: filters.size,
          uf: filters.uf,
          city: filters.city,
          programid_not_in: '',
        }
      });

      const { companies, total, sizeLarge, sizeMedium, sizeSmall } = data?.companiesData;

      setDataCompanies({
        total: total,
        sizeLarge: sizeLarge,
        sizeMedium: sizeMedium,
        sizeSmall: sizeSmall,
      })
      setCompanies(companies);
      setLoadUsers(false);
    }

    setLoadUsers(true);
    getCompanies();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <ComplexTable
            programId={0}
            onChangeCompanies={setRefreshCompanies}
            onChangeFilter={handleChangeFilter}
            submitFilter={handleSubmitFilter}
            clearFilters={() => setFilters(INITIAL_FILTERS)}
            filtersData={filters}
            loadUsersByFilter={loadUsers}
            columnsData={columnsDataComplex}
            tableData={companies}
            dataCompanies={dataCompanies}
          />
        </SimpleGrid>
      </Box>
    </>
  );
}
