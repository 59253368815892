import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  let { user } = useAuth();
  if (!user || !user.token || user.token === "") {
    return (
      <Redirect from='/' to='/auth/sign-in' />
      // retornar mensagem - sua sessao expirou
    );
  }

  return <Route {...rest} />;
};
