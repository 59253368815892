import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex, Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel 
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import ComplexTable from "views/admin/ranking/components/ComplexTable";
import {
  columnsDataComplex
} from "views/admin/ranking/variables/columnsData";

import { API_SERVER } from "config/constant";

export default function Ranking() {
  const hash = window.location.hash;
  const id = hash.split('/ranking/');
  const programIdStorage = localStorage.getItem('programId');
  const programId = id[1] ? id[1] : (programIdStorage ? programIdStorage : 0)

  const INITIAL_FILTERS = {
    name: '',
    email: '',
    gender: 'all',
    badge: 'all',
  }

  const [loading, setLoading] = useState(false);
  const [loadingRankingRealtime, setLoadingRankingRealtime] = useState(false);
  const [loadUsers, setLoadUsers] = useState(false);
  const [programIdFilter, setProgramIdFilter] = useState(programId);
  const [users, setUsers] = useState([]);
  const [rankingRealtime, setRankingRealtime] = useState([]);
  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const [dataRanking, setDataRanking] = useState({
    total: 0,
    platina: 0,
    ouro: 0,
    prata: 0,
    bronze: 0,
  });

  const [dataRankingRealtime, setDataRankingRealtime] = useState({
    total: 0,
    platina: 0,
    ouro: 0,
    prata: 0,
    bronze: 0,
  });

  useEffect(() => {    
    const getUsers = async () => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/ranking`, {
        params: {
          name: filters.name,
          email: filters.email,
          gender: filters.gender,
          badge: filters.badge,
          programId: programIdFilter,
        }
      });

      const { ranking, total, platina, ouro, prata, bronze } = data?.rankingData;

      setDataRanking({
        total: total,
        platina: platina,
        ouro: ouro,
        prata: prata,
        bronze: bronze,
      })
      setUsers(ranking);
      setLoading(false);
    }

    const getRankingRealtime = async () => {
      if (loadingRankingRealtime) {
        return false;
      }

      setLoadingRankingRealtime(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/rankingRealtime`, {
        params: {
          name: filters.name,
          email: filters.email,
          gender: filters.gender,
          badge: filters.badge,
          programId: programIdFilter,
        }
      });

      const { ranking, total, platina, ouro, prata, bronze } = data?.rankingData;

      setDataRankingRealtime({
        total: total,
        platina: platina,
        ouro: ouro,
        prata: prata,
        bronze: bronze,
      })
      setRankingRealtime(ranking);
      setLoadingRankingRealtime(false);
    }

    if (programIdFilter > 0) {
      getUsers();
      getRankingRealtime();
    }
  }, [programIdFilter])

  const handleChangeProgramId = (ev) => {
    setFilters(INITIAL_FILTERS);
    
    localStorage.setItem('programId', ev.target.value);

    setProgramIdFilter(ev.target.value);

    window.location.href = '/#/admin/ranking';
  }

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    
    setFilters({ ...filters, [name]: ev.target.value });
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getUsers = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/ranking`, {
        params: {
          name: filters.name,
          email: filters.email,
          gender: filters.gender,
          badge: filters.badge,
          programId: programIdFilter,
        }
      });

      const { ranking, total, platina, ouro, prata, bronze } = data?.rankingData;

      setDataRanking({
        total: total,
        platina: platina,
        ouro: ouro,
        prata: prata,
        bronze: bronze,
      })

      setUsers(ranking);
      setLoadUsers(false);
    }

    setLoadUsers(true);
    getUsers();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <Box>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 

        <Tabs size='md' variant='enclosed' mt='100px'>
        <TabList>
          <Tab>Ranking</Tab>
          <Tab>Última atualização</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ComplexTable
              programId={programIdFilter}
              onChangeProgramId={handleChangeProgramId}
              onChangeFilter={handleChangeFilter}
              submitFilter={handleSubmitFilter}
              clearFilters={() => setFilters(INITIAL_FILTERS)}
              filtersData={filters}
              loadUsersByFilter={loadUsers}
              columnsData={columnsDataComplex}
              tableData={users}
              dataRanking={dataRanking}
              realtime={false}
            />
          </TabPanel>
          <TabPanel>
            {!loadingRankingRealtime ? (
              <ComplexTable
                programId={programIdFilter}
                onChangeProgramId={handleChangeProgramId}
                onChangeFilter={handleChangeFilter}
                submitFilter={handleSubmitFilter}
                clearFilters={() => setFilters(INITIAL_FILTERS)}
                filtersData={filters}
                loadUsersByFilter={loadUsers}
                columnsData={columnsDataComplex}
                tableData={rankingRealtime}
                dataRanking={dataRankingRealtime}
                realtime={true}
              />
            ) : (
              <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                    <Flex mx='auto' mt='80px' align='center' direction='column'>
                      <ClipLoader 
                        color={'#2759a5'} 
                        loading={loadingRankingRealtime} 
                        size={60} 
                      />
                    </Flex>
                  </SimpleGrid>
              </Box>
            )}
          </TabPanel>
        </TabPanels>
        </Tabs>
      </SimpleGrid>
    </Box>
  );
}
