import { useState, useEffect } from 'react';
import { Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../../../store/students/filtersActions';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight } from 'react-icons/bs';

export default function Pagination({ currentPage, pageIndex, pageCount, gotoPage, canPreviousPage, canNextPage }) {
  const dispatch = useDispatch();

  const handlePageClick = (page) => {
    dispatch(setCurrentPage(page));
    gotoPage(page - 1);
  };

  const handleFirstPageClick = () => {
    dispatch(setCurrentPage(1));
    gotoPage(0);
  };

  const handleLastPageClick = () => {
    dispatch(setCurrentPage(pageCount));
    gotoPage(pageCount - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];

    const numPageButtons = 5;

    let startPage = Math.max(1, currentPage - numPageButtons);
    let endPage = Math.min(pageCount, currentPage + numPageButtons);

    if (endPage - startPage < numPageButtons * 2) {
      startPage = Math.max(1, endPage - numPageButtons * 2);
      endPage = Math.min(pageCount, startPage + numPageButtons * 2);
    }

    for (let page = startPage; page <= endPage; page++) {
      const isActive = page === currentPage;
      pageButtons.push(
        <Button 
            key={page}
            style={{marginRight: "4px", fontWeight: isActive ? 'bold' : 'normal'}}
            onClick={() => handlePageClick(page)}
            // disabled={isActive}
            colorScheme='brandScheme'
            variant={isActive ? 'solid' : 'outline'}
          >
          {page}
        </Button>
      );
    }

    return pageButtons;
  };

  return (
    <div>
        <Button 
            style={{marginRight: "4px"}}
            onClick={handleFirstPageClick} 
            disabled={!canPreviousPage}
            colorScheme='brandScheme'
            variant='outline'
        >
        <BsChevronDoubleLeft />
      </Button>
      <Button 
            style={{marginRight: "4px"}}
            onClick={() => handlePageClick(currentPage - 1)} 
            disabled={!canPreviousPage}
            colorScheme='brandScheme'
            variant='outline'
        >
          <BsChevronLeft />
      </Button>
      {renderPageButtons()}
      <Button 
            style={{marginRight: "4px"}}
            onClick={() => handlePageClick(currentPage + 1)} 
            disabled={!canNextPage}
            colorScheme='brandScheme'
            variant='outline'
        >
          <BsChevronRight />
      </Button>
      <Button 
            onClick={handleLastPageClick} 
            disabled={!canNextPage}
            colorScheme='brandScheme'
            variant='outline'
        >
            <BsChevronDoubleRight />
      </Button>
    </div>
  );
}
