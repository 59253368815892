import React, {useState, useEffect} from "react";

import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

// Chakra imports
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";

// Assets
import illustration from "assets/img/auth/auth.png";
import { useAuth } from "../../../auth-context/auth.context";
import AuthApi from "../../../api/auth";


function SignOut() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const history = useHistory();
  const { setUser } = useAuth();
  const { user } = useAuth();
  
  useEffect(() => {
    const logout = async (response) => {
      let userData = { };
      userData.token = '';
      userData = JSON.stringify(userData);
      localStorage.setItem('programId', '');
      localStorage.removeItem('programId');
      
      setUser(userData);
      
      localStorage.setItem("user", userData);

      return history.push("/auth/sign-in");
    };

    if (user && user.token) {
      setTimeout(logout, 200);
    } else {
      return history.push("/auth/sign-in");
    }
  }, []);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='26px' mb='15px'>
            Saindo...
          </Heading>
          {/* <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='lg'>
             Aguarde...
          </Text> */}
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
            <ClipLoader 
              color={'#2759a5'} 
              loading={true} 
              size={60} 
            />
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignOut;
