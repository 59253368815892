import React, { useRef } from 'react';
import { 
    useColorModeValue
} from "@chakra-ui/react";
import { useReactToPrint } from 'react-to-print';
import Information from './Information';

const GeneratePDF = ({ user }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  return (
    <>
      <button onClick={handlePrint}>Gerar PDF</button>
      <div ref={componentRef}>
        <img src={user.banner} alt={user.name} />
        <h1>{user.name}</h1>

        <Information
            boxShadow={cardShadow}
            title='E-mail'
            value={user?.email}
        />

        {/* <p>Teste de personalidade: <a href={user.url_test_personality} target={'_blank'}>{user.url_test_personality}</a></p>
        <p>Linkedin: <a href={user.linkedin_url} target={'_blank'}>{user.linkedin_url}</a></p>
        <p>GitHub: <a href={user.github_url} target={'_blank'}>{user.github_url}</a></p> */}
      </div>
    </>
  );
};

export default GeneratePDF;
