export const tableColumnsTopCreators = [
  {
    Header: "Name",
    label: 'Empresa',
    accessor: "company_name",
  },
  {
    Header: "Contratar",
    label: 'Contratar',
    accessor: "company_to_hire",
  },
  // {
  //   Header: "Ramp-up",
  //   label: 'Ramp-up',
  //   accessor: "company_ramp_up",
  // },
  {
    Header: "Contratados",
    label: 'Contratados',
    accessor: "company_hired",
  }
];
