import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Progress,
  Text,
  useColorModeValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";
import { FaPlusCircle, FaSearch, FaTrash, FaUsers } from "react-icons/fa";
import { trim } from "lodash";
import { AiFillCloseCircle } from "react-icons/ai";

export default function ModalAddCompanies({ programId, modalIsOpen, setModalIsOpen, refreshProgramById }) {
  const [loading, setLoading] = useState(false);
  const [loadingAddCompany, setLoadingAddCompany] = useState(false);
  const [editCompanies, setEditCompanies] = useState(false);
  const [loadingRemoveCompany, setLoadingRemoveCompany] = useState(false);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [companiesNotSelected, setCompaniesNotSelected] = useState([]);
  const [companySelected, setCompanySelected] = useState(0);
  const [qtdToHire, setQtdToHire] = useState('');

  useEffect(() => {
    const getCompanies = async () => {
      try {   
        if (loading) {
          return false;
        }

        setLoading(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/companiesByFilters/${programId}`);
        const { companiesSelected, companiesNotSelected } = data;

        setCompaniesNotSelected(companiesNotSelected);
        setCompaniesSelected(companiesSelected);
        setLoading(false);
      } catch (error) {
        console.log(error);
  
        setLoading(false);
      }
    }

    getCompanies();
  }, []);

  const handleChange = (ev) => {
    const name = ev.target.name;
    const qtd_to_hire = ev.target.value;
    const companyId = parseInt(name.slice(-1));

    setQtdToHire({ ...qtdToHire, [`company_id_${companyId}`]: companyId, [name]: qtd_to_hire });
  }

  const getCompaniesRefresh = async () => {
    try {   
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/companiesByFilters/${programId}`);
      const { companiesSelected, companiesNotSelected } = data;

      setCompaniesNotSelected(companiesNotSelected);
      setCompaniesSelected(companiesSelected);
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }

  const addCompany = async (companyId) => {
    setCompanySelected(companyId);

    try {      
      if (loadingAddCompany) {
        return false;
      }

      const isCompanySelected = qtdToHire?.[`company_id_${companyId}`] === companyId;
      const qtdToHireInput = (!isCompanySelected) ? 0 : qtdToHire?.[`qtd_to_hire_${companyId}`];

      if (!programId || !companyId || qtdToHireInput === undefined) {
        throw 'PROGRAMID_OR_COMPANYID_OR_QTDTOHIRE_IS_EMPTY';
      }

      setLoadingAddCompany(true);

      await axios.post(`${API_SERVER}dashboard/companies`, {
        programId: programId,
        companyId: companyId,
        qtdToHire: qtdToHireInput,
      });

      setQtdToHire(null);

      getCompaniesRefresh();

      setEditCompanies(true);
      setLoadingAddCompany(false);
    } catch (error) {
      console.log(error);

      setLoadingAddCompany(false);
    }
  }

  const removeCompany = async (companyId) => {
    setCompanySelected(companyId);

    try {      
      if (!programId || !companyId) {
        throw 'PROGRAMID_OR_COMPANYID_IS_EMPTY';
      }

      if (loadingRemoveCompany) {
        return false;
      }

      setLoadingRemoveCompany(true);

      await axios.delete(`${API_SERVER}dashboard/companies`, { data: {
        companyId: companyId,
        programId: programId,
      }});

      getCompaniesRefresh();

      setEditCompanies(true);
      setLoadingRemoveCompany(false);
    } catch (error) {
      console.log(error);

      setLoadingRemoveCompany(false);
    }
  }

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => {
              setModalIsOpen(false);
              editCompanies && refreshProgramById(true);
            }}
        >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar empresas</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {loading ?
              <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
                <SimpleGrid
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                    <Flex mx='auto' mt='10px' align='center' direction='column'>
                      <ClipLoader 
                        color={'#2759a5'} 
                        loading={loading} 
                        size={60} 
                      />
                    </Flex>
                  </SimpleGrid>
              </Box>
            : null}

            {(!loading && companiesNotSelected?.length > 0) &&
              <Text
                style={{
                  "fontSize": "17px",
                  "fontWeight": "600",
                  "marginLeft": "8px",
                  "marginBottom": "15px"
                }}
              >
                Todas empresas
              </Text>
            }

            {!loading && companiesNotSelected?.map(({ company_id, company_name }) =>
              <div 
                style={{"display": "flex",
                  "flexDirection": "row",
                  "justifyContent": "space-between",
                  "alignItems": "center",
                  "marginTop": "10px",
                  "padding": "8px 15px",
                  "border": "1px solid #2759A5",
                  "borderRadius": "100px",
                }}
              >
                <Text
                  style={{
                    "width": "40%",
                    "color": "#2759A5",
                    "fontWeight": "600"
                  }}
                >
                  {company_name}
                </Text>
                <div
                  style={{"display": "flex",
                    "flexDirection": "row",
                    "justifyContent": "flex-end",
                    "alignItems": "center",
                  }}
                >
                  <Text
                    style={{
                      "fontSize": "15px",
                      "fontWeight": "600"
                    }}
                  >
                    Qtd. contratar
                  </Text>
                  <Input 
                    type="number" 
                    name={`qtd_to_hire_${company_id}`}
                    onChange={handleChange}
                    style={{
                      "width": "45px",
                      "marginLeft": "10px",
                      "paddingLeft": "5px",
                      "paddingRight": "5px",
                      "textAlign": "center",
                      "border": "1px solid #2759A5"
                    }}
                  />
                </div>
                  {loadingAddCompany && companySelected === company_id ? 
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingAddCompany} 
                      size={20} 
                    />
                  : 
                  <Link 
                    onClick={() => addCompany(company_id)}
                  >
                  <FaPlusCircle 
                    color='#009000' 
                    size={25}
                  />
                </Link>
                }
              </div>
            )}

            {(!loading && companiesSelected?.length > 0) &&
              <>
              {companiesNotSelected?.length > 0 &&
                <br />
              }
              <Text
                style={{
                  "fontSize": "17px",
                  "fontWeight": "600",
                  "marginLeft": "8px",
                  "marginBottom": "15px"
                }}
              >
                Empresas do programa
              </Text>
              </>
            }
            
            {!loading && companiesSelected?.map(({ company_id, company_name, to_hire }) =>
              <div 
                style={{"display": "flex",
                "flexDirection": "row",
                "justifyContent": "space-between",
                "alignItems": "center",
                "marginTop": "10px",
                "padding": "10px 15px",
                "backgroundColor": "#2759A5",
                "border": "1px solid #2759A5",
                "borderRadius": "100px",
                }}
              >
                <Text
                  style={{
                    "width": "40%",
                    "color": "#ffffff",
                    "fontWeight": "600"
                  }}
                >
                  {company_name}
                </Text>
                <div
                  style={{"display": "flex",
                    "flexDirection": "row",
                    "justifyContent": "flex-end",
                    "alignItems": "center",
                  }}
                >
                  <Text
                    style={{
                      "color": "#ffffff",
                      "fontSize": "15px",
                      "fontWeight": "600"
                    }}
                  >
                    Qtd. contratar
                  </Text>
                  <Input 
                    type="number" 
                    disabled={true}
                    value={to_hire}
                    style={{
                      "width": "45px",
                      "marginLeft": "10px",
                      "paddingLeft": "5px",
                      "paddingRight": "5px",
                      "textAlign": "center",
                      "color": "#ffffff",
                      "opacity": "inherit",
                    }}
                  />
                </div>
                  {loadingRemoveCompany && companySelected === company_id ? 
                    <ClipLoader 
                      color={'#ffffff'} 
                      loading={loadingRemoveCompany} 
                      size={20} 
                    />
                  : 
                  <Link 
                    onClick={() => removeCompany(company_id)}
                  >
                  <FaTrash 
                    color='#ce1151' 
                    size={20}
                  />
                </Link>
                }
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => {
              setModalIsOpen(false);
              editCompanies && refreshProgramById(true);
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}