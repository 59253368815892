import React, { useState, useEffect } from "react";
import axios from "axios";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

// Custom components
import Banner from "views/admin/programas/components/Banner";
import TableTopCreators from "views/admin/programas/components/TableTopCreators";
import HistoryItem from "views/admin/programas/components/HistoryItem";
import NFT from "components/card/NFT";
import Card from "components/card/Card.js";
import ModalAddProgram from "./components/ModalAddProgram";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";

import trinusLogo from "assets/img/empresas/trinus.png";
import nectarLogo from "assets/img/empresas/nectar.webp";
import auvoLogo from "assets/img/empresas/auvo_tecnologia.png";
import poliLogo from "assets/img/empresas/polichat.png";

import { API_SERVER } from "config/constant";
import { FaPlus, FaPlusCircle } from "react-icons/fa";


export default function Programas() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const [loading, setLoading] = useState(false);
  const [refreshPrograms, setRefreshPrograms] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [programs, setPrograms] = useState([]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    const getPrograms = async () => {
      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/programs`);

      const { programs } = data;

      setRefreshPrograms(false);
      setPrograms(programs);
      setLoading(false);
    }

    getPrograms();
  }, [refreshPrograms]);

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  return (
    <>
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Banner 
            data={programs}
          />
          <Flex direction='column'>
            <Flex
              mt='45px'
              mb='20px'
              justifyContent='space-between'
              direction={{ base: "column", md: "row" }}
              align={{ base: "start", md: "center" }}>
              <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
                Mais programas
              </Text>
              <Flex
                align='center'
                me='20px'
                ms={{ base: "24px", md: "0px" }}
                mt={{ base: "20px", md: "0px" }}>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  me={{ base: "34px", md: "44px" }}
                  to='#art'>
                  Ativos
                </Link>
                <Link color={textColorBrand} me={{ base: "34px", md: "44px" }} fontWeight='500' to='#sports'>
                  Em breve
                </Link>
                <Link
                  color={textColorBrand}
                  fontWeight='500'
                  to='#music'>
                  Finalizados
                </Link>
              </Flex>
            </Flex>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
              {programs?.map(({ program_id, name }, i) => (i > 0 &&
                <NFT
                  name={`${name}`}
                  author=''
                  bidders={[
                    Avatar1,
                    Avatar2,
                    Avatar3,
                    Avatar4,
                    Avatar1,
                    Avatar1,
                    Avatar1,
                    Avatar1,
                  ]}
                  image={Nft1}
                  currentbid=''
                  download={`/#/admin/programa/${program_id}`}
                />  
              ))}
            </SimpleGrid>
            {/* <Text
              mt='45px'
              mb='36px'
              color={textColor}
              fontSize='2xl'
              ms='24px'
              fontWeight='700'>
              Em breve
            </Text>
            <SimpleGrid
              columns={{ base: 1, md: 3 }}
              gap='20px'
              mb={{ base: "20px", xl: "0px" }}>
              <NFT
                name='AgroCoders'
                author=''
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft5}
                currentbid='0.91 ETH'
                download='#'
              />
            </SimpleGrid> */}
          </Flex>
        </Flex>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='0px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
                Cadastrar programa
              </Text>
              <Button 
                onClick={() => setModalIsOpen(true)}
                style={{"borderRadius": "100px", "padding": "10px", "height":"auto"}}
                variant='action'
              >
                <FaPlus size={25} color='#2759a5' />
              </Button>
            </Flex>
          </Card>
          <Card p='0px'>
            <Flex
              align={{ sm: "flex-start", lg: "center" }}
              justify='space-between'
              w='100%'
              px='22px'
              py='18px'>
              <Text color={textColor} fontSize='xl' fontWeight='600'>
                Empresas
              </Text>
              <Link href="/#/admin/empresas">
                <Button variant='action'>Ver todas</Button>
              </Link>
            </Flex>

            <HistoryItem
              name='Trinus'
              author=''
              date='GO'
              image={trinusLogo}
              price=''
            />
            <HistoryItem
              name='Nectar'
              author=''
              date='GO'
              image={nectarLogo}
              price=''
            />
            <HistoryItem
              name='Auvo'
              author=''
              date='GO'
              image={auvoLogo}
              price=''
            />
            <HistoryItem
              name='Poli'
              author=''
              date='GO'
              image={poliLogo}
              price=''
            />
          </Card>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>

    {modalIsOpen &&
      <ModalAddProgram 
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        refreshPrograms={setRefreshPrograms}
      />
    }
    </>
  );
}
