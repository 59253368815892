import React from "react";
import { FaCommentDots } from "react-icons/fa";
// Chakra imports
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";

export default function CardTimeline(props) {
  const { author, description, date, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card mt='20px' bg={bg} {...rest}>
      <Box>
        <Text 
            mb='5px' 
            color={textColorPrimary} 
            fontWeight='500' 
            fontSize='md'
            dangerouslySetInnerHTML={{ __html: description?.replace(/\n/g, '<br>') }}
          >
        </Text>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm'>
          {date} | <span style={{"fontWeigth": "600"}}>{author}</span>
        </Text>
      </Box>
    </Card>
  );
}
