export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "TITLE",
    label: 'Título',
    accessor: "title",
  },
  {
    Header: "TYPE",
    label: 'Tipo',
    accessor: "subtype",
  },
  {
    Header: "ACTIVE",
    label: 'Status',
    accessor: "active",
  },
  {
    Header: "ANSWERS",
    label: 'Qtd. respostas',
    accessor: "qty_answers",
  },
  {
    Header: "DATE",
    label: 'Data',
    accessor: "date",
  },
  {
    Header: "ACTION",
    label: 'Ações',
    accessor: "form_id",
  },
];
