import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Progress,
    Text,
    useColorModeValue,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Select,
    Textarea,
    Image,
  } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";

import avatarProfile from 'assets/img/avatars/avatar.png';

export default function ModalUpdateStatus({ userId, programId, modalIsOpen, setModalIsOpen, refreshUsers }) {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [user, setUser] = useState([]);

  useEffect(() => {
    const getUserProfileByUserId = async (userId, programId) => {
      try {   
        if (loading) {
          return false;
        }

        setLoading(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/userStatus/${userId}/${programId}`);
        const { userData } = data;

        setUser(userData[0]);
        setLoading(false);
      } catch (error) {
        console.log(error);
  
        setLoading(false);
      }
    }

    getUserProfileByUserId(userId, programId);
  }, []);

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

    if (name == 'status' && (value == 'em_formacao' || value == 'formado')) {
      let userData = user;
      userData.reason_renunciation = null;
      
      setUser(userData);
    }
    
    if (name == 'current_situation' && (value == 'banco_talentos' || value == 'contratado_indireto')) {
      let userData = user;
      userData.company_id = null;
      
      setUser(userData);
    }

		setUser({...user, [name]: value});
	};

  const handleUpdateStatus = async (ev) => {
    ev.preventDefault();

    try {      
      let userData = user;
      const { status, reason_renunciation, current_situation, company_id } = userData;
            
      const validationEmptyFields = user?.status && user?.current_situation;

      if (!validationEmptyFields) {
        throw 'EMPTY_FIELDS';
      }
      
      if (loadingSubmit) {
        return false;
      }

      setLoadingSubmit(true);

      await axios.put(`${API_SERVER}dashboard/userStatus`, { 
        status: status,
        reason_renunciation: reason_renunciation,
        current_situation: current_situation,
        company_id: company_id,
        programId: programId,
        userId: userId,
       });

      refreshUsers(true);
      setModalIsOpen(false);
      setLoadingSubmit(false);
    } catch (error) {
      refreshUsers(true);
      setLoadingSubmit(false);
    }
  }

  const isNotEmptyFields = user?.status && user?.current_situation;

  return (
    <Modal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
      >
      <form onSubmit={handleUpdateStatus}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar situação</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {/* {formModalError && <Text mb='15px' color='red.600'>Data de fim deve ser maior que data de início</Text>} */}

          {loading ?
            <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
              <SimpleGrid
                mb='20px'
                columns={{ sm: 1, md: 1 }}
                spacing={{ base: "20px", xl: "20px" }}> 
                  <Flex mx='auto' mt='10px' align='center' direction='column'>
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loading} 
                      size={60} 
                    />
                  </Flex>
                </SimpleGrid>
            </Box>
          : null}

          {!loading ?
          <>
          <SimpleGrid
            mb='20px'
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}> 
              <Flex mx='auto' mt='10px' align='center' direction='column'>
                <Image
                  style={{
                    "border": "2px solid #ccc"
                  }}
                  borderRadius='full'
                  boxSize='90px'
                  mb='15px'
                  src={user?.picture || avatarProfile} 
                  alt="Photo profile" 
                />
                <Text 
                  fontSize={'lg'}
                  fontWeight={'600'}
                  style={{
                    "textAlign": "center",
                    "textTransform": "capitalize",
                  }}>
                  {user?.name}
                </Text>
                
                <Text 
                  fontSize={'md'}
                  fontWeight={'normal'}
                  style={{
                    "textAlign": "center",
                    "color": user?.last_updated_at ? "rgb(65, 105, 225)" : "#666666"
                  }}>
                  {user?.last_updated_at ? <><b>Atualizado</b>: {user?.last_updated_at}</> : 'Sem dados de atualização'}
                </Text>
              </Flex>
            </SimpleGrid>

          <FormControl mt={4}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={user?.status || ''}
              onChange={handleChange}
              required={true}
            >
              <option value="">Selecione</option>
              <option value="em_formacao">Em formação</option>
              <option value="fora_programa">Desistente</option>
              <option value="desligado">Desligado</option>
              <option value="formado">Formado</option>
            </Select>
          </FormControl>

          {user?.status == 'fora_programa' || user?.status == 'desligado' ?
            <FormControl mt={4}>
              <FormLabel>Motivo {user?.status == 'fora_programa' ? 'da desistência' : 'do desligamento'}</FormLabel>
              <Textarea 
                name="reason_renunciation"
                value={user?.reason_renunciation || ''}
                required={true}
                onChange={handleChange}
              >  
              </Textarea>
            </FormControl>
          : null}

          <FormControl mt={4}>
            <FormLabel>Situação</FormLabel>
            <Select
              name="current_situation"
              value={user?.current_situation || ''}
              onChange={handleChange}
              required={true}
            >
              <option value="">Selecione</option>
              <option value="indisponivel">Indisponível</option>
              <option value="banco_talentos">Banco de Talentos</option>
              <option value="indicado">Indicado</option>
              <option value="contratado">Contratado Direto</option>
              <option value="contratado_headhunting">Contratado Head Hunting</option>
              <option value="contratado_indireto">JobOut</option>
            </Select>
          </FormControl>

          {(user?.current_situation == 'indicado' || user?.current_situation == 'contratado' || user?.current_situation == 'contratado_headhunting') &&
            <FormControl mt={4}>
              <FormLabel>Empresa</FormLabel>
              <Select
                name="company_id"
                value={user?.company_id || user?.indicated_company_id}
                onChange={handleChange}
              >
                <option value="">Selecione</option>
                {programId == 1 && (<option value={3}>Poli</option>)}
                {programId == 1 && (<option value={2}>Trinus</option>)}
                {programId == 1 && (<option value={1}>NectarCRM</option>)}
                {programId == 1 && (<option value={4}>Auvo</option>)}
                {programId == 2 && (<option value={5}>MXM</option>)}
                {programId == 2 && (<option value={6}>eSolution</option>)}
                {programId == 2 && (<option value={7}>GenPag</option>)}
                <option value={8}>Zeeps</option>
                <option value={9}>Curta Mais</option>
              </Select>
            </FormControl>
          }
          </>
          : null}
        </ModalBody>

        {!loading ?
          <ModalFooter>
            <Button 
              type="submit"
              onClick={handleUpdateStatus}
              colorScheme='blue' mr={3}
              disabled={!isNotEmptyFields}
            >
              {loadingSubmit ? 'Salvando...' : 'Salvar'}
            </Button>
            <Button onClick={() => setModalIsOpen(false)}>Cancelar</Button>
          </ModalFooter>
        : null}
      </ModalContent>
      </form>
    </Modal>
    )
}