import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Progress,
  Text,
  useColorModeValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";

import { API_SERVER } from "config/constant";
import { FaPlusCircle, FaSearch, FaUsers } from "react-icons/fa";

export default function ModalAddClassLeader({ programId, modalIsOpen, setModalIsOpen, refreshClassLeaders }) {
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [statusSearch, setStatusSearch] = useState(false);
  const [loadingAddLeader, setLoadingAddLeader] = useState(false);
  const [formModalError, setFormModalError] = useState(false);
  const [messageAddClassLeader, setMessageAddClassLeader] = useState(false);
  const [classLeaderSelected, setClassLeaderSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [leaders, setLeaders] = useState([]);

  const handleSearchByName = async (ev) => {
    ev.preventDefault();

    if (search?.length > 0) {
      try {      
        if (loadingSearch) {
          return false;
        }

        setMessageAddClassLeader(false);
        setLoadingSearch(true);

        const { data } = await axios.get(`${API_SERVER}dashboard/users/${programId}/${search}`);
        const { users } = data;
        
        setLeaders(users);
        users?.length == 0 ? setStatusSearch(true) : setStatusSearch(false);
        setLoadingSearch(false);
      } catch (error) {
        console.log(error);

        setLoading(false);
        setStatusSearch(false);
      }
    } else {
      setStatusSearch(false);
    }
	};

  const addClassLeader = async (userId) => {
    try {      
      setClassLeaderSelected(userId);

      if (loadingAddLeader) {
        return false;
      }

      setLoadingAddLeader(true);

      await axios.post(`${API_SERVER}dashboard/classLeaders`, {
        userId: userId,
        programId: programId,
      });

      refreshClassLeaders(true);

      setLeaders([]);
      setSearch('');
      setLoadingSearch(false);
      setMessageAddClassLeader(true);
      setLoadingAddLeader(false);
      setModalIsOpen(false);
    } catch (error) {
      console.log(error);

      setMessageAddClassLeader(false);
      setLoadingAddLeader(false);
      setModalIsOpen(false);
    }
  }

    return (
        <Modal
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
        >
        <form onSubmit={handleSearchByName}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cadastrar novo monitor</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {!loadingSearch && messageAddClassLeader && <Text mb='15px' color='green.600'>Monitor adicionado com sucesso!</Text>}

            <SimpleGrid columns='2' gap='20px' mb='20px'>
              <FormControl>
                <Input 
                  type="text"
                  name="name"
                  value={search || ''}
                  style={{"minWidth": "330px"}}
                  placeholder="Digite o nome do aluno"
                  onChange={(ev) => setSearch(ev.target.value)}
                  required={true}
                />
              </FormControl>
              <FormControl 
                style={{
                  "display": "flex",
                  "justifyContent": "flex-end"
                }}>
                <Button 
                  type="button"
                  onClick={handleSearchByName}
                  colorScheme='blue' mr={3}
                  disabled={!search || loadingSearch}
                >
                  <FaSearch />
                </Button>
              </FormControl>
            </SimpleGrid>

            {!loadingSearch ?
              <>
              <Text
                style={{
                  "fontSize": "17px",
                  "fontWeight": "600",
                  "marginLeft": "8px",
                  "marginTop": "15px",
                  "marginBottom": "0px",
                }}
              >
                Resultados da busca
              </Text>
              <hr style={{"margin": "10px 0px 15px 0px"}} />
              {leaders?.length == 0 && <Text style={{"marginLeft": "8px", "marginBottom": "15px"}}>Nenhum resultado encontrado.</Text>}
              </>
            : null}

            {loadingSearch ?
              <Box pt={{ base: "0px", md: "0px", xl: "0px" }}>
                <SimpleGrid
                  mt='15px'
                  mb='20px'
                  columns={{ sm: 1, md: 1 }}
                  spacing={{ base: "20px", xl: "20px" }}> 
                    <Flex mx='auto' mt='10px' align='center' direction='column'>
                      <ClipLoader 
                        color={'#2759a5'} 
                        loading={loadingSearch} 
                        size={60} 
                      />
                    </Flex>
                  </SimpleGrid>
              </Box>
            : null}

            
            {!loadingSearch && leaders?.map(({ user_id, name, is_leader }) =>
              <div 
                style={{"display": "flex",
                  "flexDirection": "row",
                  "justifyContent": "space-between",
                  "alignItems": "center",
                  "marginTop": "10px",
                  "padding": "10px 15px",
                  "border": "1px solid #2759A5",
                  "borderRadius": "100px",
                }}
              >
                <Text
                  style={{"color": "#2759A5",
                    "fontWeight": "600"
                  }}
                >
                  {name}
                </Text>
                {!is_leader ? (
                  loadingAddLeader && classLeaderSelected == user_id ? 
                    <ClipLoader 
                      color={'#2759a5'} 
                      loading={loadingAddLeader} 
                      size={20} 
                    />
                  : 
                  <Link 
                    onClick={() => addClassLeader(user_id)}
                  >
                  <FaPlusCircle 
                    color='#009000' 
                    size={20}
                  />
                </Link>
                )
                : <FaUsers 
                    color='#2759A5' 
                    size={20}
                  />
                }
              </div>
            )}

            {!loadingSearch && statusSearch && search?.length > 0 && leaders?.length == 0 ?
              <p>Nenhum resultado encontrado.</p>
            : null}
          </ModalBody>

          <ModalFooter>
            <Button onClick={() => setModalIsOpen(false)}>Fechar</Button>
          </ModalFooter>
        </ModalContent>
        </form>
      </Modal>
    )
}