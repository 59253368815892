// Chakra imports
import { SimpleGrid, Text, useColorModeValue, FormControl, FormLabel, Input } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Information from "views/admin/profile/components/Information";

// Assets
export default function Education(props) {
  const { 
    schooling,
    formation,
    educationalInstitution,
    previousKnowledge,
    isEdit, 
    setIsEdit,
    handleChange,
    ...rest 
  } = props;

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Educação
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        As we live, our hearts turn colder. Cause pain is what we go through as
        we become older. We get insulted by others, lose trust for those others.
        We get back stabbed by friends. It becomes harder for us to give others
        a hand. We get our heart broken by people we love, even that we give
        them all...
      </Text> */}
      <SimpleGrid columns='2' gap='20px'>
          <Information
            boxShadow={cardShadow}
            title='Escolaridade'
            value={schooling?.length > 0 ? schooling : null}
          />
          <Information
            style={{"textTransform": "capitalize"}}
            boxShadow={cardShadow}
            title='Formação'
            value={formation}
          />
          <Information
            boxShadow={cardShadow}
            title='Instituição de Ensino'
            value={educationalInstitution}
          />
          <Information
            boxShadow={cardShadow}
            title='Conhecimento prévio'
            value={previousKnowledge?.length > 0 ? previousKnowledge : null}
          />
        {/* {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Escolaridade'
            value={schooling}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Escolaridade</FormLabel>
            <Input
              type={'text'}
              name={'schooling'}
              value={schooling || ''}
              onChange={handleChange}
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        }
        {!isEdit ? 
          <Information
            style={{"textTransform": "capitalize"}}
            boxShadow={cardShadow}
            title='Formação'
            value={formation}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Formação</FormLabel>
            <Input
              type={'text'}
              name={'formation'}
              value={formation || ''}
              onChange={handleChange}
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        }
        {!isEdit ? 
          <Information
            boxShadow={cardShadow}
            title='Conhecimento prévio'
            value={previousKnowledge}
          />
        :
          <FormControl>
            <FormLabel mt={4} mb={0} ml={2}>Conhecimento prévio</FormLabel>
            <Input
              type={'text'}
              name={'previous_knowledge'}
              value={previousKnowledge || ''}
              onChange={handleChange}
              required={true}
              fontWeight='500'
              variant='filled'
              style={{"border": "1px solid rgb(39, 89, 165)"}}
              mt={3}
              h='44px'
              maxh='44px'
            />
          </FormControl>
        } */}
      </SimpleGrid>
    </Card>
  );
}
