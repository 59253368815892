
const initialState = 1;

export default function currentPageReducer(state = initialState, action){
  switch (action.type) {
    case 'SET_CURRENT_PAGE':
      return action.payload;
    default:
      return state;
  }
};