// Chakra imports
import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Flex, Text, useColorModeValue, 
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel, 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tooltip,
  GridItem,
  SimpleGrid,
  Button} from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation } from "react-router-dom";
import Select, { StylesConfig } from 'react-select';
import { useReactToPrint } from 'react-to-print';
import chroma from "chroma-js";
import axios from "axios";

// Custom components
import Banner from "views/admin/profile-pdf/components/Banner";
import General from "views/admin/profile-pdf/components/General";
import Education from "views/admin/profile-pdf/components/Education";
import SelectionProcess from "views/admin/profile-pdf/components/SelectionProcess";
import Notifications from "views/admin/profile-pdf/components/Notifications";
import Projects from "views/admin/profile-pdf/components/Projects";
import Storage from "views/admin/profile-pdf/components/Storage";
import Badge from "views/admin/profile-pdf/components/Badge";
import Situation from "views/admin/profile-pdf/components/Situation";
import Score from "views/admin/profile-pdf/components/Score";
import GeneratePDF from "./components/GeneratePDF";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";

import { API_SERVER } from "config/constant";
import Timeline from "./components/Timeline";
import Card from "components/card/Card";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import { FaCheck, FaEdit, FaFilePdf } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";

export default function ProfilePdf({ userId, programId }) {
  // const hash = window.location.hash;
  // const id = hash.split('/pdf/');
  // const userId = id[1] ? id[1]?.split('/')[0] : 0;
  // const programId = id[1] ? id[1]?.split('/')[1] : 0;

  const componentRef = useRef();
  const [feedbacksBorder, setFeedbacksBorder] = useState(true);
  const [loadPdf, setLoadPdf] = useState(false);
  const [isEditFeedback, setIsEditFeedback] = useState(false);
  const [saveLoadFeedbacks, setSaveLoadFeedbacks] = useState(false);

  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const [loading, setLoading] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingInterviews, setLoadingInterviews] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingTimeline, setLoadingTimeline] = useState(false);
  const [user, setUser] = useState([]);
  const [rankingPosition, setRankingPosition] = useState(0);
  const [userProjects, setUserProjects] = useState([]);
  const [userTimeline, setUserTimeline] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [userCulturalFitTags, setUserCulturalFitTags] = useState([]);
  const [userCompaniesMatch, setUserCompaniesMatch] = useState([]);
  const [userCompanyFitTags, setUserCompanyFitTags] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [presenceTotal, setPresenceTotal] = useState(0);
  const [isEditAll, setIsEditAll] = useState(false);

  const dataUser = localStorage.getItem('user');
  const { dashboardUserId } = JSON.parse(dataUser);

  const colourOptions = [
    { value: 'autogerenciavel', label: 'AUTOGERENCIÁVEL', color: '#00B8D9' },
    { value: 'comunicativo', label: 'COMUNICATIVO', color: '#0052CC' },
    { value: 'metodico', label: 'METÓDICO', color: '#5243AA' },
    { value: 'planejador', label: 'PLANEJADOR', color: '#FF5630' },
    { value: 'executor', label: 'EXECUTOR', color: '#FF8B00' },
    { value: 'analista', label: 'ANALISTA', color: '#FFC400' },
    { value: 'lider', label: 'LÍDER', color: '#36B37E' },
  ];

  const companiesOptions = [
    { value: 1, label: 'NECTAR CRM', color: '#00B8D9' },
    { value: 2, label: 'TRINUS', color: '#0052CC' },
    { value: 3, label: 'POLI', color: '#5243AA' },
    { value: 4, label: 'AUVO', color: '#FF5630' },
    { value: 5, label: 'MXM', color: '#FF8B00' },
    { value: 6, label: 'E-SOLUTION', color: '#FFC400' },
    { value: 7, label: 'GENPAG', color: '#36B37E' },
  ];

  useEffect(() => {
    const getUser = async (userId) => {
      if (loading) { 
        return false;
      }

      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/user/${userId}/${programId}`);

      const { user, position, projects, timeline, culturalFitTags, companiesMatchStudent, companyFitTags, presenceTotal } = data;

      setRankingPosition(position);
      setUser(user[0]);
      setUserProjects(projects);
      setUserTimeline(timeline);
      setUserCulturalFitTags(culturalFitTags);
      setUserCompaniesMatch(companiesMatchStudent);
      setUserCompanyFitTags(companyFitTags);
      setPresenceTotal(presenceTotal);
      setLoading(false);
    }

    if (userId > 0) {
      getUser(userId);
    }
  }, [])

  useEffect(() => {
    const getHardSkills = async (userId) => {
      // setLoadingHardSkills(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/usersHardSkillsByUserId/${userId}`);

      const { userHardSkills } = data;
      // alert(JSON.stringify(userHardSkills));

      setHardSkills(userHardSkills);
      // setLoadingHardSkills(false);
    }

    getHardSkills(userId);
  }, [])

  const dateStart = new Date(user?.program_start_date);
  const dateEnd = new Date(user?.program_end_date);
  const dateNow = new Date();

  var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  var Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  var Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;

  const handleChange = (ev) => {
		const name = ev.target.name;
		const value = ev.target.value;

    if (user?.status != 'contratado') {
      let userData = user;
      userData.current_situation = null;
      userData.company_id = null;

      setUser(userData);
    } 
    
    if (user?.status == 'contratado' || user?.company_id > 0) {
      let userData = user;
      userData.current_situation = 'contratado';
      userData.company_id = user?.company_id;

      setUser(userData);
    }

		setUser({...user, [name]: value});
	};

  const handleEdit = () => {
    if (percentUsed >= 100) {
      setIsEditAll(false);
    } else {
      setIsEditAll(true);
    }

    setIsEdit(!isEdit);
  }

  const handleUpdateProfile = async () => {
    try {
      let userData = user;
      userData.programId = programId;
      userData.cpf = user?.cpf?.replace(/[^0-9]+/g,'');
      userData.cell_phone = user?.cell_phone?.replace(/[^0-9]+/g,'');

      const { name, email, cpf, cell_phone, gender, age, city, uf, status, company_id } = userData;
            
      if (!programId || !userId || !name || !email || !cpf || !cell_phone || !gender || !age || !city || !uf || !status) {
        throw 'EMPTY_FIELDS';
      }

      if (status == 'contratado' && !company_id) {
        throw 'EMPTY_FIELDS';
      }

      if (loading) {
        return false;
      }

      setLoading(true);

      const { data } = await axios.post(`${API_SERVER}dashboard/user/${userId}`, userData);

      if (data?.dataCompany[0]) {
        const { companyId, companyName, companyLogo, companyHiredIn } = data?.dataCompany[0];
        
        userData.company_id = companyId;
        userData.company_name = companyName;
        userData.company_logo = companyLogo;
        userData.company_hired_in = companyHiredIn;

        setUser(userData);
      }

      setIsEdit(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error == 'EMPTY_FIELDS') {
        alert('Preencha todos os campos!')
      }

      setLoading(false);
    }
  }
  
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginBottom: '20px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  const addTags = async (choices) => {
    try {
      if (loadingTags) {
        return false;
      }

      setLoadingTags(true);
      choices ? setUserCulturalFitTags(choices) : setUserCulturalFitTags([]);

      const formData = {
        userId: userId,
        programId: programId,
        choices: choices
      }

      const { data } = await axios.post(`${API_SERVER}dashboard/user/culturalFit/addTag`, formData);

      data?.companiesMatch ? setUserCompaniesMatch(data?.companiesMatch) : setUserCompaniesMatch([]);
      setLoadingTags(false);
    } catch (error) {
      setLoadingTags(false);
    }
  }

  const addCompanies = async (choices) => {
    try {
      if (loadingCompanies) {
        return false;
      }

      setLoadingCompanies(true);

      const formData = {
        userId: userId,
        programId: programId,
        choices: choices
      }

      await axios.post(`${API_SERVER}dashboard/user/companyFit/addTag`, formData);

      setLoadingCompanies(false);
    } catch (error) {
      setLoadingCompanies(false);
    }
  }

  const getNationality = (nationality) => {
    if (nationality == 'brasileiro') {
      return 'Brasileiro(a)';
    }

    if (nationality == 'venezuelano') {
      return 'Venezuelano(a)';
    }

    if (nationality == 'argentino') {
      return 'Argentino(a)';
    }

    if (nationality == 'chileno') {
      return 'Chileno(a)';
    }

    if (nationality == 'cubano') {
      return 'Cubano(a)';
    }

    if (nationality == 'boliviano') {
      return 'Boliviano(a)';
    }

    return nationality;
  }

  const getYouLiveWith = (type) => {
    if (type == 'alone') {
      return 'Sozinho(a)';
    }

    if (type == 'spouse') {
      return 'Cônjuge';
    }

    if (type == 'lover') {
      return 'Namorado(a)';
    }

    if (type == 'parents') {
      return 'Pais';
    }

    if (type == 'grandparents') {
      return 'Avós';
    }

    if (type == 'sons') {
      return 'Filhos';
    }

    if (type == 'friends') {
      return 'Amigos';
    }

    if (type == 'relatives') {
      return 'Parentes';
    }

    return type;
  }

  const getLevelProgramming = (level) => {
    if (level == 'none') {
      return 'Nenhum';
    }

    if (level == 'basic') {
      return 'Básico (já fiz cursos introdutórios)';
    }

    if (level == 'intermediary') {
      return 'Intermediário (já estudei na faculdade ou fiz cursos mais avançados)';
    }

    if (level == 'advanced') {
      return 'Avançado (já trabalho com programação)';
    }

    return level;
  }

  const getNameByKey = (key) => {
    if (key == 'dotnet') {
      return '.NET';
    }

    if (key == 'dotnetcore') {
      return '.NET Core';
    }

    if (key == 'csharp') {
      return 'C#';
    }

    if (key == 'java') {
      return 'Java';
    }

    if (key == 'php') {
      return 'PHP';
    }

    if (key == 'javascript') {
      return 'JavaScript';
    }

    if (key == 'typescript') {
      return 'TypeScript';
    }

    if (key == 'nodejs') {
      return 'NodeJS';
    }

    if (key == 'graphql') {
      return 'GraphQL';
    }

    if (key == 'nestjs') {
      return 'NestJS';
    }

    if (key == 'reactjs') {
      return 'ReactJS';
    }

    if (key == 'vuejs') {
      return 'Vue.js';
    }

    if (key == 'angularjs') {
      return 'AngularJS';
    }

    if (key == 'angular') {
      return 'Angular';
    }

    if (key == 'layout-responsivo') {
      return 'Layout Responsivo';
    }

    if (key == 'html') {
      return 'HTML';
    }

    if (key == 'css') {
      return 'CSS';
    }

    if (key == 'springboot') {
      return 'Spring Boot';
    }

    if (key == 'laravel') {
      return 'Laravel';
    }

    if (key == 'rubyonrails') {
      return 'Ruby on Rails';
    }

    if (key == 'kotlin') {
      return 'Kotlin';
    }

    if (key == 'rust') {
      return 'Rust';
    }

    if (key == 'go') {
      return 'GO';
    }

    if (key == 'python') {
      return 'Python';
    }

    if (key == 'aws') {
      return 'AWS';
    }

    if (key == 'azure') {
      return 'Azure';
    }

    if (key == 'git') {
      return 'GIT';
    }

    if (key == 'mysql') {
      return 'MySQL';
    }

    if (key == 'couchbase') {
      return 'Couchbase';
    }

    if (key == 'oracle') {
      return 'Oracle';
    }

    if (key == 'postgres') {
      return 'PostgreSQL';
    }

    if (key == 'mongodb') {
      return 'MongoDB';
    }

    if (key == 'sqlserver') {
      return 'SQL Server';
    }

    if (key == 'docker') {
      return 'Docker';
    }

    if (key == 'kubernetes') {
      return 'Kubernetes';
    }

    if (key == 'jasmine') {
      return 'Jasmine';
    }

    if (key == 'junit') {
      return 'JUnit';
    }

    if (key == 'jest') {
      return 'Jest';
    }

    if (key == 'xunit') {
      return 'XUnit.net';
    }

    if (key == 'phpunit') {
      return 'PHPUnit';
    }

    if (key == 'nunit') {
      return 'NUnit';
    }

    if (key == 'sonar') {
      return 'Sonar';
    }

    if (key == 'mocha') {
      return 'Mocha';
    }

    if (key == 'tdd') {
      return 'TDD';
    }

    if (key == 'ansible') {
      return 'Ansible';
    }

    if (key == 'gradle') {
      return 'Gradle';
    }

    if (key == 'aws_autoscale') {
      return 'AWS Auto Scaling';
    }

    if (key == 'cicd') {
      return 'CI/CD';
    }

    if (key == 'google-cloud') {
      return 'Google Cloud Platform (GCP)';
    }

    if (key == 'reactnative-testing-library') {
      return 'React Native Testing Library';
    }

    if (key == 'swift') {
      return 'Swift';
    }

    if (key == 'flutter') {
      return 'Flutter';
    }

    if (key == 'objective-c') {
      return 'Objective-C';
    }

    if (key == 'xamarin') {
      return 'Xamarin';
    }

    if (key == 'react-native') {
      return 'React Native';
    }

    return key;
  }

  const getLevelProgrammingHardSkills = (key) => {
    if (key == 'theoretical') {
      return 'teórico';
    }

    if (key == 'beginner') {
      return 'iniciante';
    }

    if (key == 'intermediate') {
      return 'intermediário';
    }

    if (key == 'advanced') {
      return 'avançado';
    }

    return key;
  }

  const handlePrint = useReactToPrint({
    content: () => {
      const component = componentRef.current;

      return component;
    },
    documentTitle: `${user?.name} - Ficha do Candidato`
  });

  const handleSaveFeedbacks = async () => {
    setSaveLoadFeedbacks(true);

    setIsEditFeedback(true);

    const userFeedback = {
      userId: user?.user_id,
      feedbackHardSkills: user?.feedback_hardskills || '',
      feedbackSoftSkills: user?.feedback_softskills || '',
      programId: programId,
    };
    try {
      await axios.post(`${API_SERVER}dashboard/user/feedback/update`, userFeedback);

      setIsEditFeedback(false);
      setSaveLoadFeedbacks(false);
    } catch (error) {
      setIsEditFeedback(false);
      setSaveLoadFeedbacks(false);

      console.error(error);
    }

    setSaveLoadFeedbacks(false);
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex mx='auto' mt='80px' align='center' direction='column'>
          <ClipLoader 
            color={'#2759a5'} 
            loading={loading} 
            size={60} 
          />
        </Flex>
      </Box>
    )
  }

  return (
    <>
    <Box pt={{ base: "0px", md: "0px", xl: "0px" }} ref={componentRef}>
        <SimpleGrid columns='1' gap='20px'>
        <Banner
          isEdit={isEdit}
          setIsEdit={handleEdit}
          handleChange={handleChange}
          handleUpdateProfile={handleUpdateProfile}
          banner={banner}
          userId={userId}
          status={user?.status}
          currentSituation={user?.current_situation}
          avatar={user?.picture}
          name={user?.name}
          course={user?.course}
          isMonitor={user?.is_monitor}
          githubUrl={user?.github_url}
          linkedinUrl={user?.linkedin_url}
        /> 
        </SimpleGrid>
        
      <General
        minH='290px'
        pe='10px'
        mb='20px'
        isEdit={isEdit}
        isEditAll={isEditAll}
        setIsEdit={handleEdit}
        handleChange={handleChange}
        programId={programId}
        userData={user}
        name={user?.name}
        cpf={user?.cpf}
        email={user?.email}
        cellphone={user?.cell_phone}
        gender={user?.gender}
        age={user?.age}
        city={user?.city}
        uf={user?.uf}
        status={user?.status}
        urlTestPersonality={user?.url_test_personality}
        currentSituation={user?.current_situation}
        companyId={user?.company_id}
        active={user?.active}
        createdAt={user?.date}
        presenceTotal={`${presenceTotal > 47 ? 47 : presenceTotal}/47`}
        schooling={user?.schooling}
        formation={user?.formation}
        previousKnowledge={user?.previous_knowledge}
        educationalInstitution={user?.educational_institution}
        userProjects={userProjects}
        hardSkills={hardSkills}
        feedbackHardSkills={user?.feedback_hardskills}
        feedbackSoftSkills={user?.feedback_softskills}
        isEditFeedback={isEditFeedback}
      />
    </Box>
      

    <Flex w='100%' justify='center' mt='8px'>
      {!isEditFeedback ? (
        <>
          <Button colorScheme='red' onClick={() => {setIsEditFeedback(!isEditFeedback)}} mr='5px'>
            <FaEdit style={{marginRight: '5px'}}/>
            Editar
          </Button>
          <Button colorScheme='green' onClick={handlePrint}>
            <FaFilePdf style={{marginRight: '5px'}}/>
            Gerar PDF
          </Button>
        </>
      ) : (
        <>
          {!saveLoadFeedbacks && (
            <Button disabled={saveLoadFeedbacks} colorScheme='gray' onClick={() => {setIsEditFeedback(false)}} mr='5px'>
              <FcCancel style={{marginRight: '5px'}}/>
              Cancelar
            </Button>
          )}
          <Button disabled={saveLoadFeedbacks} colorScheme='green' onClick={handleSaveFeedbacks} mr='0px'>
            <FaCheck style={{marginRight: '5px'}}/>
            {!saveLoadFeedbacks ? 'Salvar': 'Salvando...'}
          </Button>
        </>
      )}
    </Flex>
    </>
  );
}
