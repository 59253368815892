import React, { useEffect, useState }  from "react";
import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

import DevelopmentTable from "views/admin/employability/components/DevelopmentTable";
import CheckTable from "views/admin/employability/components/CheckTable";
import ColumnsTable from "views/admin/employability/components/ColumnsTable";
import ComplexTable from "views/admin/employability/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/employability/variables/columnsData";
import tableDataDevelopment from "views/admin/employability/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/employability/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/employability/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/employability/variables/tableDataComplex.json";

import { API_SERVER } from "config/constant";

export default function Employability() {
  const hash = window.location.hash;
  const id = hash.split('/status/');
  const programIdStorage = localStorage.getItem('programId');
  const programId = id[1] ? id[1] : (programIdStorage ? programIdStorage : 0);

  const INITIAL_FILTERS = {
    name: '',
    email: '',
    gender: 'all',
    classroomId: 'all',
    status: 'all',
    situation: 'all',
    active: 'all',
  }

  const [loading, setLoading] = useState(false);
  const [loadUsers, setLoadUsers] = useState(true);
  const [loadUsersByFilter, setLoadUsersByFilter] = useState(false);

  const [programIdFilter, setProgramIdFilter] = useState(programId);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  
  const [users, setUsers] = useState([]);
  const [dataCourse, setDataCourse] = useState({
    total: 0,
    studentsBankTalents: 0,
    studentsIndicated: 0,
    studentsHiredDirect: 0,
    studentsHiredHunting: 0,
    studentsHiredIndirect: 0,
  });

  useEffect(() => {    
    const getUsers = async () => {
      if (loading) {
        return false;
      }
      
      setLoading(true);

      const { data } = await axios.get(`${API_SERVER}dashboard/employabilityUsers/${programId}`, {
        params: {
          name: filters.name,
          email: filters.email,
          gender: filters.gender,
          classroomId: filters.classroomId,
          status: filters.status,
          situation: filters.situation,
          active: filters.active,
          programId: programIdFilter,
        }
      });

      const { users, total, studentsBankTalents, studentsIndicated, studentsHiredDirect, studentsHiredHunting, studentsHiredIndirect } = data?.usersData;

      setDataCourse({
        total: total,
        studentsBankTalents: studentsBankTalents,
        studentsIndicated: studentsIndicated,
        studentsHiredDirect: studentsHiredDirect,
        studentsHiredHunting: studentsHiredHunting,
        studentsHiredIndirect: studentsHiredIndirect,
      })
      setUsers(users);
      setLoading(false);
      setLoadUsers(false);
    }

    if (programIdFilter > 0 && loadUsers) {
      getUsers();
    }
  }, [programIdFilter, loadUsers])

  const handleChangeProgramId = (ev) => {
    setFilters(INITIAL_FILTERS);
    
    localStorage.setItem('programId', ev.target.value);

    setProgramIdFilter(ev.target.value);
    setLoadUsers(true);

    window.location.href = '/#/admin/status';
  }

  const handleChangeFilter = (ev) => {
    const name = ev.target.name;
    
    setFilters({ ...filters, [name]: ev.target.value });
  }

  const handleSubmitFilter = (ev) => {
    ev.preventDefault();

    const getUsers = async () => {
      const { data } = await axios.get(`${API_SERVER}dashboard/employabilityUsers/${programId}`, {
        params: {
          name: filters.name,
          email: filters.email,
          gender: filters.gender,
          classroomId: filters.classroomId,
          status: filters.status,
          situation: filters.situation,
          active: filters.active,
          programId: programIdFilter,
        }
      });

      const { users, total, studentsBankTalents, studentsIndicated, studentsHiredDirect, studentsHiredHunting, studentsHiredIndirect } = data?.usersData;

      setDataCourse({
        total: total,
        studentsBankTalents: studentsBankTalents,
        studentsIndicated: studentsIndicated,
        studentsHiredDirect: studentsHiredDirect,
        studentsHiredHunting: studentsHiredHunting,
        studentsHiredIndirect: studentsHiredIndirect,
      })
      setUsers(users);
      setLoadUsersByFilter(false);
    }

    setLoadUsersByFilter(true);
    getUsers();
  }

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}> 
          <Flex mx='auto' mt='80px' align='center' direction='column'>
            <ClipLoader 
              color={'#2759a5'} 
              loading={loading} 
              size={60} 
            />
          </Flex>
        </SimpleGrid>
      </Box>
    )
  }

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}> 
        <ComplexTable
          programId={programIdFilter}
          onChangeProgramId={handleChangeProgramId}
          onChangeFilter={handleChangeFilter}
          submitFilter={handleSubmitFilter}
          setLoadUsers={setLoadUsers}
          clearFilters={() => setFilters(INITIAL_FILTERS)}
          filtersData={filters}
          loadUsersByFilter={loadUsersByFilter}
          columnsData={columnsDataComplex}
          tableData={users}
          dataCourse={dataCourse}
        />
      </SimpleGrid>
    </Box>
  );
}
